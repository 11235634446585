import { AxiosMasterGet } from "../Axios";

export const GET_REPARASI_LIST = "GET_REPARASI_LIST";
export const EDIT_REPARASI = "EDIT_REPARASI";
export const GET_LIST_REPARASI_BARANG = "GET_LIST_REPARASI_BARANG";

export const getReparasiLIst = () => {
  return (dispatch) => {
    AxiosMasterGet("reparasi/get/all")
      .then((res) =>
        dispatch({
          type: GET_REPARASI_LIST,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_REPARASI_LIST,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const editReparasi = (kodeReparasi, deskripsiReparasi) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_REPARASI,
      payload: {
        kodeReparasi: kodeReparasi,
        deskripsiReparasi: deskripsiReparasi,
      },
    });
  };
};

export const getListReparasi = () => {
  const data = JSON.parse(localStorage.getItem("temp_data_reparasi") || "[]");
  return (dispatch) => {
    dispatch({
      type: GET_LIST_REPARASI_BARANG,
      payload: {
        data: data,
      },
    });
  };
};
