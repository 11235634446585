import React, { Component } from "react";
import { connect } from "react-redux";
import {  getKirimStockValid } from "../../actions/kirimStock";
import GlobalTabel from "../../GlobalComponent/GlobalTabel";
import { formatGram } from "../../helper";

class EditKirimStock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listGambar: [],
      columns: [
        {
          dataField: "no_bon",
          text: "Nomor Bon",
        },
        {
          dataField: "tanggal",
          text: "Tanggal",
        },
        {
          dataField: "kode_customer",
          text: "Kode Customer",
        },
        {
          dataField: "kode_kategori",
          text: "Kode Kategori",
        },
        {
          dataField: "total_bruto",
          text: "Total Bruto",
          align: "right",
          formatter: (data) => formatGram(data, 3),
          headerAlign: "right",
        },
        {
          dataField: "total_netto",
          text: "Total Netto",
          align: "right",
          formatter: (data) => formatGram(data),
          headerAlign: "right",
        },
        {
          dataField: "Aksi",
          text: "Aksi",
          csvExport: false,
          formatter: (rowContent, row, index) => {
            return (
              <div className="text-center">
                <button
                  id={"btn_packing_pilih" + index}
                  className="btn btn-warning mr-2"
                  onClick={() => this.props.setEdit(row)}
                  disabled={this.props.onSend}
                >
                  Pilih
                </button>
              </div>
            );
          },
        },
      ],
    };
  }
  componentDidMount() {
    this.props.dispatch(getKirimStockValid());
  }

  render() {
    return (
      <div className="col-lg-12 col-md-12">
        <GlobalTabel
          keyField="no_packing"
          data={this.props.list_kirim_stock_valid}
          columns={this.state.columns}
          textEmpty="Data Timbangan Kosong"
        />
      </div>
    );
  }
}

export default connect((state) => {
  return {
    listPacking: state.provinsi.listPacking,
    onSend: state.provinsi.onSend,
    kodeBarang: state.provinsi.kodeBarang,
    list_kirim_stock_valid: state.kirimStock.list_kirim_stock_valid
  };
})(EditKirimStock);
