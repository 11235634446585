import React, { useEffect } from "react";
import HeaderCard from "../../HeaderCard";
import GlobalTabel from "../../GlobalComponent/GlobalTabel";
import { useDispatch, useSelector } from "react-redux";
import { change, Field, reduxForm } from "redux-form";
import { renderField, renderFieldSelect } from "../../renderField";
import { formatGram, getBon, multipleDeleteLocal } from "../../helper";
import { Dropdown } from "react-bootstrap";
import {
  deleteKirimStockRetur,
  getKirimStockRetur,
  sendKirimStockRetur,
} from "./redux/kirim_stock_retur_action";
import ModalGlobal from "../../DataMaster/Component/ModalMasterData";
import { showModal } from "../../actions/ModalAction";
import FormListRetur from "./component/FormListRetur";

const KirimStockRetur = () => {
  const dispatch = useDispatch();
  const listItemTemp = useSelector(
    (state) => state.kirimStockRetur.listReturTemp
  );
  const columns = [
    {
      dataField: "no_retur",
      text: "No Retur",
      footerAttrs: {
        colSpan: "4",
      },
      footerAlign: "left",
      footer: () => {
        return "Grand Total ";
      },
    },
    {
      dataField: "kode_barang",
      text: "Kode Barang",
    },
    {
      dataField: "kode_bahan",
      text: "Kode Bahan",
    },
    {
      dataField: "kadar_cetak",
      text: "Kadar",
      align: "right",
      headerAlign: "right",
    },
    {
      dataField: "bruto",
      text: "Bruto",
      align: "right",
      headerAlign: "right",
      formatter: (data) => formatGram(data),
      footerAlign: "right",
      footer: () => {
        return formatGram(
          listItemTemp.reduce((a, b) => a + parseFloat(b.bruto), 0)
        );
      },
    },
    // {
    //   dataField: "kadar_jual",
    //   text: "Kadar Beli",
    //   align: "right",
    //   headerAlign: "right",
    //   footerAlign: "right",
    //   footer: () => {
    //     return "";
    //   },
    // },
    {
      dataField: "netto",
      text: "Netto",
      align: "right",
      headerAlign: "right",
      formatter: (data) => formatGram(data),
      footerAlign: "right",
      footer: () => {
        return formatGram(
          listItemTemp.reduce((a, b) => a + parseFloat(b.netto), 0)
        );
      },
    },
    {
      dataField: "Aksi",
      text: "Aksi",
      csvExport: false,
      formatter: (rowContent, rows, rowIndex) => {
        return (
          <div className="justify-content-center row">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <i className="fa fa-ellipsis-v" aria-hidden="true" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    dispatch(deleteKirimStockRetur(rowIndex));
                  }}
                >
                  Hapus Barang <i className="fa fa-trash ml-2" />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    multipleDeleteLocal(["kirimStockReturTemp"]);
    dispatch(getKirimStockRetur());
    dispatch(change("KirimStockRetur", "no_transaksi", "KR-" + getBon()));
  }, [dispatch]);

  return (
    <div className="container-fluid" style={{ color: "black" }}>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <HeaderCard title="Kirim Stock Retur" />
            <div className="card-body">
              <ModalGlobal
                content={<FormListRetur />}
                title={"Transaksi Retur"}
              />
              <div className="col-lg-12 col-md-12 row">
                <div className="col-lg-4">
                  <Field
                    name={"no_transaksi"}
                    label={"Nomor Transaksi"}
                    component={renderField}
                    normalize={(data) => data && data.toUpperCase()}
                    readOnly
                  />
                </div>
                <div className="col-lg-4">
                  <Field
                    name={"tujuan_stock"}
                    label={"Tujuan Stock"}
                    component={renderFieldSelect}
                    options={[
                      {
                        value: "PERHIASAN",
                        name: "PERHIASAN",
                      },
                      {
                        value: "RECYCLE",
                        name: "RECYCLE",
                      },
                    ]}
                  />
                </div>
                <div className="col-lg-4">
                  <Field
                    name={"keterangan"}
                    label={"Keterangan"}
                    component={renderField}
                    normalize={(data) => data && data.toUpperCase()}
                  />
                </div>
                <div className="col-lg-12 row justify-content-end">
                  <div className="col-lg-2">
                    <button
                      className={"btn btn-primary btn-block"}
                      onClick={() => dispatch(showModal())}
                    >
                      Cari Transaksi
                    </button>
                  </div>
                </div>
                <div className={"col-lg-12"}>
                  <GlobalTabel
                    keyField="no_retur"
                    data={listItemTemp}
                    columns={columns}
                    pagination={false}
                  />
                </div>
                <div className={"col-lg-12 row justify-content-end"}>
                  <div className="col-lg-2">
                    <button
                      className={"btn btn-primary btn-block"}
                      onClick={() => dispatch(sendKirimStockRetur())}
                    >
                      Simpan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default reduxForm({
  form: "KirimStockRetur",
})(KirimStockRetur);
