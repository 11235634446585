import Swal from "sweetalert2";
import { AxiosMasterPut } from "./Axios";

export const warning = (pesan) => {
  return new Promise((resolve, reject) => {
    Swal.fire({
      position: "top-right",
      icon: "warning",
      text: pesan,
      timer: 2500,
      showConfirmButton: false,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    })
      .then(() => resolve("Berhasil"))
      .catch(() => reject("Gagal"));
  });
};

export const berhasil = (pesan) => {
  return new Promise((resolve, reject) => {
    Swal.fire({
      position: "top-right",
      icon: "success",
      text: pesan,
      timer: 2500,
      showConfirmButton: false,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    })
      .then(() => resolve("Berhasil"))
      .catch(() => reject("Gagal"));
  });
};

export const gagal = (pesan) => {
  return new Promise((resolve, reject) => {
    Swal.fire({
      position: "top-right",
      icon: "error",
      text: pesan,
      timer: 3500,
      showConfirmButton: false,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    })
      .then(() => resolve("Berhasil"))
      .catch((err) => reject(err));
  });
};

export const reactive = (err, kode, endpoint, data, endpointUpdate) => {
  return new Promise((resolve, reject) => {
    Swal.fire({
      title: "Oopps..",
      text : err?.response?.data || "Terjadi Kesalahan Internal, Silahkan Hubungi Admin",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Re-Activate",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        AxiosMasterPut(endpoint + kode, data)
          .then(() =>
            AxiosMasterPut(endpointUpdate + kode, data)
              .then(() => resolve("Berhasil"))
              .then(() =>
                berhasil("Data Berhasil Dikembalikan").catch((err) =>
                  reject(err)
                )
              )
          )
          .then(() => resolve("Berhasil"))
          .catch((err) => reject(err));
      }
    });
  });
};

export const LogData = (keterangan, data, err) => {
  let tanggal = new Date().toLocaleDateString();
  let log = JSON.parse(localStorage.getItem("LogWebsite")) || [];
  let datanya = {
    tanggal: tanggal,
    keterangan: keterangan,
    data: data,
    error: err,
  };
  log.push(datanya);
  localStorage.setItem("LogWebsite", JSON.stringify(log));
};
