import { change, reset } from "redux-form";
import {
  finishSend,
  isExist,
  isExistSingle,
  progressSend,
} from "../../../actions/LogicAction";
import { getLocal, saveLocal } from "../../../encrypt";
import { hideModal } from "../../../actions/ModalAction";
import {
  findBy,
  formatGram,
  formatRp,
  FormatterNumber,
  getBon,
  getUserID,
  multipleDeleteLocal,
  printInfo,
  purifiedData,
  removeComma,
  setupSelectedValueCodeAndName,
} from "../../../helper";
import { toast } from "react-toastify";
import { AxiosMasterGetWithParams, AxiosMasterPost } from "../../../Axios";
import { ToastAmbil, ToastKirim } from "../../../actions/toast";
import { getToday } from "../../../getDate";
import { getUUID } from "../../../actions/fakturAction";
import PrintNota from "../../../Stocking/Component/PrintNota";

export const GET_DATA_TRANSAKSI_PENJUALAN = "GET_DATA_TRANSAKSI_PENJUALAN";
export const GET_UNVALID_PENJUALAN = "GET_UNVALID_PENJUALAN";
export const SET_EDIT_PENJUALAN = "SET_EDIT_PENJUALAN";
export const SAVE_PREV_NO_PENJUALAN = "SAVE_PREV_NO_PENJUALAN";

export const getListTransaksiPenjualan24K = () => {
  return async (dispatch) => {
    const result = await getLocal("Penjualan24KTemp");
    const totalNW = result
      .map((x) => parseFloat(removeComma(x.netto_disc)))
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    dispatch(change("TransaksiPenjualan24K", "totalNW", formatGram(totalNW)));
    dispatch(change("TransaksiPenjualan24K", "disc_global", 0));
    dispatch(change("TransaksiPenjualan24K", "disc_global_gr", 0));
    dispatch(
      change("TransaksiPenjualan24K", "grand_total_nw_ext", formatGram(totalNW))
    );
    dispatch(
      change("TransaksiPenjualan24K", "grand_total_nw", formatGram(totalNW))
    );
    dispatch({
      type: GET_DATA_TRANSAKSI_PENJUALAN,
      payload: {
        data: await getLocal("Penjualan24KTemp"),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const getUnvalidPenjualan24K = () => {
  return async (dispatch, getState) => {
    const response = await AxiosMasterGetWithParams("penjualan/unvalid", {
      type: "24K",
    });
    try {
      dispatch({
        type: GET_UNVALID_PENJUALAN,
        payload: response.data.filter(
          (x) => x.kode_customer === localStorage.getItem("kodeCustomer")
        ),
      });
    } catch (e) {
      dispatch({
        type: GET_UNVALID_PENJUALAN,
        payload: [],
      });
      dispatch(finishSend());
    }
  };
};

export const saveEditPenjualan24KTemp = (data) => {
  return async (dispatch, getState) => {
    const state = getState();
    const listCustomer = state.provinsi.customer;
    dispatch(change("TransaksiPenjualan24K", "keterangan", "-"));
    dispatch(change("TransaksiPenjualan24K", "no_bon", data.no_bon));
    dispatch(change("TransaksiPenjualan24K", "kode_lokasi", data.kode_lokasi));
    dispatch(change("TransaksiPenjualan24K", "kode_sales", data.kode_sales));
    dispatch(
      change(
        "TransaksiPenjualan24K",
        "kode_customer",
        setupSelectedValueCodeAndName({
          target: data.kode_customer,
          value: "kode_customer",
          value2: "nama_customer",
          keyTarget: "kode_customer",
          source: listCustomer,
        })
      )
    );
    dispatch({
      type: SAVE_PREV_NO_PENJUALAN,
      payload: data.no_penjualan,
    });
    for (let item of data.detail_barang) {
      item.kadar_cetak = String(item.kadar_cetak);
      delete item._id;
      item.bruto = FormatterNumber(item.bruto);
      item.netto = FormatterNumber(item.netto);
      item.gross = FormatterNumber(item.gross);
      item.no =
        data.detail_barang.findIndex(
          (x) => x.kode_barang === item.kode_barang
        ) + 1;
      await isExist({
        target: "kode_barang",
        target2: "kode_bahan",
        name: "Penjualan24KTemp",
        payload: item,
        value: item.kode_barang,
        value2: item.kode_bahan,
      });
      const result = await getLocal("Penjualan24KTemp");
      const totalNW = result
        .map((x) => parseFloat(removeComma(x.netto_disc)))
        .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
      dispatch(change("TransaksiPenjualan24K", "totalNW", formatGram(totalNW)));
      dispatch(change("TransaksiPenjualan24K", "disc_global", 0));
      dispatch(change("TransaksiPenjualan24K", "disc_global_gr", 0));
      dispatch(
        change(
          "TransaksiPenjualan24K",
          "grand_total_nw_ext",
          formatGram(totalNW)
        )
      );
      dispatch(
        change("TransaksiPenjualan24K", "grand_total_nw", formatGram(totalNW))
      );
    }
    dispatch(getListTransaksiPenjualan24K());
    dispatch(hideModal());
    dispatch(finishSend());
  };
};
export const savePenjualan24KTemp = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const selected = state.form.FormTransaksiPenjualan24K.values;
    purifiedData(selected);
    const result = await getLocal("Penjualan24KTemp");
    const payload = {
      no: result.length + 1,
      kode_barang: selected.kodeBarang.split("/")[0],
      kode_bahan: "-",
      kode_barcode: selected.kode_barcode || "-",
      qty: selected.qty,
      plastik: 0,
      kulit: 0,
      gross: FormatterNumber(selected.gross),
      pkg: FormatterNumber(selected.pkg),
      bruto: FormatterNumber(selected.bruto),
      kadar_cetak: selected.kadar,
      kadar_jual: selected.kadarTransaksi,
      netto: FormatterNumber(selected.netto),
      disc_persen: selected.discGlobalPr,
      disc_gram: FormatterNumber(selected.discGlobalGr),
      netto_disc: FormatterNumber(selected.nettoDiscGlobalExt),
      ongkos_rp: selected.ongkos_rp || 0,
      total_harga_jual: selected.total_harga_jual || 0,
    };
    await isExist({
      target: "kode_barang",
      target2: "kode_bahan",
      name: "Penjualan24KTemp",
      payload: payload,
      value: selected.kodeBarang,
      value2: "-",
    });
    const totalNW = result
      .map((x) => parseFloat(removeComma(x.netto_disc)))
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    dispatch(change("TransaksiPenjualan24K", "totalNW", formatGram(totalNW)));
    dispatch(change("TransaksiPenjualan24K", "disc_global", 0));
    dispatch(change("TransaksiPenjualan24K", "disc_global_gr", 0));
    dispatch(
      change("TransaksiPenjualan24K", "grand_total_nw", formatGram(totalNW))
    );
    dispatch(
      change("TransaksiPenjualan24K", "grand_total_nw_ext", formatGram(totalNW))
    );
    dispatch(getListTransaksiPenjualan24K());
    toast.success("Barang Berhasil Ditambahkan");
    dispatch(hideModal());
    dispatch(finishSend());
  };
};

export const savePenjualanEditTemp = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const selected = state.form.FormTransaksiPenjualan24KEdit.values;
    purifiedData(selected);
    const result = await getLocal("Penjualan24KTemp");
    const payload = {
      no: selected.no,
      kode_barang: selected.kodeBarang,
      kode_bahan: "-",
      kode_barcode: selected.kode_barcode || "-",
      qty: selected.qty,
      plastik: 0,
      kulit: 0,
      gross: FormatterNumber(selected.gross),
      pkg: FormatterNumber(selected.pkg),
      bruto: FormatterNumber(selected.bruto),
      kadar_cetak: selected.kadar,
      kadar_jual: selected.kadarTransaksi,
      netto: FormatterNumber(selected.netto),
      disc_persen: selected.discGlobalPr,
      disc_gram: FormatterNumber(selected.discGlobalGr),
      netto_disc: FormatterNumber(selected.nettoDiscGlobalExt),
      ongkos_rp: selected.ongkos_rp || 0,
      total_harga_jual: selected.total_harga_jual || 0,
    };
    await isExistSingle({
      target: "no",
      name: "Penjualan24KTemp",
      payload: payload,
      value: selected.no,
      replace: true,
    });
    const totalNW = result
      .map((x) => parseFloat(removeComma(x.netto_disc)))
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    dispatch(change("TransaksiPenjualan24K", "totalNW", formatGram(totalNW)));
    dispatch(change("TransaksiPenjualan24K", "disc_global", 0));
    dispatch(change("TransaksiPenjualan24K", "disc_global_gr", 0));
    dispatch(
      change("TransaksiPenjualan24K", "grand_total_nw_ext", formatGram(totalNW))
    );
    dispatch(
      change("TransaksiPenjualan24K", "grand_total_nw", formatGram(totalNW))
    );
    dispatch(getListTransaksiPenjualan24K());
    toast.success("Barang Berhasil Dirubah");
    dispatch(hideModal());
    dispatch(finishSend());
  };
};

export const deleteLocalPenjualan24KTemp = (nama, index, getFunction) => {
  return async (dispatch) => {
    dispatch(progressSend());
    const data = await getLocal(nama);
    data.splice(index, 1);
    await saveLocal(nama, data);
    const result = await getLocal("Penjualan24KTemp");
    const totalNW = result
      .map((x) => parseFloat(removeComma(x.netto_disc)))
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    dispatch(change("TransaksiPenjualan24K", "totalNW", formatGram(totalNW)));
    dispatch(change("TransaksiPenjualan24K", "disc_global", 0));
    dispatch(change("TransaksiPenjualan24K", "disc_global_gr", 0));
    dispatch(
      change("TransaksiPenjualan24K", "grand_total_nw_ext", formatGram(totalNW))
    );
    dispatch(
      change("TransaksiPenjualan24K", "grand_total_nw", formatGram(totalNW))
    );
    dispatch(getListTransaksiPenjualan24K());
    dispatch(finishSend());
  };
};

export const saveEditPenjualan24K = (data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_EDIT_PENJUALAN,
      payload: data,
    });
  };
};

export const sendPenjualan24K = (edit) => {
  return async (dispatch, getState) => {
    let state = getState();
    const selected = state.form.TransaksiPenjualan24K.values;
    purifiedData(selected);
    const noBon = "J24K-" + getBon();
    const detail_barang = await getLocal("Penjualan24KTemp");
    const totalNW = detail_barang
      .map((x) => parseFloat(removeComma(x.netto_disc)))
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);

    const listCustomer = state.provinsi.customer.find(
      (x) => x.kode_customer === selected.kode_customer
    );
    let result = await PostPenjualan24K({
      detail_barang: detail_barang,
      dispatch: dispatch,
      listCustomer: listCustomer,
      selected: selected,
      payload: {
        no_penjualan: state.provinsi.noFaktur,
        no_bon: noBon,
        is_edit: state.provinsi.prev_no_penjualan !== "-",
        prev_no_penjualan: state.provinsi.prev_no_penjualan,
        no_po: "-",
        no_packing: "-",
        keterangan: selected.keterangan,
        tanggal: selected.tanggal,
        kode_lokasi: "PUSAT",
        kode_sales: "-",
        kode_customer: selected.kode_customer,
        detail_barang: detail_barang.map(({ no, _id, ...rest }) => rest),
        total_netto: FormatterNumber(totalNW),
        disc_persen: selected.disc_global,
        disc_gram: FormatterNumber(selected.disc_global_gr),
        total_netto_disc: FormatterNumber(selected.grand_total_nw),
        total_netto_disc_ext: FormatterNumber(selected.grand_total_nw_ext),
      },
    });
    if (result.includes("E11000")) {
      multipleDeleteLocal(["noFaktur"]);
      dispatch(getUUID());
      state = getState();
      await PostPenjualan24K({
        detail_barang: detail_barang,
        dispatch: dispatch,
        listCustomer: listCustomer,
        selected: selected,
        payload: {
          no_penjualan: state.provinsi.noFaktur,
          no_bon: noBon,
          is_edit: state.provinsi.prev_no_penjualan !== "-",
          prev_no_penjualan: state.provinsi.prev_no_penjualan,
          no_po: "-",
          no_packing: "-",
          keterangan: selected.keterangan,
          tanggal: selected.tanggal,
          kode_lokasi: "PUSAT",
          kode_sales: "-",
          kode_customer: selected.kode_customer,
          detail_barang: detail_barang.map(({ no, _id, ...rest }) => rest),
          total_netto: FormatterNumber(totalNW),
          disc_persen: selected.disc_global,
          disc_gram: FormatterNumber(selected.disc_global_gr),
          total_netto_disc: FormatterNumber(selected.grand_total_nw),
          total_netto_disc_ext: FormatterNumber(selected.grand_total_nw_ext),
        },
      });
    }
  };
};

async function PostPenjualan24K({
  payload,
  detail_barang,
  selected,
  listCustomer,
  dispatch,
}) {
  try {
    var res = await toast.promise(
      AxiosMasterPost("penjualan/simpan", payload),
      ToastKirim
    );
    print(
      detail_barang,
      res.data.no_bon,
      selected.tanggal,
      selected.kode_customer,
      listCustomer.nama_customer,
      selected.disc_global_gr,
      selected.grand_total_nw
    );
    multipleDeleteLocal([
      "Penjualan24KTemp",
      "noBon",
      "tanggal",
      "tanggalBarang",
      "namaSupplier",
      "kodeSupplier",
      "keterangan",
      "totalNW",
      "noFaktur",
      "temp-penjualan24KCustomer",
      "temp-penjualan24KDate",
      "temp-penjualan24KKeterangan",
      "temp-penjualan24KDiscGlobal",
      "temp-penjualan24KGrandTotalNW",
    ]);
    dispatch(finishSend());
    dispatch(getUUID());
    dispatch(getListTransaksiPenjualan24K());
    dispatch(reset("TransaksiPenjualan24K"));
    dispatch(change("TransaksiPenjualan24K", "tanggal", getToday()));
    dispatch(change("TransaksiPenjualan24K", "totalNW", 0));
    dispatch(change("TransaksiPenjualan24K", "disc_global", 0));
    dispatch(change("TransaksiPenjualan24K", "disc_global_gr", 0));
    dispatch(change("TransaksiPenjualan24K", "grand_total_nw_ext", 0));
    dispatch(change("TransaksiPenjualan24K", "grand_total_nw", 0));
    window.location.reload();
    return "SUCCESS";
  } catch (e) {
    dispatch(finishSend());
    return e?.response?.data;
  }
}

export const editBarang = (data) => {
  return (dispatch) => {
    dispatch(
      change("FormTransaksiPenjualan24KEdit", "kodeBarang", data.kode_jenis)
    );
    dispatch(change("FormTransaksiPenjualan24KEdit", "p", data.p));
    dispatch(change("FormTransaksiPenjualan24KEdit", "k", data.k));
    dispatch(
      change("FormTransaksiPenjualan24KEdit", "kadar", data.kadar_cetak)
    );
    dispatch(
      change("FormTransaksiPenjualan24KEdit", "kode_kategori", "GLE17K")
    );
    dispatch(
      change("FormTransaksiPenjualan24KEdit", "keterangan", data.keterangann)
    );
    dispatch(
      change(
        "FormTransaksiPenjualan24KEdit",
        "namaKategori",
        data.nama_kategori
      )
    );
    dispatch(change("FormTransaksiPenjualan24KEdit", "pkg", data.pkg));
    dispatch(change("FormTransaksiPenjualan24KEdit", "bruto", data.bruto));
    dispatch(
      change("FormTransaksiPenjualan24KEdit", "kadarTransaksi", data.kadar_jual)
    );
    dispatch(change("FormTransaksiPenjualan24KEdit", "netto", data.netto));
    dispatch(change("FormTransaksiPenjualan24KEdit", "gross", data.gross));
    dispatch(
      change("FormTransaksiPenjualan24KEdit", "discGlobalPr", data.disc_persen)
    );
    dispatch(
      change(
        "FormTransaksiPenjualan24KEdit",
        "nettoDiscGlobalExt",
        data.netto_disc
      )
    );
    dispatch(change("FormTransaksiPenjualan24KEdit", "rp", data.ongkos_rp));
    dispatch(change("FormTransaksiPenjualan24KEdit", "qty", data.qty));
    dispatch(
      change("FormTransaksiPenjualan24KEdit", "harga_jual", data.harga_jual)
    );
    dispatch(change("FormTransaksiPenjualan24KEdit", "no", data.no));
  };
};

function print(
  table,
  noBon,
  tanggal,
  customer,
  namaCustomer,
  disc_global,
  total_netto
) {
  var tableRows = [];
  table.forEach((ticket, i) => {
    const ticketData = [
      { content: ++i },
      { content: ticket.kode_barang },
      { content: ticket.kode_bahan },
      { content: ticket.qty, styles: { halign: "right" } },
      { content: ticket.kadar_cetak, styles: { halign: "right" } },
      { content: formatGram(ticket.plastik), styles: { halign: "right" } },
      { content: formatGram(ticket.kulit), styles: { halign: "right" } },
      { content: ticket.pkg, styles: { halign: "right" } },
      { content: formatGram(ticket.bruto), styles: { halign: "right" } },
      { content: formatGram(ticket.disc_gram), styles: { halign: "right" } },
      { content: ticket.kadar_jual, styles: { halign: "right" } },
      { content: formatRp(ticket.ongkos_rp), styles: { halign: "right" } },
      { content: formatGram(ticket.netto, 3), styles: { halign: "right" } },
    ];
    // push each tickcet's info into a row
    tableRows.push(ticketData);
  });
  const summary = [
    "",
    "",
    "",
    {
      content: table
        .map((list) => parseFloat(list.qty))
        .reduce((a, b) => a + b, 0),
      styles: { halign: "right" },
    },
    "",
    "",
    "",
    {
      content: table
        .map((list) => parseFloat(list.pkg))
        .reduce((a, b) => a + b, 0),
      styles: { halign: "right" },
    },
    {
      content: formatGram(
        table.map((list) => parseFloat(list.bruto)).reduce((a, b) => a + b, 0)
      ),
      styles: { halign: "right" },
    },
    {
      content: formatGram(
        table
          .map((list) => parseFloat(list.disc_gram))
          .reduce((a, b) => a + b, 0)
      ),
      styles: { halign: "right" },
    },
    "",
    {
      content: formatRp(
        table.map((list) => parseInt(list.ongkos_rp)).reduce((a, b) => a + b, 0)
      ),
      styles: { halign: "right" },
    },
    {
      content: formatGram(
        table.map((list) => parseFloat(list.netto)).reduce((a, b) => a + b, 0),
        3
      ),
      styles: { halign: "right" },
    },
  ];
  let footer = [
    summary,
    [
      {
        content: "Diskon Global : " + disc_global,
        styles: { halign: "right" },
        colSpan: 13,
      },
    ],
    [
      {
        content: "Grand Total : " + total_netto,
        styles: { halign: "right" },
        colSpan: 13,
      },
    ],
    printInfo(),
  ];
  var columTable = [
    [{ content: "Customer : " + namaCustomer, colSpan: 13 }],
    [
      { content: "No", styles: { cellWidth: "50" } },
      { content: "Kode\nBarang" },
      { content: "Kode\nBahan" },
      { content: "Qty", styles: { halign: "right" } },
      { content: "Cap\nKode", styles: { halign: "right" } },
      { content: "P", styles: { halign: "right" } },
      { content: "K", styles: { halign: "right" } },
      { content: "Bungkus\n(Gr)", styles: { halign: "right" } },
      { content: "Berat\n(Gr)", styles: { halign: "right" } },
      { content: "Diskon\n(Gr)", styles: { halign: "right" } },
      { content: "Harga\n(Gr)", styles: { halign: "right" } },
      { content: "Ongkos\n(Rp)", styles: { halign: "right" } },
      { content: "Murni\n(Gr)", styles: { halign: "right" } },
    ],
  ];
  PrintNota(
    "No Bon",
    noBon,
    "Tanggal",
    tanggal,
    "",
    "",
    "Customer",
    customer,
    getUserID(),
    getToday(),
    "",
    columTable,
    "Transaksi Penjualan 24K",
    tableRows,
    footer
  );
}

export const findJobOrderPenjualan = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.FormTransaksiPenjualan24K.values;
    let response = await toast.promise(
      AxiosMasterGetWithParams("job-order", {
        no_job_order: selected.kode_barcode,
      }),
      ToastAmbil
    );
    dispatch(change("FormTransaksiPenjualan24K", "bruto", response.data.bruto));
    dispatch(change("FormTransaksiPenjualan24K", "qty", response.data.qty));
    dispatch(
      change(
        "FormTransaksiPenjualan24K",
        "kode_barang",
        response.data.kode_barang + "/ " + response.data.kode_bahan
      )
    );
    const result = await findBy(
      state.barang.listBarang,
      "kode_barang",
      response.data.kode_barang,
      "kode_bahan",
      response.data.kode_bahan
    );
    dispatch(change("FormTransaksiPenjualan24K", "kadar", result.kadar_cetak));
    dispatch(
      change("FormTransaksiPenjualan24K", "kadarTransaksi", result.kadar_jual)
    );
    dispatch(finishSend());
  };
};
