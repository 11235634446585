import {
  GET_BARANG,
  SET_EDIT_BARANG,
} from "../DataMaster/barang/redux/barangAction";

const initialState = {
  listBarang: [],
  editDataBarang: [],
};

const barang = (state = initialState, action) => {
  switch (action.type) {
    case GET_BARANG:
      return {
        ...state,
        listBarang: action.payload.data,
      };
    case SET_EDIT_BARANG:
      return {
        ...state,
        editDataBarang: action.payload.data,
      };
    default:
      return state;
  }
};

export default barang;
