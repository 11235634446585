import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { renderField } from "../../renderField";

class FormReparasi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      response: "",
      kode_reparasi: "",
      deskripsi_reparasi: "",
    };
  }

  render() {
    return (
      <div className="col-lg-12 col-md-12">
        <form onSubmit={this.props.handleSubmit}>
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <Field
                name="kodeReparasi"
                type="tel"
                component={renderField}
                label="Kode Reparasi"
              />
            </div>
            <div className="col-lg-4 col-md-4">
              <Field
                name="deskripsiReparasi"
                type="text"
                normalize={(value) => value && value.toUpperCase()}
                component={renderField}
                label="Deskripsi"
              />
            </div>
            <div className="col-md-12 col-lg-12 mt-5">
              <div className="text-right">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={this.props.onSend}
                >
                  {this.props.onSend ? (
                    <span
                      className="spinner-border spinner-border-sm mr-3"
                      aria-hidden="true"
                    ></span>
                  ) : null}
                  Selesai
                  <i className="fa fa-paper-plane ml-3"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

FormReparasi = reduxForm({
  form: "FormReparasi",
  enableReinitialize: true,
})(FormReparasi);
export default connect()(FormReparasi);
