// @flow
import React, {useEffect} from 'react';
import GlobalTabel from "../../../GlobalComponent/GlobalTabel";
import {connect, useDispatch, useSelector} from "react-redux";
import {formatGram} from "../../../helper";
import {Dropdown} from "react-bootstrap";
import {reduxForm} from "redux-form";
import {getListCorrectionNote, setSelectedBon} from "../redux/correction_note_action";


const ListCorrectionNote = (props) => {
    const dispatch = useDispatch();
    const listCorrectionNote = useSelector(state => state.correctionNote.listCorrectionNote)
    useEffect(() => {
        dispatch(getListCorrectionNote())
    }, [dispatch]);

    const columns = [
        {
            dataField: "no_penjualan",
            text: "No Penjualan",
        },
        {
            dataField: "tanggal",
            text: "Tanggal",
            align: "right",
            headerAlign: "right",
        },
        {
            dataField: "kode_sales",
            text: "Sales",
            align: "right",
            headerAlign: "right",
            formatter: (data) => formatGram(data),

        },
        {
            dataField: "kode_customer",
            text: "Customer",
            align: "right",
            headerAlign: "right",
        },
        {
            dataField: "total_netto",
            text: "Total Netto",
            align: "right",
            headerAlign: "right",
            formatter: (data) => formatGram(data),

        },
        {
            dataField: "total_bruto",
            text: "Total Bruto",
            align: "right",
            headerAlign: "right",
            formatter: (data) => formatGram(data),

        },
        {
            dataField: "Aksi",
            text: "Aksi",
            csvExport: false,
            formatter: (rowContent, rows, rowIndex) => {
                return (
                    <div className="justify-content-center row">
                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <i className="fa fa-ellipsis-v" aria-hidden="true"/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item  onClick={() => {dispatch(setSelectedBon(rows))} }>Pilih Faktur <i className="fa fa-check ml-2"/></Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                );
            },
        },
    ];
    return (
        <div className="col-lg-12 col-md-12">
            <GlobalTabel
                keyField="no_penjualan"
                data={listCorrectionNote}
                columns={columns}
            />
        </div>
    );
};

export default connect()(reduxForm({
    form : "ListCorrectionNote"
})(ListCorrectionNote))