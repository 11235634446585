export class CustomerModel {
    tanggal_daftar;
    nama_customer;
    nama_toko;
    kode_ext;
    level_customer = "LEVEL1";
    kode_kota;
    kode_kecamatan;
    alamat;
    no_telp;
    no_hp;
    fax;
    email;
    plafon;
    tempo_kredit;
    rekening_tujuan;
    grade_potensi;
    keterangan;


    constructor(tanggal_daftar, nama_customer, nama_toko, kode_ext, kode_kota, kode_kecamatan, alamat, no_telp, no_hp, fax, email, plafon, tempo_kredit, rekening_tujuan, grade_potensi, keterangan) {
        this.tanggal_daftar = tanggal_daftar || "2022-01-01";
        this.nama_customer = nama_customer;
        this.nama_toko = nama_toko;
        this.kode_ext = kode_ext || "-";
        this.kode_kota = kode_kota || "3273";
        this.kode_kecamatan = kode_kecamatan || "327312";
        this.alamat = alamat || "-";
        this.no_telp = no_telp || "-";
        this.no_hp = no_hp || "-";
        this.fax = fax || "-";
        this.email = email || "admin@admin.com";
        this.plafon = plafon || "0";
        this.tempo_kredit = tempo_kredit || 0;
        this.rekening_tujuan = rekening_tujuan || "-";
        this.grade_potensi = grade_potensi || "A";
        this.keterangan = keterangan || "-";
    }

    post() {
        return {
            tanggal_daftar: this.tanggal_daftar,
            nama_customer : this.nama_customer,
            nama_toko : this.nama_toko,
            kode_ext : this.kode_ext,
            level_customer : "LEVEL1",
            kode_kota : this.kode_kota,
            kode_kecamatan : this.kode_kecamatan,
            alamat : this.alamat,
            no_telp : this.no_telp,
            no_hp : this.no_hp,
            fax : this.fax,
            email : this.email,
            plafon : this.plafon,
            tempo_kredit : this.tempo_kredit,
            rekening_tujuan : this.rekening_tujuan,
            grade_potensi : this.grade_potensi,
            keterangan : this.keterangan,
        }
    }


    get tanggal_daftar() {
        return this._tanggal_daftar;
    }

    set tanggal_daftar(value) {
        this._tanggal_daftar = value;
    }

    get nama_customer() {
        return this._nama_customer;
    }

    set nama_customer(value) {
        this._nama_customer = value;
    }

    get nama_toko() {
        return this._nama_toko;
    }

    set nama_toko(value) {
        this._nama_toko = value;
    }

    get kode_ext() {
        return this._kode_ext;
    }

    set kode_ext(value) {
        this._kode_ext = value;
    }

    get level_customer() {
        return this._level_customer;
    }

    set level_customer(value) {
        this._level_customer = value;
    }

    get kode_kota() {
        return this._kode_kota;
    }

    set kode_kota(value) {
        this._kode_kota = value;
    }

    get kode_kecamatan() {
        return this._kode_kecamatan;
    }

    set kode_kecamatan(value) {
        this._kode_kecamatan = value;
    }

    get alamat() {
        return this._alamat;
    }

    set alamat(value) {
        this._alamat = value;
    }

    get kode_pos() {
        return this._kode_pos;
    }

    set kode_pos(value) {
        this._kode_pos = value;
    }


    get no_telp() {
        return this._no_telp;
    }

    set no_telp(value) {
        this._no_telp = value;
    }

    get no_hp() {
        return this._no_hp;
    }

    set no_hp(value) {
        this._no_hp = value;
    }

    get fax() {
        return this._fax;
    }

    set fax(value) {
        this._fax = value;
    }

    get email() {
        return this._email;
    }

    set email(value) {
        this._email = value;
    }
}