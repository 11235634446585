import {finishSend, progressSend} from "../../../actions/LogicAction";
import {toast} from "react-toastify";
import {AxiosMasterGet} from "../../../Axios";
import {ToastAmbil} from "../../../actions/toast";
import LaporanTitipSupplier from "../component/LaporanTitipSupplier";
import LaporanTitipSupplierCard from "../component/LaporanTitipSupplierCard";
import LaporanTitipSupplierSummary from "../component/LaporanTitipSupplierSummary";


export const GET_LAPORAN_TITIP_SUPPLIER = "GET_LAPORAN_TITIP_SUPPLIER";
export const GET_LAPORAN_TITIP_SUPPLIER_CARD = "GET_LAPORAN_TITIP_SUPPLIER_CARD";
export const GET_LAPORAN_TITIP_SUPPLIER_SUMMARY = "GET_LAPORAN_TITIP_SUPPLIER_SUMMARY";


export const GetLaporanTitipSupplier = (kode_supplier) => {
  return async (dispatch ,getState) => {
    dispatch(progressSend())
      try {
                const response = await toast.promise(AxiosMasterGet(`report/titip-supplier/${kode_supplier}`), ToastAmbil);
                dispatch({
                    type: GET_LAPORAN_TITIP_SUPPLIER,
                    payload : response.data
                })
          dispatch(finishSend())
      } catch (e) {

            dispatch(finishSend())
            }
  }
}

export const GetLaporanTitipSupplierCard = (kode_supplier,jenis_transkasi,tanggal_awal, tanggal_akhir) => {
    return async (dispatch ,getState) => {
        dispatch(progressSend())
        try {
            const response = await toast.promise(AxiosMasterGet(
                `report/titip-supplier-card/${kode_supplier}&${jenis_transkasi}&${tanggal_awal}&${tanggal_akhir}`
            ), ToastAmbil);
            dispatch({
                type: GET_LAPORAN_TITIP_SUPPLIER_CARD,
                payload : {
                    data : response.data,
                    tanggal_awal: tanggal_awal,
                    tanggal_akhir: tanggal_akhir
                }
            })
            dispatch(finishSend())
        } catch (e) {

            dispatch(finishSend())
        }
    }
}

export const GetLaporanTitipSupplierSummary = (kode_supplier,jenis_transkasi,tanggal_awal, tanggal_akhir) => {
    return async (dispatch ,getState) => {
        dispatch(progressSend())
        try {
            const response = await toast.promise(AxiosMasterGet(
                `report/titip-supplier-summary/${kode_supplier}&${jenis_transkasi}&${tanggal_awal}&${tanggal_akhir}`
            ), ToastAmbil);
            dispatch({
                type: GET_LAPORAN_TITIP_SUPPLIER_SUMMARY,
                payload : {
                    data : response.data,
                    tanggal_awal: tanggal_awal,
                    tanggal_akhir: tanggal_akhir
                }
            })
            dispatch(finishSend())
        } catch (e) {

            dispatch(finishSend())
        }
    }
}

export const ResetLaporanTitipanSupplier = () => {
    return async (dispatch) => {
        dispatch({
            type : GET_LAPORAN_TITIP_SUPPLIER,
            payload : []
        })
        dispatch({
            type : GET_LAPORAN_TITIP_SUPPLIER_CARD,
            payload : {
                data : [],
                tanggal_awal : "",
                tanggal_akhir: ""
            }
        })
        dispatch({
            type : GET_LAPORAN_TITIP_SUPPLIER_SUMMARY,
            payload : {
                data : [],
                tanggal_awal : "",
                tanggal_akhir: ""
            }
        })
    }
}

export const PrintLaporanTitipSupplierPDF = () => {
  return async (dispatch ,getState) => {
    const state =getState();
    const dataLaporanTitipSupplier = state.laporan.dataLaporanTitipSupplier;
      LaporanTitipSupplier(dataLaporanTitipSupplier)
  }
}

export const PrintLaporanTitipSupplierCardPDF = () => {
  return async (dispatch ,getState) => {
    const state =getState();
    const dataLaporanTitipSupplierCard = state.laporan.dataLaporanTitipSupplierCard;
      LaporanTitipSupplierCard(
          dataLaporanTitipSupplierCard.data,
          dataLaporanTitipSupplierCard.tanggal_awal,
          dataLaporanTitipSupplierCard.tanggal_akhir
      );
  }
}

export const PrintLaporanTitipSupplierSummaryPDF = () => {
    return async (dispatch ,getState) => {
        const state =getState();
        const dataLaporanTitipSupplierSummary = state.laporan.dataLaporanTitipSupplierSummary;
        LaporanTitipSupplierSummary(
            dataLaporanTitipSupplierSummary.data,
            dataLaporanTitipSupplierSummary.tanggal_awal,
            dataLaporanTitipSupplierSummary.tanggal_akhir
        );
    }
}