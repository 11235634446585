import { AxiosMasterGet } from "../Axios";
import { checkLaporan } from "../helper";

export const TOTAL_BON = "TOTAL_BON";
export const GET_LIST_PIUTANG_CUSTOMER = "GET_LIST_PIUTANG_CUSTOMER";

export const setTotalBon = (input) => {
  return (dispatch) => {
    dispatch({
      type: TOTAL_BON,
      payload: {
        data: input,
      },
    });
  };
};

export const getPiutangCustomer = (customer, tanggal) => {
  return (dispatch) => {
    AxiosMasterGet(
      `trx-transfer-balance/get-piutang-titipan/${customer}&${tanggal}`
    )
      .then((res) => {
        if (checkLaporan(res.data)) {
          dispatch({
            type: GET_LIST_PIUTANG_CUSTOMER,
            payload: {
              data: res.data,
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch((err) =>
        dispatch({
          type: GET_LIST_PIUTANG_CUSTOMER,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};
