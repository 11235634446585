import React, { useEffect } from "react";
import GlobalTabel from "../../../GlobalComponent/GlobalTabel";
import { connect, useDispatch, useSelector } from "react-redux";
import { formatGram } from "../../../helper";
import { Dropdown } from "react-bootstrap";
import { reduxForm } from "redux-form";
import {
  saveKirimStockReturnTemp,
  getListKirimStockRetur,
} from "../redux/kirim_stock_retur_action";

const FormListReturn = (props) => {
  const dispatch = useDispatch();
  let data = useSelector((state) => state.kirimStockRetur.listAvailableRetur);
  const columns = [
    {
      dataField: "tanggal_invoice",
      text: "Tanggal",
      footerAttrs: {
        colSpan: "4",
      },
      footerAlign: "left",
      footer: () => {
        return "Grand Total ";
      },
    },
    {
      dataField: "kode_customer",
      text: "Kode Customer",
      align: "right",
      headerAlign: "right",
    },
    {
      dataField: "no_retur",
      text: "No Retur",
    },
    {
      dataField: "no_bon",
      text: "Nomor Bon",
    },
    {
      dataField: "total_bruto",
      text: "Total Bruto",
      align: "right",
      headerAlign: "right",
      formatter: (data) => formatGram(data),
      footerAlign: "right",
      footer: () => {
        return formatGram(data.reduce((a, b) => a + parseFloat(b.total_bruto), 0));
      },
    },
    {
      dataField: "Aksi",
      text: "Aksi",
      csvExport: false,
      formatter: (rowContent, rows, rowIndex) => {
        return (
          <div className="justify-content-center row">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <i className="fa fa-ellipsis-v" aria-hidden="true" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    dispatch(saveKirimStockReturnTemp(rows));
                  }}
                >
                  Pilih Faktur <i className="fa fa-check ml-2" />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    dispatch(getListKirimStockRetur());
  }, [dispatch]);
  return (
    <div className="col-lg-12 col-md-12">
      <GlobalTabel keyField="no_retur" data={data} columns={columns} />
    </div>
  );
};

export default connect()(
  reduxForm({
    form: "FormListReturn",
  })(FormListReturn)
);
