import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { finishSend, progressSend } from "../actions/LogicAction";
import { gagal } from "../Alert";
import { AxiosMasterGet } from "../Axios";
import { getToday } from "../getDate";
import { checkLaporan, purifiedData } from "../helper";
import LaporanPrintReparasi from "./Component/LaporanPrintReparasi";
import {
  requestLaporanKartuStockSampel,
  requestLaporanPindahBarangSampel,
  requestLaporanStockSampel,
  requestLaporanSummaryStockSampel,
} from "./redux/laporan_action";
import { getLokasi } from "../DataMaster/lokasi/redux/lokasiAction";
import { getkategoriList } from "../actions/kategoriAction";
import { getBarang } from "../DataMaster/barang/redux/barangAction";
import { getListBahan } from "../DataMaster/bahan/redux/bahanAction";
import { getListKelompok } from "../DataMaster/kelompok/redux/kelompokAction";
const StockSampel = React.lazy(() => import("./laporan_stock_sampel"));
const KartuStockSampel = React.lazy(() =>
  import("./laporan_kartu_stock_sampel")
);
const SummaryStockSampel = React.lazy(() =>
  import("./laporan_summary_stock_sampel")
);
const PindahBarangSampel = React.lazy(() =>
  import("./laporan_pindah_barang_sampel")
);

class laporanSampel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.dispatch(getLokasi());
    this.props.dispatch(getkategoriList());
    this.props.dispatch(getBarang());
    this.props.dispatch(getListBahan());
    this.props.dispatch(getListKelompok());
  }

  handleProses(data) {
    purifiedData(data);

    this.props.dispatch(progressSend());
    let status = data.status;
    let customer = data.nama_toko;
    let tanggal = data.tanggal;
    AxiosMasterGet(`report/reparasi/${tanggal}&${customer}&${status}`)
      .then((res) => {
        this.props.dispatch(finishSend());
        if (checkLaporan(res.data)) {
          LaporanPrintReparasi(
            localStorage.getItem("username"),
            getToday(),
            localStorage.getItem("username"),
            res.data,
            status
          );
        }
      })
      .catch((err) =>
        gagal("Gagal Mengambil data, Mohon periksa koneksi anda").then(() =>
          this.props.dispatch(finishSend())
        )
      );
  }

  handleStockSampel() {
    this.props.dispatch(requestLaporanStockSampel());
  }

  handleKartuStockSampel() {
    this.props.dispatch(requestLaporanKartuStockSampel());
  }

  handleSummaryStockSampel() {
    this.props.dispatch(requestLaporanSummaryStockSampel());
  }

  handlePindahBarangSampel() {
    this.props.dispatch(requestLaporanPindahBarangSampel());
  }
  render() {
    return (
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                    <li className="nav-item">
                      <a
                        href="#detail-tab"
                        data-toggle="tab"
                        aria-expanded="false"
                        className="nav-link active"
                      >
                        <i className="mdi mdi-home-variant d-lg-none d-block mr-1" />
                        <span className="d-none d-lg-block">
                          Laporan Stock Sampel
                        </span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#card-tab"
                        data-toggle="tab"
                        aria-expanded="false"
                        className="nav-link"
                      >
                        <i className="mdi mdi-home-variant d-lg-none d-block mr-1" />
                        <span className="d-none d-lg-block">
                          Laporan Kartu Stock Sampel
                        </span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#summary-tab"
                        data-toggle="tab"
                        aria-expanded="false"
                        className="nav-link"
                      >
                        <i className="mdi mdi-home-variant d-lg-none d-block mr-1" />
                        <span className="d-none d-lg-block">
                          Laporan Summary Stock Sample
                        </span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#pindahSampel-tab"
                        data-toggle="tab"
                        aria-expanded="false"
                        className="nav-link"
                      >
                        <i className="mdi mdi-home-variant d-lg-none d-block mr-1" />
                        <span className="d-none d-lg-block">
                          Laporan Pindah Barang Sample
                        </span>
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div className="tab-pane show active" id="detail-tab">
                      <StockSampel
                        from="PROSES"
                        onSubmit={(data) => this.handleStockSampel(data)}
                      />
                    </div>
                    <div className="tab-pane" id="card-tab">
                      <KartuStockSampel
                        from="PROSES"
                        onSubmit={(data) => this.handleKartuStockSampel(data)}
                      />
                    </div>
                    <div className="tab-pane" id="summary-tab">
                      <Suspense fallback={<></>}>
                        <SummaryStockSampel
                          from="PROSES"
                          onSubmit={(data) =>
                            this.handleSummaryStockSampel(data)
                          }
                        />
                      </Suspense>
                    </div>
                    <div className="tab-pane" id="pindahSampel-tab">
                      <Suspense fallback={<></>}>
                        <PindahBarangSampel
                          from="PROSES"
                          onSubmit={(data) =>
                            this.handlePindahBarangSampel(data)
                          }
                        />
                      </Suspense>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(laporanSampel);
