import { AxiosMasterGet } from "../Axios";
import { checkLaporan } from "../helper";

export const GET_LIST_VALIDASI_PEMBBAYARAN_GIRO =
  "GET_LIST_VALIDASI_PEMBBAYARAN_GIRO";
export const GET_DATA_SELECTED_VALIDASI_GIRO =
  "GET_DATA_SELECTED_VALIDASI_GIRO";

export const getValidasiGiro = (jenis_giro) => {
  return (dispatch) => {
    AxiosMasterGet(`validate-bank-outstand-giro/get/${jenis_giro}&AC_ASAL&ALL`)
      .then((res) => {
        if (checkLaporan(res.data)) {
          dispatch({
            type: GET_LIST_VALIDASI_PEMBBAYARAN_GIRO,
            payload: {
              data: res.data,
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch({
            type: GET_LIST_VALIDASI_PEMBBAYARAN_GIRO,
            payload: {
              data: res.data,
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() =>
        dispatch({
          type: GET_LIST_VALIDASI_PEMBBAYARAN_GIRO,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const getValidasiGiroSelected = (row) => {
  return (dispatch) => {
    dispatch({
      type: GET_DATA_SELECTED_VALIDASI_GIRO,
      payload: {
        data: row,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
        error: true,
      },
    });
  };
};
