import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatDateIndo, formatGram, printInfo } from "../../../helper";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporanStockSummary = (data, mulai_dari, sampai_dengan) => {
  console.log(data);
  // initialize jsPDF
  const doc = new jsPDF("landscape");
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];

  doc.setFontSize(15);
  doc.text("LAPORAN STOK BARANG SUMMARY ", 14, 10);
  // doc.addImage(logo_ayu_base64, "PNG", 14, 10, 35, 12);

  doc.setFontSize(10);
  doc.text(`Mulai Dari : ${formatDateIndo(mulai_dari)}`, 14, 16);
  //   row 2
  doc.text(`Sampai Dengan	: ${formatDateIndo(sampai_dengan)}`, 100, 16);
  data.forEach((item, index) => {
    let tableColumn = [
      [
        {
          content: `KODE LOKASI : ${item.kode_lokasi}`,
          colSpan: 10,
        },
      ],
      [
        {
          content: `KODE BARANG`,
        },
        {
          content: `KODE BAHAN`,
        },
        {
          content: `AWAL\nBRT`,
          styles: { halign: "right", cellWidth: 25 },
        },
        {
          content: `AWAL\nNETT`,
          styles: { halign: "right", cellWidth: 25 },
        },
        {
          content: `IN\nBRT`,
          styles: { halign: "right", cellWidth: 25 },
        },
        {
          content: `IN\nNETT`,
          styles: { halign: "right", cellWidth: 25 },
        },
        {
          content: `OUT\nBRT`,
          styles: { halign: "right", cellWidth: 25 },
        },
        {
          content: `OUT\nNETT`,
          styles: { halign: "right", cellWidth: 25 },
        },
        {
          content: `AKHIR\nBRT`,
          styles: { halign: "right", cellWidth: 25 },
        },
        {
          content: `AKHIR\nNETT`,
          styles: { halign: "right", cellWidth: 25 },
        },
      ],
    ];
    item.detail_barang.forEach((hasil) => {
      let rows = [
        hasil.kode_barang,
        hasil.kode_bahan,
        { content: formatGram(hasil.awal_bruto), styles: { halign: "right" } },
        {
          content: formatGram(hasil.awal_netto, 3),
          styles: { halign: "right" },
        },
        { content: formatGram(hasil.in_bruto), styles: { halign: "right" } },
        { content: formatGram(hasil.in_netto, 3), styles: { halign: "right" } },
        { content: formatGram(hasil.out_bruto), styles: { halign: "right" } },
        {
          content: formatGram(hasil.out_netto, 3),
          styles: { halign: "right" },
        },
        { content: formatGram(hasil.akhir_bruto), styles: { halign: "right" } },
        {
          content: formatGram(hasil.akhir_netto, 3),
          styles: { halign: "right" },
        },
      ];
      tableRows.push(rows);
    });
    doc.autoTable({
      head: tableColumn,
      body: tableRows,
      foot: [printInfo()],
      showHead: "firstPage",
      showFoot: "lastPage",
      startY: 20,
      theme: "plain",
      rowPageBreak: "avoid",

      margin: { top: 20 },
      bodyStyles: { lineColor: "#969696", fontSize: 8, lineWidth: 0.0 },
      headStyles: {
        lineColor: "#969696",
        lineWidth: 0.0,
        fontSize: 8,
        fillColor: "#E8E8E8",
      },
      footStyles: {
        lineColor: "#969696",
        lineWidth: 0.0,
        fillColor: "#E8E8E8",
        fontSize: 8,
      },
    });
  });

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  doc.autoPrint();
  doc.setProperties({
    title: "LAPORAN STOCK - SUMMARY",
  });
  // doc.save(`PenerimaanSUpplier.pdf`);
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    `
<html>
<head>
  <title>LAPORAN STOCK - SUMMARY</title>
  <style>
    #overlay {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1000;
    }
  </style>
</head>
<body style='margin:0 !important; position: relative;'>
  <iframe src='${string}#toolbar=0' width='100%' height='100%' frameborder='0' style='position: absolute; top: 0; left: 0;'></iframe>
  <div id='overlay' oncontextmenu='return false;'></div>
</body>
</html>
`
  );
  // setInterval(() => {
  //   x.close();
  // }, 1000);
};

export default LaporanStockSummary;
