import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatGram, formatRp, getUserID } from "../../helper";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporanPrintReparasi = (
  username = "",
  tanggal = "",
  validby = "",
  data,
  jenis = ""
) => {
  // initialize jsPDF
  const doc = new jsPDF("portrait");
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];

  let finalY = 25;
  doc.setFontSize(15);
  doc.text("LAPORAN REPARASI " + jenis, 14, 10);
  // doc.addImage(logo_ayu_base64, "PNG", 14, 10, 35, 12);

  doc.setFontSize(10);
  data.forEach((item, index) => {
    let tableColumn = [
      [
        {
          content: "Nomor Bon : " + item.no_bon,
          colSpan: 5,
        },
      ],
      [
        {
          content: "Tanggal Transaksi : " + item.tanggal,
          colSpan: 5,
        },
      ],
      [
        {
          content: `KODE KATEGORI`,
        },
        {
          content: `QTY`,
          styles: { halign: "right" },
        },
        {
          content: `BRUTO (GR)`,
          styles: { halign: "right" },
        },
        {
          content: `ONGKOS REPARASI (RP)`,
          styles: { halign: "right" },
        },
        {
          content: `TOTAL ONGKOS REPARASI (RP)`,
          styles: { halign: "right" },
        },
      ],
    ];
    let total_qty = 0;
    let total_bruto = 0;
    let total_ongkos = 0;
    item.detail_barang.forEach((detail) => {
      let rows = [
        detail.kode_kategori,
        { content: detail.qty, styles: { halign: "right" } },
        { content: formatGram(detail.bruto), styles: { halign: "right" } },
        { content: formatRp(detail.ongkos_rp), styles: { halign: "right" } },
        {
          content: formatRp(
            parseFloat(detail.ongkos_rp) * parseInt(detail.qty)
          ),
          styles: { halign: "right" },
        },
      ];
      tableRows.push(rows);
      total_qty = parseInt(total_qty) + parseInt(detail.qty);
      total_bruto = parseFloat(total_bruto) + parseFloat(detail.bruto);
      total_ongkos =
        parseInt(total_ongkos) +
        parseFloat(detail.ongkos_rp) * parseInt(detail.qty);
    });
    let foot = [
      [
        "Grand Total",
        { content: total_qty, styles: { halign: "right" } },
        { content: formatGram(total_bruto), styles: { halign: "right" } },
        { content: "", styles: { halign: "right" } },
        { content: formatRp(total_ongkos), styles: { halign: "right" } },
      ],
    ];
    doc.autoTable({
      head: tableColumn,
      body: tableRows,
      foot: foot,
      showHead: "firstPage",
      showFoot: "lastPage",
      startY: finalY,
      theme: "plain",
      rowPageBreak: "avoid",

      margin: { top: 20 },
      bodyStyles: { lineColor: "#969696", fontSize: 8, lineWidth: 0.0 },
      headStyles: {
        lineColor: "#969696",
        lineWidth: 0.0,
        fontSize: 8,
        fillColor: "#E8E8E8",
      },
      footStyles: {
        lineColor: "#969696",
        lineWidth: 0.0,
        fillColor: "#E8E8E8",
        fontSize: 8,
      },
    });
    finalY = doc.autoTableEndPosY() + 10;
    tableRows = [];
  });

  tableRows = [];

  doc.text(`Cetak By	: ${getUserID()}`, 14, finalY + 10);

  doc.text(`Cetak	Date : ${tanggal}`, 14, finalY + 16);

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  doc.autoPrint();
  doc.setProperties({
    title: "LAPORAN REPARASI " + jenis,
  });
  // doc.save(`PenerimaanSUpplier.pdf`);
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    "<html><head><title>LAPORAN REPARASI</title></head><body style='margin:0 !important'><embed width='100%' height='100%'  src=" +
      string +
      "></embed></body></html>"
  );
  // setInterval(() => {
  //   x.close();
  // }, 1000);
};

export default LaporanPrintReparasi;
