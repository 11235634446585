import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { createNumberMask } from "redux-form-input-masks";
import { renderField, renderFieldSelect } from "../../../renderField";
import {
  formatGram,
  NormalizeGramasi,
  preventTabAction,
  removeComma,
  setFocus,
  updaterForm,
} from "../../../helper";
import CustomForm from "../../../CustomForm";
import ButtonSelesai from "../../../button_selesai";
import validasiForm24K from "../../../Validations/validasiForm24K";

const currencyMask = createNumberMask({
  prefix: "Rp. ",
  suffix: ",-",
  locale: "id-ID",
});

class FormTransaksiPenjualan24KEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kodeBarang: [],
      non_aksesoris: "col-lg-12 row",
      aksesoris: "col-lg-12 row d-none",
      stockBruto: 0,
      stockNetto: 0,
    };
  }

  componentDidMount() {
    preventTabAction();
  }

  onclick1() {
    updaterForm(this.props);
  }

  pilihBarang(data) {
    let hasil = this.props.dataBarang.find((x) => x.kode_ciok_rosok === data);
    this.props.change("kadar", hasil.kadar_cetak);
    this.props.change("kadarTransaksi", hasil.kadar_jual);
    setFocus("Pkg (Gr)");
  }

  render() {
    return (
      <CustomForm onSubmit={this.props.handleSubmit}>
        <div className="row">
          <div className="col-md-12 col-lg-12 d-none">
            <Field
              name="noFaktur"
              label={this.props.noFaktur}
              type="text"
              readOnly={true}
              component={renderField}
              defaultValue={this.props.noFaktur}
              normalize={(value) => value && value.toUpperCase()}
            />
          </div>
          <div className="col-md-4 col-lg-4">
            <Field
              name="kodeBarang"
              label="Kode Barang"
              onChange={(data) => this.pilihBarang(data.value)}
              component={renderFieldSelect}
              options={this.props.dataBarang.map((kode) => {
                return {
                  value: kode.kode_ciok_rosok,
                  name: kode.kode_ciok_rosok,
                };
              })}
            />
          </div>
          <div className={this.state.non_aksesoris}>
            <div className="col-md-3 col-lg-3 d-none">
              <Field
                name="namaKategori"
                label="Harga Jual"
                type="number"
                component={renderField}
              />
            </div>
            <div className="col-md-3 col-lg-3">
              <Field
                name="pkg"
                label="Pkg (Gr)"
                type="number"
                component={renderField}
                normalize={NormalizeGramasi}
              />
            </div>
            <div className="col-md-3 col-lg-3">
              <Field
                name="qty"
                label="Qty"
                type="number"
                component={renderField}
              />
            </div>
            <div className="col-md-3 col-lg-3">
              <Field
                name="bruto"
                label="Berat (Gr)"
                type="number"
                component={renderField}
                onChange={this.onclick1()}
                normalize={NormalizeGramasi}

                // onChange={this.setBruto()}
                // onBlur={this.setBruto()}
              />
            </div>
            <div className="col-md-3 col-lg-3">
              <Field
                name="kadar"
                label="Kadar"
                type="number"
                component={renderField}
                readOnly
              />
            </div>
            <div className="col-md-3 col-lg-3">
              <Field
                name="kadarTransaksi"
                label="Harga"
                type="number"
                component={renderField}
                readOnly
              />
            </div>
            <div className="col-md-3 col-lg-3">
              <Field
                name="netto"
                label="Netto (Gr)"
                type="text"
                component={renderField}
                readOnly={true}
              />
            </div>
            <div className="col-md-3 col-lg-3">
              <Field
                name="gross"
                label="Gross (Gr)"
                type="text"
                component={renderField}
                readOnly={true}
              />
            </div>
            <div className="col-md-3 col-lg-3">
              <Field
                name="discGlobalPr"
                label="Diskon (%)"
                type="number"
                component={renderField}
              />
            </div>
            <div className="col-md-3 col-lg-3">
              <Field
                name="discGlobalGr"
                label="Diskon (Gr)"
                type="text"
                component={renderField}
                readOnly={true}
              />
            </div>
            <div className="col-md-3 col-lg-3">
              <Field
                name="nettoDiscGlobalExt"
                label="Netto - Diskon (Gr)"
                type="text"
                component={renderField}
                readOnly={true}
              />
            </div>
            <div className="col-md-3 col-lg-3">
              <Field
                name="ongkos_rp"
                component={renderField}
                label={"Ongkos (Rp)"}
                type="tel"
                className="form-control"
                {...currencyMask}
              />
            </div>
            <div className="col-md-3 col-lg-3">
              <Field
                label={"Total Harga Jual"}
                name="total_harga_jual"
                component={renderField}
                type="tel"
                className="form-control"
                {...currencyMask}
              />
            </div>
          </div>
          <div className="col-lg-12 col-md-12">
            <ButtonSelesai />
          </div>
        </div>
      </CustomForm>
    );
  }
}

FormTransaksiPenjualan24KEdit = reduxForm({
  form: "FormTransaksiPenjualan24KEdit",
  enableReinitialize: true,
  validate: validasiForm24K,
})(FormTransaksiPenjualan24KEdit);
const selector = formValueSelector("FormTransaksiPenjualan24KEdit"); // <-- same as form name
export default connect((state) => {
  const { bruto, kadarTransaksi, pkg, discGlobalPr } = selector(
    state,
    "bruto",
    "kadarTransaksi",
    "pkg",
    "discGlobalPr"
  );
  return {
    netto: formatGram(
      removeComma(bruto || 0) * (removeComma(kadarTransaksi || 0) / 100)
    ),
    gross: formatGram(
      parseFloat(removeComma(bruto) || 0) + parseFloat(removeComma(pkg) || 0)
    ),
    discGlobalGr: formatGram(
      removeComma(bruto || 0) * (removeComma(discGlobalPr || 0) / 100)
    ),
    dataBarang: state.provinsi.rosok,
    onSend: state.provinsi.onSend,
    initialValues: {
      discGlobalPr: state.provinsi.editPenjualanData.disc_persen,
      kodeBarang: {
        value: state.provinsi.editPenjualanData.kode_barang,
        label: state.provinsi.editPenjualanData.kode_barang,
      },
      kadar: state.provinsi.editPenjualanData.kadar_cetak,
      p: state.provinsi.editPenjualanData.plastik,
      k: state.provinsi.editPenjualanData.kulit,
      qty: state.provinsi.editPenjualanData.qty,
      berat_per_item: state.provinsi.editPenjualanData.berat_per_item,
      pkg: state.provinsi.editPenjualanData.pkg,
      bruto: state.provinsi.editPenjualanData.bruto,
      kadarTransaksi: state.provinsi.editPenjualanData.kadar_jual,
      gross: state.provinsi.editPenjualanData.gross,
      netto: state.provinsi.editPenjualanData.netto,
      ongkos_rp: state.provinsi.editPenjualanData.ongkos_rp,
      total_harga_jual: state.provinsi.editPenjualanData.total_harga_jual,
      no: state.provinsi.editPenjualanData.no,
    },
    bruto: bruto,
  };
})(FormTransaksiPenjualan24KEdit);
