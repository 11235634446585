import { toast } from "react-toastify";
import { change, reset } from "redux-form";
import Swal from "sweetalert2";
import { finishSend, progressSend } from "../../../actions/LogicAction";
import { hideModal } from "../../../actions/ModalAction";
import { ToastAmbil, ToastKirim } from "../../../actions/toast";
import { warning } from "../../../Alert";
import {
  AxiosMasterDelete,
  AxiosMasterGet,
  AxiosMasterGetWithParams,
  AxiosMasterPost,
  AxiosMasterPut,
  postImage,
} from "../../../Axios";
import { dataURLtoFile, findBy, purifiedData } from "../../../helper";

export const GET_LIST_SAMPLE = "GET_LIST_SAMPLE";
export const SET_EDIT_SAMPLE = "SET_EDIT_SAMPLE";

export const setEditSample = (row) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_EDIT_SAMPLE,
      payload: row,
    });
  };
};

export const getSample = () => {
  return async (dispatch, getState) => {
    try {
      const response = await AxiosMasterGet("sampel");
      dispatch({
        type: GET_LIST_SAMPLE,
        payload: response.data.result,
      });
    } catch (e) {}
  };
};

export const sendSample = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.AddSample.values;
    purifiedData(selected);
    if (selected.lokasi === undefined) {
      warning("Mohon Pilih Lokasi");
      return false;
    }
    if (selected.berat === undefined) {
      warning("Mohon Isi Berat");
      return false;
    }
    if (selected.kode_barang === undefined) {
      warning("Mohon Pilih Kode Barang");
      return false;
    }
    let data = {
      tanggal: selected.tanggal,
      lokasi: selected.lokasi,
      kode_barang: selected.kode_barang.split("/")[0],
      kode_bahan: selected.kode_barang.split("/")[1],
      nama_sampel: selected.nama_barang,
      nama_atribut: selected.atribut || "-",
      harga_atribut: selected.harga_atribut || 0,
      kadar_cetak: selected.kadar_cetak,
      kadar_jual: selected.kadar_jual,
      kadar_beli: selected.kadar_beli,
      berat: selected.berat,
      kode_intern: selected.kode_intern || "-",
      diamond_weight: selected.diamond_weight || 0,
      diamond_carat: selected.diamond_carat || 0,
      diamond_sn: selected.diamond_sn || "-",
      is_diamond: selected.isDiamond || false,
      gambar: "-",
    };
    try {
      const file =
        state.provinsi.cameraURI.length !== 0
          ? dataURLtoFile(state.provinsi.cameraURI)
          : undefined;
      if (file !== undefined) {
        data.gambar = await postImage(
          file,
          `foto_produk/${selected.kode_barang.split("/")[0]}/${
            selected.kode_barang.split("/")[1]
          }`
        ).catch((e) => {
          toast.error("Upload Gambar Gagal, Penyimpanan Data Dihentikan..");
          return false;
        });
        await toast.promise(AxiosMasterPost("sampel", data), ToastKirim);
      } else {
        await toast.promise(AxiosMasterPost("sampel", data), ToastKirim);
      }
    } catch (err) {
      dispatch(getSample());
      dispatch(finishSend());
    }
    dispatch(finishSend());
    dispatch(getSample());
    dispatch(reset("DataBank"));
    dispatch(hideModal());
  };
};

export const sendEditSample = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.EditSample.values;
    purifiedData(selected);
    let data = {
      nama_sampel: selected.nama_barang,
      nama_atribut: selected.atribut,
      harga_atribut: selected.harga_atribut,
      kode_intern: selected.kode_intern,
      diamond_weight: selected.diamond_weight || 0,
      diamond_carat: selected.diamond_carat || 0,
      diamond_sn: selected.diamond_sn || "-",
      is_diamond: selected.isDiamond || false,
      gambar:
        state.provinsi.cameraURI.length < 1
          ? state.sample.editSample.gambar
          : state.provinsi.cameraURI,
    };
    try {
      await toast.promise(
        AxiosMasterPut("sampel/" + selected.kode_sampel, data),
        ToastKirim
      );
      dispatch(finishSend());
      dispatch(getSample());
      dispatch(reset("FormBankEdit"));
      dispatch(hideModal());
    } catch (err) {
      dispatch(finishSend());
      dispatch(getSample());
      dispatch(reset("FormBankEdit"));
      dispatch(hideModal());
    }
  };
};

export const findJobOrderSample = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.AddSample.values;
    purifiedData(selected);
    let response = await toast.promise(
      AxiosMasterGetWithParams("job-order", {
        no_job_order: selected.no_job_order,
      }),
      ToastAmbil
    );
    dispatch(change("AddSample", "berat", response.data.bruto));
    dispatch(
      change("AddSample", "lokasi", {
        value: "PUSAT",
        label: "PUSAT",
      })
    );
    dispatch(
      change("AddSample", "kode_barang", {
        value: response.data.kode_barang + "/" + response.data.kode_bahan,
        label: response.data.kode_barang + " / " + response.data.kode_bahan,
      })
    );
    const result = await findBy(
      state.barang.listBarang,
      "kode_barang",
      response.data.kode_barang,
      "kode_bahan",
      response.data.kode_bahan
    );
    dispatch(change("AddSample", "kadar_cetak", result.kadar_cetak));
    dispatch(change("AddSample", "kadar_jual", result.kadar_jual));
    dispatch(finishSend());
  };
};

export const deleteSample = (kode) => {
  return async (dispatch, getState) => {
    Swal.fire({
      title: "Anda Yakin ?",
      text: "Anda Akan Menghapus Data Ini ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(progressSend());
        await toast.promise(AxiosMasterDelete("bank/" + kode), ToastKirim);
        dispatch(finishSend());
        dispatch(getSample());
        dispatch(reset("ProvinsiEdit"));
        dispatch(hideModal());
      }
    });
  };
};
