import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatGram } from "../../../helper";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporanPrintMutasi = (
  row1isi = "",
  row2isi = "",
  username = "",
  tanggal = "",
  validby = "",
  data
) => {
  // initialize jsPDF
  const doc = new jsPDF("portrait");
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  let footRows = [];
  let finalY = 25;
  let tableColumn = [];
  doc.setFontSize(15);
  doc.text("LAPORAN BARCODE SAMPLE", 14, 10);
  // doc.addImage(logo_ayu_base64, "PNG", 14, 10, 35, 12);

  doc.setFontSize(10);
  //row 1
  doc.text(`Mulai Dari : ${row1isi}`, 14, 16);
  //   row 2
  doc.text(`Sampai Dengan	: ${row2isi}`, 100, 16);
  //   row 2
  // doc.text(`Tanggal	: ${row2isi}`, 120, 25);
  data.forEach((barang, index) => {
    tableColumn = [
      [
        {
          content: `Kode Barcode`,
        },
        {
          content: `Kode Barang`,
        },
        {
          content: `Kode Bahan`,
        },
        {
          content: `Cap Kode`,
          styles: { halign: "right" },
        },
        {
          content: `Kadar Jual`,
        },
        {
          content: `Bruto (Gr)`,
          styles: { halign: "right" },
        },
        {
          content: `Pkg (Gr)`,
          styles: { halign: "right" },
        },
        {
          content: `Netto (Gr)`,
          styles: { halign: "right" },
        },
      ],
    ];
    barang.detail_barang.forEach((data) => {
      let rows = [
        data.kode_barcode,
        data.kode_barang,
        data.kode_bahan,
        { content: data.kadar_cetak, styles: { halign: "right" } },
        { content: data.kadar_jual, styles: { halign: "right" } },
        { content: formatGram(data.bruto, 3), styles: { halign: "right" } },
        { content: formatGram(data.pkg, 3), styles: { halign: "right" } },
        { content: formatGram(data.netto, 3), styles: { halign: "right" } },
      ];
      tableRows.push(rows);
    });
    doc.autoTable({
      head: tableColumn,
      body: tableRows,
      foot: footRows,
      showHead: "firstPage",
      showFoot: "lastPage",
      startY: index === 0 ? 25 : finalY + 5,
      theme: "plain",
      margin: { top: 20 },
      bodyStyles: { lineColor: "#969696", fontSize: 8, lineWidth: 0.0 },
      headStyles: {
        lineColor: "#969696",
        lineWidth: 0.0,
        fontSize: 8,
        fillColor: "#E8E8E8",
      },
      footStyles: {
        lineColor: "#969696",
        lineWidth: 0.0,
        fillColor: "#E8E8E8",
        fontSize: 8,
      },
    });
    finalY = doc.autoTableEndPosY();
    tableRows = [];
    tableColumn = [];
  });

  // const date = Date().split(" ");
  // we use a date string to generate our filename.
  // const dateStr = date[2] + date[3] + date[4];
  // ticket title. and margin-top + margin-left
  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  doc.autoPrint();
  doc.setProperties({
    title: "LAPORAN BARCODE SAMPLE",
  });
  // doc.save(`PenerimaanSUpplier.pdf`);
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    `
<html>
<head>
  <title>LAPORAN BARCODE SAMPLE</title>
  <style>
    #overlay {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1000;
    }
  </style>
</head>
<body style='margin:0 !important; position: relative;'>
  <iframe src='${string}#toolbar=0' width='100%' height='100%' frameborder='0' style='position: absolute; top: 0; left: 0;'></iframe>
  <div id='overlay' oncontextmenu='return false;'></div>
</body>
</html>
`
  );
  // setInterval(() => {
  //   x.close();
  // }, 1000);
};

export default LaporanPrintMutasi;
