import { AxiosMasterGet, AxiosMasterGetNormal } from "../Axios";

export const GET_SALDO_BARANG = "GET_SALDO_BARANG";
export const GET_SALDO_BARANG_REKAP = "GET_SALDO_BARANG_REKAP";
export const VALIDATE_OUTSTAND = "VALIDATE_OUTSTAND";
export const KIRIM_STOCK_PUSAT = "KIRIM_STOCK_PUSAT";

export const getSaldoBarang = (kode_kategori) => {
  return (dispatch) => {
    AxiosMasterGetNormal(`/saldo-barang/get/by-kategori/${kode_kategori}`)
      .then((res) =>
        dispatch({
          type: GET_SALDO_BARANG,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_SALDO_BARANG,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const getRekapKategori = () => {
  return (dispatch) => {
    AxiosMasterGetNormal(`/saldo-barang/rekap`)
      .then((res) =>
        dispatch({
          type: GET_SALDO_BARANG,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_SALDO_BARANG,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const getValidateOutstand = () => {
  return (dispatch) => {
    AxiosMasterGet(`validate-customer/get/outstand-pengiriman`)
      .then((res) =>
        dispatch({
          type: VALIDATE_OUTSTAND,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: VALIDATE_OUTSTAND,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const getKirimReturnPusat = () => {
  return (dispatch) => {
    AxiosMasterGet(`trx-customer/retur-customer/get/outstand-kirim`)
      .then((res) =>
        dispatch({
          type: KIRIM_STOCK_PUSAT,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: KIRIM_STOCK_PUSAT,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};
