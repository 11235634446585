import { change } from "redux-form";

export const GET_LIST_BARANG_PO = "GET_LIST_BARANG_PO";
export const SET_TAMBAH_BARANG_PO = "SET_TAMBAH_BARANG_PO";

export const getListBarangPo = () => {
  const data = JSON.parse(localStorage.getItem("barangPO_temp")) || [];
  return (dispatch) => {
    dispatch({
      type: GET_LIST_BARANG_PO,
      payload: {
        data: data,
      },
    });
  };
};

export const setTambahBarangPO = (data) => {
  return (dispatch) => {
    dispatch(change("FormPOCustomerNew", "kodeBarang", data));
  }
} 
