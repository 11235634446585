import { AxiosMasterGetWithParams } from "../../../Axios";

export const GET_CETAK_BARCODE = "GET_CETAK_BARCODE";

export const SendCetakBarcode = (feedback, logo) => {
  return async (dispatch, getState) => {
    const state = getState();
    const selected = state.form.CetakBarcodeHeadCADM.values;
    document.getElementById("myInput").value = feedback;
    const element = document.createElement("a");
    const file = new Blob([document.getElementById("myInput").value], {
      type: "text/plain;charset=utf-8",
    });
    element.href = URL.createObjectURL(file);
    console.log(selected.jenis_barcode);
    if (selected.jenis_barcode.value === "MUTASI") {
      if (logo) {
        element.download = "autoprint_barcode_sample_logo.txt";
      } else {
        element.download = "autoprint_barcode_sample.txt";
      }
    } else if (selected.jenis_barcode.value === "BARANG") {
      if (logo) {
        element.download = "autoprint_barcode_pabrik_logo.txt";
      } else {
        element.download = "autoprint_barcode_pabrik.txt";
      }
    } else {
      if (logo) {
        element.download = "autoprint_barcode_diamond_logo.txt";
      } else {
        element.download = "autoprint_barcode_diamond.txt";
      }
    }
    document.body.appendChild(element);
    element.click();
    window.location.reload();
  };
};

export const GetCetakBarcodeData = (type) => {
  return async (dispatch, getState) => {
    const response = await AxiosMasterGetWithParams("perhiasan/all", {
      type: type,
    });
    dispatch({
      type: GET_CETAK_BARCODE,
      payload: response.data,
    });
  };
};
