import React, { Component,  Suspense } from "react";

import { connect } from "react-redux";
import Swal from "sweetalert2";
import ReactTooltip from "react-tooltip";
import { reset } from "redux-form";
import {
  getListPindahBarang,
} from "../actions/pindahBarangAction";
import { getUUID } from "../actions/fakturAction";
import {
  finishSend,
  progressSend,
} from "../actions/LogicAction";
import PrintNota from "./Component/PrintNota";
import { gagal, warning } from "../Alert";
import { deleteIndex } from "../deleteLocalStorage";
import ModalMasterData from "../DataMaster/Component/ModalMasterData";
import { AxiosMasterPost } from "../Axios";
import Skeleton from "react-loading-skeleton";
import HeaderCard from "../HeaderCard";
import { formatGram,  getBon, getUserID, printInfo } from "../helper";
import HeadKirimStock from "./Component/HeadKirimStock";
import FormKirimStock from "./Component/FormKirimStock";
import { getListKirimStock, setEditKirimStock } from "../actions/kirimStock";
import { getToday } from "../GlobalComponent/function";
import HeadKirimStockEdit from "./Component/HeadKirimStockEdit";
import EditKirimStock from "./Component/EditKirimStock";
import { showModal } from "../actions/ModalAction";

const maptoState = (state) => {
  return {
    listBarang: state.provinsi.pindahBarang,
    listEditPindahBarang: state.provinsi.listEditPindahBarang,
    noFaktur: state.provinsi.noFaktur,
    username: state.provinsi.username,
    onSend: state.provinsi.onSend,
    kirimStock : state.kirimStock.list_barang
  };
};

class PindahBarang extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dari: [],
      kepada: [],
      tanggal: "",
      dariIsi: "",
      ke: "",
      noBon: "",
      kategori: "",
      kodeJenis: "",
      keterangan: "",
      isEdit: false,
      handleEdit: false,
      columns: [
        {
          dataField: "kode_jenis",
          text: "Kode Barang",
        },
        {
          dataField: "bruto",
          text: "Bruto (Gr)",
          align: "right",
          formatter: (data) => formatGram(data),
          headerAlign: "right",
        },
        {
          dataField: "netto",
          text: "Netto (Gr)",
          align: "right",
          headerAlign: "right",
          formatter: (data) => formatGram(data, 3),
        },
        {
          dataField: "Aksi",
          text: "Aksi",
          csvExport: false,
          formatter: (rowContent, row, rowIndex) => {
            return (
              <div className="text-center">
                <button
                  data-tip
                  data-for="hapus"
                  type="button"
                  className="btn btn-danger"
                  onClick={() =>
                    deleteIndex(
                      "pindahBarang",
                      rowIndex,
                      this.props.dispatch,
                      getListPindahBarang()
                    )
                  }
                >
                  <ReactTooltip id="hapus" type="dark" effect="solid">
                    <span>Hapus</span>
                  </ReactTooltip>
                  <i className="fa fa-trash"></i>
                </button>
                <br />
              </div>
            );
          },
        },
      ],
    };
  }
  setEdit(row) {
    this.props.dispatch(setEditKirimStock(row));
    this.props.dispatch(getListKirimStock())
    this.setState({
      handleEdit: true,
    });
  }
  getTabelData() {
    this.props.dispatch(getListPindahBarang());
  }
  componentDidMount() {
    this.props.dispatch(getListKirimStock());
    this.props.dispatch(getUUID());
    this.dataEditPindahBarang();
    localStorage.removeItem("kirimStock")
    this.setState({
      noBon: getBon()
    })
  }
  handleSubmit(data) {
    var supp = data.kodeBarang
    this.setState({
      kodeJenis: supp
    });
    var barang1 = JSON.parse(localStorage.getItem("kirimStock")) || [];
    
    const index = barang1.findIndex((x) => x.kode_jenis === supp)
    if(index < 0){
      if(barang1.length === 1){
        warning("Barang sudah ada di tabel")
        return false
      }
      var simpan = {
        kode_jenis: supp,
        kadar_cetak : "100",
        kadar_modal: 100,
        bruto: data.bruto,
        netto: Number(data.bruto) * 100 / 100
      };
      barang1.push(simpan);
    } else {
        barang1.splice(index, 1);
        var simpan2 = {
          kode_jenis: supp,
          kadar_cetak : "100",
          kadar_modal: 100,
          bruto: data.bruto,
          netto: Number(data.bruto) * 100 / 100
        };
        barang1.push(simpan2);
    }
    localStorage.setItem("kirimStock", JSON.stringify(barang1));
    Swal.fire({
      position: "top-end",
      icon: "success",
      text: "Transaksi Berhasil",
      showConfirmButton: false,
      timer: 1500,
    })
      .then(() => this.props.dispatch(reset("FormKirimStock")))
      .then(() => this.props.dispatch(getListKirimStock()))
      .catch((err) =>
        gagal(`Gagal Simpan Data, Detail : ${err.response.data}`)
      );
  }

  SubmitPindah(data) {
    if (localStorage.getItem("kirimStock") === null) {
      warning("Mohon Tambahkan Barang Yang akan dipindahkan");
      return false;
    }
    const hasil = JSON.parse(localStorage.getItem("kirimStock"))
    var isi = {
      no_kirim: data.no_kirim || this.props.noFaktur,
      no_bon: data.no_bon || "AS/KS-" + this.state.noBon,
      tanggal: getToday(),
      kode_customer: data.kode_customer.split("|")[0],
      kode_kategori: data.kode_kategori,
      total_bruto: hasil.map((x) => parseFloat(x.bruto)).reduce((a,b) => a+b, 0),
      total_netto: hasil.map((x) => parseFloat(x.netto)).reduce((a,b) => a+b, 0),
      detail_barang: hasil,
    };
    this.props.dispatch(progressSend());
    console.log(JSON.stringify(isi));
    // INISIALISASI AUTOTABLE
    const tableRows = [];
    var table = JSON.parse(localStorage.getItem("kirimStock"));
    // for each ticket pass all its data into an array
    table.forEach((ticket, i) => {
      const ticketData = [
        ++i,
        ticket.kode_jenis,
        { content: formatGram(ticket.bruto), styles: { halign: "right" } },
        { content: formatGram(ticket.netto), styles: { halign: "right" } },
      ];
      // push each tickcet's info into a row
      tableRows.push(ticketData);
    });
    const footer = [
      { content: "" },
      { content: "Grand Total" },
      {
        content: table
          .map((list) => parseFloat(list.bruto))
          .reduce((a, b) => a + b, 0),
        styles: {
          halign: "right",
        },
      },
      {
        content: formatGram(
          table.map((list) => parseFloat(list.netto)).reduce((a, b) => a + b, 0)
        ),
        styles: {
          halign: "right",
        },
      },
    ];
    // tableRows.push(footer);
    var columTable = [
      [
        { content: "No" },
        { content: "Kode Jenis" },
        {
          content: "Bruto (Gr)",
          styles: {
            halign: "right",
          },
        },
        {
          content: "Netto (Gr)",
          styles: {
            halign: "right",
          },
        }
      ],
    ];
    // AKHIR INISIALISASI AUTOTABLE
    AxiosMasterPost("/kirim-stock/simpan", isi)
      .then(() =>
        Swal.fire({
          position: "top-end",
          icon: "success",
          text: "Transaksi Berhasil",
          showConfirmButton: false,
          timer: 1500,
        })
          .then(() => this.props.dispatch(finishSend()))
          .then(() => localStorage.removeItem("noFaktur"))
          .then(() =>
            PrintNota(
              "Tanggal",
              getToday(),
              "Customer",
              data.kode_customer.split("|")[1],
              "Nomor Bon",
              this.state.noBon,
              "",
              "",
              getUserID(),
              getToday(),
              "",
              columTable,
              "Kirim Stock",
              tableRows,
              [footer, printInfo()]
            )
          )
          .then(() => localStorage.removeItem("kirimStock"))
          .then(() => this.props.dispatch(reset("headKirimStock")))
      )
      .then(() => this.props.dispatch(finishSend()))
      .then(() => localStorage.removeItem("noFaktur"))
      .then(() => localStorage.removeItem("kirimStock"))
      .then(() => this.props.dispatch(getUUID()))
      .then(() => this.props.dispatch(getListKirimStock()))
      .then(()=> window.location.reload())
      .catch((err) =>
        Swal.fire({
          position: "top-end",
          icon: "error",
         text : err?.response?.data || "Terjadi Kesalahan Internal, Silahkan Hubungi Admin",
          showConfirmButton: false,
          timer: 1500,
        })
          .then(() => console.log(err))
          .then(() => this.props.dispatch(finishSend()))
      );
      
  }
  onChange = (input) => (e) => {
    this.setState({
      [input]: e.target.value,
    });
  };
  resetField() {
    localStorage.removeItem("editPindahBarang");
    localStorage.removeItem("pindahBarang");
    this.props.dispatch(getUUID());
    this.props.dispatch(getListPindahBarang());
    this.setState({
      tanggal: "",
      dariIsi: "",
      ke: "",
      noBon: "",
      kategori: "",
      kodeJenis: "",
      keterangan: "",
    });
  }
  dataEditPindahBarang() {
    var data = JSON.parse(localStorage.getItem("editPindahBarang")) || [];
    this.setState({
      dariIsi: data.kodeLokasiAsal,
      ke: data.kodeLokasiTujuan,
      tanggal: data.tanggal,
      keterangan: data.keterangan,
      noBon: data.noBon,
    });
    this.props.dispatch(getListPindahBarang());
  }
  showTambahBarang() {
    this.setState({
      isEdit : false
    })

    this.props.dispatch(showModal())
  }
  showEditKirimStock() {
    this.setState({
      isEdit : true
    })
    console.log(this.state.isEdit);
    this.props.dispatch(showModal())
  }

  render() {
    return (
      <div className="container-fluid" style={{ color: "black" }}>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <HeaderCard title="Kirim Stock" />
              <div className="card-body">
                <div className="row">
                  <ModalMasterData
                    content={
                      this.state.isEdit ? <Suspense
                      fallback={
                        <Skeleton width={"100%"} height={50} count={3} />
                      }
                    >
                      <EditKirimStock
                       setEdit={(data) => this.setEdit(data)}
                       
                      />
                    </Suspense> : <Suspense
                        fallback={
                          <Skeleton width={"100%"} height={50} count={3} />
                        }
                      >
                        <FormKirimStock
                          onSubmit={(data) => this.handleSubmit(data)}
                          listBarang={this.props.listBarang}
                         
                        />
                      </Suspense>
                    }
                    title="Tambah Data Pindah Barang"
                  />
                  <div className="col-lg-12">
                    <div className="col-lg-3 mb-2">
                      <button className="btn btn-secondary" onClick={() => {
                        this.showEditKirimStock() 
                      }}>Edit Kirim Stock</button>
                    </div>
                    {this.state.handleEdit ? <HeadKirimStockEdit
                      onSubmit={(data) => this.SubmitPindah(data)}
                      onSend={this.props.onSend}
                      tambahModal={() => this.showTambahBarang()}
                      showTambahBarang={() => this.showTambahBarang()}
                    /> : <HeadKirimStock
                    onSubmit={(data) => this.SubmitPindah(data)}
                    onSend={this.props.onSend}
                    tambahModal={() => this.showTambahBarang()}
                  />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(maptoState, null)(PindahBarang);
