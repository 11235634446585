import firebase from "./firebase";

const namaTimbangan = localStorage.getItem("namaTimbangan");
let enableTimbangan = localStorage.getItem("enableTimbangan");
export const getTimbangan = (props) => {
  if (enableTimbangan) {
    firebase
      .database()
      .ref(namaTimbangan)
      .on("value", (snapshot) => {
        let data = snapshot.val();
        props("bruto", data.nilai);
        console.log(data.nilai);
      });
  } else {
    console.log("TIMBANGAN TIDAK AKTIF");
  }
};
export const getTimbanganValidasi = (props) => {
  if (enableTimbangan) {
    firebase
      .database()
      .ref(namaTimbangan)
      .on("value", (snapshot) => {
        let data = snapshot.val();
        props("jumlah", data.nilai);
        console.log(data.nilai);
        let coba = Object.keys(data).map((list) => data[list]);
        console.log(coba);
      });
  } else {
    console.log("TIMBANGAN TIDAK AKTIF");
  }
};
export const getTimbanganGross = (props) => {
  if (enableTimbangan) {
    firebase
      .database()
      .ref(namaTimbangan)
      .on("value", (snapshot) => {
        let data = snapshot.val();
        props("gross", data.nilai);
        console.log(data.nilai);
        let coba = Object.keys(data).map((list) => data[list]);
        console.log(coba);
      });
  } else {
    console.log("TIMBANGAN TIDAK AKTIF");
  }
};
