import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatGram, printInfo } from "../../../helper";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporanGlobal24K = (data) => {
  // initialize jsPDF
  const doc = new jsPDF("landscape");
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];

  let tableColumn = [];
  let finalY = 25;
  let listKelompok = [];
  let listFootKelompok = [];
  let footer = [
    {
      content: "Grand Total",
      colSpan: 2,
      styles: { halign: "right" },
    },
  ];
  doc.setFontSize(15);
  doc.text("LAPORAN GLOBAL 24K", 14, 10);
  // doc.addImage(logo_ayu_base64, "PNG", 14, 10, 35, 12);
  doc.setFontSize(10);
  data.kelompok.forEach((x) => {
    listKelompok = data.kelompok.map((x) => {
      return {
        content: x,
        colSpan: 3,
        styles: { halign: "center" },
      };
    });
  });
  data.kelompok.forEach((x) => {
    let temp = [
      {
        content: "-",
      },
      {
        content: "+",
      },
      {
        content: "Saldo",
      },
    ];
    listFootKelompok.push(...temp);
  });
  tableColumn = [
    [
      {
        content: `TANGGAL`,
        rowSpan: 2,
        styles: { valign: "center" },
      },
      {
        content: `KETERANGAN`,
        rowSpan: 2,
        styles: { valign: "center" },
      },
      ...listKelompok,
    ],
    listFootKelompok,
  ];
  data.detail.forEach((hasil) => {
    let temp = [];
    hasil.detail_saldo.forEach((x) => {
      let result = [
        { content: formatGram(x.stock_out), styles: { halign: "right" } },
        { content: formatGram(x.stock_in), styles: { halign: "right" } },
        { content: formatGram(x.stock), styles: { halign: "right" } },
      ];
      temp.push(...result);
    });
    let rows = [hasil.tanggal, hasil.keterangan, ...temp];
    data.kelompok.forEach((x, index) => {
      let temp = [
        {
          content: formatGram(hasil.detail_saldo[index]["stock_out"]),
          styles: { halign: "right" },
        },
        {
          content: formatGram(hasil.detail_saldo[index]["stock_in"]),
          styles: { halign: "right" },
        },
        {
          content: formatGram(hasil.detail_saldo[index]["stock"]),
          styles: { halign: "right" },
        },
      ];
      footer.push(...temp);
    });
    tableRows.push(rows);
  });

  // let footer = [
  //   {
  //     content: "Grand Total",
  //     colSpan: 3,
  //     styles: {
  //       halign: "right",
  //     },
  //   },
  //   {
  //     content: formatGram(total_bruto),
  //     styles: {
  //       halign: "right",
  //     },
  //   },
  //   {
  //     content: formatGram(total_netto, 3),
  //     styles: {
  //       halign: "right",
  //     },
  //   },
  // ];
  //   // const date = Date().split(" ");
  //   // we use a date string to generate our filename.
  //   // const dateStr = date[2] + date[3] + date[4];
  //   // ticket title. and margin-top + margin-left
  //   finalY = doc.autoTableEndPosY() + 20;
  //   footer = [];
  //   tableRows = [];
  //   tableColumn = [];
  //   total_bruto = 0;
  //   total_netto = 0;
  // });

  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    foot: [footer, printInfo()],
    startY: finalY,
    theme: "plain",
    rowPageBreak: "avoid",

    margin: { top: 20 },
    bodyStyles: { lineColor: "#969696", fontSize: 8, lineWidth: 0.0 },
    headStyles: {
      lineColor: "#969696",
      lineWidth: 0.2,
      fontSize: 8,
      fillColor: "#E8E8E8",
    },
    footStyles: {
      lineColor: "#969696",
      lineWidth: 0.0,
      fillColor: "#E8E8E8",
      fontSize: 8,
    },
  });

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  doc.autoPrint();
  doc.setProperties({
    title: "LAPORAN GLOBAL 24K",
  });
  // doc.save(`PenerimaanSUpplier.pdf`);
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    `
<html>
<head>
  <title>LAPORAN GLOBAL 24K</title>
  <style>
    #overlay {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1000;
    }
  </style>
</head>
<body style='margin:0 !important; position: relative;'>
  <iframe src='${string}#toolbar=0' width='100%' height='100%' frameborder='0' style='position: absolute; top: 0; left: 0;'></iframe>
  <div id='overlay' oncontextmenu='return false;'></div>
</body>
</html>
`
  );
  // setInterval(() => {
  //   x.close();
  // }, 1000);
};

export default LaporanGlobal24K;
