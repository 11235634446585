import React, { Component } from "react";
import { connect } from "react-redux";
import { finishSend, progressSend } from "../actions/LogicAction";
import { gagal } from "../Alert";
import { AxiosMasterGet } from "../Axios";
import { getToday } from "../getDate";
import { checkLaporan } from "../helper";
import FormTimbangStock from "./Component/FormTimbangStock";
import LaporanPrintTimbangStock from "./Component/LaporanPrintTimbangStock";

class laporanTimbangStock extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleProses(data) {
    this.props.dispatch(progressSend());
    let kode_kategori = data.kode_kategori;
    let tanggal = data.tanggal || getToday();
      AxiosMasterGet(`report/timbang-stock/${tanggal}&${kode_kategori}`)
        .then((res) => {
          this.props.dispatch(finishSend());
          if (checkLaporan(res.data[0]?.detail_barang)) {
              LaporanPrintTimbangStock(
                localStorage.getItem("username"),
                getToday(),
                localStorage.getItem("username"),
                res.data,
              )
          }
        })
        .catch((err) =>
          gagal("Gagal Mengambil data, Mohon periksa koneksi anda").then(() =>
            this.props.dispatch(finishSend())
          )
    );
  }
  render() {
    return (
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                    <li className="nav-item">
                      <a
                        href="#proses-tab"
                        data-toggle="tab"
                        aria-expanded="false"
                        className="nav-link active"
                      >
                        <i className="mdi mdi-home-variant d-lg-none d-block mr-1" />
                        <span className="d-none d-lg-block">Laporan Timbang Stock</span>
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div className="tab-pane show active" id="proses-tab">
                      <FormTimbangStock
                        from="PROSES"
                        onSubmit={(data) => this.handleProses(data)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(laporanTimbangStock);
