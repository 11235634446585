import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { getToday } from "../../GlobalComponent/function";
import { renderField, renderFieldSelect } from "../../renderField";
import { LaporanExcelTambahSaldo24K } from "../laporan_tambah_saldo_24k/component/LaporanExcelTambahSaldo24K";
import { PrintLaporanTambahSaldo24KPDF } from "../laporan_tambah_saldo_24k/redux/laporan_tambah_saldo_24k_action";
import { LaporanExcelPenjualan24K } from "../laporan_penjualan_24k/component/LaporanExcelPenjualan24K";
import { PrintLaporanPenjualan24KPDF } from "../laporan_penjualan_24k/redux/laporan_penjualan_24k_action";
import { PrintLaporanGlobal24KPDF } from "../laporan_global_24k/redux/laporan_global_24k_action";
import { LaporanExcelGlobal24K } from "../laporan_global_24k/component/LaporanExcelGlobal24K";
import { formatGram, showOwnerOnly } from "../../helper";
import GlobalTabel from "../../GlobalComponent/GlobalTabel";

class Form24K extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type_laporan: "SIMPLE",
      tambahSaldo: [
        {
          dataField: "kode_barang",
          text: "Kode Barang",
        },
        {
          dataField: "kadar_beli",
          text: "Kadar Beli",
        },
        {
          dataField: "pkg",
          text: "Pkg",
        },
        {
          dataField: "bruto",
          text: "Bruto",
          align: "right",
          headerAlign: "right",
          formatter: (data) => formatGram(data),
        },
        {
          dataField: "netto",
          text: "Netto",
          align: "right",
          headerAlign: "right",
          formatter: (data) => formatGram(data),
        },
      ],
      penjualan24K: [
        {
          dataField: "no_bon",
          text: "No Bon",
        },
        {
          dataField: "kode_customer",
          text: "Kode Customer",
        },
        {
          dataField: "nama_toko",
          text: "Nama Toko",
        },
        {
          dataField: "total_netto",
          text: "Total Netto",
          align: "right",
          headerAlign: "right",
          formatter: (data) => formatGram(data),
        },
      ],
    };
  }

  render() {
    const listBarang = [{ value: "ALL", name: "SEMUA" }];
    this.props.listBarang.forEach((x) => {
      listBarang.push({
        value: x.kode_24k,
        name: x.kode_24k + "/" + x.nama_24k,
      });
    });
    const listCustomer = [{ value: "ALL", name: "SEMUA" }];
    this.props.listCustomer.forEach((x) => {
      listCustomer.push({
        value: x.kode_customer,
        name: x.nama_customer + "/" + x.nama_toko,
      });
    });
    switch (this.props.from) {
      case "GLOBAL BAHAN":
        return (
          <form onSubmit={this.props.handleSubmit}>
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-3">
                  <Field
                    type="date"
                    label="Dari Tanggal"
                    name="tglFrom"
                    component={renderField}
                  />
                </div>

                <div className="col-lg-3">
                  <Field
                    type="date"
                    label="Sampai Tanggal"
                    name="tglTo"
                    component={renderField}
                  />
                </div>
                <div className={"col-lg-3"}>
                  <label> &nbsp;</label>
                  <button
                    className="btn btn-primary form-control"
                    type="submit"
                    disabled={this.props.onSend}
                  >
                    {this.props.onSend ? (
                      <span
                        className="spinner-border spinner-border-sm mr-3"
                        aria-hidden="true"
                      />
                    ) : (
                      <i className="fa fa-search mr-2" />
                    )}
                    Cari Data
                  </button>
                </div>
              </div>
              {this.props.dataLaporanGlobal24k.data.detail.length > 0 && (
                <div className={showOwnerOnly()}>
                  <div className={"col-lg-6"}>
                    <LaporanExcelGlobal24K
                      data={this.props.dataLaporanGlobal24k.data}
                    />
                  </div>
                  <div className={"col-lg-6"}>
                    <button
                      className={"btn btn-danger form-control"}
                      onClick={() =>
                        this.props.dispatch(PrintLaporanGlobal24KPDF())
                      }
                    >
                      Export PDF
                    </button>
                  </div>
                </div>
              )}
            </div>
          </form>
        );
      case "PENJUALAN":
        return (
          <form onSubmit={this.props.handleSubmit}>
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-3">
                  <div className="form-group">
                    <Field
                      name="kodeCustomer"
                      label={"Customer"}
                      component={renderFieldSelect}
                      options={listCustomer}
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <Field
                    type="date"
                    label="Dari Tanggal"
                    name="tglFrom"
                    component={renderField}
                  />
                </div>

                <div className="col-lg-3">
                  <Field
                    type="date"
                    label="Sampai Tanggal"
                    name="tglTo"
                    component={renderField}
                  />
                </div>
                <div className={"col-lg-3"}>
                  <label> &nbsp;</label>
                  <button
                    className="btn btn-primary form-control"
                    type="submit"
                    disabled={this.props.onSend}
                  >
                    {this.props.onSend ? (
                      <span
                        className="spinner-border spinner-border-sm mr-3"
                        aria-hidden="true"
                      />
                    ) : (
                      <i className="fa fa-search mr-2" />
                    )}
                    Cari Data
                  </button>
                </div>
              </div>
              {this.props.dataLaporanPenjualan24k.data.length > 0 && (
                <div className={showOwnerOnly()}>
                  <div className={"col-lg-12 mt-2"}>
                    <GlobalTabel
                      keyField={"no_bon"}
                      columns={this.state.penjualan24K}
                      data={this.props.dataLaporanPenjualan24k.data}
                    />
                  </div>
                  <div className={"col-lg-6"}>
                    <LaporanExcelPenjualan24K
                      data={this.props.dataLaporanPenjualan24k}
                    />
                  </div>
                  <div className={"col-lg-6"}>
                    <button
                      className={"btn btn-danger form-control"}
                      onClick={() =>
                        this.props.dispatch(PrintLaporanPenjualan24KPDF())
                      }
                    >
                      Export PDF
                    </button>
                  </div>
                </div>
              )}
            </div>
          </form>
        );
      case "TAMBAH SALDO":
        return (
          <form onSubmit={this.props.handleSubmit}>
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-3">
                  <div className="form-group">
                    <Field
                      name="kodeBarang"
                      label={"Kode 24K"}
                      component={renderFieldSelect}
                      options={listBarang}
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <Field
                    type="date"
                    label="Dari Tanggal"
                    name="tglFrom"
                    component={renderField}
                  />
                </div>

                <div className="col-lg-3">
                  <Field
                    type="date"
                    label="Sampai Tanggal"
                    name="tglTo"
                    component={renderField}
                  />
                </div>
                <div className={"col-lg-3"}>
                  <label> &nbsp;</label>
                  <button
                    className="btn btn-primary form-control"
                    type="submit"
                    disabled={this.props.onSend}
                  >
                    {this.props.onSend ? (
                      <span
                        className="spinner-border spinner-border-sm mr-3"
                        aria-hidden="true"
                      />
                    ) : (
                      <i className="fa fa-search mr-2" />
                    )}
                    Cari Data
                  </button>
                </div>
              </div>
              {this.props.dataLaporanTambahSaldo24k.data.length > 0 && (
                <div className={showOwnerOnly()}>
                  {this.props.dataLaporanTambahSaldo24k.data.map((x) => {
                    return (
                      <>
                        <div className={"col-lg-12 mt-2"}>
                          <h3 className={"text-left"}>
                            No Transaksi : {x.no_transaksi}
                          </h3>
                          <div className={"col-lg-12 mt-2"}>
                            <GlobalTabel
                              keyField={"_id"}
                              columns={this.state.tambahSaldo}
                              data={x.detail_barang}
                            />
                          </div>
                        </div>
                      </>
                    );
                  })}
                  <div className={"col-lg-6"}>
                    <LaporanExcelTambahSaldo24K
                      data={this.props.dataLaporanTambahSaldo24k.data}
                    />
                  </div>
                  <div className={"col-lg-6"}>
                    <button
                      className={"btn btn-danger form-control"}
                      onClick={() =>
                        this.props.dispatch(PrintLaporanTambahSaldo24KPDF())
                      }
                    >
                      Export PDF
                    </button>
                  </div>
                </div>
              )}
            </div>
          </form>
        );
      default:
        break;
    }
  }
}

Form24K = reduxForm({
  form: "Form24K",
  enableReinitialize: true,
})(Form24K);
export default connect((state) => {
  return {
    listBarang: state.master24k.list24K,
    listCustomer: state.provinsi.customer,
    listBank: state.provinsi.bank,
    dataLaporanKeuanganCash: state.laporan.dataLaporanKeuanganCash,
    dataLaporanTambahSaldo24k: state.laporan.dataLaporanTambahSaldo24k,
    dataLaporanPenjualan24k: state.laporan.dataLaporanPenjualan24k,
    dataLaporanGlobal24k: state.laporan.dataLaporanGlobal24k,
    dataLaporanKeuanganCashCard: state.laporan.dataLaporanKeuanganCashCard,
    dataLaporanKeuanganCashSummary:
      state.laporan.dataLaporanKeuanganCashSummary,
    dataLaporanKeuanganTransfer: state.laporan.dataLaporanKeuanganTransfer,
    dataLaporanKeuanganTransferCard:
      state.laporan.dataLaporanKeuanganTransferCard,
    dataLaporanKeuanganTransferSummary:
      state.laporan.dataLaporanKeuanganTransferSummary,
    onSend: state.provinsi.onSend,
    initialValues: { tglFrom: getToday(), tglTo: getToday() },
  };
})(Form24K);
