import { finishSend, progressSend } from "../../../actions/LogicAction";
import { toast } from "react-toastify";
import { AxiosMasterGet } from "../../../Axios";
import { ToastAmbil } from "../../../actions/toast";
import LaporanTitipCustomer from "../component/LaporanTitipCustomer";
import LaporanTitipCUstomerCard from "../component/LaporanTitipCustomerCard";
import LaporanTitipCustomerSummary from "../component/LaporanTitipCustomerSummary";

export const GET_LAPORAN_TITIP_CUSTOMER = "GET_LAPORAN_TITIP_CUSTOMER";
export const GET_LAPORAN_TITIP_CUSTOMER_CARD =
  "GET_LAPORAN_TITIP_CUSTOMER_CARD";
export const GET_LAPORAN_TITIP_CUSTOMER_SUMMARY =
  "GET_LAPORAN_TITIP_CUSTOMER_SUMMARY";

export const GetLaporanTitipCustomer = (kode_customer) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    try {
      const response = await toast.promise(
        AxiosMasterGet(`report/titip-customer/${kode_customer.split("|")[0]}`),
        ToastAmbil
      );
      dispatch({
        type: GET_LAPORAN_TITIP_CUSTOMER,
        payload: {
          data: response.data,
          kode_customer: kode_customer,
        },
      });
      dispatch(finishSend());
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

export const GetLaporanTitipCustomerCard = (
  kode_customer,
  jenis_transaksi,
  tanggal_awal,
  tanggal_akhir
) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    try {
      const response = await toast.promise(
        AxiosMasterGet(
          `report/titip-customer-card/${
            kode_customer.split("|")[0]
          }&${jenis_transaksi}&${tanggal_awal}&${tanggal_akhir}`
        ),
        ToastAmbil
      );
      dispatch({
        type: GET_LAPORAN_TITIP_CUSTOMER_CARD,
        payload: {
          data: response.data,
          kode_customer: kode_customer,
          jenis_transaksi: jenis_transaksi,
          tanggal_awal: tanggal_awal,
          tanggal_akhir: tanggal_akhir,
        },
      });
      dispatch(finishSend());
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

export const GetLaporanTitipCustomerSummary = (
  kode_customer,
  jenis_transaksi,
  tanggal_awal,
  tanggal_akhir
) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    try {
      const response = await toast.promise(
        AxiosMasterGet(
          `report/titip-customer-card/${
            kode_customer.split("|")[0]
          }&${jenis_transaksi}&${tanggal_awal}&${tanggal_akhir}`
        ),
        ToastAmbil
      );
      dispatch({
        type: GET_LAPORAN_TITIP_CUSTOMER_SUMMARY,
        payload: {
          data: response.data,
          kode_customer: kode_customer,
          jenis_transaksi: jenis_transaksi,
          tanggal_awal: tanggal_awal,
          tanggal_akhir: tanggal_akhir,
        },
      });
      dispatch(finishSend());
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

export const ResetLaporanTitipCustomer = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_LAPORAN_TITIP_CUSTOMER_SUMMARY,
      payload: {
        data: [],
        kode_customer: "",
        jenis_transaksi: "",
        tanggal_awal: "",
        tanggal_akhir: "",
      },
    });
    dispatch({
      type: GET_LAPORAN_TITIP_CUSTOMER_CARD,
      payload: {
        data: [],
        kode_customer: "",
        jenis_transaksi: "",
        tanggal_awal: "",
        tanggal_akhir: "",
      },
    });
    dispatch({
      type: GET_LAPORAN_TITIP_CUSTOMER,
      payload: {
        data: [],
        kode_customer: "",
      },
    });
  };
};

export const PrintLaporanTitipCustomerPDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanTitipCustomer = state.laporan.dataLaporanTitipCustomer;
    LaporanTitipCustomer(dataLaporanTitipCustomer.data);
  };
};

export const PrintLaporanTitipCustomerCardPDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanTitipCustomerCard =
      state.laporan.dataLaporanTitipCustomerCard;
    LaporanTitipCUstomerCard(
      dataLaporanTitipCustomerCard.data,
      dataLaporanTitipCustomerCard.tanggal_awal,
      dataLaporanTitipCustomerCard.tanggal_akhir
    );
  };
};

export const PrintLaporanTitipCustomerSummaryPDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanTitipCustomerSummary =
      state.laporan.dataLaporanTitipCustomerSummary;
    LaporanTitipCustomerSummary(
      dataLaporanTitipCustomerSummary.data,
      dataLaporanTitipCustomerSummary.tanggal_awal,
      dataLaporanTitipCustomerSummary.tanggal_akhir
    );
  };
};
