import React from "react";

function CustomForm(props) {
  const number = props.number || 1;
  return (
    <form
      onSubmit={props.onSubmit}
      onKeyPress={(e) => {
        if (
          document.activeElement ===
          document.getElementById("button_selesai_" + number)
        ) {
        } else {
          e.key === "Enter" && e.preventDefault();
        }
      }}
    >
      {props.children}
    </form>
  );
}

export default CustomForm;
