import { finishSend, progressSend } from "../../../actions/LogicAction";
import { toast } from "react-toastify";
import { AxiosMasterGet } from "../../../Axios";
import { ToastAmbil } from "../../../actions/toast";
import LaporanStockPerKategori from "../component/LaporanStockPerKategori";
import { GET_LAPORAN_STOCK_PER_LOKASI } from "../../laporan_stock_per_lokasi/redux/laporan_stock_per_lokasi_action";

export const GET_LAPORAN_PER_KATEGORI = "GET_LAPORAN_PER_KATEGORI";

export const GetLaporanStockingPerKategori = (
  group_by,
  filter_by,
  kode_kategori,
  kode_lokasi
) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    try {
      const response = await toast.promise(
        AxiosMasterGet(
          "report/stock-barang/" +
            `${group_by}&${filter_by}&${kode_kategori || kode_lokasi || "ALL"}`
        ),
        ToastAmbil
      );
      dispatch({
        type: GET_LAPORAN_PER_KATEGORI,
        payload: response.data,
      });
      dispatch(finishSend());
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

export const ResetLaporanStockBarang = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_LAPORAN_PER_KATEGORI,
      payload: [],
    });
    dispatch({
      type: GET_LAPORAN_STOCK_PER_LOKASI,
      payload: {
        data: [],
        kode_lokasi: "",
      },
    });
  };
};

export const PrintLaporanStockPerKategoriPDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanStockPerKategori =
      state.laporan.dataLaporanStockPerKategori;
    LaporanStockPerKategori(dataLaporanStockPerKategori);
  };
};
