import { finishSend, progressSend } from "../../../actions/LogicAction";
import { toast } from "react-toastify";
import { AxiosMasterGet } from "../../../Axios";
import { ToastAmbil } from "../../../actions/toast";
import LaporanTolakanSupplier from "../component/LaporanTolakanSupplier";
import { getToday } from "../../../GlobalComponent/function";
import { getUserID } from "../../../helper";

export const GET_LAPORAN_TOLAKAN_SUPPLIER = "GET_LAPORAN_TOLAKAN_SUPPLIER";

export const GetLaporanTolakanSupplier = (
  kode_supplier,
  tanggal_awal,
  tanggal_akhir
) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    try {
      const response = await toast.promise(
        AxiosMasterGet(
          `report/tolakan-supplier/${kode_supplier}&${tanggal_awal}&${tanggal_akhir}`
        ),
        ToastAmbil
      );
      dispatch({
        type: GET_LAPORAN_TOLAKAN_SUPPLIER,
        payload: {
          data: response.data,
          tanggal_awal: tanggal_awal,
          tanggal_akhir: tanggal_akhir,
          kode_supplier: kode_supplier,
        },
      });
      dispatch(finishSend());
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

export const PrintLaporanTolakanSupplierPDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanTolakanSupplier = state.laporan.dataLaporanTolakanSupplier;
    LaporanTolakanSupplier(
      dataLaporanTolakanSupplier.kode_supplier,
      dataLaporanTolakanSupplier.tanggal_awal,
      dataLaporanTolakanSupplier.tanggal_akhir,
      getToday(),
      getUserID(),
      dataLaporanTolakanSupplier.data
    );
  };
};
