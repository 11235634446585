import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import validationRosok from "../../../Validations/validationRosok";
import { renderField, renderFieldSelect } from "../../../renderField";
import { SkeletonLoading } from "../../../GlobalComponent/function";
import { preventTabAction } from "../../../helper";
import CustomForm from "../../../CustomForm";
import ButtonSelesai from "../../../button_selesai";

class FormMaster24K extends Component {
  constructor(props) {
    super(props);
    this.state = {
      response: "",
      listPerhiasan: [],
      listJual: [],
      listBeli: [],
      listKategori: [],
    };
  }

  componentDidMount() {
    preventTabAction();
  }

  render() {
    return (
      <CustomForm onSubmit={this.props.handleSubmit}>
        <div className="row">
          {/* Awal FIeld */}
          <div className="col-md-3">
            <div className="form-group">
              {String(this.props.listKelompok) !== "" ? (
                <Field
                  component={renderFieldSelect}
                  label="Kode Kategori"
                  name="kodeKategori"
                  options={this.props.listKelompok
                    .filter((x) => x.kode_kelompok === "24K")
                    .map((list) => {
                      return {
                        value: `${list.kode_group}`,
                        name: list.kode_group,
                      };
                    })}
                />
              ) : (
                <SkeletonLoading label="Kode Jenis" />
              )}
            </div>
          </div>
          {/* Akhir Field */}
          {/* Awal Field */}
          <div className="col-md-3">
            <Field
              name="kodeJenis"
              type="text"
              normalize={(value) => value && value.toUpperCase()}
              component={renderField}
              label="Kode Jenis"
            />
          </div>
          {/* Akhir Field */}
          {/* Awal Field */}
          <div className="col-md-6">
            <Field
              name="namaJenis"
              type="text"
              normalize={(value) => value && value.toUpperCase()}
              component={renderField}
              label="Nama Jenis"
            />
          </div>
          {/* Akhir Field */}
          {/* Awal Field */}
          <div className="col-md-4 col-lg-4">
            {String(this.props.listKadar) !== "" ? (
              <Field
                component={renderFieldSelect}
                label="Cap Kode"
                name="kadarCetak"
                options={this.props.listKadar
                  .filter((x) => x.type_kadar === "KADAR-CETAK")
                  .map((obj) => {
                    return {
                      value: obj.kadar,
                      name: obj.kadar,
                    };
                  })}
              />
            ) : (
              <SkeletonLoading label="Cap Kode" />
            )}
          </div>
          <div className="col-md-4 col-lg-4">
            {String(this.props.listKadar) !== "" ? (
              <Field
                component={renderFieldSelect}
                label="Kadar Beli"
                name="kadarBeli"
                options={this.props.listKadar
                  .filter((x) => x.type_kadar === "KADAR-BELI")
                  .map((obj) => {
                    return {
                      value: obj.kadar,
                      name: obj.kadar,
                    };
                  })}
              />
            ) : (
              <SkeletonLoading label="Kadar Beli" />
            )}
          </div>
          <div className="col-md-4 col-lg-4">
            {String(this.props.listKadar) !== "" ? (
              <Field
                component={renderFieldSelect}
                label="Kadar Jual"
                name="kadarJual"
                options={this.props.listKadar
                  .filter((x) => x.type_kadar === "KADAR-JUAL")
                  .map((obj) => {
                    return {
                      value: obj.kadar,
                      name: obj.kadar,
                    };
                  })}
              />
            ) : (
              <SkeletonLoading label="Kadar Jual" />
            )}
          </div>
          {/* AKhir FIeld */}

          <div className="col-lg-12 col-md-12 mt-3">
            <ButtonSelesai />
          </div>
        </div>
      </CustomForm>
    );
  }
}

FormMaster24K = reduxForm({
  form: "FormMaster24K",
  enableReinitialize: true,
  validate: validationRosok,
})(FormMaster24K);
export default connect((state) => {
  return {
    listKadar: state.provinsi.kadar,
    listKelompok: state.provinsi.Kelompok,
  };
})(FormMaster24K);
