import React, { useEffect } from "react";
import GlobalTabel from "../../../GlobalComponent/GlobalTabel";
import { connect, useDispatch, useSelector } from "react-redux";
import { formatGram, formatRp, getToday } from "../../../helper";
import { change, Field, reduxForm } from "redux-form";
import { renderField } from "../../../renderField";

const DetailBarangClosePenjualan = (props) => {
  const dispatch = useDispatch();
  let onSend = useSelector((state) => state.provinsi.onSend);
  let data = useSelector((state) => state.closePenjualan.editClosePenjualan);
  const { handleSubmit } = props;
  const columns = [
    {
      dataField: "kode_barang",
      text: "Kode Barang",
    },
    {
      dataField: "kode_bahan",
      text: "Kode Bahan",
    },
    {
      dataField: "kadar_cetak",
      text: "Cap Kode",
      align: "right",
      headerAlign: "right",
    },
    {
      dataField: "qty",
      text: "Qty",
      align: "right",
      headerAlign: "right",
      headerStyle: () => {
        return { width: "5%" };
      },
    },
    {
      dataField: "pkg",
      text: "Bungkus\n(Gr)",
      align: "right",
      headerAlign: "right",
      formatter: (data) => formatGram(data),
    },
    {
      dataField: "bruto",
      text: "Berat\n(Gr)",
      align: "right",
      headerAlign: "right",
      formatter: (data) => formatGram(data),
    },
    {
      dataField: "kadar_jual",
      text: "Kode\nTukar",
      align: "right",
      headerAlign: "right",
      headerStyle: () => {
        return { width: "7%" };
      },
    },
    {
      dataField: "netto",
      text: "Murni\n(Gr)",
      align: "right",
      headerAlign: "right",
      formatter: (data) => formatGram(data, 3),
      footerClasses: "sum-netto-terima-supplier",
    },
    {
      dataField: "disc_gram",
      text: "Disc\n(Gr)",
      align: "right",
      headerAlign: "right",
      formatter: (data) => formatGram(data),
      footerClasses: "sum-netto-terima-supplier",
    },
    {
      dataField: "netto_disc",
      text: "Netto\nDisc(Gr)",
      align: "right",
      headerAlign: "right",
      formatter: (data) => formatGram(data, 3),
    },
    {
      dataField: "ongkos_rp",
      text: "Ongkos\n(Rp)",
      align: "right",
      headerAlign: "right",
      formatter: (data) => {
        return formatRp(data);
      },
    },
  ];
  useEffect(() => {
    dispatch(change("DetailBarangClosePenjualan", "tanggal", getToday()));
  }, [dispatch]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="col-lg-3">
        <Field
          name={"tanggal"}
          label={"Tangal Close"}
          component={renderField}
          type={"date"}
        />
      </div>
      <div className="col-lg-12 col-md-12">
        <GlobalTabel keyField="no_penjualan" data={data} columns={columns} />
        <div className={"row justify-content-end"}>
          <div className={"col-lg-12"}>
            <Field
              name={"keterangan"}
              label={"Keterangan"}
              component={renderField}
              normalize={(data) => data && data.toUpperCase()}
            />
          </div>
          <button type="submit" disabled={onSend} className="btn btn-primary">
            {onSend ? (
              <span
                className="spinner-border spinner-border-sm mr-3"
                aria-hidden="true"
              />
            ) : null}
            Simpan
            <i className="fa fa-paper-plane ml-3" />
          </button>
        </div>
      </div>
    </form>
  );
};

export default connect()(
  reduxForm({
    form: "DetailBarangClosePenjualan",
  })(DetailBarangClosePenjualan)
);
