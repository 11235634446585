import { finishSend, progressSend } from "../../../actions/LogicAction";
import { toast } from "react-toastify";
import LaporanPenjualanCustomer from "../component/LaporanPenjualanCustomer";
import { AxiosMasterGetWithParams } from "../../../Axios";
import { purifiedData } from "../../../helper";
import { warning } from "../../../Alert";
import { change } from "redux-form";

export const GET_LAPORAN_PENJUALAN_CUSTOMER = "GET_LAPORAN_PENJUALAN_CUSTOMER";

export const GetLaporanPenjualanCustomer = (data) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    dispatch(change("FormCustomer", "kode_customer", data.kode_customer));
    purifiedData(data);
    if (data.kode_customer === undefined) {
      warning("Mohon Pilih Customer");
      dispatch(finishSend());
      return false;
    } else if (data.kode_sales === undefined) {
      warning("Mohon Pilih Sales");
      dispatch(finishSend());
      return false;
    }
    // dispatch(change("FormCustomer", "kode_customer", rawData.kode_customer));
    // dispatch(change("FormCustomer", "kode_sales", rawData.kode_sales));
    const params = {
      kode_customer: data.kode_customer.split("|")[0],
      kode_barang: data.kode_barang,
      kode_sales: data.kode_sales,
      start_date: data.tglFrom,
      end_date: data.tglTo,
    };
    AxiosMasterGetWithParams(`report/penjualan/real`, params)
      .then((response) => {
        dispatch({
          type: GET_LAPORAN_PENJUALAN_CUSTOMER,
          payload: {
            data: response.data,
            tglFrom: data.tglFrom,
            tglTo: data.tglTo,
            kodeBarang: data.kode_barang,
          },
        });
        dispatch(finishSend());
        if (response.data.length === 0) {
          toast.info("Data Kosong...");
        } else {
          toast.success("Mengambil Data Berhasil... ✔️✔️");
        }
      })
      .catch(() => {
        dispatch(finishSend());
      });
  };
};

export const PrintLaporanPenjualanCustomerPDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanPenjualanCustomer =
      state.laporan.dataLaporanPenjualanCustomer;
    LaporanPenjualanCustomer(
      dataLaporanPenjualanCustomer.data,
      dataLaporanPenjualanCustomer.tglFrom,
      dataLaporanPenjualanCustomer.tglTo,
      dataLaporanPenjualanCustomer.kodeBarang
    );
  };
};
