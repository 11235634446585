const validasiSaldoAwalCT = (value) => {
  const errors = {};
  if (!value.tanggal) {
    errors.tanggal = "Tidak Boleh kosong";
  }
  if (!value.kode_kategori) {
    errors.kode_kategori = "Tidak Boleh kosong";
  }
  if (!value.bruto) {
    errors.bruto = "Tidak Boleh kosong";
  }
  if (!value.netto) {
    errors.netto = "Tidak Boleh kosong";
  }
  return errors;
};

export default validasiSaldoAwalCT;
