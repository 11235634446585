import {GET_KIRIM_STOCK_BAYAR} from "./kirim_stock_bayar_action";

const initialState = {
    listItemTemp : []
}

const kirimStockBayar = (state = initialState, action) => {
    switch (action.type) {
        case GET_KIRIM_STOCK_BAYAR: {
            return {
                ...state,
                listItemTemp : action.payload
            }
        }
        default:
            return state
    }
}

export default kirimStockBayar