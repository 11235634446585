export const GET_CAMERA_URI = "GET_CAMERA_URI";
export const GET_CAMERA_URI_S = "GET_CAMERA_URI_S";
export const GET_CAMERA_URI_M = "GET_CAMERA_URI_M";

export const setCameraURI = (dataUri) => {
  return (dispatch) => {
    dispatch({
      type: GET_CAMERA_URI,
      payload: {
        data: dataUri,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const setCameraURI_S = (dataUri) => {
  return (dispatch) => {
    dispatch({
      type: GET_CAMERA_URI_S,
      payload: {
        data: dataUri,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const setCameraURI_M = (dataUri) => {
  return (dispatch) => {
    dispatch({
      type: GET_CAMERA_URI_M,
      payload: {
        data: dataUri,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};