import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  convertKadarCetak,
  formatGram,
  formatRp,
  printInfo,
} from "../../../helper";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporanPrintReturnSupplier = (
  row1isi = "",
  row2isi = "",
  username = "",
  tanggal = "",
  validby = "",
  data
) => {
  // initialize jsPDF
  const doc = new jsPDF("portrait");
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  let footRows = [];
  let tableColumn = [];
  let finalY = 25;
  doc.setFontSize(15);
  doc.text("LAPORAN RETURN SUPPLIER - DETAIL", 14, 10);
  // doc.addImage(logo_ayu_base64, "PNG", 14, 10, 35, 12);
  doc.setFontSize(10);
  //row 1
  doc.text(`Mulai Dari : ${row1isi}`, 14, 16);
  //   row 2
  doc.text(`Sampai Dengan : ${row2isi}`, 100, 16);
  data.forEach((item, index) => {
    doc.text(`Kode Supplier : ${item.kode_supplier}`, 14, 21);
    doc.text(`Nama Supplier : ${item.nama_supplier}`, 100, 21);
    let total_netto = 0;
    item.detail.forEach((list, index) => {
      tableColumn = [
        [
          {
            content: `TANGGAL INVOICE : ${list.tanggal_invoice}`,
            colSpan: 12,
          },
        ],
        [
          {
            content: `NO BON : ${list.no_bon}`,
            colSpan: 12,
          },
        ],
        [
          {
            content: `NO`,
          },
          {
            content: `KODE BARANG`,
          },
          {
            content: `KODE BAHAN`,
          },
          {
            content: `CAP KODE`,
            styles: { halign: "right", cellWidth: 16 },
          },
          {
            content: `HARGA BELI`,
            styles: { halign: "right", cellWidth: 16 },
          },
          {
            content: `QTY`,
            styles: { halign: "right" },
          },
          {
            content: `P`,
            styles: { halign: "right" },
          },
          {
            content: `K`,
            styles: { halign: "right" },
          },
          {
            content: `PKG`,
            styles: { halign: "right" },
          },
          {
            content: `GROSS (GR)`,
            styles: { halign: "right" },
          },
          {
            content: `BRUTO (GR)`,
            styles: { halign: "right" },
          },
          {
            content: `NETTO (GR)`,
            styles: { halign: "right" },
          },
          {
            content: `ONGKOS (RP)`,
            styles: { halign: "right" },
          },
        ],
      ];
      list.detail_barang.forEach((barang, index) => {
        let rows = [
          barang.no_urut,
          barang.kode_barang,
          barang.kode_bahan,
          {
            content: convertKadarCetak(barang.kode_bahan, barang.kadar_cetak),
            styles: { halign: "right" },
          },
          { content: barang.kadar_beli, styles: { halign: "right" } },
          { content: barang.qty, styles: { halign: "right" } },
          { content: formatGram(barang.plastik), styles: { halign: "right" } },
          { content: formatGram(barang.kulit), styles: { halign: "right" } },
          { content: formatGram(barang.pkg), styles: { halign: "right" } },
          { content: formatGram(barang.gross), styles: { halign: "right" } },
          { content: formatGram(barang.bruto), styles: { halign: "right" } },
          { content: formatGram(barang.netto, 3), styles: { halign: "right" } },
          { content: formatRp(barang.ongkos_rp), styles: { halign: "right" } },
        ];
        tableRows.push(rows);
        console.log(tableRows);
        total_netto = barang.netto + total_netto;
      });
      let footer = [
        { content: "Grand Total", styles: { halign: "right" }, colSpan: 10 },
        { content: formatGram(list.total_bruto), styles: { halign: "right" } },
        {
          content: formatGram(total_netto, 3),
          styles: { halign: "right" },
        },
      ];
      footRows.push(footer);
      footRows.push(printInfo());
      doc.autoTable({
        head: tableColumn,
        body: tableRows,
        foot: footRows,
        startY: index === 0 ? 25 : finalY + 5,
        theme: "plain",
        rowPageBreak: "avoid",
        pageBreak: "avoid",
        margin: { top: 20 },
        bodyStyles: { lineColor: "#969696", fontSize: 8, lineWidth: 0.0 },
        headStyles: {
          lineColor: "#969696",
          lineWidth: 0.0,
          fontSize: 8,
          fillColor: "#E8E8E8",
        },
        footStyles: {
          lineColor: "#969696",
          lineWidth: 0.0,
          fillColor: "#E8E8E8",
          fontSize: 8,
        },
      });
      finalY = doc.autoTableEndPosY() + 10;
      tableRows = [];
      footRows = [];
    });
  });

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  doc.autoPrint();
  doc.setProperties({
    title: "LAPORAN RETURN SUPPLIER - DETAIL",
  });
  // doc.save(`RETURNSUpplier.pdf`);
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    `
<html>
<head>
  <title>LAPORAN RETURN SUPPLIER - DETAIL</title>
  <style>
    #overlay {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1000;
    }
  </style>
</head>
<body style='margin:0 !important; position: relative;'>
  <iframe src='${string}#toolbar=0' width='100%' height='100%' frameborder='0' style='position: absolute; top: 0; left: 0;'></iframe>
  <div id='overlay' oncontextmenu='return false;'></div>
</body>
</html>
`
  );
  // setInterval(() => {
  //   x.close();
  // }, 1000);
};

export default LaporanPrintReturnSupplier;
