import React, { Component } from "react";
import { getCetakUlang } from "../../actions/cetakUlangAction";
import { getUUID } from "../../actions/fakturAction";
import { getsupplierList } from "../../DataMaster/supplier/redux/supplierAction";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import HeaderCard from "../../HeaderCard";
import GlobalTabel from "../../GlobalComponent/GlobalTabel";
import { formValueSelector, reduxForm } from "redux-form";
import { getcustomerList } from "../../DataMaster/customer/redux/customerAction";
import { AxiosMasterGet } from "../../Axios";
import { checkLaporan, formatGram, formatRp, getUserID } from "../../helper";
import { getToday } from "../../GlobalComponent/function";
import { gagal } from "../../Alert";
import PrintNotaSuratJalan from "../../Stocking/Component/PrintNotaSuratJalan";

class FormLaporanPackingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listSupplier: [],
      columns: [
        {
          dataField: "tanggal",
          text: "Tanggal",
        },

        {
          dataField: "no_ext_packing",
          text: "Nomor Packing",
        },
        {
          dataField: "Aksi",
          text: "Aksi",
          csvExport: false,
          formatter: (rowContent, row) => {
            return (
              <div className="text-center">
                <button
                  className="btn btn-warning mr-2"
                  onClick={() => this.getDetails(row.no_packing)}
                >
                  <i className="fa fa-print mr-1"></i>
                  Print
                </button>
              </div>
            );
          },
        },
      ],
      data: [
        {
          tanggal: "2020-11-12",
        },
      ],
      listPenerimaan: "ada",
    };
  }
  componentDidMount() {
    this.props.dispatch(getsupplierList());
    this.props.dispatch(getcustomerList());
    this.props.dispatch(getUUID());
    this.props.dispatch(getCetakUlang());
    this.caridata();
  }
  inputChange = (input) => (e) => {
    this.setState({
      [input]: e.target.value,
    });
  };

  pilihSupplier = (input) => (e) => {
    var supp = e.target.value ? e.target.value : "DEFALT | DEFAULT";
    var result_explode = supp.split("|");
    this.setState({
      kodeSupplier: result_explode[0],
      namaSupplier: result_explode[1],
    });
  };
  caridata() {
    AxiosMasterGet(`packing-list/get-packing-list-open-no-image`).then(
      (res) => {
        if (checkLaporan(res.data)) {
          this.setState({
            hasilDataGet: res.data,
          });
        }
      }
    );
  }

  async getDetails(data) {
    let hasil = [];
    await AxiosMasterGet(`packing-list/get-packing/${data}`)
      .then((res) => {
        // console.log(res.data);
        // return false
        let gambar = [];
        hasil = res.data[0];
        console.log(hasil);
        gambar.push(...hasil.image);
        let packingM = res.data[0]?.detail_barang || [];
        let tableRows = [];
        let columTable = [];
        let footer = [];
        let bruto_total = 0;
        let ongkos_total = 0;
        let gross_total = 0;
        packingM.forEach((ticket, i) => {
          const ticketData = [
            ++i,
            ticket.nama_jenis,
            { content: formatGram(ticket.bruto), styles: { halign: "right" } },
            {
              content: formatRp(ticket.ongkos_rp),
              styles: { halign: "right" },
            },
          ];
          tableRows.push(ticketData);
          bruto_total = parseFloat(bruto_total) + parseFloat(ticket.bruto);
          ongkos_total =
            parseInt(ongkos_total) + parseInt(ticket.ongkos_rp || 0);
          gross_total = parseFloat(gross_total) + parseFloat(ticket.gross);
        });
        footer = [
          {
            content: "Total",
            colSpan: 2,
          },
          {
            content: `${formatGram(bruto_total)}`,
            styles: { halign: "right" },
          },
          {
            content: `${formatRp(ongkos_total)}`,
            styles: { halign: "right" },
          },
        ];
        let total_berat = [
          {
            content: "Total Berat Packing",
            colSpan: 2,
          },
          {
            content: `${formatGram(res.data[0]?.berat_packing)}`,
            styles: { halign: "right" },
          },
        ];
        let total_gross = [
          {
            content: "Total Gross",
            colSpan: 2,
          },
          {
            content: `${formatGram(gross_total)}`,
            styles: { halign: "right" },
          },
        ];
        tableRows.push(footer);
        tableRows.push(total_berat);
        tableRows.push(total_gross);
        columTable = [
          [
            "No",
            "Nama Jenis",
            { content: "Bruto (Rp)", styles: { halign: "right" } },
            { content: "Ongkos (Rp)", styles: { halign: "right" } },
          ],
        ];
        // AKHIR INISIALISASI AUTOTABLE
        PrintNotaSuratJalan(
          "No Nota",
          hasil.no_packing,
          "Tanggal",
          hasil.tanggal,
          "Customer",
          "ADMIN INTERN",
          "",
          "",
          getUserID(),
          getToday(),
          "",
          columTable,
          "SURAT JALAN",
          tableRows,
          gambar
        );
        PrintNotaSuratJalan(
          "No Nota",
          hasil.no_packing,
          "Tanggal",
          hasil.tanggal,
          "Customer",
          hasil.kode_customer,
          "",
          "",
          getUserID(),
          getToday(),
          "",
          columTable,
          "SURAT JALAN",
          tableRows,
          gambar
        );
        // PrintNota(
        //   "No Nota",
        //   hasil.no_packing,
        //   "Tanggal",
        //   hasil.tanggal,
        //   "Customer",
        //   hasil.kode_customer,
        //   "",
        //   "",
        //   getUserID(),
        //   getToday(),
        //   "",
        //   columTable,
        //   "PACKING LIST",
        //   tableRows
        // );
      })
      .catch((err) =>
        gagal(`Gagal Ambil Packing, Detail : ${err.response.data}`)
      );
  }
  render() {
    return (
      <>
        <div className="container-fluid">
          <ol className="breadcrumb mb-2">
            <Link to="/cetakUlangBukti">
              <button type="button" className="btn btn-secondary">
                <i className="fa fa-angle-left mr-3" aria-hidden="true"></i>{" "}
                Back
              </button>
            </Link>
          </ol>
          <div className="card">
            <HeaderCard title="Cetak Ulang Packing list" />
            <div className="card-body">
              <br />
              <div className="row">
                <div className="col-md-12 col-lg-12 mt-3">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="text-left">
                        {/* <Link to="/ViewPDF">
                              <button className="btn btn-primary" type="button">
                                PDF
                              </button>
                            </Link> */}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <GlobalTabel
                        keyField="no_bon"
                        data={this.state.hasilDataGet}
                        columns={this.state.columns}
                        search
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

FormLaporanPackingList = reduxForm({
  form: "FormLaporanPackingList",
})(FormLaporanPackingList);
const selector = formValueSelector("FormLaporanPackingList");
export default connect((state) => {
  const { kode_customer, tanggal_awal, tanggal_akhir } = selector(
    state,
    "kode_customer",
    "tanggal_awal",
    "tanggal_akhir"
  );
  return {
    customer: state.provinsi.customer,
    kode_customer: kode_customer,
    tanggal_akhir: tanggal_akhir,
    tanggal_awal: tanggal_awal,
    initialValues: {
      tanggal_awal: getToday(),
      tanggal_akhir: getToday(),
    },
  };
})(FormLaporanPackingList);
