import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatGram, formatRp, printInfo } from "../../../helper";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporanHutangSupplierCard = (data, mulai_dari, sampai_dengan) => {
  // initialize jsPDF
  const doc = new jsPDF("portrait");
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];

  doc.setFontSize(15);
  doc.text("LAPORAN HUTANG SUPPLIER - CARD ", 14, 10);
  // doc.addImage(logo_ayu_base64, "PNG", 14, 10, 35, 12);

  doc.setFontSize(10);
  doc.text(`Mulai Dari : ${mulai_dari}`, 14, 16);
  //   row 2
  doc.text(`Sampai Dengan	: ${sampai_dengan}`, 100, 16);
  data.forEach((item, index) => {
    let tableColumn = [
      [
        {
          content: `KODE SUPPLIER : ${item.kode_supplier}`,
          colSpan: 6,
        },
        {
          content: `NAMA SUPPLIER : ${item.nama_supplier}`,
          colSpan: 5,
        },
      ],
      [
        {
          content: `TANGGAL`,
        },
        {
          content: `AWAL\nNETTO`,
          styles: { halign: "right" },
        },
        {
          content: `AWAL\nRP`,
          styles: { halign: "right" },
        },
        {
          content: `IN\nNETTO`,
          styles: { halign: "right" },
        },
        {
          content: `IN\nRP`,
          styles: { halign: "right" },
        },
        {
          content: `OUT\nNETTO`,
          styles: { halign: "right" },
        },
        {
          content: `OUT\nRP`,
          styles: { halign: "right" },
        },
        {
          content: `AKHIR\nNETTO`,
          styles: { halign: "right" },
        },
        {
          content: `AKHIR\nRP`,
          styles: { halign: "right" },
        },
        {
          content: `KETERANGAN`,
        },
        {
          content: `NO FAKTUR`,
        },
      ],
    ];
    item.detail.forEach((hasil) => {
      let rows = [
        hasil.tanggal,
        { content: formatGram(hasil.awal_netto), styles: { halign: "right" } },
        { content: formatRp(hasil.awal_rp), styles: { halign: "right" } },
        { content: formatGram(hasil.in_netto), styles: { halign: "right" } },
        { content: formatRp(hasil.in_rp), styles: { halign: "right" } },
        { content: formatGram(hasil.out_netto), styles: { halign: "right" } },
        { content: formatRp(hasil.out_rp), styles: { halign: "right" } },
        { content: formatGram(hasil.akhir_netto), styles: { halign: "right" } },
        { content: formatRp(hasil.akhir_rp), styles: { halign: "right" } },
        hasil.keterangan,
        hasil.no_ext,
      ];
      tableRows.push(rows);
    });
    doc.autoTable({
      head: tableColumn,
      body: tableRows,
      foot: [printInfo()],
      showHead: "firstPage",
      showFoot: "lastPage",
      startY: 20,
      theme: "plain",
      rowPageBreak: "avoid",

      margin: { top: 20 },
      bodyStyles: { lineColor: "#969696", fontSize: 8, lineWidth: 0.0 },
      headStyles: {
        lineColor: "#969696",
        lineWidth: 0.0,
        fontSize: 8,
        fillColor: "#E8E8E8",
      },
      footStyles: {
        lineColor: "#969696",
        lineWidth: 0.0,
        fillColor: "#E8E8E8",
        fontSize: 8,
      },
    });
  });
  // const date = Date().split(" ");
  // we use a date string to generate our filename.
  // const dateStr = date[2] + date[3] + date[4];
  // ticket title. and margin-top + margin-left

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  doc.autoPrint();
  doc.setProperties({
    title: "LAPORAN HUTANG SUPPLIER - CARD",
  });
  // doc.save(`PenerimaanSUpplier.pdf`);
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    `
<html>
<head>
  <title>LAPORAN HUTANG SUPPLIER - CARD</title>
  <style>
    #overlay {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1000;
    }
  </style>
</head>
<body style='margin:0 !important; position: relative;'>
  <iframe src='${string}#toolbar=0' width='100%' height='100%' frameborder='0' style='position: absolute; top: 0; left: 0;'></iframe>
  <div id='overlay' oncontextmenu='return false;'></div>
</body>
</html>
`
  );
  // setInterval(() => {
  //   x.close();
  // }, 1000);
};

export default LaporanHutangSupplierCard;
