import {
  AxiosMasterDelete,
  AxiosMasterGet,
  AxiosMasterPost,
  AxiosMasterPut,
} from "../../../Axios";
import { toast } from "react-toastify";
import { ToastKirim } from "../../../actions/toast";
import { finishSend, progressSend } from "../../../actions/LogicAction";
import { hideModal } from "../../../actions/ModalAction";
import Swal from "sweetalert2";
import { purifiedData } from "../../../helper";

export const GET_24K = "GET_24K";
export const EDIT_24K = "EDIT_24K";

export const getList24K = () => {
  return (dispatch) => {
    AxiosMasterGet("24K")
      .then((res) =>
        dispatch({
          type: GET_24K,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_24K,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const edit24K = (data) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_24K,
      payload: data,
    });
  };
};

export const send24K = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const selected = state.form.FormMaster24K.values;
    purifiedData(selected);
    let data = {
      kode_kategori: selected.kodeKategori,
      kode_kelompok: "24K",
      kode_24k: selected.kodeJenis,
      nama_24k: selected.namaJenis,
      kadar_cetak: selected.kadarCetak.toString(),
      kadar_beli: selected.kadarBeli,
      kadar_jual: selected.kadarJual,
      kode_barcode:
        selected.kodeJenis +
        "|" +
        selected.namaJenis +
        "|" +
        selected.kadarCetak +
        "|" +
        selected.kadarJual,
    };
    await toast.promise(AxiosMasterPost("24K", data), ToastKirim);
    dispatch(finishSend());
    dispatch(hideModal());
    dispatch(getList24K());
  };
};

export const delete24K = (kode) => {
  return async (dispatch, getstate) => {
    dispatch(progressSend());
    Swal.fire({
      title: "Anda Yakin ?",
      text: "Anda Akan Menghapus Data Ini ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await toast.promise(AxiosMasterDelete("24K/" + kode), ToastKirim);
          dispatch(getList24K());
          dispatch(finishSend());
        } catch (e) {
          dispatch(finishSend());
        }
      }
    });
    dispatch(finishSend());
  };
};

export const sendEdit24K = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const selected = state.form.FormMaster24KEdit.values;
    purifiedData(selected);
    let data = {
      kode_kategori: selected.kodeKategori,
      kode_kelompok: "24K",
      kode_24k: selected.kodeJenis,
      nama_24k: selected.namaJenis,
      kadar_cetak: selected.kadarCetak.toString(),
      kadar_beli: selected.kadarBeli,
      kadar_jual: selected.kadarJual,
      kode_barcode:
        selected.kodeJenis +
        "|" +
        selected.namaJenis +
        "|" +
        selected.kadarCetak +
        "|" +
        selected.kadarJual,
    };
    await toast.promise(AxiosMasterPut("24K", data), ToastKirim);
    dispatch(finishSend());
    dispatch(hideModal());
    dispatch(getList24K());
  };
};
