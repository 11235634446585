import {finishSend, progressSend} from "../../../actions/LogicAction";
import {toast} from "react-toastify";
import {AxiosMasterGet} from "../../../Axios";
import {ToastAmbil} from "../../../actions/toast";
import {getUserID} from "../../../helper";
import {getToday} from "../../../GlobalComponent/function";
import LaporanPrintPacking from "../component/LaporanPrintPacking";


export const GET_LAPORAN_PINDAH_BARANG = "GET_LAPORAN_PINDAH_BARANG"


export const GetLaporanPackingBarang = (tanggal_awal,tanggal_akhir) => {
  return async (dispatch ,getState) => {
    dispatch(progressSend())
      try {
                const repsonse = await toast.promise(AxiosMasterGet(
                    `report/packing-list/ALL&${tanggal_awal}&${tanggal_akhir}`
                ), ToastAmbil)
            dispatch({
                type : GET_LAPORAN_PINDAH_BARANG,
                payload : {
                    data : repsonse.data,
                    tanggal_awal: tanggal_awal,
                    tanggal_akhir : tanggal_akhir
                }
            })
          dispatch(finishSend())
            } catch (e) {

            dispatch(finishSend())
            }
  }
}

export const PrintLaporanPackingBarangPDF = () => {
    return async  (dispatch, getState) => {
        const state = getState();
        const dataLaporanPackingBarang = state.laporan.dataLaporanPackingBarang
        LaporanPrintPacking(
            dataLaporanPackingBarang.tanggal_awal,
            dataLaporanPackingBarang.tanggal_akhir,
            getUserID(),
            getToday(),
            getUserID(),
            dataLaporanPackingBarang.data
        );
    }
}