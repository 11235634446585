import { finishSend, progressSend } from "../../../actions/LogicAction";
import { toast } from "react-toastify";
import { AxiosMasterGet } from "../../../Axios";
import { ToastAmbil } from "../../../actions/toast";
import LaporanPembayaranCustomer from "../component/LaporanPembayaranCustomer";

export const GET_LAPORAN_PEMBAYARAN_CUSTOMER =
  "GET_LAPORAN_PEMBAYARAN_CUSTOMER";

export const GetLaporanPembayaranCustomer = (kode_customer, tglFrom, tglTo) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    try {
      const response = await toast.promise(
        AxiosMasterGet(
          `report/bayar-piutang-customer/${
            kode_customer.split("|")[0]
          }&${tglFrom}&${tglTo}&REKAP&REKAP`
        ),
        ToastAmbil
      );
      dispatch({
        type: GET_LAPORAN_PEMBAYARAN_CUSTOMER,
        payload: {
          data: response.data,
          kode_customer: kode_customer,
          tglFrom: tglFrom,
          tglTo: tglTo,
        },
      });
      dispatch(finishSend());
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

export const PrintLaporanPembayaranCustomerPDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanPembayaranCustomer =
      state.laporan.dataLaporanPembayaranCustomer;
    LaporanPembayaranCustomer(
      dataLaporanPembayaranCustomer.data,
      dataLaporanPembayaranCustomer.tglFrom,
      dataLaporanPembayaranCustomer.tglTo
    );
  };
};
