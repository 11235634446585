import { toast } from "react-toastify";
import { reset } from "redux-form";
import { AxiosMasterGet, AxiosMasterPut } from "../../../Axios";
import { finishSend, progressSend } from "../../../actions/LogicAction";
import { hideModal } from "../../../actions/ModalAction";
import { ToastKirim } from "../../../actions/toast";

export const GET_PARAMETER_HARGA_EMAS_LIST = "GET_PARAMETER_HARGA_EMAS_LIST";
export const GET_PARAMETER_HARGA_EMAS = "GET_PARAMETER_HARGA_EMAS";
export const EDIT_PARAMETER_HARGA_EMAS = "EDIT_PARAMETER_HARGA_EMAS";

export const getParameterHargaEmasList = () => {
  return (dispatch) => {
    AxiosMasterGet("harga-emas")
      .then((res) =>
        dispatch({
          type: GET_PARAMETER_HARGA_EMAS_LIST,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_PARAMETER_HARGA_EMAS_LIST,
          payload: {
            data: null,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const getParameterHargaEmas = (kode_harga) => {
  return (dispatch) => {
    AxiosMasterGet(`harga-emas/${kode_harga}`)
      .then((res) => {
        dispatch({
          type: GET_PARAMETER_HARGA_EMAS,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        });
      })
      .catch((err) =>
        dispatch({
          type: GET_PARAMETER_HARGA_EMAS,
          payload: {
            data: null,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const editParameterHargaEmas = (kode_harga, rupiah, usd) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_PARAMETER_HARGA_EMAS,
      payload: {
        kode_harga: kode_harga,
        rupiah: rupiah,
        usd: usd,
      },
    });
  };
};

export const sendEditParameterHargaEmas = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.FormParameterHargaEmasEdit.values;
    let data = {
      rupiah: selected.rupiah,
      usd: selected.usd,
    };
    try {
      await toast.promise(
        AxiosMasterPut("harga-emas/" + selected.kode_harga, data),
        ToastKirim
      );
      dispatch(finishSend());
      dispatch(getParameterHargaEmasList());
      dispatch(reset("FormParameterHargaEmasEdit"));
      dispatch(hideModal());
    } catch (err) {
      dispatch(finishSend());
      dispatch(getParameterHargaEmasList());
      dispatch(reset("FormParameterHargaEmasEdit"));
      dispatch(hideModal());
    }
  };
};
