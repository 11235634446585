import React, { Component } from "react";
import { connect } from "react-redux";
import {requestLaporanValidasi} from "./redux/laporan_action";
import FormLaporanValidasi from "./laporan_validasi/FormLaporanValidasi";

class laporanValidasi extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleProses(data) {
    this.props.dispatch(requestLaporanValidasi())
  }
  render() {
    return (
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                    <li className="nav-item">
                      <a
                        href="#validasi-tab"
                        data-toggle="tab"
                        aria-expanded="false"
                        className="nav-link active"
                      >
                        <i className="mdi mdi-home-variant d-lg-none d-block mr-1" />
                        <span className="d-none d-lg-block">
                          Laporan Validasi
                        </span>
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div className="tab-pane show active" id="validasi-tab">
                      <FormLaporanValidasi
                        from="PROSES"
                        onSubmit={(data) => this.handleProses(data)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(laporanValidasi);
