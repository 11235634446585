import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { getLokasi } from "../../../DataMaster/lokasi/redux/lokasiAction";
import { showModal } from "../../../actions/ModalAction";
import { getListTambah24K } from "../redux/kirim-bahan-24k-action";
import { getToday } from "../../../getDate";
import GlobalTabel from "../../../GlobalComponent/GlobalTabel";
import { formatGram, getBon, preventTabAction } from "../../../helper";
import { renderField } from "../../../renderField";
import validasiHeadMutasiBarang from "../../../Validations/validasiHeadMutasiBarang";
import { Dropdown } from "react-bootstrap";
import { deleteLocalTemp } from "../../../actions/LogicAction";
import CustomForm from "../../../CustomForm";
import ButtonSelesai from "../../../button_selesai";

class HeadKirimBahan24K extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lokasi: false,
      columns: [
        {
          dataField: "kode_barang",
          text: "Kode Barang",
        },
        {
          dataField: "kadar_beli",
          text: "Kadar Beli",
          align: "right",
          headerAlign: "right",
        },
        {
          dataField: "pkg",
          text: "Pkg (Gr)",
          align: "right",
          headerAlign: "right",
          formatter: (data) => formatGram(data),
        },
        {
          dataField: "bruto",
          text: "Bruto (Gr)",
          align: "right",
          headerAlign: "right",
          formatter: (data) => formatGram(data),
        },
        {
          dataField: "netto",
          text: "Netto (Gr)",
          align: "right",
          formatter: (data) => formatGram(data, 3),
          headerAlign: "right",
        },
        {
          dataField: "Aksi",
          text: "Aksi",
          csvExport: false,
          formatter: (rowContent, row, rowIndex) => {
            return (
              <div className="text-center">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <i className="fa fa-ellipsis-v" aria-hidden="true" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        this.props.dispatch(
                          deleteLocalTemp(
                            "24KTemp",
                            rowIndex,
                            getListTambah24K()
                          )
                        );
                      }}
                    >
                      Hapus <i className="fa fa-trash ml-2" />
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            );
          },
        },
      ],
    };
  }
  componentDidMount() {
    preventTabAction(2);
    this.props.dispatch(getLokasi());
    this.props.change("tanggal", getToday);
    this.props.change("noMutasi", localStorage.getItem("noFaktur"));
    this.props.change("bonMutasi", "T24K-" + getBon());
  }
  render() {
    return (
      <CustomForm onSubmit={this.props.handleSubmit} number={2}>
        <div className="row">
          <div className="col-md-3 form-group d-none">
            <Field
              component={renderField}
              normalize={(value) => value && value.toUpperCase()}
              label="Nomor Mutasi"
              type="text"
              name="noMutasi"
              className="form-control"
              readOnly
            />
          </div>
          <div className="col-md-3 form-group">
            <Field
              type="date"
              label="Tanggal"
              id="tanggal"
              name="tanggal"
              component={renderField}
            />
          </div>
          <div className="col-md-3 form-group d-none">
            <Field
              component={renderField}
              normalize={(value) => value && value.toUpperCase()}
              label="Bon Mutasi"
              type="text"
              id="bonMutasi"
              name="bonMutasi"
            />
          </div>
          <div className="col-lg-12 col-md-12 mb-3 row justify-content-end">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => this.props.dispatch(showModal())}
            >
              Tambah Barang
              <i className="fa fa-plus ml-3" />
            </button>
          </div>
          <div className="col-lg-12">
            <GlobalTabel
              keyField="id"
              data={this.props.listBarang}
              columns={this.state.columns}
            />
          </div>
          <div className="col-lg-12 col-md-3 mb-3">
            <ButtonSelesai number={2} />
          </div>
        </div>
      </CustomForm>
    );
  }
}

HeadKirimBahan24K = reduxForm({
  form: "HeadKirimBahan24K",
  enableReinitialize: true,
  validate: validasiHeadMutasiBarang,
})(HeadKirimBahan24K);
export default connect((state) => {
  return {
    listBarang: state.master24k.list24KTemp,
    lokasi: state.provinsi.lokasi,
    initialValues: {
      discGlobalPr: 0,
      p: 0,
      k: 0,
      pkg: 0,
      bruto: 0,
    },
  };
})(HeadKirimBahan24K);
