import { getLocal, saveLocal } from "../../../encrypt";
import {
  finishSend,
  isExist,
  progressSend,
} from "../../../actions/LogicAction";
import { reset } from "redux-form";
import { AxiosMasterPost } from "../../../Axios";
import { ToastKirim } from "../../../actions/toast";
import { toast } from "react-toastify";
import {
  FormatterNumber,
  multipleDeleteLocal,
  purifiedData,
} from "../../../helper";
import { hideModal } from "../../../actions/ModalAction";

export const GET_KIRIM_STOCK_BAYAR = "GET_KIRIM_STOCK_BAYAR";

export const getKirimStockBayarTemp = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_KIRIM_STOCK_BAYAR,
      payload: await getLocal("kirimStockBayarTemp"),
    });
  };
};

export const deleteKirimStockBayar = (index) => {
  return async (dispatch, getState) => {
    const prevData = await getLocal("kirimStockBayarTemp");
    prevData.splice(index, 1);
    await saveLocal("kirimStockBayarTemp", prevData);
    dispatch(getKirimStockBayarTemp());
  };
};

export const saveKirimStockBayarTemp = (data) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    purifiedData(data);
    data.netto = FormatterNumber(data.netto);
    await isExist({
      value: data.kode_ciok_rosok,
      value2: data.kode_ciok_rosok,
      payload: data,
      name: "kirimStockBayarTemp",
      target: "kode_ciok_rosok",
      target2: "kode_ciok_rosok",
    });
    dispatch(getKirimStockBayarTemp());
    dispatch(hideModal());
    dispatch(finishSend());
    dispatch(reset("FormKirimStockBayar"));
  };
};

export const sendKirimStockBayar = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const selected = state.form.KirimStockBayar.values;
    purifiedData(selected);
    const detail_barang = await getLocal("kirimStockBayarTemp");
    const payload = {
      no_transaksi: selected.no_transaksi,
      jenis_stock: selected.jenis_stock,
      tujuan_stock: selected.tujuan_stock,
      keterangan: selected.keterangan,
      detail_barang: detail_barang,
    };
    await toast.promise(
      AxiosMasterPost("kirim-stock-bayar", payload),
      ToastKirim
    );
    multipleDeleteLocal(["kirimStockBayarTemp"]);
    dispatch(getKirimStockBayarTemp());
    dispatch(finishSend());
    dispatch(reset("KirimStockBayar"));
  };
};
