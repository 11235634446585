import { GET_LIST_BARANG_BARCODE_SET } from "../actions/barangSetAction";

const initialState = {
    list_barang : []
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case GET_LIST_BARANG_BARCODE_SET:
            return {
                list_barang : action.payload.data
            }
        default:
            return state
    }
}

export default reducer;
