import { AxiosMasterGet } from "../Axios";
import { checkLaporan } from "../helper";

export const GET_LIST_VALIDASI_TRANSFER_CUSTOMER =
  "GET_LIST_VALIDASI_TRANSFER_CUSTOMER";
export const GET_DATA_SELECTED_VALIDASI_TRANSFER =
  "GET_DATA_SELECTED_VALIDASI_TRANSFER";

export const getValidasiTransferCustomer = (jenis_transaksi) => {
  return (dispatch) => {
    AxiosMasterGet(
      `validate-bank-outstand-transfer/get/${jenis_transaksi}&AC_ASAL&ALL`
    )
      .then((res) => {
        if (checkLaporan(res.data)) {
          dispatch({
            type: GET_LIST_VALIDASI_TRANSFER_CUSTOMER,
            payload: {
              data: res.data,
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch({
            type: GET_LIST_VALIDASI_TRANSFER_CUSTOMER,
            payload: {
              data: res.data,
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() =>
        dispatch({
          type: GET_LIST_VALIDASI_TRANSFER_CUSTOMER,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const getDataSelectedValidasiTransfer = (row) => {
  return (dispatch) => {
    dispatch({
      type: GET_DATA_SELECTED_VALIDASI_TRANSFER,
      payload: {
        data: row,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
        error: true,
      },
    });
  };
};
