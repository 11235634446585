import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import HeaderCard from "./HeaderCard";
import { useDispatch, useSelector } from "react-redux";
import { setPDF } from "./actions/pdf";

export function PdfViewer() {
  // eslint-disable-next-line no-unused-vars
  const [numPages, setNumPages] = useState(null);
  const base64 = useSelector((state) => state.pdf.data);
  // eslint-disable-next-line no-unused-vars
  const [pageNumber, setPageNumber] = useState(1);
  const dispatch = useDispatch();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  function clearPDF() {
    dispatch(setPDF(""));
  }

  function printPDF() {
    var winparams =
      "dependent=yes,locationbar=no,scrollbars=yes,menubar=no," +
      "resizable,screenX=50,screenY=50,width=850,height=1050";

    var htmlPop =
      "<embed width=100% height=100%" +
      ' type="application/pdf"' +
      ' src="data:application/pdf;base64,' +
      escape(base64) +
      '#toolbar=0"></embed>';

    var printWindow = window.open("", "PDF", winparams);
    printWindow.document.write(htmlPop);
    setTimeout(() => {
      printWindow.print();
    }, 500);
  }

  return (
    <div className="container-fluid">
      <div className="col-lg-12">
        <div className="card">
          <HeaderCard title={"Preview PDF"} />
          <div className="card-body">
            <button className={"btn btn-primary"} onClick={clearPDF}>
              Back
            </button>
            <button className={"btn btn-primary"} onClick={printPDF}>
              Print
            </button>
            <div className={"row justify-content-center"}>
              <Document
                file={`data:application/pdf;base64,${base64}`}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} />
              </Document>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
