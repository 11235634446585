import {
  AxiosMasterDelete,
  AxiosMasterGet,
  AxiosMasterPost,
  AxiosMasterPut,
} from "../../../Axios";
import { finishSend, progressSend } from "../../../actions/LogicAction";
import { toast } from "react-toastify";
import { ToastKirim } from "../../../actions/toast";
import { gagal, reactive } from "../../../Alert";
import { hideModal } from "../../../actions/ModalAction";
import { reset } from "redux-form";
import Swal from "sweetalert2";
import { purifiedData } from "../../../helper";

export const GET_KELOMPOK = "GET_KELOMPOK";
export const EDIT_KELOMPOK = "EDIT_KELOMPOK";

export const getListKelompok = () => {
  return (dispatch) => {
    AxiosMasterGet("group/get/all")
      .then((res) =>
        dispatch({
          type: GET_KELOMPOK,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_KELOMPOK,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const editKelompok = (data) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_KELOMPOK,
      payload: {
        data: data,
      },
    });
  };
};
export const sendKelompok = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.KelompokTambah.values;
    purifiedData(selected);
    let data = {
      kode_group: selected.kodeKelompok,
      nama_group: selected.keterangan,
      kode_kelompok: selected.kode_kelompok,
    };
    try {
      await toast.promise(AxiosMasterPost("group/add", data), ToastKirim);
    } catch (err) {
      let error = err.response.data;
      let check = error.includes("DELETED");
      check
        ? reactive(
            err,
            selected.kodeKelompok,
            "group/reactive/by-kode-group/",
            {
              nama_group: selected.keterangan,
              kode_kelompok: selected.kode_kelompok,
            },
            "group/edit/by-kode-group/"
          ).then(() => dispatch(getListKelompok()))
        : gagal("Data Gagal Ditambahkan");
      dispatch(getListKelompok());
    }
    dispatch(finishSend());
    dispatch(getListKelompok());
    dispatch(reset("KelompokTambah"));
    dispatch(hideModal());
  };
};

export const sendEditKelompok = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.KelompokTambah.values;
    purifiedData(selected);
    let data = {
      nama_group: selected.keterangan,
      kode_kelompok: selected.kode_kelompok,
    };
    try {
      await toast.promise(
        AxiosMasterPut(
          "group/edit/by-kode-group/" + selected.kodeKelompok,
          data
        ),
        ToastKirim
      );
      dispatch(finishSend());
      dispatch(getListKelompok());
      dispatch(reset("KelompokTambah"));
      dispatch(hideModal());
    } catch (err) {
      dispatch(finishSend());
      dispatch(getListKelompok());
      dispatch(reset("KelompokTambah"));
      dispatch(hideModal());
    }
  };
};

export const deleteKelompok = (kode) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    Swal.fire({
      title: "Anda Yakin ?",
      text: "Anda Akan Menghapus Data Ini ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          dispatch(progressSend());
          await toast.promise(
            AxiosMasterDelete("group/delete/by-kode-group/" + kode),
            ToastKirim
          );
          dispatch(finishSend());
          dispatch(getListKelompok());
          dispatch(reset("KelompokTambah"));
          dispatch(hideModal());
        } catch (e) {
          dispatch(finishSend());
        }
      }
    });
    dispatch(finishSend());
  };
};
