import {GET_CLOSE_PENJUALAN, SET_DETAIL_CLOSE_PENJUALAN} from "./close_penjualan_action";

const initialState = {
    editClosePenjualan : [],
    dataClosePenjualan : [],
    selectedClosePenjualan : []
}

const closePenjualan = (state = initialState, action) => {
    switch(action.type){
        case GET_CLOSE_PENJUALAN:
            return {
                ...state,
                dataClosePenjualan : action.payload
            }
        case SET_DETAIL_CLOSE_PENJUALAN:
            return {
                ...state,
                editClosePenjualan : action.payload,
                selectedClosePenjualan : action.selected
            }
        default:
            return state
    }
}

export default closePenjualan