import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import { Field, reduxForm } from "redux-form";
import Swal from "sweetalert2";
import { getcustomerList } from "../../DataMaster/customer/redux/customerAction";
import { getkategoriList } from "../../actions/kategoriAction";
import { getListKirimStock, setBrutoKirimStock } from "../../actions/kirimStock";
import { showModal } from "../../actions/ModalAction";
import { getLokasiPindah } from "../../DataMaster/supplier/redux/supplierAction";
import { getToday } from "../../getDate";
import GlobalTabel from "../../GlobalComponent/GlobalTabel";
import { formatGram } from "../../helper";
import { renderField, renderFieldSelect } from "../../renderField";

class HeadKirimStockEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supplier: false,
      columns: [
        {
          dataField: "kode_jenis",
          text: "Kode Barang",
        },
        {
          dataField: "bruto",
          text: "Bruto (Gr)",
          align: "right",
          formatter: (data) => formatGram(data),
          headerAlign: "right",
        },
        {
          dataField: "netto",
          text: "Netto (Gr)",
          align: "right",
          headerAlign: "right",
          formatter: (data) => formatGram(data, 3),
        },
        {
          dataField: "Aksi",
          text: "Aksi",
          csvExport: false,
          formatter: (rowContent, row, rowIndex) => {
            return (
              <div className="row text-center justify-content-center">
                <button
                  data-tip
                  type="button"
                  data-for="hapus"
                  className="btn btn-danger"
                  onClick={() => this.deleteIndex(rowIndex)}
                >
                  <ReactTooltip id="hapus" type="dark" effect="solid">
                    <span>Hapus</span>
                  </ReactTooltip>
                  <i className="fa fa-trash"></i>
                </button>
                <br />
                <button
                  data-tip
                  data-for="edit"
                  className="btn btn-warning ml-1"
                  data-toggle="modal"
                  data-target="#editModal"
                  type="button"
                  onClick={() => {
                    this.props.dispatch(setBrutoKirimStock(row.bruto, row.kode_jenis))
                    this.props.showTambahBarang()
                  }}
                >
                  <ReactTooltip id="edit" type="dark" effect="solid">
                    <span>Edit</span>
                  </ReactTooltip>
                  <i className="fa fa-edit"></i>
                </button>
              </div>
            );
          },
        },
      ],
      lokasi_asal: "",
      lokasi_tujuan: "",
    };
  }

  deleteIndex(index) {
    Swal.fire({
      title: "Anda Yakin ?",
      text: "Anda Akan Menghapus Data Ini ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const local = JSON.parse(localStorage.getItem("kirimStock"));
        local.splice(index, 1);
        localStorage.setItem("kirimStock", JSON.stringify(local));
        
            Swal.fire({
          text: "Berhasil",
          timer: 2000,
          showConfirmButton: false,
          position: "top-right",
          icon: "success",
        })
          .then(() => this.props.dispatch(getListKirimStock()))
      }
    });
    
  }

  componentDidMount() {
    this.props.dispatch(getLokasiPindah());
    this.props.dispatch(getcustomerList());
    this.props.dispatch(getkategoriList());
    this.props.change("tanggal", getToday);
  }
  tambahModal() {
    this.props.dispatch(showModal());
    this.setState({ isEdit: false });
  }
  render() {
    return (
      <>
        <form onSubmit={this.props.handleSubmit}>
          <div className="row">
            <div className="col-md-3 form-group">
              <Field
                component={renderField}
                type="date"
                name="tanggal"
                label="Tanggal"
                readOnly
              />
            </div>
            <div className="col-md-3 form-group">
              <Field
                component={renderField}
                type="text"
                name="no_kirim"
                label="Nomor Kirim"
                readOnly
              />
            </div>
            <div className="col-md-3 form-group">
              <Field
                component={renderField}
                type="text"
                name="no_bon"
                label="Nomor Bon"
                readOnly
              />
            </div>
            <div className="col-md-4 form-group">
              <Field
                component={renderFieldSelect}
                label="Nama Toko"
                name="kode_customer"
                disabled
                value={this.props.status}
                normalize={(value) => value && value.toUpperCase()}
                options={this.props.listCustomer.map((obj) => {
                  var payload = {
                    value: obj.kode_customer ,
                    name: obj.nama_toko,
                  };
                  return payload;
                })}
              ></Field>
            </div>
            <div className="col-md-4 form-group">
              <Field
                component={renderFieldSelect}
                label="Kategori"
                name="kode_kategori"
                disabled
                value={this.props.status}
                normalize={(value) => value && value.toUpperCase()}
                options={this.props.listKategori.map((obj) => {
                  var payload = {
                    value: obj.kode_kategori,
                    name: obj.nama_kategori,
                  };
                  return payload;
                })}
              ></Field>
            </div>
            <div className="col-lg-8"></div>
            <div className="col-lg-4 col-md-4 mb-3">
              <div className="text-right">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.props.tambahModal()}
                >
                  TAMBAH BARANG
                  <i className="fa fa-plus ml-3"></i>
                </button>
              </div>
            </div>
            <div className="col-md-12 col-lg-12">
              <GlobalTabel
                keyField="KodeBarang"
                data={this.props.kirimStock || []}
                columns={this.state.columns}
                bordered={true}
                headerClasses="header-table"
              />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="text-right">
              <button
                className="btn btn-primary"
                data-toggle="modal"
                disabled={this.props.onSend}
                onClick={this.props.handleSubmit}
              >
                {this.props.onSend ? (
                  <span
                    className="spinner-border spinner-border-sm mr-3"
                    aria-hidden="true"
                  ></span>
                ) : null}
                SIMPAN
                <i className="fa fa-paper-plane ml-3"></i>
              </button>
            </div>
          </div>
        </form>
      </>
    );
  }
}
HeadKirimStockEdit = reduxForm({
  form: "HeadKirimStockEdit",
  enableReinitialize: true,
})(HeadKirimStockEdit);
export default connect((state) => {
  return {
    dataLokasiPindah: state.provinsi.dataLokasiPindah,
    listBarang: state.provinsi.pindahBarang,
    listCustomer: state.provinsi.customer,
    listKategori: state.provinsi.kategori,
    kirimStock: state.kirimStock.list_barang,
  };
})(HeadKirimStockEdit);
