import jsPDF from "jspdf";
import "jspdf-autotable";
import { convertKadarCetak, formatGram, formatRp } from "../../../helper";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporanPrintPenerimaanSupplier = (
  row1isi = "",
  row2isi = "",
  username = "",
  tanggal = "",
  validby = "",
  data
) => {
  // initialize jsPDF
  const doc = new jsPDF("l");
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  let footRows = [];
  let tableColumn = [];
  let finalY = 25;
  let total_bruto = 0;
  let total_netto = 0;
  let total_rp = 0;
  let total_plastik = 0;
  let total_kulit = 0;
  let total_pkg = 0;
  let total_gross = 0;
  let total_qty = 0;
  let totaL_harga_beli = 0;
  let grand_totaL_harga_beli = 0;
  let grand_bruto = 0;
  let grand_netto = 0;
  let grand_rp = 0;
  let grand_plastik = 0;
  let grand_kulit = 0;
  let grand_pkg = 0;
  let grand_gross = 0;
  let grand_qty = 0;
  let grand_harga_beli = 0;
  let grand_grand_harga_beli = 0;

  data.forEach((item, indexData) => {
    doc.setFontSize(15);
    doc.text("LAPORAN DETAIL PENERIMAAN SUPPLIER", 14, 10);
    // doc.addImage(logo_ayu_base64, "PNG", 14, 10, 35, 12);
    doc.setFontSize(10);
    //row 1
    doc.text(`Mulai Dari : ${row1isi}`, 14, 16);
    //   row 2
    doc.text(`Sampai Dengan	: ${row2isi}`, 100, 16);
    doc.text(`Kode Supplier : ${item.kode_supplier}`, 14, 21);
    doc.text(`Nama Supplier : ${item.nama_supplier}`, 100, 21);
    item.detail.forEach((list, indexItem) => {
      tableColumn = [
        [
          {
            content: `TANGGAL INVOICE : ${list.tanggal_invoice}`,
            colSpan: 15,
          },
        ],
        [
          {
            content: `NO BON : ${list.no_bon}`,
            colSpan: 15,
          },
        ],
        [
          {
            content: `NO`,
          },
          {
            content: `KODE BARANG`,
          },
          {
            content: `KODE BAHAN`,
          },
          {
            content: `CAP KODE`,
            styles: { halign: "right", cellWidth: 16 },
          },
          {
            content: `HARGA BELI`,
            styles: { halign: "right", cellWidth: 16 },
          },
          {
            content: `QTY`,
            styles: { halign: "right" },
          },
          {
            content: `P`,
            styles: { halign: "right" },
          },
          {
            content: `K`,
            styles: { halign: "right" },
          },
          {
            content: `PKG`,
            styles: { halign: "right" },
          },
          {
            content: `GROSS (GR)`,
            styles: { halign: "right" },
          },
          {
            content: `BRUTO (GR)`,
            styles: { halign: "right" },
          },
          {
            content: `NETTO (GR)`,
            styles: { halign: "right" },
          },
          {
            content: `ONGKOS (RP)`,
            styles: { halign: "right" },
          },
        ],
      ];
      list.detail_barang.forEach((barang, index) => {
        let rows = [
          ++index,
          barang.kode_barang,
          barang.kode_bahan,
          {
            content: convertKadarCetak(barang.kode_bahan, barang.kadar_cetak),
            styles: { halign: "right" },
          },
          { content: barang.kadar_beli, styles: { halign: "right" } },
          { content: barang.qty, styles: { halign: "right" } },
          { content: formatGram(barang.plastik), styles: { halign: "right" } },
          { content: formatGram(barang.kulit), styles: { halign: "right" } },
          { content: barang.pkg, styles: { halign: "right" } },
          { content: formatGram(barang.gross), styles: { halign: "right" } },
          { content: formatGram(barang.bruto), styles: { halign: "right" } },
          { content: formatGram(barang.netto, 3), styles: { halign: "right" } },
          { content: formatRp(barang.ongkos_rp), styles: { halign: "right" } },
        ];
        tableRows.push(rows);
        total_bruto = parseFloat(total_bruto) + parseFloat(barang.bruto);
        total_netto = parseFloat(total_netto) + parseFloat(barang.netto);
        total_rp = parseFloat(total_rp) + parseFloat(barang.ongkos_rp);
        total_kulit = parseFloat(total_kulit) + parseFloat(barang.kulit);
        total_pkg = parseFloat(total_pkg) + parseFloat(barang.pkg);
        total_plastik = parseFloat(total_plastik) + parseFloat(barang.plastik);
        total_gross = parseFloat(total_gross) + parseFloat(barang.gross);
        total_qty = parseFloat(total_qty) + parseFloat(barang.qty);
        grand_totaL_harga_beli =
          parseFloat(grand_totaL_harga_beli) +
          parseFloat(barang.qty) * parseFloat(barang.harga_beli);
        totaL_harga_beli =
          parseFloat(totaL_harga_beli) + parseFloat(barang.harga_beli);

        grand_bruto = parseFloat(grand_bruto) + parseFloat(barang.bruto);
        grand_netto = parseFloat(grand_netto) + parseFloat(barang.netto);
        grand_rp = parseFloat(grand_rp) + parseFloat(barang.ongkos_rp);
        grand_kulit = parseFloat(grand_kulit) + parseFloat(barang.kulit);
        grand_pkg = parseFloat(grand_pkg) + parseFloat(barang.pkg);
        grand_plastik = parseFloat(grand_plastik) + parseFloat(barang.plastik);
        grand_gross = parseFloat(grand_gross) + parseFloat(barang.gross);
        grand_qty = parseFloat(grand_qty) + parseFloat(barang.qty);
        grand_grand_harga_beli =
          parseFloat(grand_grand_harga_beli) +
          parseFloat(barang.qty) * parseFloat(barang.harga_beli);
        grand_harga_beli =
          parseFloat(grand_harga_beli) + parseFloat(barang.harga_beli);
      });
      let footer = [
        {
          colSpan: 5,
          content: "Sub Total",
          styles: {
            halign: "right",
          },
        },
        { content: total_qty, styles: { halign: "right" } },
        { content: formatGram(total_plastik), styles: { halign: "right" } },
        { content: formatGram(total_kulit), styles: { halign: "right" } },
        { content: total_pkg, styles: { halign: "right" } },
        { content: formatGram(total_gross), styles: { halign: "right" } },
        { content: formatGram(total_bruto), styles: { halign: "right" } },
        { content: formatGram(total_netto, 3), styles: { halign: "right" } },
        { content: formatRp(total_rp), styles: { halign: "right" } },
      ];
      footRows.push(footer);
      console.log(indexItem);
      if (
        indexData === data.length - 1 &&
        indexItem === item.detail.length - 1
      ) {
        let footer = [
          {
            colSpan: 5,
            content: "Grand Total",
            styles: {
              halign: "right",
            },
          },
          { content: grand_qty, styles: { halign: "right" } },
          { content: formatGram(grand_plastik), styles: { halign: "right" } },
          { content: formatGram(grand_kulit), styles: { halign: "right" } },
          { content: grand_pkg, styles: { halign: "right" } },
          { content: formatGram(grand_gross), styles: { halign: "right" } },
          { content: formatGram(grand_bruto), styles: { halign: "right" } },
          { content: formatGram(grand_netto, 3), styles: { halign: "right" } },
          { content: formatRp(grand_rp), styles: { halign: "right" } },
        ];
        footRows.push(footer);
      }

      doc.autoTable({
        head: tableColumn,
        body: tableRows,
        foot: footRows,
        showHead: "firstPage",
        showFoot: "lastPage",
        startY: indexItem === 0 ? 25 : finalY + 5,
        theme: "plain",
        rowPageBreak: "avoid",
        margin: { top: 20 },
        bodyStyles: { lineColor: "#969696", fontSize: 8, lineWidth: 0.0 },
        headStyles: {
          lineColor: "#969696",
          lineWidth: 0.0,
          fontSize: 8,
          fillColor: "#E8E8E8",
        },
        footStyles: {
          lineColor: "#969696",
          lineWidth: 0.0,
          fillColor: "#E8E8E8",
          fontSize: 8,
        },
      });
      finalY = doc.autoTableEndPosY() + 10;
      tableRows = [];
      footRows = [];
      total_bruto = 0;
      total_netto = 0;
      total_rp = 0;
      total_plastik = 0;
      total_kulit = 0;
      total_pkg = 0;
      total_gross = 0;
      total_qty = 0;
      totaL_harga_beli = 0;
    });
    if (indexData < data.length - 1) {
      doc.addPage();
    }
  });
  // const date = Date().split(" ");
  // we use a date string to generate our filename.
  // const dateStr = date[2] + date[3] + date[4];
  // ticket title. and margin-top + margin-left

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  doc.autoPrint();
  doc.setProperties({
    title: "LAPORAN PENERIMAAN SUPPLIER - DETAIL",
  });
  // doc.save(`PenerimaanSUpplier.pdf`);
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    `
<html>
<head>
  <title>LAPORAN PENERIMAAN SUPPLIER - DETAIL</title>
  <style>
    #overlay {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1000;
    }
  </style>
</head>
<body style='margin:0 !important; position: relative;'>
  <iframe src='${string}#toolbar=0' width='100%' height='100%' frameborder='0' style='position: absolute; top: 0; left: 0;'></iframe>
  <div id='overlay' oncontextmenu='return false;'></div>
</body>
</html>
`
  );
  // setInterval(() => {
  //   x.close();
  // }, 1000);
};

export default LaporanPrintPenerimaanSupplier;
