import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { multipleDeleteLocal } from "./helper";

class HeaderCard extends Component {
  render() {
    return (
      <div
        className="card-header mb-3"
        style={{
          fontSize: 30,
          backgroundColor: "#394867",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          color: "#ffffff",
        }}
      >
        <div className="row align-items-center">
          <div className="col-lg-10 align-items-center">
            <h4
              style={{
                fontSize: 25,
                color: "#ffffff",
              }}
            >
              {this.props.title}
            </h4>
          </div>
          <div className="col-lg-2 text-right my-auto">
            <ReactTooltip id="reload" type="dark" effect="solid">
              <span>Reload</span>
            </ReactTooltip>
            <button
              data-tip
              data-for="reload"
              className="btn btn-success btn-small btn-circle my-auto"
              onClick={() => {
                multipleDeleteLocal(this.props.listDeleteLocal || []);
                if (this.props.SetLocal !== undefined) {
                  this.props.SetLocal();
                }
                window.location.reload();
              }}
            >
              <i className="fa fa-retweet"></i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default HeaderCard;
