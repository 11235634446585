import { SET_PDF } from "../actions/pdf";

const initialState = {
  data: "",
};

const pdf = (state = initialState, action) => {
  switch (action.type) {
    case SET_PDF:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default pdf;
