import { AxiosMasterGet } from "../Axios";
import { formatGram, removeComma } from "../helper";

export const GET_PEMBAYARANSUPPLIER_LIST = "GET_PEMBAYARANSUPPLIER_LIST";
export const SET_CIOK_TEMP = "SET_CIOK_TEMP";
export const SET_24K_TEMP = "SET_24K_TEMP";
export const SET_GIRO_TEMP = "SET_GIRO_TEMP";
export const SET_RONGSOK_TEMP = "SET_RONGSOK_TEMP";
export const SET_TOTAL_NETTO_SUPPLIER = "SET_TOTAL_NETTO_SUPPLIER";
export const SET_TOTAL_RP_SUPPLIER = "SET_TOTAL_RP_SUPPLIER";
export const GET_HUTANG_SUPPLIER = "GET_HUTANG_SUPPLIER";

export const getpembayaranSupplierList = () => {
  return (dispatch) => {
    AxiosMasterGet("subcategorys")
      .then((res) =>
        dispatch({
          type: GET_PEMBAYARANSUPPLIER_LIST,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_PEMBAYARANSUPPLIER_LIST,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const setTampungCiok = () => {
  return (dispatch) => {
    let nettoSum = 0;
    let brutoSum = 0;
    let total = JSON.parse(localStorage.getItem("ciokTemp")) || "kosong";
    console.log(total);
    if (total === "kosong" || total.length === 0) {
      nettoSum = 0;
      brutoSum = 0;
    } else {
      nettoSum = total
        .map((hasil) => parseFloat(removeComma(hasil.netto)))
        .reduce((acc, hasil) => acc + hasil);
      localStorage.setItem("totalCiok", formatGram(nettoSum));
      brutoSum = total
        .map((hasil) => parseFloat(removeComma(hasil.bruto)))
        .reduce((acc, hasil) => acc + hasil);
      localStorage.setItem("totalBrutoCiok", formatGram(brutoSum));
    }
    dispatch({
      type: SET_CIOK_TEMP,
      payload: {
        data: JSON.parse(localStorage.getItem("ciokTemp")) || [],
        totalNetto: nettoSum,
        totalBruto: brutoSum,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const setTampung24K = () => {
  let nettoSum = 0;
  let brutoSum = 0;
  let total = JSON.parse(localStorage.getItem("24kTemp")) || "kosong";
  if (total === "kosong") {
    nettoSum = 0;
  } else {
    nettoSum = total
      .map((hasil) => parseFloat(removeComma(hasil.netto)))
      .reduce((acc, hasil) => acc + hasil, 0);
    localStorage.setItem("total24k", formatGram(nettoSum));
    brutoSum = total
      .map((hasil) => parseFloat(removeComma(hasil.bruto)))
      .reduce((acc, hasil) => acc + hasil, 0);
    localStorage.setItem("totalBruto24k", formatGram(brutoSum));
  }
  return (dispatch) => {
    dispatch({
      type: SET_24K_TEMP,
      payload: {
        data: JSON.parse(localStorage.getItem("24kTemp")) || [],
        totalNetto: nettoSum,
        totalBruto: brutoSum,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const settampungRongsok = () => {
  return (dispatch) => {
    var total = JSON.parse(localStorage.getItem("rongsokTemp"))
      ? JSON.parse(localStorage.getItem("rongsokTemp"))
      : "kosong";

    var sum = 0;
    var sum2 = 0;
    if (total === "kosong" || total.length === 0) {
      sum = 0;
    } else {
      sum = total
        .map((hasil) => parseFloat(removeComma(hasil.netto)))
        .reduce((acc, hasil) => acc + hasil);
      localStorage.setItem("totalRongsok", formatGram(sum));
      sum2 = total
        .map((hasil) => parseFloat(removeComma(hasil.bruto)))
        .reduce((acc, hasil) => acc + hasil);
      localStorage.setItem("totalBrutoRongsok", formatGram(sum2));
    }
    dispatch({
      type: SET_RONGSOK_TEMP,
      payload: {
        data: JSON.parse(localStorage.getItem("rongsokTemp")) || [],
        totalNetto: sum,
        totalBruto: sum2,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const setTampungGiro = () => {
  var sum = 0;
  var sum2 = 0;
  var total = JSON.parse(localStorage.getItem("giroTemp"))
    ? JSON.parse(localStorage.getItem("giroTemp"))
    : "kosong";
  if (total === "kosong") {
    sum = 0;
  } else {
    sum = total
      .map((hasil) => parseFloat(hasil.netto))
      .reduce((acc, hasil) => acc + hasil);
    localStorage.setItem("totalGiro", sum);
    sum2 = total
      .map((hasil) => parseFloat(hasil.bruto))
      .reduce((acc, hasil) => acc + hasil);
    localStorage.setItem("totalBrutoGiro", sum2);
  }
  return (dispatch) => {
    dispatch({
      type: SET_GIRO_TEMP,
      payload: {
        data: JSON.parse(localStorage.getItem("giroTemp")) || [],
        totalNetto: sum,
        totalBruto: sum2,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const setTotalNetto = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_TOTAL_NETTO_SUPPLIER,
      payload: data,
    });
  };
};

export const setTotalRp = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_TOTAL_RP_SUPPLIER,
      payload: data,
    });
  };
};

export const getHutangSupplier = (tanggal, kode_supplier) => {
  return (dispatch) => {
    AxiosMasterGet(
      "trx-supplier/get/hutang-titipan-supplier/" +
        tanggal +
        "&" +
        kode_supplier +
        ""
    )
      .then((res) =>
        dispatch({
          type: GET_HUTANG_SUPPLIER,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_HUTANG_SUPPLIER,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};
