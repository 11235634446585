import { AxiosMasterGet } from "../../../Axios";
import { ToastAmbil } from "../../../actions/toast";
import { toast } from "react-toastify";
import LaporanPrintCash from "../component/LaporanPrintCash";
import { getToday } from "../../../getDate";
import { finishSend, progressSend } from "../../../actions/LogicAction";
import LaporanPrintCashCard from "../component/LaporanPrintCashCard";
import LaporanPrintCashSummary from "../component/LaporanPrintCashSummary";
import { getUserID } from "../../../helper";

export const GET_LAPORAN_KEUANGAN_CASH = "GET_LAPORAN_KEUANGAN_CASH";
export const GET_LAPORAN_KEUANGAN_CASH_CARD = "GET_LAPORAN_KEUANGAN_CASH_CARD";
export const GET_LAPORAN_KEUANGAN_CASH_SUMMARY =
  "GET_LAPORAN_KEUANGAN_CASH_SUMMARY";

export const ResetLaporanKeuanganCash = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_LAPORAN_KEUANGAN_CASH,
      payload: [],
    });
    dispatch({
      type: GET_LAPORAN_KEUANGAN_CASH_CARD,
      payload: {
        data: [],
        tglFrom: "",
        tglTo: "",
      },
    });
    dispatch({
      type: GET_LAPORAN_KEUANGAN_CASH_SUMMARY,
      payload: {
        data: [],
        tglFrom: "",
        tglTo: "",
      },
    });
  };
};
export const getLaporanKeuanganCash = (link) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    try {
      const response = await toast.promise(
        AxiosMasterGet("report/keuangan-cash"),
        ToastAmbil
      );
      dispatch({
        type: GET_LAPORAN_KEUANGAN_CASH,
        payload: response.data,
      });
      dispatch(finishSend());
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

export const getLaporanKeuanganCashCard = (tglFrom, tglTo) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    try {
      const response = await toast.promise(
        AxiosMasterGet(`report/keuangan-cash-card/KAS&${tglFrom}&${tglTo}`),
        ToastAmbil
      );
      dispatch({
        type: GET_LAPORAN_KEUANGAN_CASH_CARD,
        payload: {
          data: response.data,
          tglFrom: tglFrom,
          tglTo: tglTo,
        },
      });
      dispatch(finishSend());
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

export const getLaporanKeuanganCashSummary = (tglFrom, tglTo) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    try {
      const response = await toast.promise(
        AxiosMasterGet(`report/keuangan-cash-summary/KAS&${tglFrom}&${tglTo}`),
        ToastAmbil
      );
      dispatch({
        type: GET_LAPORAN_KEUANGAN_CASH_SUMMARY,
        payload: {
          data: response.data,
          tglFrom: tglFrom,
          tglTo: tglTo,
        },
      });
      dispatch(finishSend());
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

export const PrintLaporanKeuanganCashPDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const laporanKeuanganCash = state.laporan.dataLaporanKeuanganCash;
    LaporanPrintCash(getUserID(), getToday(), getUserID(), laporanKeuanganCash);
    // dispatch(setPDF(data.substring(51)));
  };
};

export const PrintLaporanKeuanganCashCardPDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const laporanKeuanganCashCard = state.laporan.dataLaporanKeuanganCashCard;
    LaporanPrintCashCard(
      localStorage.getItem("username"),
      getToday(),
      localStorage.getItem("username"),
      laporanKeuanganCashCard
    );
  };
};

export const PrintLaporanKeuanganCashSummaryPDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const laporanKeuanganCashSummary =
      state.laporan.dataLaporanKeuanganCashSummary;
    LaporanPrintCashSummary(
      localStorage.getItem("username"),
      getToday(),
      localStorage.getItem("username"),
      laporanKeuanganCashSummary
    );
  };
};
