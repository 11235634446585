export const GET_LIST_PEMGAMBILAN_CIOK = "GET_LIST_PEMGAMBILAN_CIOK"

export const getListPengambilanCiok = () => {
    const data = JSON.parse(localStorage.getItem("PengambilanCiok")) || [];
    return (dispatch) => {
      dispatch({
        type: GET_LIST_PEMGAMBILAN_CIOK,
        payload: {
          data: data,
        },
      });
    };
  };