import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  convertKadarCetak,
  formatDateIndo,
  formatGram,
  formatRp,
  printInfo,
} from "../../../helper";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporanPenjualanCustomer = (data, mulai_dari, sampai_dengan, kodeBarang) => {
  console.log(data);
  // initialize jsPDF
  const doc = new jsPDF("portrait");
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  let finalY = 30;
  let totalBruto = 0;
  let totalNetto = 0;
  let totalPKG = 0;
  let totalQty = 0;
  let totalGross = 0;
  let totalOngkos = 0;
  doc.setFontSize(15);
  doc.text("LAPORAN PENJUALAN CUSTOMER", 14, 10);
  // doc.addImage(logo_ayu_base64, "PNG", 14, 10, 35, 12);
  doc.setFontSize(10);
  doc.text(`Mulai Dari : ${formatDateIndo(mulai_dari)}`, 14, 16);
  //   row 2
  doc.text(`Sampai Dengan	: ${formatDateIndo(sampai_dengan)}`, 100, 16);
  //   //row 1
  //   doc.text(`Supplier : ${row1isi}`, 14, 25);
  //   //   row 2
  //   doc.text(`Tanggal	: ${row2isi}`, 120, 25);
  data.forEach((item, index) => {
    let tableColumn = [
      [
        {
          content: `TANGGAL : ${formatDateIndo(item.tanggal)}`,
          colSpan: 2,
        },
        {
          content: `NO BON : ${item.no_bon}`,
          colSpan: 3,
        },
        {
          content: `NAMA TOKO : ${item.nama_toko}`,
          colSpan: 5,
        },
      ],
      [
        {
          content: `KODE BARANG`,
        },
        {
          content: `KODE BAHAN`,
        },
        {
          content: `CAP KODE`,
          styles: { halign: "right", cellWidth: 16 },
        },
        {
          content: `HARGA JUAL`,
          styles: { halign: "right", cellWidth: 16 },
        },
        {
          content: `QTY`,
          styles: { halign: "right" },
        },
        {
          content: `BRUTO (GR)`,
          styles: { halign: "right" },
        },
        {
          content: `PKG (GR)`,
          styles: { halign: "right" },
        },
        {
          content: `GROSS (GR)`,
          styles: { halign: "right" },
        },
        {
          content: `NETTO (GR)`,
          styles: { halign: "right" },
        },
        {
          content: `ONGKOS (RP)`,
          styles: { halign: "right" },
        },
      ],
    ];
    item.detail_barang.forEach((hasil) => {
      let rows = [
        hasil.kode_barang,
        hasil.kode_bahan,
        {
          content: convertKadarCetak(hasil.kode_bahan, hasil.kadar_cetak),
          styles: { halign: "right" },
        },
        { content: hasil.kadar_jual, styles: { halign: "right" } },
        { content: hasil.qty, styles: { halign: "right" } },
        { content: formatGram(hasil.bruto), styles: { halign: "right" } },
        { content: formatGram(hasil.pkg), styles: { halign: "right" } },
        { content: formatGram(hasil.gross), styles: { halign: "right" } },
        { content: formatGram(hasil.netto, 3), styles: { halign: "right" } },
        { content: formatRp(hasil.ongkos_rp), styles: { halign: "right" } },
      ];
      totalBruto += Number(hasil.bruto);
      totalQty += Number(hasil.qty);
      totalGross += Number(hasil.gross);
      totalNetto += Number(hasil.netto);
      totalPKG += Number(hasil.pkg);
      totalOngkos += Number(hasil.ongkos_rp);
      tableRows.push(rows);
    });
    let foot = [
      [
        { content: "Sub Total", styles: { halign: "right" }, colSpan: 4 },
        { content: totalQty, styles: { halign: "right" } },
        { content: formatGram(totalBruto), styles: { halign: "right" } },
        { content: formatGram(totalPKG), styles: { halign: "right" } },
        { content: formatGram(totalGross), styles: { halign: "right" } },
        { content: formatGram(totalNetto), styles: { halign: "right" } },
        { content: formatRp(totalOngkos), styles: { halign: "right" } },
      ],
    ];
    if (kodeBarang === "ALL" || kodeBarang === undefined) {
      foot.push(
        [
          {
            content: `ONGKOS : ${formatRp(item.ongkos_rp)}`,
            colSpan: 2,
          },
          {
            content: `DISC GLOB (GR) :\n ${formatGram(item.disc_gram)}`,
            colSpan: 3,
          },
          {
            content: `TOTAL NET (GR) : \n${formatGram(item.total_netto)}`,
            colSpan: 3,
          },
          // {
          //   content: `TOTAL NET EXT (GR) :\n ${formatGram(item.total_netto_ext)}`,
          //   colSpan: 2,
          // },
        ],
        printInfo()
      );
    } else {
      foot.push(printInfo());
    }

    doc.autoTable({
      head: tableColumn,
      body: tableRows,
      foot: foot,
      showHead: "firstPage",
      showFoot: "lastPage",
      startY: index === 0 ? 25 : finalY + 10,
      theme: "plain",
      rowPageBreak: "avoid",
      margin: { top: 20 },
      bodyStyles: { lineColor: "#969696", fontSize: 8, lineWidth: 0.0 },
      headStyles: {
        lineColor: "#969696",
        lineWidth: 0.0,
        fontSize: 8,
        fillColor: "#E8E8E8",
      },
      footStyles: {
        lineColor: "#969696",
        lineWidth: 0.0,
        fillColor: "#E8E8E8",
        fontSize: 8,
      },
    });
    finalY = doc.lastAutoTable.finalY + 10;
    tableRows = [];
    totalBruto = 0;
    totalNetto = 0;
    totalPKG = 0;
    totalGross = 0;
    totalQty = 0;
    totalOngkos = 0;
  });

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  doc.autoPrint();
  doc.setProperties({
    title: "LAPORAN PENJUALAN CUSTOMER",
  });
  // doc.save(`PenerimaanSUpplier.pdf`);
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    `
<html>
<head>
  <title>LAPORAN PENJUALAN CUSTOMER</title>
  <style>
    #overlay {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1000;
    }
  </style>
</head>
<body style='margin:0 !important; position: relative;'>
  <iframe src='${string}#toolbar=0' width='100%' height='100%' frameborder='0' style='position: absolute; top: 0; left: 0;'></iframe>
  <div id='overlay' oncontextmenu='return false;'></div>
</body>
</html>
`
  );
  // setInterval(() => {
  //   x.close();
  // }, 1000);
};

export default LaporanPenjualanCustomer;
