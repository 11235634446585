import {
  finishSend,
  isExist,
  progressSend,
} from "../../../actions/LogicAction";
import { getLocal } from "../../../encrypt";
import { reset } from "redux-form";
import { hideModal } from "../../../actions/ModalAction";
import {
  getBon,
  getToday,
  multipleDeleteLocal,
  purifiedData,
} from "../../../helper";
import { toast } from "react-toastify";
import { AxiosMasterPost } from "../../../Axios";
import { ToastKirim } from "../../../actions/toast";

export const GET_TEMP_HANCUR_BARANG = "GET_TEMP_HANCUR_BARANG";

export const getHacurTemp = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_TEMP_HANCUR_BARANG,
      payload: await getLocal("HancurSampleTemp"),
    });
  };
};

export const setHancurBarangTemp = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.AddBarangHancurSample.values;
    purifiedData(selected);
    delete selected.kode_barang;
    await isExist({
      name: "HancurSampleTemp",
      value: selected.kode_sampel,
      value2: selected.kode_sampel,
      target: "kode_sampel",
      target2: "kode_sampel",
      payload: selected,
    });
    dispatch(getHacurTemp());
    dispatch(finishSend());
    dispatch(hideModal());
    dispatch(reset("AddBarangHancurSample"));
  };
};

export const sendHancur = () => {
  return async (dispatch, getState) => {
    const detail_barang = await getLocal("HancurSampleTemp");
    const payload = {
      no_hancur_barang: "BS-" + getBon(),
      tanggal_hancur: getToday(),
      lokasi: "PUSAT",
      detail_barang: detail_barang,
    };
    try {
      await toast.promise(
        AxiosMasterPost("/hancur-sampel", payload),
        ToastKirim
      );
      multipleDeleteLocal(["HancurSampleTemp"]);
      dispatch(finishSend());
      dispatch(getHacurTemp());
    } catch (e) {
      dispatch(finishSend());
    }
  };
};
