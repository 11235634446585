const validasiForm24K = (value) => {
  const errors = {};
  if (!value.kode_barang) {
    errors.kode_barang = "Tidak Boleh kosong";
  }
  if (!value.total_harga_jual) {
    errors.total_harga_jual = "Tidak Boleh kosong";
  }
  if (!value.bruto) {
    errors.bruto = "Tidak Boleh kosong";
  }
  if (!value.netto) {
    errors.netto = "Tidak Boleh kosong";
  }
  return errors;
};

export default validasiForm24K;
