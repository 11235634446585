import jsPDF from "jspdf";
import "jspdf-autotable";
import { getToday } from "../../../GlobalComponent/function";
import {
  convertKadarCetak,
  formatGram,
  formatRp,
  getUserID,
} from "../../../helper";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporanTolakanSupplier = (
  row1isi = "",
  mulai_dari,
  sampai_dengan,
  tanggal = "",
  validby = "",
  data
) => {
  // initialize jsPDF
  const doc = new jsPDF("portrait");
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  let footRows = [];
  let tableColumn = [];
  let finalY = 25;
  doc.setFontSize(18);
  doc.text("LAPORAN TOLAKAN SUPPLIER", 14, 10);
  // doc.addImage(logo_ayu_base64, "PNG", 14, 10, 35, 12);

  doc.setFontSize(10);
  doc.text(`Mulai Dari : ${mulai_dari}`, 14, 16);
  //   row 2
  doc.text(`Sampai Dengan	: ${sampai_dengan}`, 100, 16);
  //   row 2
  data.forEach((item, index) => {
    doc.text(`Kode Supplier : ${item.kode_supplier}`, 14, 21);
    doc.text(`Nama Supplier : ${item.nama_supplier}`, 100, 21);
    item.detail.forEach((list, index) => {
      tableColumn = [
        [
          {
            content: `TANGGAL : ${list.tanggal}`,
            colSpan: 11,
          },
        ],
        [
          {
            content: `NO BON : ${list.no_bon}`,
            colSpan: 11,
          },
        ],
        [
          {
            content: `NO`,
          },
          {
            content: `KODE BARANG`,
          },
          {
            content: `CAP KODE`,
            styles: { halign: "right", cellWidth: 16 },
          },
          {
            content: `HARGA BELI`,
            styles: { halign: "right", cellWidth: 16 },
          },
          {
            content: `QTY`,
            styles: { halign: "right" },
          },
          {
            content: `P`,
            styles: { halign: "right" },
          },
          {
            content: `K`,
            styles: { halign: "right" },
          },
          {
            content: `PKG`,
            styles: { halign: "right" },
          },
          {
            content: `GROSS (GR)`,
            styles: { halign: "right" },
          },
          {
            content: `BRUTO (GR)`,
            styles: { halign: "right" },
          },
          {
            content: `NETTO (GR)`,
            styles: { halign: "right" },
          },
          {
            content: `ONGKOS (RP)`,
            styles: { halign: "right" },
          },
        ],
      ];
      list.detail_barang.forEach((barang, index) => {
        let rows = [
          ++index,
          barang.kode_barang,
          {
            content: convertKadarCetak(barang.kode_bahan, barang.kadar_cetak),
            styles: { halign: "right" },
          },
          { content: barang.kadar_beli, styles: { halign: "right" } },
          { content: barang.qty, styles: { halign: "right" } },
          {
            content: formatGram(barang.plastik) || 0,
            styles: { halign: "right" },
          },
          {
            content: formatGram(barang.kulit) || 0,
            styles: { halign: "right" },
          },
          { content: formatGram(barang.pkg), styles: { halign: "right" } },
          { content: formatGram(barang.gross), styles: { halign: "right" } },
          { content: formatGram(barang.bruto), styles: { halign: "right" } },
          { content: formatGram(barang.netto, 3), styles: { halign: "right" } },
          { content: formatRp(barang.ongkos_rp), styles: { halign: "right" } },
        ];
        tableRows.push(rows);
        console.log(tableRows);
      });
      let footer = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        { content: formatGram(list.total_bruto), styles: { halign: "right" } },
        {
          content: formatGram(list.total_netto, 3),
          styles: { halign: "right" },
        },
      ];
      footRows.push(footer);
      let info = [
        {
          content: `Printed on : ${getToday()} by ${getUserID()}`,
          colSpan: 11,
        },
      ];
      footRows.push(info);
      doc.autoTable({
        head: tableColumn,
        body: tableRows,
        foot: footRows,
        startY: index === 0 ? 25 : finalY + 4,
        theme: "plain",
        rowPageBreak: "avoid",
        pageBreak: "avoid",
        margin: { top: 20 },
        bodyStyles: { lineColor: "#969696", fontSize: 8, lineWidth: 0.0 },
        headStyles: {
          lineColor: "#969696",
          lineWidth: 0.0,
          fontSize: 8,
          fillColor: "#E8E8E8",
        },
        footStyles: {
          lineColor: "#969696",
          lineWidth: 0.0,
          fillColor: "#E8E8E8",
          fontSize: 8,
        },
      });
      finalY = doc.autoTableEndPosY();
      tableRows = [];
      footRows = [];
    });
  });

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  doc.autoPrint();
  doc.setProperties({
    title: "LAPORAN TOLAKAN SUPPLIER",
  });
  // doc.save(`PenerimaanSUpplier.pdf`);
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    `
<html>
<head>
  <title>LAPORAN TOLAKAN SUPPLIER</title>
  <style>
    #overlay {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1000;
    }
  </style>
</head>
<body style='margin:0 !important; position: relative;'>
  <iframe src='${string}#toolbar=0' width='100%' height='100%' frameborder='0' style='position: absolute; top: 0; left: 0;'></iframe>
  <div id='overlay' oncontextmenu='return false;'></div>
</body>
</html>
`
  );
  // setInterval(() => {
  //   x.close();
  // }, 1000);
};

export default LaporanTolakanSupplier;
