import { AxiosMasterGet } from "../Axios";

export const GET_LIST_DATA_USER = "GET_LIST_DATA_USER";
export const GET_DATA_USER = "GET_DATA_USER";

export const getDatauser = () => {
  return (dispatch) => {
    AxiosMasterGet("users/get/all")
      .then((res) =>
        dispatch({
          type: GET_LIST_DATA_USER,
          payload: {
            data: res.data.filter((x) => x.level !== "SU"),
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch(() =>
        dispatch({
          type: GET_LIST_DATA_USER,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const getUserData = (idUser, namaUser, level, password) => {
  return (dispatch) => {
    dispatch({
      type: GET_DATA_USER,
      payload: {
        idUser: idUser,
        namaUser: namaUser,
        level: level,
        password: password,
      },
    });
  };
};
