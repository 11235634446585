import { toast } from "react-toastify";
import { change, reset } from "redux-form";
import { finishSend, progressSend } from "../../../actions/LogicAction";
import { ToastKirim } from "../../../actions/toast";
import { warning } from "../../../Alert";
import { AxiosMasterPostWithParam } from "../../../Axios";
import { getLocal, saveLocal } from "../../../encrypt";
import {
  formatGram,
  FormatterNumber,
  getBon,
  getToday,
  getUserID,
  multipleDeleteLocal,
  purifiedData,
} from "../../../helper";
import PrintNota from "../../Component/PrintNota";

export const GET_DATA_MUTASI_BARANG = "GET_DATA_MUTASI_BARANG";

export const getListMutasiBarang = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_DATA_MUTASI_BARANG,
      payload: {
        data: await getLocal("MutasiBarangTemp"),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const saveMutasiTemp = (type) => {
  return async (dispatch, getState) => {
    const state = getState();
    const selected = state.form.FormMutasiBarang.values;
    purifiedData(selected);
    const payload = {
      kode_sampel: type === "barang" ? "-" : selected.kode_barang.split("/")[0],
      kode_barang: type === "barang" ? selected.kode_barang.split("/")[0] : "-",
      kode_bahan: type === "barang" ? selected.kode_barang.split("/")[1] : "-",
      kadar_jual: selected.kadar_jual,
      kadar_cetak: selected.kadar_cetak,
      pkg: FormatterNumber(selected.pkg),
      netto: FormatterNumber(selected.netto),
      bruto: FormatterNumber(selected.bruto),
    };
    const prevData = await getLocal("MutasiBarangTemp");
    prevData.push(payload);
    await saveLocal("MutasiBarangTemp", prevData);
    dispatch(getListMutasiBarang());
    dispatch(finishSend());
    dispatch(change("FormMutasiBarang", "pkg", "0"));
    dispatch(change("FormMutasiBarang", "bruto", "0"));
    if (type === "barang") {
      dispatch(
        change("FormMutasiBarang", "kode_barang", {
          value:
            selected.kode_barang.split("/")[0] +
            "/" +
            selected.kode_barang.split("/")[1],
          label:
            selected.kode_barang.split("/")[0] +
            "/" +
            selected.kode_barang.split("/")[1],
        })
      );
    } else {
      dispatch(
        change("FormMutasiBarang", "kode_barang", {
          value:
            selected.kode_barang.split("/")[0] +
            "/" +
            selected.kode_barang.split("/")[1],
          label:
            selected.kode_barang.split("/")[0] +
            "/" +
            selected.kode_barang.split("/")[1],
        })
      );
    }
    toast.success("Barang Berhasil Ditambahkan");
  };
};

export const sendMutasiBarang = (type) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.HeadMutasiBarang.values;
    purifiedData(selected);
    const noBon = type === "MUTASI" ? "MB-" + getBon() : "PK-" + getBon();
    const detail_barang = await getLocal("MutasiBarangTemp");
    if (selected.lokasi === undefined) {
      warning("Mohon Pilih Lokasi");
      return false;
    }
    const payload = {
      tanggal: selected.tanggal || getToday(),
      lokasi: selected.lokasi,
      no_pindah_barang: noBon,
      detail_barang: detail_barang,
    };
    try {
      const response = await toast.promise(
        AxiosMasterPostWithParam("pindah-barang", payload, { type: type }),
        ToastKirim
      );
      print(
        response.data.detail_barang,
        selected.tanggal || getToday(),
        response.data.no_pindah_barang,
        type
      );
      multipleDeleteLocal(["MutasiBarangTemp"]);
      dispatch(finishSend());
      dispatch(getListMutasiBarang());
      dispatch(reset("HeadMutasiBarang"));
      dispatch(change("HeadMutasiBarang", "tanggal", getToday()));
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

function print(table, tanggal, bon, type) {
  var tableRows = [];
  var no_packing = "";
  table.forEach((ticket, i) => {
    no_packing = ticket.no_packing;
    const ticketData = [
      ++i,
      ticket.kode_barcode,
      { content: ticket.kode_sampel },
      { content: ticket.kadar_jual, styles: { halign: "right" } },
      { content: formatGram(ticket.pkg), styles: { halign: "right" } },
      { content: formatGram(ticket.bruto), styles: { halign: "right" } },
      { content: formatGram(ticket.netto), styles: { halign: "right" } },
    ];
    tableRows.push(ticketData);
  });
  var columTable = [
    [
      { content: "No" },
      { content: "Barcode" },
      { content: "Kode Sample" },
      { content: "Kadar Jual", styles: { halign: "right" } },
      { content: "Pkg", styles: { halign: "right" } },
      { content: "Bruto", styles: { halign: "right" } },
      { content: "Netto", styles: { halign: "right" } },
    ],
  ];
  PrintNota(
    "Tanggal",
    tanggal,
    "Nomor Packing",
    no_packing,
    "Nomor Bon",
    bon,
    "",
    "",
    getUserID(),
    tanggal,
    "",
    columTable,
    type === "MUTASI" ? "BARCODE SAMPLE" : "BARCODE BARANG",
    tableRows
  );
}
