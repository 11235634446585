export const SET_TITLE = "SET_TITLE";
export const SET_DASHBOARD = "SET_DASHBOARD";
export const SET_MASTER = "SET_MASTER";
export const SET_STOKING = "SET_STOKING";
export const SET_FICO = "SET_FICO";
export const SET_TRANSAKSI = "SET_TRANSAKSI";
export const SET_VALIDASI = "SET_VALIDASI";
export const SET_DEFAULT = "SET_DEFUALT";
export const SET_LOGIN = "SET_LOGIN";
export const SET_LAPORAN = "SET_LAPORAN";
export const SET_SUPERVISORY = "SET_SUPERVISORY";
export const SET_JUALBELILM = "SET_JUALBELILM";
export const SET_TOTALAN = "SET_TOTALAN";
export const SET_ALERT_LOGIN = "SET_ALERT_LOGIN";
export const SET_REPARASI = "SET_REPARASI";
export const SET_SAMPLE = "SET_SAMPLE";
export const HIDE_ALERT_LOGIN = "HIDE_ALERT_LOGIN";

export const setTitle = (isi) => {
  return (dispatch) => {
    dispatch({
      type: SET_TITLE,
      payload: {
        title: isi,
      },
    });
  };
};

export const setDashboard = (isi) => {
  return (dispatch) => {
    dispatch({
      type: SET_DASHBOARD,
      payload: {
        Dashboard: "sidebar-item selected",
        Transaksi: "sidebar-item",
        Fico: "sidebar-item",
        Stoking: "sidebar-item",
        Master: "sidebar-item",
        Validasi: "sidebar-item",
        Laporan: "sidebar-item",
        JualBeliLM: "sidebar-item",
        Supervisory: "sidebar-item ",
        Totalan: "sidebar-item",
        Reparasi : "sidebar-item",
        title: isi,
      },
    });
  };
};

export const setMaster = (isi) => {
  return (dispatch) => {
    dispatch({
      type: SET_MASTER,
      payload: {
        Dashboard: "sidebar-item",
        Transaksi: "sidebar-item",
        Supervisory: "sidebar-item ",
        Fico: "sidebar-item",
        Stoking: "sidebar-item",
        Master: "sidebar-item selected",
        Validasi: "sidebar-item",
        Laporan: "sidebar-item",
        JualBeliLM: "sidebar-item",
        Totalan: "sidebar-item",
        Reparasi : "sidebar-item",
        title: isi,
      },
    });
  };
};

export const setSample = (isi) => {
  return (dispatch) => {
    dispatch({
      type: SET_SAMPLE,
      payload: {
        Dashboard: "sidebar-item",
        Transaksi: "sidebar-item",
        Supervisory: "sidebar-item ",
        Fico: "sidebar-item",
        Stoking: "sidebar-item",
        Master: "sidebar-item ",
        Sample: "sidebar-item selected",
        Validasi: "sidebar-item",
        Laporan: "sidebar-item",
        JualBeliLM: "sidebar-item",
        Totalan: "sidebar-item",
        Reparasi : "sidebar-item",
        title: isi,
      },
    });
  };
};
export const setStocking = (isi) => {
  return (dispatch) => {
    dispatch({
      type: SET_STOKING,
      payload: {
        Stoking: "sidebar-item selected",
        Supervisory: "sidebar-item ",
        Transaksi: "sidebar-item",
        Fico: "sidebar-item",
        Dashboard: "sidebar-item",
        Master: "sidebar-item",
        Validasi: "sidebar-item",
        JualBeliLM: "sidebar-item",
        Laporan: "sidebar-item",
        Totalan: "sidebar-item",
        Reparasi : "sidebar-item",
        title: isi,
      },
    });
  };
};
export const setFICO = (isi) => {
  return (dispatch) => {
    dispatch({
      type: SET_FICO,
      payload: {
        Supervisory: "sidebar-item ",
        Transaksi: "sidebar-item",
        Fico: "sidebar-item selected",
        Stoking: "sidebar-item",
        Dashboard: "sidebar-item",
        Master: "sidebar-item",
        Validasi: "sidebar-item",
        JualBeliLM: "sidebar-item ",
        Laporan: "sidebar-item",
        Totalan: "sidebar-item",
        Reparasi : "sidebar-item",
        title: isi,
      },
    });
  };
};
export const setTransaksi = (isi) => {
  return (dispatch) => {
    dispatch({
      type: SET_TRANSAKSI,
      payload: {
        Transaksi: "sidebar-item selected",
        Supervisory: "sidebar-item ",
        Fico: "sidebar-item",
        Stoking: "sidebar-item",
        Dashboard: "sidebar-item",
        Master: "sidebar-item",
        Validasi: "sidebar-item",
        JualBeliLM: "sidebar-item",
        Laporan: "sidebar-item",
        Totalan: "sidebar-item",
        Reparasi : "sidebar-item",
        title: isi,
      },
    });
  };
};
export const setValidasi = (isi) => {
  return (dispatch) => {
    dispatch({
      type: SET_VALIDASI,
      payload: {
        Validasi: "sidebar-item selected",
        Supervisory: "sidebar-item",
        Transaksi: "sidebar-item",
        Fico: "sidebar-item",
        Stoking: "sidebar-item",
        Dashboard: "sidebar-item",
        Master: "sidebar-item",
        Laporan: "sidebar-item",
        JualBeliLM: "sidebar-item",
        Totalan: "sidebar-item",
        Reparasi : "sidebar-item",
        title: isi,
      },
    });
  };
};
export const setLaporan = (isi) => {
  return (dispatch) => {
    dispatch({
      type: SET_LAPORAN,
      payload: {
        Laporan: "sidebar-item selected",
        title: isi,
        Supervisory: "sidebar-item ",
        Transaksi: "sidebar-item",
        Fico: "sidebar-item",
        Stoking: "sidebar-item",
        Dashboard: "sidebar-item",
        Master: "sidebar-item",
        Validasi: "sidebar-item",
        JualBeliLM: "sidebar-item",
        Reparasi : "sidebar-item",
        Totalan: "sidebar-item",
      },
    });
  };
};
export const setSupervisory = (isi) => {
  return (dispatch) => {
    dispatch({
      type: SET_SUPERVISORY,
      payload: {
        Supervisory: "sidebar-item selected",
        Transaksi: "sidebar-item",
        Fico: "sidebar-item",
        Stoking: "sidebar-item",
        Dashboard: "sidebar-item",
        Master: "sidebar-item",
        Validasi: "sidebar-item",
        JualBeliLM: "sidebar-item",
        Totalan: "sidebar-item",
        Reparasi : "sidebar-item",
        title: isi,
      },
    });
  };
};
export const setJualBeliLM = (isi) => {
  return (dispatch) => {
    dispatch({
      type: SET_JUALBELILM,
      payload: {
        Supervisory: "sidebar-item ",
        Transaksi: "sidebar-item",
        Fico: "sidebar-item",
        Stoking: "sidebar-item",
        Dashboard: "sidebar-item",
        Master: "sidebar-item",
        Validasi: "sidebar-item",
        Totalan: "sidebar-item",
        JualBeliLM: "sidebar-item selected",
        Reparasi : "sidebar-item",
        title: isi,
      },
    });
  };
};
export const setTotalan = (isi) => {
  return (dispatch) => {
    dispatch({
      type: SET_TOTALAN,
      payload: {
        Supervisory: "sidebar-item ",
        Transaksi: "sidebar-item",
        Fico: "sidebar-item",
        Stoking: "sidebar-item",
        Dashboard: "sidebar-item",
        Master: "sidebar-item",
        Validasi: "sidebar-item",
        Totalan: "sidebar-item selected",
        JualBeliLM: "sidebar-item",
        Reparasi : "sidebar-item",
        title: isi,
      },
    });
  };
};

export const setReparasi = (isi) => {
  return (dispatch) => {
    dispatch({
      type: SET_REPARASI,
      payload: {
        Supervisory: "sidebar-item ",
        Transaksi: "sidebar-item",
        Fico: "sidebar-item",
        Stoking: "sidebar-item",
        Dashboard: "sidebar-item",
        Master: "sidebar-item",
        Validasi: "sidebar-item",
        Totalan: "sidebar-item ",
        JualBeliLM: "sidebar-item",
        Reparasi : "sidebar-item selected",
        title: isi,
      },
    });
  };
};
export const setDefault = (isi) => {
  return (dispatch) => {
    dispatch({
      type: SET_DEFAULT,
      payload: {
        Supervisory: "sidebar-item ",
        Transaksi: "sidebar-item",
        Fico: "sidebar-item",
        Stoking: "sidebar-item",
        Dashboard: "sidebar-item",
        Master: "sidebar-item",
        Validasi: "sidebar-item",
        JualBeliLM: "sidebar-item",
        Totalan: "sidebar-item",
        Reparasi : "sidebar-item",
        title: isi,
      },
    });
  };
};

export const setLogin = (user_name, level) => {
  localStorage.setItem("isLogin", true);
  return (dispatch) => {
    dispatch({
      type: SET_LOGIN,
      payload: {
        data: "TRUE",
        username: localStorage.getItem("username") || "ADMIN",
        level: localStorage.getItem("level") || "ADMIN",
      },
    });
  };
};
export const setAlertLogin = () => {
  return (dispatch) => {
    dispatch({
      type: SET_ALERT_LOGIN,
      payload: {
        data: true,
      },
    });
  };
};
export const hideAlertLogin = () => {
  return (dispatch) => {
    dispatch({
      type: HIDE_ALERT_LOGIN,
      payload: {
        data: false,
      },
    });
  };
};

export const setLogout = () => {
  localStorage.setItem("isLogin", false);
  return (dispatch) => {
    dispatch({
      type: SET_LOGIN,
      payload: {
        data: "FALSE",
      },
    });
  };
};
