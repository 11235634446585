import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import EmptyTable from "./EmptyTable";

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
function GlobalTabel(props) {
  let text = props.textEmpty;
  let handleClick = props.handleClick;
  let tambahData = props.tambahData;
  let expandRow = props.expandRow;
  let selectRow = props.selectRow;
  let exportCSV = props.exportCSV;
  let link = props.link;
  let location = props.location;
  let pagination = props.pagination ?? true;
  let load = props.isDataFetched ?? false;
  return (
    <ToolkitProvider
      keyField={props.keyField}
      data={props.data || []}
      columns={props.columns}
      search
    >
      {(props) => (
        <div className="row">
          <div className="col-6">
            <div className="text-left">
              <SearchBar {...props.searchProps} />
            </div>
          </div>
          <div className="col-6">
            <div className="text-right">
              {tambahData && (
                <button
                  onClick={handleClick}
                  className="btn btn-primary"
                  type="button"
                >
                  Tambah Data
                  <i className="fa fa-plus ml-3"></i>
                </button>
              )}
            </div>
          </div>
          <hr />
          <div className="col-12">
            <BootstrapTable
              bootstrap4
              pagination={
                pagination
                  ? paginationFactory({
                      sizePerPage: 25,
                    })
                  : null
              }
              selectRow={selectRow}
              expandRow={expandRow}
              wrapperClasses="table-responsive"
              rowClasses="text-nowrap"
              headerClasses="header-table"
              footerClasses="footer-table"
              classes="body-table"
              {...props.baseProps}
              striped
              noDataIndication={
                <EmptyTable
                  text={text || "Tidak Ada Data"}
                  link={link}
                  location={location}
                />
              }
              loading={load}
              overlay={overlayFactory({
                spinner: true,
                background: "rgba(192,192,192,0.3)",
              })}
            />
            <br />
            {exportCSV && (
              <ExportCSVButton {...props.csvProps}>
                Export CSV!!
              </ExportCSVButton>
            )}
          </div>
        </div>
      )}
    </ToolkitProvider>
  );
}

export default GlobalTabel;
