import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatRp, printInfo } from "../../../helper";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporanPrintCash = (username = "", tanggal = "", validby = "", data) => {
  // initialize jsPDF
  const doc = new jsPDF("portrait");
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];

  let tableColumn = [
    [
      {
        content: `TYPE KAS`,
      },
      {
        content: `NOMINAL`,
        styles: { halign: "right" },
      },
      {
        content: `CURRENCY`,
        styles: { halign: "right" },
      },
    ],
  ];
  doc.setFontSize(15);
  doc.text("LAPORAN KEUANGAN CASH", 14, 10);
  // doc.addImage(logo_ayu_base64, "PNG", 14, 10, 35, 12);
  doc.setFontSize(10);
  data.forEach((item, index) => {
    let rows = [
      item.kode_kas,
      { content: formatRp(item.saldo), styles: { halign: "right" } },
      { content: item.curr, styles: { halign: "right" } },
    ];
    tableRows.push(rows);
  });
  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    foot: [printInfo()],
    startY: 20,
    theme: "plain",
    rowPageBreak: "avoid",
    margin: { top: 20 },
    bodyStyles: { lineColor: "#969696", fontSize: 8, lineWidth: 0.0 },
    headStyles: {
      lineColor: "#969696",
      lineWidth: 0.0,
      fontSize: 8,
      fillColor: "#E8E8E8",
    },
    footStyles: {
      lineColor: "#969696",
      lineWidth: 0.0,
      fillColor: "#E8E8E8",
      fontSize: 8,
    },
  });
  tableRows = [];

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  doc.autoPrint();
  doc.setProperties({
    title: "LAPORAN KEUANGAN CASH",
  });

  var string = doc.output("datauristring");
  // return string;
  var x = window.open();
  x.document.open();
  x.document.write(
    `
<html>
<head>
  <title>LAPORAN KEUANGAN CASH</title>
  <style>
    #overlay {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1000;
    }
  </style>
</head>
<body style='margin:0 !important; position: relative;'>
  <iframe src='${string}#toolbar=0' width='100%' height='100%' frameborder='0' style='position: absolute; top: 0; left: 0;'></iframe>
  <div id='overlay' oncontextmenu='return false;'></div>
</body>
</html>
`
  );
  // setInterval(() => {
  //   x.close();
  // }, 1000);
};

export default LaporanPrintCash;
