import React from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { formatGram, reduceAndFormatGram } from "../../../helper";
import { HeadLaporanExcel } from "../../Component/HeadLaporanExcel";

export const LaporanExcelTambahSaldo24K = (props) => {
  const { data } = props;
  return (
    <>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="btn btn-success btn-block"
        table="table-to-xls"
        filename="Laporan Tambah Saldo 24K"
        sheet="Laporan Tambah Saldo 24K"
        buttonText="Export Excel"
      />
      <table rowkey="kode_member" id="table-to-xls" style={{ display: "none" }}>
        <thead>
          <HeadLaporanExcel nama={"LAPORAN TAMBAH SALDO 24K"} colSpan={6} />
          <tr>
            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>NO</th>
            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
              KODE BARANG
            </th>
            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
              KADAR BELI
            </th>
            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>PKG</th>
            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>BRUTO</th>
            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>NETTO</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, no) => (
            <>
              <tr>
                <td colSpan={6}>Nomor Transaksi : {item.no_transaksi}</td>
              </tr>
              <tr>
                <td colSpan={6}>Tanggal Transaksi : {item.tanggal}</td>
              </tr>
              {item.detail_barang.map((x, i) => (
                <tr>
                  <td>{++i}</td>
                  <td>{x.kode_barang}</td>
                  <td style={{ textAlign: "right" }}>
                    {" "}
                    &nbsp;{formatGram(x.kadar_beli)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {" "}
                    &nbsp;{formatGram(x.pkg)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {" "}
                    &nbsp;{formatGram(x.bruto)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {" "}
                    &nbsp;{formatGram(x.netto)}
                  </td>
                </tr>
              ))}
              <tr>
                <td
                  colSpan={4}
                  style={{ backgroundColor: "#E8E5E5", color: "#000" }}
                >
                  Grand Total
                </td>
                <td
                  style={{
                    textAlign: "right",
                    backgroundColor: "#E8E5E5",
                    color: "#000",
                  }}
                >
                  {" "}
                  &nbsp;{reduceAndFormatGram(item.detail_barang, "bruto")}
                </td>
                <td
                  style={{
                    textAlign: "right",
                    backgroundColor: "#E8E5E5",
                    color: "#000",
                  }}
                >
                  {" "}
                  &nbsp;{reduceAndFormatGram(item.detail_barang, "netto")}
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </>
  );
};
