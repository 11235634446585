import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { connect } from "react-redux";

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // ROW STYLE TABLE
  rowStyle = (row, rowIndex) => {
    row.index = rowIndex;
    const style = {};
    if (rowIndex % 2 === 0) {
      style.backgroundColor = "transparent";
    } else {
      style.backgroundColor = "#DEDEDE";
    }
    style.borderTop = "none";

    return style;
  };

  render() {
    return (
      <ToolkitProvider
        bootstrap4
        keyField={this.props.namaKey ? this.props.namaKey : "kode"}
        data={this.props.data}
        columns={this.props.columns}
        defaultSorted={this.props.defaultSorted}
        search
      >
        {(props) => (
          <div className="col-lg-12 col-md-12 mt-5">
            <div className="text-right">
              <SearchBar
                {...props.searchProps}
                className="form-control"
                placeholder="Tuliskan Kode / Nama"
                style={{ margin: 0 }}
              />
            </div>
            <BootstrapTable
              {...props.baseProps}
              headerClasses="header-table"
              striped
              hover
              bordered={true}
              pagination={paginationFactory()}
            />
            <ExportCSVButton {...props.csvProps} className="btn btn-primary">
              Export CSV
            </ExportCSVButton>
          </div>
        )}
      </ToolkitProvider>
    );
  }
}

export default connect()(Table);
