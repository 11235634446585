import {getLocal} from "../encrypt";

export const GET_DATA_TRANSAKSAI_PENJUALAN_LM =
  "GET_DATA_TRANSAKSAI_PENJUALAN_LM";
export const GET_DATA_LIST_PENJUALAN_LM = "GET_DATA_LIST_PENJUALAN_LM";
export const GET_DATA_LIST_PEMBELIAN_LM = "GET_DATA_LIST_PEMBELIAN_LM";
export const GET_DATA_LIST_SUM_PEMBAYARAN_LM =
  "GET_DATA_LIST_SUM_PEMBAYARAN_LM";
export const GET_DATA_SALDO_AWAL_PIUTANG_CUSTOMER =
  "GET_DATA_SALDO_AWAL_PIUTANG_CUSTOMER";
export const GET_DATA_SALDO_AWAL_STOK = "GET_DATA_SALDO_AWAL_STOK";

export const getListPenjualanLM = (ongkos) => {
  var hasil = JSON.parse(localStorage.getItem("transaksiPenjualanLM")) || [];
  var jumlah = hasil.map((list) => list.netto * list.rp);
  var total = jumlah + ongkos ? ongkos : 0;
  return (dispatch) => {
    dispatch({
      type: GET_DATA_TRANSAKSAI_PENJUALAN_LM,
      payload: {
        data: JSON.parse(localStorage.getItem("transaksiPenjualanLM")),
        jumlah: jumlah,
        total: total,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const getListDataTablePenjualanLM = () => {
  return (dispatch) => {
    dispatch({
      type: GET_DATA_LIST_PENJUALAN_LM,
      payload: {
        data: JSON.parse(localStorage.getItem("TransaksiPenjualanLM")),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};
export const getListDataTablePembelianLM = () => {
  return (dispatch) => {
    dispatch({
      type: GET_DATA_LIST_PEMBELIAN_LM,
      payload: {
        data: JSON.parse(localStorage.getItem("TransaksiPembelianLM")),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};
export const getListDataSaldoAwalPiutangCustomer = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_DATA_SALDO_AWAL_PIUTANG_CUSTOMER,
      payload: {
        data: await getLocal("SaldoAwalPiutangCustomer"),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};
export const getListDataSaldoAwalStok = () => {
  return async(dispatch) => {
    dispatch({
      type: GET_DATA_SALDO_AWAL_STOK,
      payload: {
        data: await getLocal("SaldoAwalStock"),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const getTotalBayarLM = () => {
  var giro = JSON.parse(localStorage.getItem("alatBayarGiroLM"));
  var cash = JSON.parse(localStorage.getItem("alatBayarTunaiLM"));
  var transfer = JSON.parse(localStorage.getItem("alatBayarTrfLM"));
  var sumGiro = giro === null ? 0 : giro[0].giroNetto;
  var sumCash = cash === null ? 0 : cash[0].tunaiNetto;
  var sumtransfer = transfer === null ? 0 : transfer[0].transferRp;
  return (dispatch) => {
    dispatch({
      type: GET_DATA_LIST_SUM_PEMBAYARAN_LM,
      payload: {
        data: sumGiro + sumCash + sumtransfer,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};
