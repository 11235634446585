const validaitonRekening = (values) => {
  const errors = {};
  if (!values.noRekening) {
    errors.noRekening = "Tidak Boleh Kosong";
  // } else if (values.noRekening.length < 8) {
  //   errors.noRekening = "Sepertinya Norekening Salah";
  }

  if (!values.atasNama) {
    errors.atasNama = "Tidak Boleh Kosong";
  } else if (values.atasNama > 30) {
    errors.atasNama = "Nama Terlalu Panjang";
  }
  if (values.mataUang === "DEFAULT") {
    errors.mataUang = "Tidak Boleh Kosong";
  }
  return errors;
};

export default validaitonRekening;
