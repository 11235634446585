export const SET_DETAIL_BARANG = "SET_DETAIL_BARANG";
export const SET_DETAIL_PEMBAYARAN = "SET_DETAIL_PEMBAYARAN";
export const SET_DETAIL_TITIP = "SET_DETAIL_TITIP";
export const SET_DETAIL_KETERANGAN = "SET_DETAIL_KETERANGAN";

export const setDetailPembayaran = (pembayaran) => {
  return (dispatch) => {
    dispatch({
      type: SET_DETAIL_PEMBAYARAN,
      payload: {
        data: pembayaran,
      },
    });
  };
};

export const setDetailBarang = (barang) => {
    return (dispatch) => {
      dispatch({
        type: SET_DETAIL_BARANG,
        payload: {
          data: barang,
        },
      });
    };
  };

  export const setDetailTitip = (titip) => {
    return (dispatch) => {
      dispatch({
        type: SET_DETAIL_TITIP,
        payload: {
          data: titip,
        },
      });
    };
  };

  export const setDetailKeterangan = (keterangan) => {
    return (dispatch) => {
      dispatch({
        type: SET_DETAIL_KETERANGAN,
        payload: {
          data: keterangan,
        },
      });
    };
  };