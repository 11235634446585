export const GET_TABLE_TOLAKAN_CUSTOMER = "GET_TABLE_TOLAKAN_CUSTOMER";

export const getTabelCustomer = () => {
  return (dispatch) => {
    dispatch({
      type: GET_TABLE_TOLAKAN_CUSTOMER,
      payload: {
        data: JSON.parse(localStorage.getItem("tolakanCustomer")),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};
