import { SET_TITIP_CUSTOMER } from "../actions/pembayaranCustomerAction"

const initialState = {
    titip_gr : 0,
    titip_rp : 0
}


const reducer = (state = initialState, action) => {
    switch(action.type){
        case SET_TITIP_CUSTOMER:
            return {
                titip_gr : action.payload.titip_gr,
                titip_rp : action.payload.titip_rp
            }
        default:
            return state
    }
}

export default reducer