import React from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { formatGram, reduceAndFormatGram } from "../../../helper";
import { HeadLaporanExcel } from "../../Component/HeadLaporanExcel";

export const LaporanExcelPenjualan24K = (props) => {
  const { data } = props;
  return (
    <>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="btn btn-success btn-block"
        table="table-to-xls"
        filename="Laporan Penjualan 24K"
        sheet="Laporan Penjualan 24K"
        buttonText="Export Excel"
      />
      <table rowkey="kode_member" id="table-to-xls" style={{ display: "none" }}>
        <thead>
          <HeadLaporanExcel
            nama={" LAPORAN PENJUALAN 24K"}
            colSpan={9}
            rangeDate
            start={data.tglFrom}
            end={data.tglTo}
          />
          <tr>
            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>NO</th>
            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
              KODE BARANG
            </th>
            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}></th>
            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
              HARGA JUAL
            </th>
            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>BRUTO</th>
            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>NETTO</th>
            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
              ONGKOS RP
            </th>
          </tr>
        </thead>
        <tbody>
          {data.data.map((item, no) => (
            <>
              <tr>
                <td colSpan={2}>TANGGAL : {item.tanggal}</td>
                <td colSpan={2}>NO BON : {item.no_bon}</td>
                <td colSpan={3}>NAMA TOKO : {item.nama_toko}</td>
              </tr>
              <tr>
                <td colSpan={7}>TOTAL NETTO : {item.total_netto}</td>
                {/*<td colSpan={4}>TOTAL NETTO EXT : {item.total_netto_ext}</td>*/}
              </tr>
              {item.detail_barang.map((x, i) => (
                <tr>
                  <td>{++i}</td>
                  <td>{x.kode_barang}</td>
                  <td style={{ textAlign: "right" }}>&nbsp;{x.kadar_cetak}</td>
                  <td style={{ textAlign: "right" }}>&nbsp;{x.kadar_jual}</td>
                  <td style={{ textAlign: "right" }}>
                    &nbsp;{formatGram(x.bruto)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    &nbsp;{formatGram(x.netto)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    &nbsp;{formatGram(x.ongkos_rp)}
                  </td>
                </tr>
              ))}
              <tr>
                <td
                  colSpan={4}
                  style={{ backgroundColor: "#E8E5E5", color: "#000" }}
                >
                  Grand Total
                </td>
                <td
                  style={{
                    textAlign: "right",
                    backgroundColor: "#E8E5E5",
                    color: "#000",
                  }}
                >
                  &nbsp;{reduceAndFormatGram(item.detail_barang, "bruto")}
                </td>
                <td
                  style={{
                    textAlign: "right",
                    backgroundColor: "#E8E5E5",
                    color: "#000",
                  }}
                >
                  &nbsp;{reduceAndFormatGram(item.detail_barang, "netto")}
                </td>
                <td
                  style={{
                    textAlign: "right",
                    backgroundColor: "#E8E5E5",
                    color: "#000",
                  }}
                >
                  &nbsp;{reduceAndFormatGram(item.detail_barang, "ongkos_rp")}
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </>
  );
};
