import {
  HIDE_MODAL,
  SHOW_MODAL,
  SHOW_MODAL_SECOND,
  HIDE_MODAL_SECOND,
} from "../actions/ModalAction";

const initialState = {
  show: false,
  showSecond: false,
};

const modal = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        show: action.payload.data,
      };
    case HIDE_MODAL:
      return {
        ...state,
        show: action.payload.data,
      };
    case SHOW_MODAL_SECOND:
      return {
        ...state,
        showSecond: action.payload.data,
      };
    case HIDE_MODAL_SECOND:
      return {
        ...state,
        showSecond: action.payload.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export default modal;
