import React, { Component } from "react";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { renderField, renderFieldSelect } from "../../renderField";
import { getListRosok } from "../../DataMaster/ciok-rosok/redux/rosokAction";

class FormPengambilanCiok extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kodeBarang: [],
      kodeJenis: "",
      namaJenis: "",
      kadarCetak: "",
      kadarBeli: "",
      columns: [
        {
          dataField: "kode_jenis",
          text: "Kode Model",
        },
        {
          dataField: "bruto",
          text: "Bruto",
          align: "right",
          headerAlign: "right",
        },
        {
          dataField: "kadar_transaksi",
          text: "Harga (%)",
          align: "right",
          headerAlign: "right",
        },
        {
          dataField: "netto",
          text: "Netto(Gr)",
          align: "right",
          headerAlign: "right",
        },
      ],
      tampungCiok: [],
    };
  }

  componentDidMount() {
    this.props.dispatch(getListRosok());
  }
  pilihBarang(data) {
    var supp = data ? data : "DEFAULT | DEFAULT | DEFAULT | DEFAULT ";
    var result_explode = supp.split("|");
    this.props.change("kadar", result_explode[1]);
    this.props.change("kadarTransaksi", result_explode[2]);
  }

  setNetto() {
    this.props.change("netto", this.props.netto);
  }

  render() {
    var filtered = this.props.rosok.filter((d) => d.kode_kelompok === "CIOK" || d.kode_kelompok === "RONGSOK");
    return (
      <div className="col-lg-12">
        <form onSubmit={this.props.handleSubmit}>
          <div className="row">
            <div className="col-lg-4 mb-3">
              <Field
                name="kodeBarang"
                className="form-control"
                component={renderFieldSelect}
                label="Kode Model"
                onChange={(data) => this.pilihBarang(data)}
                options={filtered.map((list) => {
                  let data = {
                    value:
                      list.kode_jenis +
                      "|" +
                      list.kadar_cetak +
                      "|" +
                      list.kadar_beli,
                    name: list.nama_jenis,
                  };
                  return data;
                })}
              />
            </div>
            <div className="col-lg-8"></div>
            <div className="col-md-3 form-group">
              <Field
                name="kadar"
                label="Kadar"
                type="number"
                component={renderField}
                readOnly={true}
              />
            </div>
            <div className="col-md-3 form-group">
              <Field
                name="bruto"
                label="Berat (Gr)"
                type="number"
                component={renderField}
                onChange={this.setNetto()}
              />
            </div>
            <div className="col-md-3 form-group">
              <Field
                name="kadarTransaksi"
                label="Harga Jual"
                type="number"
                component={renderField}
              />
            </div>
            <div className="col-md-3 form-group">
              <Field
                name="netto"
                label="Murni (Gr)"
                type="number"
                component={renderField}
                readOnly={true}
              />
            </div>
            <div className="col-lg-12 mb-3">
              <div className="text-right">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={this.props.onSend}
                >
                  {this.props.onSend ? (
                    <span
                      className="spinner-border spinner-border-sm mr-3"
                      aria-hidden="true"
                    ></span>
                  ) : null}
                  Simpan
                  <i className="fa fa-paper-plane ml-3"></i>
                </button>
              </div>
            </div>
            {this.props.data ? (
              <ToolkitProvider
                keyField="id"
                data={this.props.data}
                columns={this.state.columns}
                search
              >
                {(props) => (
                  <div className="form-group">
                    <BootstrapTable
                      {...props.baseProps}
                      striped
                      hover
                      condensed
                      bordered={true}
                      headerClasses="header-table"
                      pagination={paginationFactory()}
                    />
                  </div>
                )}
              </ToolkitProvider>
            ) : null}
          </div>
        </form>
      </div>
    );
  }
}

FormPengambilanCiok = reduxForm({
  form: "FormPengambilanCiok",
  enableReinitialize: true,
})(FormPengambilanCiok);
const selector = formValueSelector("FormPengambilanCiok"); // <-- same as form name
export default connect((state) => {
  const { bruto, kadarTransaksi } = selector(state, "bruto", "kadarTransaksi");
  return {
    netto: ((parseFloat(bruto) * parseFloat(kadarTransaksi)) / 100).toFixed(3),
    rosok: state.provinsi.rosok,
  };
})(FormPengambilanCiok);
