import { getLocal, saveLocal } from "../../../encrypt";
import {
  finishSend,
  isExist,
  progressSend,
} from "../../../actions/LogicAction";
import { toast } from "react-toastify";
import { AxiosMasterGet, AxiosMasterPost } from "../../../Axios";
import { ToastAmbil, ToastKirim } from "../../../actions/toast";
import { hideModal } from "../../../actions/ModalAction";
import { getToday } from "../../../GlobalComponent/function";
import { getBon, multipleDeleteLocal, purifiedData } from "../../../helper";
import { change, reset } from "redux-form";

export const GET_KIRIM_STOCK_RETUR = "GET_KIRIM_STOCK_RETUR";
export const GET_KIRIM_STOCK_RETUR_TEMP = "GET_KIRIM_STOCK_RETUR_TEMP";
export const SET_KIRIM_STOCK_RETUR = "SET_KIRIM_STOCK_RETUR";
export const SET_LIST_KIRM_STOCK_RETUR = "SET_LIST_KIRM_STOCK_RETUR";
export const NO_RETUR_SELECTED = "NO_RETUR_SELECTED";

export const getKirimStockRetur = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_KIRIM_STOCK_RETUR_TEMP,
      payload: await getLocal("kirimStockReturTemp"),
    });
  };
};

export const saveKirimStockReturnTemp = (data) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const prev = state.kirimStockRetur.noReturSelected;
    prev.push({ no_retur: data.no_retur });
    dispatch({
      type: NO_RETUR_SELECTED,
      payload: prev,
    });
    for (var x of data.detail_barang) {
      const payload = {
        no_retur: data.no_retur,
        kode_barang: x.kode_barang,
        kode_bahan: x.kode_bahan,
        kadar_cetak: x.kadar_cetak,
        bruto: x.bruto,
        kadar_beli: x.kadar_beli,
        netto: x.netto,
      };
      await isExist({
        value: payload.netto,
        value2: payload.netto,
        payload: payload,
        name: "kirimStockReturTemp",
        target: "netto",
        target2: "netto",
      });
    }
    dispatch(getKirimStockRetur());
    dispatch(hideModal());
    dispatch(finishSend());
  };
};

export const sendKirimStockRetur = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.KirimStockRetur.values;
    purifiedData(selected);
    const payload = {
      no_transaksi: selected.no_transaksi,
      tujuan_stock: selected.tujuan_stock,
      keterangan: selected.keterangan,
      tanggal: getToday(),
      detail: state.kirimStockRetur.noReturSelected,
    };
    try {
      await toast.promise(
        AxiosMasterPost("kirim-stock-retur", payload),
        ToastKirim
      );
      multipleDeleteLocal(["kirimStockReturTemp"]);
      dispatch(reset("KirimStockRetur"));
      dispatch(change("KirimStockRetur", "no_transaksi", "KR-" + getBon()));
      dispatch(finishSend());
      dispatch(getKirimStockRetur());
      // dispatch(getListKirimStockRetur());
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

export const deleteKirimStockRetur = (index) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const data = await getLocal("kirimStockReturTemp");
    data.splice(index, 1);
    await saveLocal("kirimStockReturTemp", data);
    dispatch(getKirimStockRetur());
    dispatch(finishSend());
  };
};

export const getListKirimStockRetur = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const localTemp = await getLocal("kirimStockReturTemp");
    const response = await toast.promise(
      AxiosMasterGet("retur-customer/valid"),
      ToastAmbil
    );
    const result = response.data.filter(
      (item1) => !localTemp.some((item2) => item2.no_retur === item1.no_retur)
    );

    dispatch({
      type: SET_LIST_KIRM_STOCK_RETUR,
      payload: result,
    });
  };
};
