import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formatGram,
  preventTabAction,
  removeComma,
  setFocus,
} from "../../../helper";
import { change, Field, reduxForm } from "redux-form";
import { renderField, renderFieldSelect } from "../../../renderField";
import FormValueSelector from "redux-form/lib/formValueSelector";
import ButtonSelesai from "../../../button_selesai";
import CustomForm from "../../../CustomForm";

const FormKirimStockBayar = (props) => {
  const dispatch = useDispatch();
  const { handleSubmit } = props;
  let listRosok = useSelector((state) => state.provinsi.rosok);
  const state = useSelector((state) => state);
  const selector = FormValueSelector("FormKirimStockBayar");

  useEffect(() => {
    preventTabAction();
  }, []);
  let { bruto, kadar_beli } = selector(state, "bruto", "kadar_beli");

  const setNettoBruto = (e) => {
    var netto = removeComma(e.target.value) * (kadar_beli / 100);
    dispatch(change("FormKirimStockBayar", "netto", formatGram(netto)));
  };
  const setNettoKadar = (e) => {
    var netto = bruto * (removeComma(e.target.value) / 100);
    dispatch(change("FormKirimStockBayar", "netto", formatGram(netto)));
  };

  const setItems = (data) => {
    const result = listRosok.find((x) => x.kode_ciok_rosok === data.value);
    if (result) {
      dispatch(
        change("FormKirimStockBayar", "kadar_cetak", result.kadar_cetak)
      );
      dispatch(change("FormKirimStockBayar", "kadar_beli", result.kadar_beli));
    }
    setFocus("Bruto");
  };

  return (
    <CustomForm onSubmit={handleSubmit}>
      <div className="col-lg-12 col-md-12 row">
        <div className={"col-lg-3"}>
          <Field
            name={"kode_ciok_rosok"}
            label={"Kode Barang"}
            component={renderFieldSelect}
            onChange={setItems}
            options={listRosok.map((x) => {
              return {
                value: x.kode_ciok_rosok,
                name: x.nama_ciok_rosok,
              };
            })}
          />
        </div>
        <div className={"col-lg-2"}>
          <Field
            name={"kadar_cetak"}
            label={"Kadar"}
            component={renderField}
            readOnly
          />
        </div>
        <div className={"col-lg-2"}>
          <Field
            name={"bruto"}
            label={"Bruto"}
            component={renderField}
            onChange={setNettoBruto}
          />
        </div>
        <div className={"col-lg-2"}>
          <Field
            name={"kadar_beli"}
            label={"Harga"}
            component={renderField}
            onChange={setNettoKadar}
          />
        </div>
        <div className={"col-lg-2"}>
          <Field
            name={"netto"}
            label={"Netto"}
            component={renderField}
            type={"text"}
          />
        </div>
        <div className={"col-lg-12 form-group"}>
          <label>&nbsp;</label>
          <ButtonSelesai title={"Tambah Barang"} />
        </div>
      </div>
    </CustomForm>
  );
};

export default reduxForm({
  form: "FormKirimStockBayar",
})(FormKirimStockBayar);
