import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { reduxForm } from "redux-form";
import { getUUID } from "../../actions/fakturAction";
import { warning } from "../../Alert";
import HeaderCard from "../../HeaderCard";
import FormSaldoAwal24K from "./component/FormSaldoAwal24K";
import { sendSaldoAwal24K } from "./redux/saldo-awal-24k-action";
import { getList24K } from "../24k/redux/24kAction";

const maptostate = (state) => {
  return {
    noFaktur: state.provinsi.noFaktur,
    username: state.provinsi.username,
  };
};
class SaldoAwal24K extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipeTransksi: "HUTANG",
      listSales: [],
    };
  }

  componentDidMount() {
    this.props.dispatch(getUUID());
    this.props.dispatch(getList24K());
  }
  handleChange = (input) => (e) => {
    this.setState({
      [input]: e.target.value,
    });
  };

  handlerSimpan(data) {
    if (data.tanggal === undefined) {
      warning("Tanggal Tidak boleh kosong");
      return false;
    }
    this.props.dispatch(sendSaldoAwal24K());
  }
  render() {
    return (
      <div className="container-fluid">
        <ol className="breadcrumb mb-2">
          <Link to="/saldoawal">
            <button type="button" className="btn btn-secondary">
              <i className="fa fa-angle-left mr-3" aria-hidden="true" /> Back
            </button>
          </Link>
        </ol>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <HeaderCard title="Saldo Awal 24K" />
              <div className="card-body">
                <div className="col-lg-12 mt-3">
                  <FormSaldoAwal24K
                    onSubmit={(data) => this.handlerSimpan(data)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SaldoAwal24K = reduxForm({
  form: "SaldoAwal24K",
  enableReinitialize: true,
})(SaldoAwal24K);
export default connect(maptostate, null)(SaldoAwal24K);
