import {GET_ALL_PINDAH_SAMPEL} from "./pindahSampleAction";

const initialState = {
    dataPindahSampleTemp: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_PINDAH_SAMPEL:
            return {
                ...state,
                dataPindahSampleTemp: action.payload,
            };

        default:
            return state;
    }
};

export default reducer;
