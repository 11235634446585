import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatDateIndo, formatGram, printInfo } from "../../../helper";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporanReturnCustomer = (data, mulai_dari, sampai_dengan) => {
  console.log(data);
  // initialize jsPDF
  const doc = new jsPDF("portrait");
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];

  doc.setFontSize(15);
  doc.text("LAPORAN RETURN CUSTOMER", 14, 10);
  // doc.addImage(logo_ayu_base64, "PNG", 14, 10, 35, 12);

  doc.setFontSize(10);
  doc.text(`Mulai Dari : ${formatDateIndo(mulai_dari)}`, 14, 16);
  //   row 2
  doc.text(`Sampai Dengan	: ${formatDateIndo(sampai_dengan)}`, 100, 16);
  //   //row 1
  //   doc.text(`Supplier : ${row1isi}`, 14, 25);
  //   //   row 2
  //   doc.text(`Tanggal	: ${row2isi}`, 120, 25);
  let finalY = 20;
  let grandTotalBruto = 0;
  let grandTotalNetto = 0;
  let grandTotalTotal = 0;
  let grandTotalExt = 0;
  data.forEach((item, index) => {
    let tableRows = [];
    let tableColumn = [
      [
        {
          content: `KODE CUSTOMER : ${item.kode_customer}`,
          colSpan: 2,
        },
        {
          content: `NAMA CUSTOMER : ${item.nama_customer}`,
          colSpan: 4,
        },
      ],
      [
        {
          content: `TANGGAL`,
        },
        {
          content: `NO BON`,
        },

        {
          content: `TOTAL BRUTO (GR)`,
          styles: { halign: "right" },
        },
        {
          content: `TOTAL NETTO (GR)`,
          styles: { halign: "right" },
        },
        {
          content: `TOTAL (GR)`,
          styles: { halign: "right" },
        },
        {
          content: `TOTAL EXT (GR)`,
          styles: { halign: "right" },
        },
      ],
    ];
    item.detail.forEach((hasil, index) => {
      let rows = [
        hasil.tanggal_invoice,
        hasil.no_bon,
        {
          content: formatGram(hasil.total_bruto, 3),
          styles: { halign: "right" },
        },
        {
          content: formatGram(hasil.total_netto, 3),
          styles: { halign: "right" },
        },
        { content: formatGram(hasil.total), styles: { halign: "right" } },
        { content: formatGram(hasil.total_ext), styles: { halign: "right" } },
      ];
      tableRows.push(rows);
    });
    grandTotalBruto += item.detail.reduce((a, b) => a + b.total_bruto, 0);
    grandTotalNetto += item.detail.reduce((a, b) => a + b.total_netto, 0);
    grandTotalTotal += item.detail.reduce((a, b) => a + b.total, 0);
    grandTotalExt += item.detail.reduce((a, b) => a + b.total_ext, 0);
    let subTotalRows = [
      {
        colSpan: 2,
        content: "Sub Total",
        styles: {
          halign: "right",
        },
      },
      {
        content: formatGram(item.detail.reduce((a, b) => a + b.total_bruto, 0)),
        styles: { halign: "right" },
      },
      {
        content: formatGram(item.detail.reduce((a, b) => a + b.total_netto, 0)),
        styles: { halign: "right" },
      },
      {
        content: formatGram(item.detail.reduce((a, b) => a + b.total, 0)),
        styles: { halign: "right" },
      },
      {
        content: formatGram(item.detail.reduce((a, b) => a + b.total_ext, 0)),
        styles: { halign: "right" },
      },
    ];
    tableRows.push(subTotalRows);
    if (index === data.length - 1) {
      let grandTotalRows = [
        {
          colSpan: 2,
          content: "Grand Total",
          styles: {
            halign: "right",
          },
        },
        {
          content: formatGram(grandTotalBruto),
          styles: { halign: "right" },
        },
        {
          content: formatGram(grandTotalNetto),
          styles: { halign: "right" },
        },
        {
          content: formatGram(grandTotalTotal),
          styles: { halign: "right" },
        },
        {
          content: formatGram(grandTotalExt),
          styles: { halign: "right" },
        },
      ];
      tableRows.push(grandTotalRows);
    }

    doc.autoTable({
      head: tableColumn,
      body: tableRows,
      showHead: "firstPage",
      showFoot: "lastPage",
      foot: [printInfo()],
      startY: finalY,
      theme: "plain",
      rowPageBreak: "avoid",
      pageBreak: "avoid",
      margin: { top: 20 },
      bodyStyles: { lineColor: "#969696", fontSize: 8, lineWidth: 0.0 },
      headStyles: {
        lineColor: "#969696",
        lineWidth: 0.0,
        fontSize: 8,
        fillColor: "#E8E8E8",
      },
      footStyles: {
        lineColor: "#969696",
        lineWidth: 0.0,
        fillColor: "#E8E8E8",
        fontSize: 8,
      },
    });
    finalY = doc.lastAutoTable.finalY + 10;
  });

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  doc.autoPrint();
  doc.setProperties({
    title: "LAPORAN RETURN CUSTOMER",
  });
  // doc.save(`PenerimaanSUpplier.pdf`);
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    `
<html>
<head>
  <title>LAPORAN RETURN CUSTOMER</title>
  <style>
    #overlay {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1000;
    }
  </style>
</head>
<body style='margin:0 !important; position: relative;'>
  <iframe src='${string}#toolbar=0' width='100%' height='100%' frameborder='0' style='position: absolute; top: 0; left: 0;'></iframe>
  <div id='overlay' oncontextmenu='return false;'></div>
</body>
</html>
`
  );
  // setInterval(() => {
  //   x.close();
  // }, 1000);
};

export default LaporanReturnCustomer;
