import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import validaitonRekening from "../../../Validations/validaitonRekening";
import { renderField } from "../../../renderField";
import CustomForm from "../../../CustomForm";
import { preventTabAction, setFocus } from "../../../helper";
import ButtonSelesai from "../../../button_selesai";
import { createNumberMask } from "redux-form-input-masks";

const maptoState = (state) => {
  return {
    initialValues: {
      kode_harga: state.parameterHargaEmas.editDataHargaEmas.kode_harga,
      rupiah: state.parameterHargaEmas.editDataHargaEmas.rupiah,
      usd: state.parameterHargaEmas.editDataHargaEmas.usd
    },
  };
};

const currencyMask = createNumberMask({
  prefix: "Rp. ",
  suffix: ",-",
  locale: "id-ID",
});

const currencyMaskUSD = createNumberMask({
  prefix: "$ ",
  locale: "en-US"
})

class FormParameterHargaEmasEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      response: "",
    };
  }
  componentDidMount() {
    preventTabAction();
    setTimeout(() => {
      setFocus("Rupiah");
    }, 300);
  }

  render() {
    return (
      <div className="col-lg-12 col-md-12">
        <CustomForm onSubmit={this.props.handleSubmit}>
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <Field
                name="kode_harga"
                type="text"
                component={renderField}
                label="Kode"
                readOnly={true}
              />
            </div>
            <div className="col-md-3 col-lg-3">
              <label htmlFor="">Rupiah</label>
              <Field
                name="rupiah"
                component="input"
                type="text"
                className="form-control"
                id="Rupiah"
                {...currencyMask}
              />
            </div>
            <div className="col-md-3 col-lg-3">
              <label htmlFor="">USD</label>
              <Field
                name="usd"
                component="input"
                type="text"
                className="form-control"
                id="USD"
                {...currencyMaskUSD}
              />
            </div>
            <div className="col-md-12 col-lg-12 mt-5">
              <ButtonSelesai />
            </div>
          </div>
        </CustomForm>
      </div>
    );
  }
}

FormParameterHargaEmasEdit = reduxForm({
  form: "FormParameterHargaEmasEdit",
  validate: validaitonRekening,
  enableReinitialize: true,
})(FormParameterHargaEmasEdit);
export default connect(maptoState, null)(FormParameterHargaEmasEdit);
