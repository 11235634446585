import { SET_DETAIL_BARANG, SET_DETAIL_KETERANGAN, SET_DETAIL_PEMBAYARAN, SET_DETAIL_TITIP } from "../actions/detailValidasi";

const initialState = {
    data_pembayaran: [],
    data_barang: [],
    data_titip: [],
    keterangan : ""
  };
  
  const detailValidasi = (state = initialState, action) => {
    switch (action.type) {
      case SET_DETAIL_BARANG:
        return {
          ...state,
          data_barang: action.payload.data,
        };
      case SET_DETAIL_PEMBAYARAN:
        return {
          ...state,
          data_pembayaran: action.payload.data,
        };
    case SET_DETAIL_TITIP:
        return {
            ...state,
            data_titip: action.payload.data,
        }
      case SET_DETAIL_KETERANGAN:
        return {
          ...state,
          keterangan : action.payload.data,
        }
      default:
        return {
          ...state,
        };
    }
  };
  
  export default detailValidasi;