import React, { Component } from "react";
import Table from "./Component/Table";
import { connect } from "react-redux";
import notrespond from "../Images/500.svg";
import Swal from "sweetalert2";
import { reset } from "redux-form";
import Skeleton from "react-loading-skeleton";
import { berhasil, gagal, warning } from "../Alert";
import { AxiosMasterDelete, AxiosMasterPost, AxiosMasterPut } from "../Axios";
import { hideModal, showModal } from "../actions/ModalAction";
import ModalMasterData from "./Component/ModalMasterData";
import HeaderCard from "../HeaderCard";
import TourGuide from "../TourGuide";
import { finishSend, progressSend } from "../actions/LogicAction";
import { editReparasi, getReparasiLIst } from "../actions/reparasiAction";
import FormReparasiEdit from "./Component/FormReparasiEdit";
import FormReparasi from "./Component/FormReparasi";

function hapus(dispatch, kode) {
  Swal.fire({
    title: "Anda Yakin ?",
    text: "Anda Akan Menghapus Data Ini ?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      AxiosMasterDelete("reparasi/delete/" + kode)
        .then(() => dispatch(hideModal()))
        .then(() =>
          berhasil("Data Berhasil Dihapus!").then(() =>
            dispatch(getReparasiLIst())
          )
        )
        .catch((err) => gagal("Data Gagal Dihapus!"));
    }
  });
}

const defaultSorted = [
  {
    dataField: "kode_reparasi",
    order: "asc",
  },
];

const maptoState = (state) => {
  return {
    daftarReparasi: state.provinsi.reparasi,
    alert: state.provinsi.alert,
    error: state.provinsi.error,
    onSend: state.provinsi.onSend,
  };
};

class Reparasi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Rekening: [],
      response: "",
      columns: [
        {
          dataField: "kode_reparasi",
          text: "Kode Reparasi",
        },
        {
          dataField: "deskripsi_reparasi",
          text: "Deskripsi",
        },
        {
          dataField: "Aksi",
          text: "Aksi",
          csvExport: false,
          formatter: (rowContent, row) => {
            return (
              <div className="text-center">
                <button
                  className="btn btn-danger mr-2"
                  data-tut="reactour_3"
                  onClick={() => hapus(this.props.dispatch, row.kode_reparasi)}
                >
                  <i className="fa fa-trash mr-1"></i>
                  HAPUS
                </button>
                <button
                  className="btn btn-warning"
                  data-tut="reactour_4"
                  onClick={() =>
                    this.props.dispatch(
                      editReparasi(
                        row.kode_reparasi,
                        row.deskripsi_reparasi,
                        this.edithModal()
                      )
                    )
                  }
                >
                  <i className="fa fa-edit mr-2"></i>
                  EDIT
                </button>
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    this.props.dispatch(getReparasiLIst());
  }

  updateStatus = (input) => {
    console.log(input);
    this.setState({
      response: input,
    });
    this.props.dispatch(getReparasiLIst());
  };

  handleSubmit(hasil) {
    let data = {
      kode_reparasi: hasil.kodeReparasi,
      deskripsi_reparasi: hasil.deskripsiReparasi,
    };
    this.props.dispatch(progressSend());
    AxiosMasterPost("reparasi/add", data)
      .then(() => this.props.dispatch(finishSend()))
      .then(() => this.props.dispatch(hideModal()))
      .then((res) =>
        berhasil("Data Berhasil Disimpan!")
          .then(() => this.props.dispatch(getReparasiLIst()))
          .then(() => this.props.dispatch(reset("DataRekening")))
      )
      .catch((err) => {
        warning(err.response.data);
        this.props.dispatch(finishSend());
      });
  }
  handleEdit(hasil) {
    let data = {
      deskripsi_reparasi: hasil.deskripsiReparasi,
    };
    this.props.dispatch(progressSend());
    AxiosMasterPut("reparasi/edit/" + hasil.kodeReparasi, data)
      .then(() => this.props.dispatch(finishSend()))
      .then(() => this.props.dispatch(hideModal()))
      .then((res) =>
        berhasil("Data Berhasil Diubah!").then(() =>
          this.props.dispatch(getReparasiLIst())
        )
      )
      .catch((err) =>
        gagal("Data Gagal Diubah!").then(() =>
          this.props.dispatch(finishSend())
        )
      );
  }
  tambahModal() {
    this.props.dispatch(showModal());
    this.setState({
      isEdit: false,
    });
  }
  edithModal() {
    this.props.dispatch(showModal());
    this.setState({
      isEdit: true,
    });
  }

  render() {
    return (
      <div className="container-fluid" style={{ color: "black" }}>
        <TourGuide
          tourConfig={[
            `Untuk Menambahkan Data Reparasi Bank, Tekan Tombol ini Dan Isi kolom yang disediakan`,
            `Ini adalah Data Reparasi Bank yang sudah tersimpan`,
            `Ini adalah tombol untuk menghapus data pada baris yang dipilih`,
            `Ini adalah tombol untuk mengubah data pada baris yang dipilih`,
          ]}
        />
        <div className="row">
          <div className="col-12">
            <div className="card">
              <HeaderCard title="Data Reparasi" />
              <div className="card-body">
                <ModalMasterData
                  content={
                    this.state.isEdit ? (
                      <FormReparasiEdit
                        updateStatus={this.updateStatus}
                        onSubmit={(data) => this.handleEdit(data)}
                        onSend={this.props.onSend}
                      />
                    ) : (
                      <FormReparasi
                        updateStatus={this.updateStatus}
                        onSubmit={(data) => this.handleSubmit(data)}
                        onSend={this.props.onSend}
                      />
                    )
                  }
                  title={
                    this.state.isEdit
                      ? "Edit Data Reparasi"
                      : "Tambah Data Reparasi"
                  }
                />
                <div className="col-lg-12 col-md-12">
                  <div className="text-right">
                    <button
                      name="simpan"
                      id="simpan"
                      type="button"
                      className="btn btn-info"
                      onClick={() => this.tambahModal()}
                      data-tut="reactour_1"
                    >
                      Tambah Data Bank
                    </button>
                  </div>
                </div>
                <div className="col-lg-12" data-tut="reactour_2">
                  {this.props.daftarReparasi ? (
                    <Table
                      data={this.props.daftarReparasi}
                      columns={this.state.columns}
                      defaultSorted={defaultSorted}
                      namaKey="kode_bank"
                    />
                  ) : (
                    <div>
                      {this.props.error ? (
                        <div className="text-center">
                          <img
                            src={notrespond}
                            style={{ width: "30%" }}
                            alt="ERROR GET DATA"
                          />
                          <br />
                          <h4>Request API Gagal, Silahkan Refresh</h4>
                        </div>
                      ) : (
                        <Skeleton width={"100%"} height={180} />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(maptoState, null)(Reparasi);
