import { finishSend, progressSend } from "../../../actions/LogicAction";
import { toast } from "react-toastify";
import { AxiosMasterGetWithParams } from "../../../Axios";
import LaporanPenjualan24K from "../component/LaporanPenjualan24K";

export const GET_LAPORAN_PENJUALAN_24K = "GET_LAPORAN_PENJUALAN_24K";

export const GetLaporanPenjualan24K = (kode_customer, tglFrom, tglTo) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    AxiosMasterGetWithParams(`report/24K/penjualan`, {
      kode_customer: kode_customer,
      start_date: tglFrom,
      end_date: tglTo,
    })
      .then((response) => {
        dispatch({
          type: GET_LAPORAN_PENJUALAN_24K,
          payload: {
            data: response.data,
            tgl_from: tglFrom,
            tgl_to: tglTo,
            kode_customer: kode_customer,
          },
        });
        dispatch(finishSend());
        if (response.data.length === 0) {
          toast.info("Data Kosong...");
        } else {
          toast.success("Mengambil Data Berhasil... ✔️✔️");
        }
      })
      .catch(() => {
        dispatch({
          type: GET_LAPORAN_PENJUALAN_24K,
          payload: {
            data: [],
            kode_customer: kode_customer,
            tgl_from: tglFrom,
            tgl_to: tglTo,
          },
        });
        dispatch(finishSend());
      });
  };
};

export const PrintLaporanPenjualan24KPDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanPenjualan24k = state.laporan.dataLaporanPenjualan24k;
    LaporanPenjualan24K(
      dataLaporanPenjualan24k.data,
      dataLaporanPenjualan24k.tgl_from,
      dataLaporanPenjualan24k.tgl_to
    );
  };
};
