import { AxiosMasterGet } from "../Axios";
import { checkLaporan } from "../helper";

export const GET_LIST_SERAH_REPARASI = "GET_LIST_SERAH_REPARASI";

export const getSerahReparasi = () => {
  return (dispatch) => {
    AxiosMasterGet(`reparasi/get/belum-serah`)
      .then((res) => {
        if (checkLaporan(res.data)) {
          dispatch({
            type: GET_LIST_SERAH_REPARASI,
            payload: {
              data: res.data,
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch({
            type: GET_LIST_SERAH_REPARASI,
            payload: {
              data: res.data,
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() =>
        dispatch({
          type: GET_LIST_SERAH_REPARASI,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};
