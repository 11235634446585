import { AxiosMasterGet } from "../Axios";

export const GET_PARAMETER_VALIDASI = "GET_PARAMETER_VALIDASI";
export const EDIT_VALIDASI = "EDIT_VALIDASI";

export const getListParameter = () => {
  return (dispatch) => {
    AxiosMasterGet("parameteruangs")
      .then((res) =>
        dispatch({
          type: GET_PARAMETER_VALIDASI,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_PARAMETER_VALIDASI,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const setParameterValidasi = (jenis, dari, sampai) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_VALIDASI,
      payload: {
        jenis: jenis,
        dari: dari,
        sampai: sampai,
      },
    });
  };
};
