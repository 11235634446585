import { change, reset } from "redux-form";
import {
  finishSend,
  isExist,
  isExistSingle,
  progressSend,
} from "../../../actions/LogicAction";
import { getLocal, saveLocal } from "../../../encrypt";
import {
  hideModal,
  hideModalSecond,
  showModalSecond,
} from "../../../actions/ModalAction";
import {
  convertKadarCetak,
  findBy,
  formatDateIndo,
  formatGram,
  formatRp,
  FormatterNumber,
  getBon,
  // getUserID,
  multipleDeleteLocal,
  printInfo,
  purifiedData,
  removeComma,
  setFocus,
  setupSelectedValue,
  setupSelectedValueCodeAndName,
} from "../../../helper";
import { toast } from "react-toastify";
import {
  AxiosMasterDelete,
  AxiosMasterGet,
  AxiosMasterGetWithParams,
  AxiosMasterPost,
} from "../../../Axios";
import { ToastAmbil, ToastKirim } from "../../../actions/toast";
import { getToday } from "../../../getDate";
import { getUUID } from "../../../actions/fakturAction";
import PrintNota from "../../../Stocking/Component/PrintNota";
import Swal from "sweetalert2";

export const GET_DATA_TRANSAKSI_PENJUALAN = "GET_DATA_TRANSAKSI_PENJUALAN";
export const GET_DATA_TRANSKASI_REPARASI = "GET_DATA_TRANSKASI_REPARASI";
export const GET_UNVALID_PENJUALAN = "GET_UNVALID_PENJUALAN";
export const SET_DATA_PO = "SET_DATA_PO";
export const SET_EDIT_PENJUALAN = "SET_EDIT_PENJUALAN";
export const SAVE_PREV_NO_PENJUALAN = "SAVE_PREV_NO_PENJUALAN";
export const CHANGE_EDIT_BON_PENJUALAN = "CHANGE_EDIT_BON_PENJUALAN";
export const SET_EDIT_TRANSAKSI_PENJUALAN = "SET_EDIT_TRANSAKSI_PENJUALAN";

export const getListTransaksiPenjualan = () => {
  return async (dispatch) => {
    const result = await getLocal("PenjualanTemp");
    const totalNW = result
      .map((x) => parseFloat(removeComma(x.netto_disc)))
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    dispatch(change("TransaksiPenjualan", "totalNW", formatGram(totalNW)));
    dispatch(change("TransaksiPenjualan", "disc_global", 0));
    dispatch(change("TransaksiPenjualan", "disc_global_gr", 0));
    dispatch(
      change("TransaksiPenjualan", "grand_total_nw_ext", formatGram(totalNW))
    );
    dispatch(
      change("TransaksiPenjualan", "grand_total_nw", formatGram(totalNW))
    );
    dispatch({
      type: GET_DATA_TRANSAKSI_PENJUALAN,
      payload: {
        data: await getLocal("PenjualanTemp"),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const setEditTransaksiPenjualan = () => {
  return (dispatch) => {
    dispatch({
      type: SET_EDIT_TRANSAKSI_PENJUALAN,
      payload: {
        data: localStorage.getItem("EditTransaksiPenjualan") || "false",
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const setPrevNoBON = () => {
  return (dispatch) => {
    dispatch({
      type: SAVE_PREV_NO_PENJUALAN,
      payload: localStorage.getItem("prev_no_bon") || "-",
    });
  };
};

export const getListTransaksiReparasi = () => {
  return (dispatch) => {
    dispatch({
      type: GET_DATA_TRANSKASI_REPARASI,
      payload: {
        data: JSON.parse(localStorage.getItem("transaksiReparasi")),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const setPOPenjualan = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_DATA_PO,
      payload: {
        data: data,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const getUnvalidPenjualan = () => {
  return async (dispatch, getState) => {
    const response = await AxiosMasterGet("penjualan/unvalid");
    try {
      dispatch({
        type: GET_UNVALID_PENJUALAN,
        payload:
          localStorage.getItem("kodeCustomer") === "ALL"
            ? response.data
            : response.data.filter(
                (x) => x.kode_customer === localStorage.getItem("kodeCustomer")
              ),
      });
    } catch (e) {
      dispatch({
        type: GET_UNVALID_PENJUALAN,
        payload: [],
      });
      dispatch(finishSend());
    }
  };
};
export const otorisasiEditKadarTransaksi = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    let state = getState();
    const selected = state.form.FormOtorisasi.values;
    purifiedData(selected);
    AxiosMasterPost("users/check", selected)
      .then(async () => {
        dispatch(hideModalSecond());
        const selected = state.form.TransaksiPenjualan.values;
        purifiedData(selected);
        const noBon = `${selected.kode_customer}-` + getBon();
        const detail_barang = await getLocal("PenjualanTemp");
        const totalNW = detail_barang
          .map((x) => parseFloat(removeComma(x.netto_disc)))
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
        const listSales = state.provinsi.salesman.find(
          (x) => x.kode_sales === selected.kode_sales || "MRKT"
        );
        const listCustomer = state.provinsi.customer.find(
          (x) => x.kode_customer === selected.kode_customer
        );
        detail_barang.forEach((el) => {
          delete el.isEditKodeBarang;
        });
        var result = await PostPenjualan({
          detail_barang: detail_barang,
          dispatch: dispatch,
          listCustomer: listCustomer,
          listSales: listSales,
          selected: selected,
          payload: {
            no_penjualan: state.provinsi.noFaktur,
            no_bon: noBon,
            is_edit: state.provinsi.prev_no_penjualan !== "-",
            prev_no_penjualan: state.provinsi.prev_no_penjualan,
            no_po: "-",
            no_packing: "-",
            keterangan: selected.keterangan,
            tanggal: selected.tanggal,
            kode_lokasi: selected.kode_lokasi || "PUSAT",
            kode_sales: selected.kode_sales || "MRKT",
            kode_customer: selected.kode_customer,
            detail_barang: detail_barang.map(({ no, _id, ...rest }) => rest),
            total_netto: FormatterNumber(totalNW),
            disc_persen: selected.disc_global,
            disc_gram: FormatterNumber(selected.disc_global_gr),
            total_netto_disc: FormatterNumber(selected.grand_total_nw),
            total_netto_disc_ext: FormatterNumber(selected.grand_total_nw_ext),
          },
        });
        if (result.includes("E11000")) {
          multipleDeleteLocal(["noFaktur"]);
          dispatch(finishSend());
          dispatch(getUUID());
          state = getState();
          await PostPenjualan({
            detail_barang: detail_barang,
            dispatch: dispatch,
            listCustomer: listCustomer,
            listSales: listSales,
            selected: selected,
            payload: {
              no_penjualan: state.provinsi.noFaktur,
              no_bon: noBon,
              is_edit: state.provinsi.prev_no_penjualan !== "-",
              prev_no_penjualan: state.provinsi.prev_no_penjualan,
              no_po: "-",
              no_packing: "-",
              keterangan: selected.keterangan,
              tanggal: selected.tanggal,
              kode_lokasi: selected.kode_lokasi || "PUSAT",
              kode_sales: selected.kode_sales || "MRKT",
              kode_customer: selected.kode_customer,
              detail_barang: detail_barang.map(({ no, _id, ...rest }) => rest),
              total_netto: FormatterNumber(totalNW),
              disc_persen: selected.disc_global,
              disc_gram: FormatterNumber(selected.disc_global_gr),
              total_netto_disc: FormatterNumber(selected.grand_total_nw),
              total_netto_disc_ext: FormatterNumber(
                selected.grand_total_nw_ext
              ),
            },
          });
        }
        localStorage.setItem("EditTransaksiPenjualan", "false");
        localStorage.setItem("prev_no_bon", "-");
      })
      .catch((err) => {
        toast.error("User ID Atau Password Salah!");
        dispatch(finishSend());
      });
  };
};

export const saveEditPenjualanTemp = (data) => {
  return async (dispatch, getState) => {
    await saveLocal("noBonEdit", data.no_bon);
    dispatch(changeEditBonPenjualan(true));
    const state = getState();
    const listCustomer = state.provinsi.customer;
    const listSales = state.provinsi.salesman;
    const listLokasi = state.provinsi.lokasi;
    console.log(listSales);
    dispatch(change("TransaksiPenjualan", "keterangan", "-"));
    dispatch(change("TransaksiPenjualan", "no_bon", data.no_bon));
    dispatch(
      change(
        "TransaksiPenjualan",
        "kode_lokasi",
        setupSelectedValue({
          target: data.kode_lokasi,
          value: "nama_lokasi",
          keyTarget: "kode_lokasi",
          source: listLokasi,
        })
      )
    );
    dispatch(
      change(
        "TransaksiPenjualan",
        "kode_sales",
        setupSelectedValue({
          target: data.kode_sales === "-" ? "MRKT" : data.kode_sales,
          value: "nama_sales",
          keyTarget: "kode_sales",
          source: listSales,
        })
      )
    );
    dispatch(
      change(
        "TransaksiPenjualan",
        "kode_customer",
        setupSelectedValueCodeAndName({
          target: data.kode_customer,
          value: "kode_customer",
          value2: "nama_customer",
          keyTarget: "kode_customer",
          source: listCustomer,
        })
      )
    );
    localStorage.setItem("kodeCustomer", data.kode_customer);
    const cust = setupSelectedValueCodeAndName({
      target: data.kode_customer,
      value: "kode_customer",
      value2: "nama_customer",
      keyTarget: "kode_customer",
      source: listCustomer,
    });

    localStorage.setItem(
      "temp-penjualanCustomer",
      `${cust.value}|${cust.label}`
    );
    dispatch({
      type: SAVE_PREV_NO_PENJUALAN,
      payload: data.no_penjualan,
    });
    let number = 1;
    for (let item of data.detail_barang) {
      delete item._id;
      item.bruto = FormatterNumber(item.bruto);
      item.netto = FormatterNumber(item.netto);
      item.gross = FormatterNumber(item.gross);
      item.no = number;
      await isExist({
        target: "kode_barang",
        target2: "kode_bahan",
        name: "PenjualanTemp",
        payload: item,
        value: item.kode_barang,
        value2: item.kode_bahan,
      });
      const result = await getLocal("PenjualanTemp");
      const totalNW = result
        .map((x) => parseFloat(removeComma(x.netto_disc)))
        .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
      dispatch(change("TransaksiPenjualan", "totalNW", formatGram(totalNW)));
      dispatch(change("TransaksiPenjualan", "disc_global", 0));
      dispatch(change("TransaksiPenjualan", "disc_global_gr", 0));
      dispatch(
        change("TransaksiPenjualan", "grand_total_nw_ext", formatGram(totalNW))
      );
      dispatch(
        change("TransaksiPenjualan", "grand_total_nw", formatGram(totalNW))
      );
      number += 1;
    }
    localStorage.setItem("EditTransaksiPenjualan", "true");
    localStorage.setItem("prev_no_bon", data.no_penjualan);
    dispatch(getListTransaksiPenjualan());
    dispatch(setEditTransaksiPenjualan());
    dispatch(setPrevNoBON());
    toast.success("Barang Berhasil Dirubah");

    dispatch(hideModal());
    dispatch(finishSend());
  };
};
export const savePenjualanTemp = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const selected = state.form.FormTransaksiPenjualan.values;
    purifiedData(selected);
    const expolode = selected.kodeBarang.split("/");
    console.log("BARANG", expolode[1], "");
    if (selected.bruto < 0) {
      toast.error("Berat Tidak Boleh Kurang Dari 0");
      return false;
    }
    const result = (await getLocal("PenjualanTemp")) || [];
    const payload = {
      no: result.length + 1,
      kode_barang: selected.kodeBarang.split("/")[0],
      kode_bahan: selected.kodeBarang.split("/")[1].trim(),
      kode_barcode: selected.kode_barcode || "-",
      qty: selected.qty,
      plastik: selected.p,
      kulit: selected.k,
      gross: FormatterNumber(selected.gross),
      pkg: FormatterNumber(selected.pkg),
      bruto: FormatterNumber(selected.bruto),
      kadar_cetak: selected.kadar,
      kadar_jual: selected.kadarTransaksi,
      netto: FormatterNumber(selected.netto),
      disc_persen: selected.discGlobalPr,
      disc_gram: FormatterNumber(selected.discGlobalGr),
      netto_disc: FormatterNumber(selected.nettoDiscGlobalExt),
      ongkos_satuan: selected.ongkos_rp || 0,
      total_harga_jual: 0,
      ongkos_rp: selected.totalOngkos || 0,
    };
    await isExist({
      target: "kode_barang",
      target2: "kode_bahan",
      name: "PenjualanTemp",
      payload: payload,
      value: selected.kodeBarang.split("/")[0],
      value2: selected.kodeBarang.split("/")[1].trim(),
    });

    const totalNW = result
      .map((x) => parseFloat(removeComma(x.netto_disc)))
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    dispatch(change("TransaksiPenjualan", "totalNW", formatGram(totalNW)));
    dispatch(change("TransaksiPenjualan", "disc_global", 0));
    dispatch(change("TransaksiPenjualan", "disc_global_gr", 0));
    dispatch(
      change("TransaksiPenjualan", "grand_total_nw", formatGram(totalNW))
    );
    dispatch(
      change("TransaksiPenjualan", "grand_total_nw_ext", formatGram(totalNW))
    );
    dispatch(getListTransaksiPenjualan());
    dispatch(reset("FormTransaksiPenjualan"));
    // dispatch(hideModal());
    toast.success("Barang Berhasil Ditambahkan");
    setFocus("Kode Barcode / Job order");
    dispatch(finishSend());
  };
};

export const saveBacrodePenjualanTemp = (data) => {
  return async (dispatch) => {
    const prevData = [];
    data.map((x, index) => {
      const selected = x;
      const payload = {
        no: ++index,
        kode_barang: selected.kode_barang,
        kode_bahan: selected.kode_bahan,
        kode_barcode: selected.kode_barcode || "-",
        qty: 1,
        plastik: 0,
        kulit: 0,
        gross: FormatterNumber(selected.bruto),
        pkg: 0,
        bruto: FormatterNumber(selected.bruto),
        kadar_cetak: selected.kadar_cetak,
        kadar_jual: selected.kadar_jual,
        netto: FormatterNumber(selected.netto),
        disc_persen: 0,
        disc_gram: 0,
        netto_disc: FormatterNumber(selected.netto),
        ongkos_rp: 0,
        total_harga_jual: 0,
      };
      prevData.push(payload);
      return true;
    });
    await saveLocal("PenjualanTemp", prevData);
    const result = await getLocal("PenjualanTemp");
    const totalNW = result
      .map((x) => parseFloat(removeComma(x.netto_disc)))
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    dispatch(change("TransaksiPenjualan", "totalNW", formatGram(totalNW)));
    dispatch(change("TransaksiPenjualan", "disc_global", 0));
    dispatch(change("TransaksiPenjualan", "disc_global_gr", 0));
    dispatch(
      change("TransaksiPenjualan", "grand_total_nw_ext", formatGram(totalNW))
    );
    dispatch(
      change("TransaksiPenjualan", "grand_total_nw", formatGram(totalNW))
    );
    dispatch(getListTransaksiPenjualan());
    toast.success("Barang Berhasil Ditambahkan");
    dispatch(hideModal());
    dispatch(finishSend());
  };
};

export const savePenjualanEditTemp = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const selected = state.form.FormTransaksiPenjualanEdit.values;
    purifiedData(selected);
    const payload = {
      no: selected.no,
      kode_barang: selected.kodeBarang.split("/")[0],
      kode_bahan: selected.kodeBarang.split("/")[1].trim(),
      kode_barcode: selected.kode_barcode || "-",
      qty: selected.qty,
      plastik: selected.p,
      kulit: selected.k,
      gross: FormatterNumber(selected.gross),
      pkg: FormatterNumber(selected.pkg),
      bruto: FormatterNumber(selected.bruto),
      kadar_cetak: selected.kadar,
      kadar_jual: selected.kadarTransaksi,
      netto: FormatterNumber(selected.netto),
      disc_persen: selected.discGlobalPr,
      disc_gram: FormatterNumber(selected.discGlobalGr),
      netto_disc: FormatterNumber(selected.nettoDiscGlobalExt),
      ongkos_satuan: selected.ongkos_rp || 0,
      total_harga_jual: 0,
      ongkos_rp: selected.totalOngkos || 0,
      isEditKadarJual:
        parseFloat(selected.kadarTransaksiPrev) !==
        parseFloat(selected.kadarTransaksi),
      isEditKodeBarang: selected.kodeBarangPrev !== selected.kodeBarang,
    };
    const res = await isExistSingle({
      target: "no",
      name: "PenjualanTemp",
      payload: payload,
      value: selected.no,
      replace: true,
    });
    const result = await getLocal("PenjualanTemp");
    const totalNW = result
      .map((x) => parseFloat(removeComma(x.netto_disc)))
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    dispatch(change("TransaksiPenjualan", "totalNW", formatGram(totalNW)));
    dispatch(change("TransaksiPenjualan", "disc_global", 0));
    dispatch(change("TransaksiPenjualan", "disc_global_gr", 0));
    dispatch(
      change("TransaksiPenjualan", "grand_total_nw_ext", formatGram(totalNW))
    );
    dispatch(
      change("TransaksiPenjualan", "grand_total_nw", formatGram(totalNW))
    );
    dispatch(getListTransaksiPenjualan());
    if (res !== "DECLINED") {
      toast.success("Barang Berhasil Dirubah");
    }
    dispatch(hideModal());
    dispatch(finishSend());
  };
};

export const deleteLocalPenjualanTemp = (nama, index) => {
  return async (dispatch) => {
    dispatch(progressSend());
    const data = await getLocal(nama);
    data.splice(index, 1);
    console.log(data);
    await saveLocal(nama, data);
    const result = await getLocal("PenjualanTemp");
    const totalNW = result
      .map((x) => parseFloat(removeComma(x.netto_disc)))
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    dispatch(change("TransaksiPenjualan", "totalNW", formatGram(totalNW)));
    dispatch(change("TransaksiPenjualan", "disc_global", 0));
    dispatch(change("TransaksiPenjualan", "disc_global_gr", 0));
    dispatch(
      change("TransaksiPenjualan", "grand_total_nw_ext", formatGram(totalNW))
    );
    dispatch(
      change("TransaksiPenjualan", "grand_total_nw", formatGram(totalNW))
    );
    dispatch(getListTransaksiPenjualan());
    dispatch(finishSend());
  };
};

export const saveEditPenjualan = (data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_EDIT_PENJUALAN,
      payload: data,
    });
  };
};

export const getNoBonEdit = () => {
  return async (dispatch, getState) => {
    const noBon = await getLocal("noBonEdit");
    if (!Array.isArray(noBon)) {
      dispatch(changeEditBonPenjualan(true));
    } else {
      dispatch(changeEditBonPenjualan(false));
    }
  };
};

export const changeEditBonPenjualan = (data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: CHANGE_EDIT_BON_PENJUALAN,
      payload: data,
    });
  };
};

export const sendPenjualan = (edit) => {
  return async (dispatch, getState) => {
    let state = getState();
    const selected = state.form.TransaksiPenjualan.values;
    purifiedData(selected);
    const noBon = `${selected.kode_customer}-` + getBon();
    const detail_barang = await getLocal("PenjualanTemp");
    const barangfind = detail_barang.find(
      (val) =>
        (val.isEditKadarJual === true || val.isEditKadarJual === "true") &&
        (val.isEditKodeBarang === false || val.isEditKodeBarang === "false")
    );
    if (barangfind !== undefined) {
      dispatch(showModalSecond());
    } else {
      const totalNW = detail_barang
        .map((x) => parseFloat(removeComma(x.netto_disc)))
        .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
      const listSales = state.provinsi.salesman.find(
        (x) => x.kode_sales === selected.kode_sales || "MRKT"
      );
      const listCustomer = state.provinsi.customer.find(
        (x) => x.kode_customer === selected.kode_customer
      );
      detail_barang.forEach((el) => {
        delete el.isEditKodeBarang;
      });
      var result = await PostPenjualan({
        detail_barang: detail_barang,
        dispatch: dispatch,
        listCustomer: listCustomer,
        listSales: listSales,
        selected: selected,
        payload: {
          no_penjualan: state.provinsi.noFaktur,
          no_bon: noBon,
          is_edit: state.provinsi.prev_no_penjualan !== "-",
          prev_no_penjualan: state.provinsi.prev_no_penjualan,
          no_po: "-",
          no_packing: "-",
          keterangan: selected.keterangan,
          tanggal: selected.tanggal,
          kode_lokasi: selected.kode_lokasi || "PUSAT",
          kode_sales: selected.kode_sales || "MRKT",
          kode_customer: selected.kode_customer,
          detail_barang: detail_barang.map(({ no, _id, ...rest }) => rest),
          total_netto: FormatterNumber(totalNW),
          disc_persen: selected.disc_global,
          disc_gram: FormatterNumber(selected.disc_global_gr),
          total_netto_disc: FormatterNumber(selected.grand_total_nw),
          total_netto_disc_ext: FormatterNumber(selected.grand_total_nw_ext),
        },
      });
      if (result.includes("E11000")) {
        multipleDeleteLocal(["noFaktur"]);
        dispatch(finishSend());
        dispatch(getUUID());
        state = getState();
        await PostPenjualan({
          detail_barang: detail_barang,
          dispatch: dispatch,
          listCustomer: listCustomer,
          listSales: listSales,
          selected: selected,
          payload: {
            no_penjualan: state.provinsi.noFaktur,
            no_bon: noBon,
            is_edit: state.provinsi.prev_no_penjualan !== "-",
            prev_no_penjualan: state.provinsi.prev_no_penjualan,
            no_po: "-",
            no_packing: "-",
            keterangan: selected.keterangan,
            tanggal: selected.tanggal,
            kode_lokasi: selected.kode_lokasi || "PUSAT",
            kode_sales: selected.kode_sales || "MRKT",
            kode_customer: selected.kode_customer,
            detail_barang: detail_barang.map(({ no, _id, ...rest }) => rest),
            total_netto: FormatterNumber(totalNW),
            disc_persen: selected.disc_global,
            disc_gram: FormatterNumber(selected.disc_global_gr),
            total_netto_disc: FormatterNumber(selected.grand_total_nw),
            total_netto_disc_ext: FormatterNumber(selected.grand_total_nw_ext),
          },
        });
      }
      localStorage.setItem("EditTransaksiPenjualan", "false");
      localStorage.setItem("prev_no_bon", "-");
    }
  };
};

async function PostPenjualan({
  payload,
  detail_barang,
  selected,
  listCustomer,
  listSales,
  dispatch,
}) {
  try {
    var response = await toast.promise(
      AxiosMasterPost("penjualan/simpan", payload),
      ToastKirim
    );
    print(
      detail_barang.map(({ no, ...rest }) => rest),
      response.data.no_bon,
      selected.tanggal,
      selected.kode_customer,
      listCustomer.nama_toko,
      listSales,
      selected.disc_global_gr,
      selected.grand_total_nw
    );
    multipleDeleteLocal([
      "PenjualanTemp",
      "noBon",
      "tanggal",
      "tanggalBarang",
      "namaCustomer",
      "kodeCustomer",
      "keterangan",
      "totalNW",
      "noFaktur",
      "FakturTerpilih",
      "temp-penjualanDate",
      "temp-penjualanKeterangan",
      "temp-penjualanCustomer",
      "temp-penjualanSales",
      "temp-penjualanLokasi",
      "temp-penjualanDiscGlobal",
      "noBonEdit",
    ]);
    dispatch(finishSend());
    dispatch(getUUID());
    dispatch(getListTransaksiPenjualan());
    dispatch(reset("TransaksiPenjualan"));
    dispatch(change("TransaksiPenjualan", "tanggal", getToday()));
    dispatch(change("TransaksiPenjualan", "totalNW", 0));
    dispatch(change("TransaksiPenjualan", "disc_global", 0));
    dispatch(change("TransaksiPenjualan", "disc_global_gr", 0));
    dispatch(change("TransaksiPenjualan", "grand_total_nw_ext", 0));
    dispatch(change("TransaksiPenjualan", "grand_total_nw", 0));
    window.location.reload();
    return "SUCCESS";
  } catch (e) {
    dispatch(finishSend());
    return e?.response?.data;
  }
}

export const editBarang = (data) => {
  return (dispatch) => {
    dispatch(
      change("FormTransaksiPenjualanEdit", "kodeBarang", data.kode_jenis)
    );
    dispatch(change("FormTransaksiPenjualanEdit", "p", data.p));
    dispatch(change("FormTransaksiPenjualanEdit", "no", data.no));
    dispatch(change("FormTransaksiPenjualanEdit", "k", data.k));
    dispatch(change("FormTransaksiPenjualanEdit", "kadar", data.kadar_cetak));
    dispatch(change("FormTransaksiPenjualanEdit", "kode_kategori", "GLE17K"));
    dispatch(
      change("FormTransaksiPenjualanEdit", "keterangan", data.keterangann)
    );
    dispatch(
      change("FormTransaksiPenjualanEdit", "namaKategori", data.nama_kategori)
    );
    dispatch(change("FormTransaksiPenjualanEdit", "pkg", data.pkg));
    dispatch(change("FormTransaksiPenjualanEdit", "bruto", data.bruto));
    dispatch(
      change("FormTransaksiPenjualanEdit", "kadarTransaksi", data.kadar_jual)
    );
    dispatch(change("FormTransaksiPenjualanEdit", "netto", data.netto));
    dispatch(change("FormTransaksiPenjualanEdit", "gross", data.gross));
    dispatch(
      change("FormTransaksiPenjualanEdit", "discGlobalPr", data.disc_persen)
    );
    dispatch(
      change(
        "FormTransaksiPenjualanEdit",
        "nettoDiscGlobalExt",
        data.netto_disc
      )
    );
    dispatch(change("FormTransaksiPenjualanEdit", "rp", data.ongkos_rp));
    dispatch(change("FormTransaksiPenjualanEdit", "qty", data.qty));
    dispatch(
      change("FormTransaksiPenjualanEdit", "harga_jual", data.harga_jual)
    );
  };
};

function print(
  table,
  noBon,
  tanggal,
  customer,
  namaCustomer,
  sales,
  disc_global,
  total_netto
) {
  const tableRows = [];
  // for each ticket pass all its data into an array
  let totalPkg = 0;
  table.forEach((ticket, i) => {
    const brutopkg = parseFloat(ticket.bruto) + parseFloat(ticket.pkg);
    const ticketData = [
      ++i,
      ticket.kode_barang,
      ticket.kode_bahan,
      { content: formatGram(ticket.bruto), styles: { halign: "right" } },
      { content: ticket.kadar_jual, styles: { halign: "right" } },
      { content: formatGram(ticket.disc_gram), styles: { halign: "right" } },
      {
        content: formatGram(ticket.netto_disc, 3),
        styles: { halign: "right" },
      },
      { content: formatGram(ticket.pkg), styles: { halign: "right" } },
      { content: formatGram(brutopkg), styles: { halign: "right" } },
      { content: ticket.qty, styles: { halign: "right" } },
      { content: formatRp(ticket.ongkos_rp), styles: { halign: "right" } },
    ];
    // push each tickcet's info into a row
    tableRows.push(ticketData);
    totalPkg += brutopkg;
  });
  const summary = [
    "",
    "",
    "Total",
    {
      content: formatGram(
        table.map((list) => parseFloat(list.bruto)).reduce((a, b) => a + b, 0)
      ),
      styles: { halign: "right" },
    },
    "",
    {
      content: formatGram(
        table
          .map((list) => parseFloat(list.disc_gram))
          .reduce((a, b) => a + b, 0)
      ),
      styles: { halign: "right" },
    },
    {
      content: formatGram(
        table
          .map((list) => parseFloat(list.netto_disc, 3))
          .reduce((a, b) => a + b, 0),
        3
      ),
      styles: { halign: "right" },
    },
    {
      content: formatGram(
        table.map((list) => parseFloat(list.pkg, 3)).reduce((a, b) => a + b, 0)
      ),
      styles: { halign: "right" },
    },
    {
      content: formatGram(totalPkg),
      styles: { halign: "right" },
    },
    {
      content: table
        .map((list) => parseFloat(list.qty))
        .reduce((a, b) => a + b, 0),
      styles: { halign: "right" },
    },
    {
      content: formatRp(
        table
          .map((list) => parseFloat(list.ongkos_rp, 3))
          .reduce((a, b) => a + b, 0)
      ),
      styles: { halign: "right" },
    },
  ];

  let footer = [
    summary,
    [
      {
        content: "Diskon Global : " + formatGram(disc_global),
        styles: { halign: "right" },
        colSpan: 11,
      },
    ],
    [
      {
        content: "Total 24K : " + formatGram(total_netto, 3),
        styles: { halign: "right" },
        colSpan: 11,
      },
    ],
    printInfo(),
  ];
  var columTable = [
    "No",
    "Kode Barang",
    "Kode Bahan",
    "Bruto \n(Gr)",
    "Harga \n(%)",
    "Diskon",
    "Netto \n(Gr)",
    "Pkg",
    "Bruto + Pkg \n(Gr)",
    "Qty",
    "Ongkos \n(Rp)",
  ];
  // AKHIR INISIALISASI AUTOTABLE
  PrintNota(
    "No Inv",
    noBon,
    "Tanggal",
    formatDateIndo(tanggal),
    "",
    "",
    "Customer",
    namaCustomer,
    "ADMIN",
    getToday(),
    "",
    [columTable],
    "Transaksi Penjualan",
    tableRows,
    footer
  );
}

export const findJobOrderPenjualan = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.FormTransaksiPenjualan.values;
    let response = await toast.promise(
      AxiosMasterGetWithParams("job-order", {
        no_job_order: selected.kode_barcode,
      }),
      ToastAmbil
    );
    dispatch(change("FormTransaksiPenjualan", "bruto", response.data.bruto));
    dispatch(change("FormTransaksiPenjualan", "qty", response.data.qty));
    dispatch(
      change(
        "FormTransaksiPenjualan",
        "kodeBarang",
        response.data.kode_barang + "/ " + response.data.kode_bahan
      )
    );
    const result = await findBy(
      state.barang.listBarang,
      "kode_barang",
      response.data.kode_barang,
      "kode_bahan",
      response.data.kode_bahan
    );
    dispatch(
      change(
        "FormTransaksiPenjualan",
        "kadar",
        convertKadarCetak(result.kode_bahan, result.kadar_cetak)
      )
    );
    dispatch(
      change("FormTransaksiPenjualan", "kadarTransaksi", result.kadar_jual)
    );
    dispatch(finishSend());
  };
};

export const deleteFaktur = (data) => {
  return async (dispatch) => {
    dispatch(progressSend());
    Swal.fire({
      title: "Anda Yakin ?",
      text: "Anda Akan Menghapus Data Faktur Ini ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        AxiosMasterDelete("penjualan?no_penjualan=" + data.no_penjualan)
          .then(() => {
            dispatch(getUnvalidPenjualan());
            toast.success("Berhasil Menghapus Data");
            dispatch(finishSend());
          })
          .catch(() => {
            toast.error("Gagal Menghapus Data");
            dispatch(finishSend());
          });
      } else {
        dispatch(finishSend());
      }
    });
  };
};
