export const GET_DATA_MUTASI_CIOK = "GET_DATA_MUTASI_CIOK";

export const getListMutasiCiok = () => {
  return (dispatch) => {
    dispatch({
      type: GET_DATA_MUTASI_CIOK,
      payload: {
        data: JSON.parse(localStorage.getItem("mutasiCiok")),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

