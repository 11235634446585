export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const SHOW_MODAL_SECOND = "SHOW_MODAL_SECOND";
export const HIDE_MODAL_SECOND = "HIDE_MODAL_SECOND";
export const SET_TIPE_BAYAR_MODAL = "SET_TIPE_BAYAR_MODAL";
export const SHOW_GUIDE_TOUR = "SHOW_GUIDE_TOUR";
export const HIDE_GUIDE_TOUR = "HIDE_GUIDE_TOUR";

export const showModal = () => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL,
      payload: {
        data: true,
      },
    });
  };
};

export const hideModal = () => {
  return (dispatch) => {
    dispatch({
      type: HIDE_MODAL,
      payload: {
        data: false,
      },
    });
  };
};
export const showModalSecond = () => {
  return (dispatch) => {
    // dispatch(hideModal());
    dispatch({
      type: SHOW_MODAL_SECOND,
      payload: {
        data: true,
      },
    });
  };
};

export const hideModalSecond = () => {
  return (dispatch) => {
    dispatch({
      type: HIDE_MODAL_SECOND,
      payload: {
        data: false,
      },
    });
  };
};

export const setTipeBayarModal = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_TIPE_BAYAR_MODAL,
      payload: {
        data: data,
      },
    });
  };
};

export const showGuide = () => {
  return (dispatch) => {
    dispatch({
      type: SHOW_GUIDE_TOUR,
      payload: {
        data: true,
      },
    });
  };
};
export const hideGuide = () => {
  return (dispatch) => {
    dispatch({
      type: HIDE_GUIDE_TOUR,
      payload: {
        data: false,
      },
    });
  };
};
