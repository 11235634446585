import React from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { formatGram } from "../../../helper";
import { HeadLaporanExcel } from "../../Component/HeadLaporanExcel";

export const LaporanExcelGlobal24K = (props) => {
  const { data } = props;
  let length = data.kelompok.length * 3 + 2;

  function getSumStock(index, name) {
    let hasil = 0;
    data.detail.forEach((x) => (hasil += x.detail_saldo[index][name]));
    return hasil;
  }
  return (
    <>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="btn btn-success btn-block"
        table="table-to-xls"
        filename="Laporan Global 24K"
        sheet="Laporan Global 24K"
        buttonText="Export Excel"
      />
      <table rowkey="kode_member" id="table-to-xls" style={{ display: "none" }}>
        <thead>
          <HeadLaporanExcel nama={"LAPORAN GLOBAL 24K"} colSpan={length} />
          <tr>
            <th
              rowSpan={2}
              style={{ backgroundColor: "#E8E5E5", color: "#000" }}
            >
              TANGGAL
            </th>
            <th
              rowSpan={2}
              style={{ backgroundColor: "#E8E5E5", color: "#000" }}
            >
              KETERANGAN
            </th>
            {data.kelompok.map((x) => (
              <th
                colSpan={3}
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "center",
                }}
              >
                {x}
              </th>
            ))}
          </tr>
          <tr>
            {data.kelompok.map((x) => (
              <>
                <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>-</th>
                <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>+</th>
                <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                  Saldo
                </th>
              </>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.detail.map((item, no) => (
            <>
              <tr>
                <td>{item.tanggal}</td>
                <td>{item.keterangan}</td>
                {item.detail_saldo.map((x, i) => (
                  <>
                    <td style={{ textAlign: "right" }}>
                      {" "}
                      &nbsp;{formatGram(x.stock_out)}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {" "}
                      &nbsp;{formatGram(x.stock_in)}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {" "}
                      &nbsp;{formatGram(x.stock)}
                    </td>
                  </>
                ))}
              </tr>
            </>
          ))}
          <tr>
            <th
              colSpan={2}
              style={{ backgroundColor: "#E8E5E5", color: "#000" }}
            >
              Grand Total
            </th>
            {data.kelompok.map((x, index) => (
              <>
                <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                  {formatGram(getSumStock(index, "stock_out"))}
                </th>
                <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                  {formatGram(getSumStock(index, "stock_in"))}
                </th>
                <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                  {formatGram(getSumStock(index, "stock"))}
                </th>
              </>
            ))}
          </tr>
        </tbody>
      </table>
    </>
  );
};
