export const GET_DATA_TIMBANG_BARANG = "GET_DATA_TIMBANG_BARANG";
export const GET_DATA_TIMBANG_STOCK = "GET_DATA_TIMBANG_STOCK";

export const getListTimbangBarang = () => {
    return (dispatch) => {
        dispatch({
            type: GET_DATA_TIMBANG_BARANG,
            payload: {
                data: JSON.parse(localStorage.getItem("timbangBarang")),
                alert:
                    "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
                error: false,
            },
        });
    };
};

export const getTotalTimbangStock = () => {
    let data = JSON.parse(localStorage.getItem("TimbangStock")) || []
    return (dispatch) => {
        dispatch({
            type: GET_DATA_TIMBANG_STOCK,
            payload: {
                data: data.map((el) => parseFloat(el?.berat_bersih)).reduce((a,b) => a + b, 0) ,
                alert:
                    "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
                error: false,
            },
        });
    };
};