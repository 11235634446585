import {finishSend, progressSend} from "../../../actions/LogicAction";
import {toast} from "react-toastify";
import {AxiosMasterGet} from "../../../Axios";
import {ToastAmbil} from "../../../actions/toast";
import LaporanStockSummary from "../component/LaporanStockSummary";

export const GET_LAPORAN_SUMMARY_BARANG = "GET_LAPORAN_SUMMARY_BARANG"

export const GetLaporanSummaryBarang = (kode_lokasi , tanggal_awal, tanggal_akhir) => {
  return async (dispatch ,getState) => {
    dispatch(progressSend())
      try {
                const response = await toast.promise(AxiosMasterGet(
                    `report/stock-barang-summary/${kode_lokasi}&${tanggal_awal}&${tanggal_akhir}`
                ), ToastAmbil)
            dispatch({
                type : GET_LAPORAN_SUMMARY_BARANG,
                payload : {
                    data : response.data,
                    tanggal_awal : tanggal_awal,
                    tanggal_akhir : tanggal_akhir
                }
            })
          dispatch(finishSend())
            } catch (e) {
            dispatch(finishSend())
            }
  }
}

export const PrintLaporanSummaryBarangPDF = () => {
  return async (dispatch ,getState) => {
    const state = getState();
    const dataLaporanSummaryBarang = state.laporan.dataLaporanSummaryBarang
      LaporanStockSummary(
          dataLaporanSummaryBarang.data,
          dataLaporanSummaryBarang.tanggal_awal,
          dataLaporanSummaryBarang.tanggal_akhir
      );
  }
}