import {
    GET_CORRECTION_NOTE_TEMP,
    GET_ITEM_LIST_CORRECTION_NOTE,
    GET_LIST_CORRECTION_NOTE
} from "./correction_note_action";

const initialState = {
listBarangTemp : [],
    listCorrectionNote: [],
    listItemsCorrectionNote : []
}

const correctionNote = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_CORRECTION_NOTE:
            return {
                ...state,
                listCorrectionNote: action.payload
            }
        case GET_ITEM_LIST_CORRECTION_NOTE:
            return {
                ...state,
                listItemsCorrectionNote: action.payload
            }
        case GET_CORRECTION_NOTE_TEMP:
            return {
                ...state,
                listBarangTemp : action.payload
            }
        default:
            return state
    }
}

export default correctionNote