import { finishSend, progressSend } from "../../../actions/LogicAction";
import { toast } from "react-toastify";
import { AxiosMasterGet } from "../../../Axios";
import { ToastAmbil } from "../../../actions/toast";
import LaporanStockCard from "../component/LaporanStockCard";

export const GET_LAPORAN_KARTU_STOCK_BARANG = "GET_LAPORAN_KARTU_STOCK_BARANG";

export const GetLaporanKartuStockBarang = (
  kode_lokasi,
  kode_jenis = "-",
  kode_bahan = "-",
  tanggal_awal,
  tanggal_akhir
) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    try {
      const response = await toast.promise(
        AxiosMasterGet(
          `report/stock-barang-card/${kode_lokasi}&${kode_jenis}&${kode_bahan}&${tanggal_awal}&${tanggal_akhir}`
        ),
        ToastAmbil
      );
      dispatch({
        type: GET_LAPORAN_KARTU_STOCK_BARANG,
        payload: {
          data: response.data,
          tanggal_awal: tanggal_awal,
          tanggal_akhir: tanggal_akhir,
        },
      });
      dispatch(finishSend());
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

export const PrintLaporanKartuStockBarangPDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanKartuStockBarang =
      state.laporan.dataLaporanKartuStockBarang;
    LaporanStockCard(
      dataLaporanKartuStockBarang.data,
      dataLaporanKartuStockBarang.tanggal_awal,
      dataLaporanKartuStockBarang.tanggal_akhir
    );
  };
};
