import { AxiosMasterDelete, AxiosMasterGet } from "../../../Axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { ToastKirim } from "../../../actions/toast";
import { finishSend, progressSend } from "../../../actions/LogicAction";

export const GET_PROVINSI_LIST = "GET_PROVINSI_LIST";
export const EDIT_PROVINSI = "EDIT_PROVINSI";
export const DELETE_PROVINSI = "DELETE_PROVINSI";

const server = process.env.REACT_APP_BACKEND_URL;
export const getProvinsiLIst = () => {
  return (dispatch) => {
    AxiosMasterGet("provinsi/get/all")
      .then((res) =>
        dispatch({
          type: GET_PROVINSI_LIST,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_PROVINSI_LIST,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const editProvinsiList = (data) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_PROVINSI,
      payload: {
        data: data,
      },
    });
  };
};
export const deleteProvinsiList = (kode, link) => {
  var data = {
    data: {
      kodeProvinsi: kode,
    },
  };
  return (dispatch) => {
    AxiosMasterDelete(server + link, data)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };
};

export const deleteProvinsi = (kode) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    Swal.fire({
      title: "Anda Yakin ?",
      text: "Anda Akan Menghapus Data Ini ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await toast.promise(
          AxiosMasterDelete("provinsi/delete/" + kode),
          ToastKirim
        );
        dispatch(getProvinsiLIst());
      }
    });
    dispatch(finishSend());
  };
};
