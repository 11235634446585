import React, { Component } from "react";
import { connect } from "react-redux";
import {
  delete24K,
  edit24K,
  getList24K,
  send24K,
  sendEdit24K,
} from "./redux/24kAction";
import { gagal, reactive } from "../../Alert";
import { hideModal, showModal } from "../../actions/ModalAction";
import ModalMasterData from "../Component/ModalMasterData";
import HeaderCard from "../../HeaderCard";
import { formatGram2 } from "../../helper";
import { Dropdown } from "react-bootstrap";
import GlobalTabel from "../../GlobalComponent/GlobalTabel";
import { getListKelompok } from "../kelompok/redux/kelompokAction";
import { getAllKadar } from "../kadar/redux/kadarAction";
import FormMaster24K from "./component/FormMaster24K";
import FormMaster24KEdit from "./component/FormMaster24KEdit";

const maptoState = (state) => {
  return {
    list24K: state.master24k.list24K,
    alert: state.provinsi.alert,
    error: state.provinsi.error,
    onSend: state.provinsi.onSend,
  };
};
class Master24K extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Master24K: [],
      response: "",
      display: "none",
      columns: [
        {
          dataField: "kode_kategori",
          text: "Kode Kategori",
          headerStyle: () => {
            return { width: "10%" };
          },
        },
        {
          dataField: "kode_24k",
          text: "Kode 24K",
          headerStyle: () => {
            return { width: "10%" };
          },
        },
        {
          dataField: "nama_24k",
          text: "Nama 24K",
        },
        {
          dataField: "kadar_cetak",
          text: "Kadar",
          align: "right",
          headerAlign: "right",
          formatter: (data) => formatGram2(data),
          headerStyle: () => {
            return { width: "10%" };
          },
        },
        {
          dataField: "kadar_beli",
          text: "Harga Beli",
          align: "right",
          formatter: (data) => formatGram2(data),
          headerAlign: "right",
          headerStyle: () => {
            return { width: "10%" };
          },
        },
        {
          dataField: "kadar_jual",
          text: "Harga Jual",
          align: "right",
          formatter: (data) => formatGram2(data),
          headerAlign: "right",
          headerStyle: () => {
            return { width: "10%" };
          },
        },

        {
          dataField: "link",
          text: "Aksi",
          csvExport: false,
          formatter: (rowContent, row) => {
            return (
              <div className={"row justify-content-center"}>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <i className="fa fa-ellipsis-v" aria-hidden="true" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() =>
                        this.props.dispatch(delete24K(row.kode_ciok_rosok))
                      }
                    >
                      Delete <i className="fa fa-trash ml-2" />
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            );
          },
        },
      ],
    };
  }
  componentDidMount() {
    this.props.dispatch(getList24K());
    this.props.dispatch(getListKelompok());
    this.props.dispatch(getAllKadar());
  }

  handleSubit() {
    this.props.dispatch(send24K());
  }
  handleEdit() {
    this.props.dispatch(sendEdit24K());
  }

  tambahModal() {
    this.props.dispatch(showModal());
    this.setState({ isEdit: false });
  }
  editModal(row) {
    this.props.dispatch(edit24K(row));
    this.props.dispatch(showModal());
    this.setState({ isEdit: true });
  }
  handleReactive(err, kode, data) {
    this.props.dispatch(hideModal());
    let error = err.response.data;
    let check = error.includes("Deleted");
    check
      ? reactive(
          err,
          kode,
          "jenis/reactive/by-kode-jenis/",
          data,
          "jenis/edit/by-kode-jenis/"
        ).then(() => this.props.dispatch(getList24K()))
      : gagal("Data Gagal Ditambahkan");
  }

  render() {
    return (
      <div className="container-fluid" style={{ color: "black" }}>
        <ModalMasterData
          content={
            this.state.isEdit ? (
              <FormMaster24KEdit
                onSubmit={(data) => this.handleEdit(data)}
                onSend={this.props.onSend}
              />
            ) : (
              <FormMaster24K
                onSubmit={(data) => this.handleSubit(data)}
                onSend={this.props.onSend}
              />
            )
          }
          title={this.state.isEdit ? "Edit 24K" : "Tambah 24K"}
        />
        <div className="row">
          <div className="col-12">
            <div className="card">
              <HeaderCard title="Master Data 24K" />
              <div className="card-body">
                <form action="#">
                  <div className="form-body">
                    <div className="col-lg-12 col-md-12">
                      <div className="text-right">
                        <button
                          name="simpan"
                          id="simpan"
                          type="button"
                          className="btn btn-info"
                          data-tut="reactour_1"
                          onClick={() => this.tambahModal()}
                        >
                          Tambah Data 24K
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <div data-tut="reactour_2">
                  <GlobalTabel
                    data={this.props.list24K}
                    columns={this.state.columns}
                    keyField="kode_supplier"
                    bordered={true}
                    headerClasses="header-table"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(maptoState, null)(Master24K);
