import React, { useEffect } from "react";
import { reduxForm } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import GlobalTabel from "../../../GlobalComponent/GlobalTabel";
import { Dropdown } from "react-bootstrap";
import {
  getUnvalidPenjualan24K,
  saveEditPenjualan24KTemp,
} from "../redux/transaksiPenjualan24KAction";
import { formatGram } from "../../../helper";

const ListEditPenjualan24K = () => {
  const dispatch = useDispatch();
  const listUnvalidPenjualan = useSelector(
    (state) => state.provinsi.listUnvalidPenjualan
  );
  const columns = [
    {
      dataField: "no_bon",
      text: "Nomor Bon",
    },
    {
      dataField: "tanggal",
      text: "Tanggal",
    },
    {
      dataField: "total_bruto",
      text: "Total Bruto",
      formatter: (data) => formatGram(data),
    },
    {
      dataField: "total_netto",
      text: "Total Netto",
      formatter: (data) => formatGram(data),
    },

    {
      dataField: "aksi",
      text: "Aksi",
      formatter: (rowContent, rows, rowIndex) => {
        return (
          <div className="justify-content-center row">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <i className="fa fa-ellipsis-v" aria-hidden="true" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    dispatch(saveEditPenjualan24KTemp(rows));
                  }}
                >
                  Pilih Faktur <i className="fa fa-check ml-2" />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getUnvalidPenjualan24K());
  }, [dispatch]);

  return (
    <div className="col-lg-12 col-md-12 row">
      <div className={"col-lg-12"}>
        <GlobalTabel
          keyField="kodeJenis"
          data={
            listUnvalidPenjualan.filter((x) => x.input_by !== "peter") || []
          }
          columns={columns}
          textEmpty="Silahkan Tambah Data Dengan Klik Tombol Biru Diatas"
        />
      </div>
    </div>
  );
};

export default reduxForm({
  form: "ListEditPenjualan24K",
})(ListEditPenjualan24K);
