import uuid from "react-uuid";

export const GET_UUID = "GET_UUID";

export const getUUID = () => {
  var noFaktur = localStorage.getItem("noFaktur");
  if (noFaktur === null) {
    localStorage.setItem("noFaktur", uuid().toUpperCase());
  }
  return (dispatch) => {
    dispatch({
      type: GET_UUID,
      payload: {
        data: localStorage.getItem("noFaktur"),
      },
    });
  };
};
