import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { renderField } from "../../../renderField";
import { preventTabAction } from "../../../helper";
import CustomForm from "../../../CustomForm";
import ButtonSelesai from "../../../button_selesai";

class FormBank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      response: "",
      kode_bank: "",
      nama_bank: "",
    };
  }

  componentDidMount() {
    preventTabAction();
  }

  render() {
    return (
      <div className="col-lg-12 col-md-12">
        <CustomForm onSubmit={this.props.handleSubmit}>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-4">
              <Field
                name="kodeBank"
                type="text"
                component={renderField}
                normalize={(value) => value && value.toUpperCase()}
                label="Kode Bank"
              />
            </div>
            <div className="col-lg-4 col-md-4">
              <Field
                name="namaBank"
                type="telp"
                normalize={(value) => value && value.toUpperCase()}
                component={renderField}
                label="Nama Bank"
              />
            </div>
            <div className="col-md-12 col-lg-12 mt-5">
              <ButtonSelesai />
            </div>
          </div>
        </CustomForm>
      </div>
    );
  }
}

FormBank = reduxForm({
  form: "DataBank",
  enableReinitialize: true,
  validate: (values) => {
    const errors = {};
    if (!values.kodeBank) {
      errors.kodeBank = "Tidak Boleh Kosong";
    }
    if (!values.namaBank) {
      errors.namaBank = "Tidak Boleh Kosong";
    }
    return errors;
  },
})(FormBank);
export default connect()(FormBank);
