import { AxiosMasterGet } from "../Axios";
import { checkLaporan } from "../helper";

export const GET_VALIDASI_BARANG_PINDAH_BARANG =
  "GET_VALIDASI_BARANG_PINDAH_BARANG";
export const GET_VALIDASI_BARANG_MUTASI_BARANG =
  "GET_VALIDASI_BARANG_MUTASI_BARANG";
export const GET_VALIDASI_KIRIM_STOCK = "GET_VALIDASI_KIRIM_STOCK";
export const GET_VALIDASI_BARANG_MUTASI_LK = "GET_VALIDASI_BARANG_MUTASI_LK";
export const GET_VALIDASI_BARANG_KIRIM_MASAK =
  "GET_VALIDASI_BARANG_KIRIM_MASAK";
export const GET_VALIDASI_BARANG_TERIMA_MASAK =
  "GET_VALIDASI_BARANG_TERIMA_MASAK";

export const getValidasiBarangPindahBarang = () => {
  return (dispatch) => {
    AxiosMasterGet("validate-stock/get/un-valid-data")
      .then((res) => {
        if (
          checkLaporan(
            res.data.filter((list) => list.keterangan === "PINDAH BARANG")
          )
        ) {
          dispatch({
            type: GET_VALIDASI_BARANG_PINDAH_BARANG,
            payload: {
              data: res.data.filter(
                (list) => list.keterangan === "PINDAH BARANG"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch({
            type: GET_VALIDASI_BARANG_PINDAH_BARANG,
            payload: {
              data: res.data.filter(
                (list) => list.keterangan === "PINDAH BARANG"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() =>
        dispatch({
          type: GET_VALIDASI_BARANG_PINDAH_BARANG,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};
export const getValidasiBarangMutasiBarang = () => {
  return (dispatch) => {
    AxiosMasterGet("validate-stock/get/un-valid-data")
      .then((res) => {
        if (
          checkLaporan(
            res.data.filter((list) => list.keterangan === "MUTASI STOCK")
          )
        ) {
          dispatch({
            type: GET_VALIDASI_BARANG_PINDAH_BARANG,
            payload: {
              data: res.data.filter(
                (list) => list.keterangan === "MUTASI STOCK"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch({
            type: GET_VALIDASI_BARANG_PINDAH_BARANG,
            payload: {
              data: res.data.filter(
                (list) => list.keterangan === "MUTASI STOCK"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() =>
        dispatch({
          type: GET_VALIDASI_BARANG_PINDAH_BARANG,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};
export const getValidasiBarangKirimStock = () => {
  return (dispatch) => {
    AxiosMasterGet("/kirim-stock/get/UnValidAll")
      .then((res) => {
        if (checkLaporan(res.data)) {
          dispatch({
            type: GET_VALIDASI_KIRIM_STOCK,
            payload: {
              data: res.data,
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch({
            type: GET_VALIDASI_KIRIM_STOCK,
            payload: {
              data: res.data,
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() =>
        dispatch({
          type: GET_VALIDASI_KIRIM_STOCK,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};
export const getValidasiBarangMutasiLK = () => {
  return (dispatch) => {
    AxiosMasterGet("item/dc/mutasilk/heads?statusValid=OPEN")
      .then((res) =>
        dispatch({
          type: GET_VALIDASI_BARANG_MUTASI_LK,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch(() =>
        dispatch({
          type: GET_VALIDASI_BARANG_MUTASI_LK,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};
export const getValidasiBarangKirimMasak = () => {
  return (dispatch) => {
    AxiosMasterGet("item/dc/kirimmasak/heads?statusValid=OPEN")
      .then((res) =>
        dispatch({
          type: GET_VALIDASI_BARANG_KIRIM_MASAK,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch(() =>
        dispatch({
          type: GET_VALIDASI_BARANG_KIRIM_MASAK,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};
export const getValidasiBarangTerimaMasak = () => {
  return (dispatch) => {
    AxiosMasterGet("item/dc/terimamasak/heads?statusValid=OPEN")
      .then((res) =>
        dispatch({
          type: GET_VALIDASI_BARANG_TERIMA_MASAK,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch(() =>
        dispatch({
          type: GET_VALIDASI_BARANG_TERIMA_MASAK,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};
