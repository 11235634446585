import { GET_IS_LOGIN } from "../actions/loginAction";
import {
  HIDE_ALERT_LOGIN,
  SET_ALERT_LOGIN,
  SET_DASHBOARD,
  SET_DEFAULT,
  SET_FICO,
  SET_JUALBELILM,
  SET_LAPORAN,
  SET_LOGIN,
  SET_MASTER,
  SET_REPARASI, SET_SAMPLE,
  SET_STOKING,
  SET_SUPERVISORY,
  SET_TITLE,
  SET_TOTALAN,
  SET_TRANSAKSI,
  SET_VALIDASI,
} from "../actions/titleAction";

const initialState = {
  title: "AYU GOLD",
  Dashboard: "sidebar-item",
  Master: "sidebar-item",
  Sample: "sidebar-item",
  Stoking: "sidebar-item",
  Transaksi: "sidebar-item",
  Fico: "sidebar-item",
  Validasi: "sidebar-item",
  Laporan: "sidebar-item",
  Supervisory: "sidebar-item",
  JualBeliLM: "sidebar-item",
  Totalan: "sidebar-item",
  Reparasi : "sidebar-item",
  Login: false,
  alert: false,
};

const title = (state = initialState, action) => {
  switch (action.type) {
    case SET_TITLE:
      return {
        ...state,
        title: action.payload.title,
      };
    case SET_DASHBOARD:
      return {
        ...state,
        Dashboard: action.payload.Dashboard,
        Master: "sidebar-item",
        Sample: "sidebar-item",
        Stoking: "sidebar-item",
        Transaksi: "sidebar-item",
        Fico: "sidebar-item",
        Validasi: "sidebar-item",
        Laporan: "sidebar-item",
        Supervisory: "sidebar-item",
        JualBeliLM: "sidebar-item",
        Totalan: "sidebar-item",
      };
    case SET_MASTER:
      return {
        ...state,
        Master: action.payload.Master,
        Dashboard: "sidebar-item",
        Stoking: "sidebar-item",
        Sample: "sidebar-item",
        Transaksi: "sidebar-item",
        Fico: "sidebar-item",
        Validasi: "sidebar-item",
        Laporan: "sidebar-item",
        Supervisory: "sidebar-item",
        JualBeliLM: "sidebar-item",
        Totalan: "sidebar-item",
        Reparasi: "sidebar-item"
      };
    case SET_STOKING:
      return {
        ...state,
        Stoking: action.payload.Stoking,
        Dashboard: "sidebar-item",
        Master: "sidebar-item",
        Sample: "sidebar-item",
        Transaksi: "sidebar-item",
        Fico: "sidebar-item",
        Validasi: "sidebar-item",
        Laporan: "sidebar-item",
        Supervisory: "sidebar-item",
        JualBeliLM: "sidebar-item",
        Totalan: "sidebar-item",
        Reparasi: "sidebar-item"
      };
    case SET_TRANSAKSI:
      return {
        ...state,
        Transaksi: action.payload.Transaksi,
        Dashboard: "sidebar-item",
        Master: "sidebar-item",
        Sample: "sidebar-item",
        Stoking: "sidebar-item",
        Fico: "sidebar-item",
        Validasi: "sidebar-item",
        Laporan: "sidebar-item",
        Supervisory: "sidebar-item",
        JualBeliLM: "sidebar-item",
        Totalan: "sidebar-item",
        Reparasi: "sidebar-item"
      };
    case SET_FICO:
      return {
        ...state,
        Fico: action.payload.Fico,
        Dashboard: "sidebar-item",
        Master: "sidebar-item",
        Sample: "sidebar-item",
        Stoking: "sidebar-item",
        Validasi: "sidebar-item",
        Transaksi: "sidebar-item",
        Laporan: "sidebar-item",
        Supervisory: "sidebar-item",
        JualBeliLM: "sidebar-item",
        Totalan: "sidebar-item",
        Reparasi: "sidebar-item"
      };
    case SET_VALIDASI:
      return {
        ...state,
        Validasi: action.payload.Validasi,
        Dashboard: "sidebar-item",
        Sample: "sidebar-item",
        Master: "sidebar-item",
        Stoking: "sidebar-item",
        Fico: "sidebar-item",
        Laporan: "sidebar-item",
        Transaksi: "sidebar-item",
        Supervisory: "sidebar-item",
        JualBeliLM: "sidebar-item",
        Totalan: "sidebar-item",
        Reparasi: "sidebar-item"
      };
    case SET_LAPORAN:
      return {
        ...state,
        Laporan: action.payload.Laporan,
        Dashboard: "sidebar-item",
        Master: "sidebar-item",
        Sample: "sidebar-item",
        Stoking: "sidebar-item",
        Fico: "sidebar-item",
        Validasi: "sidebar-item",
        Transaksi: "sidebar-item",
        Supervisory: "sidebar-item",
        JualBeliLM: "sidebar-item",
        Totalan: "sidebar-item",
        Reparasi: "sidebar-item"
      };
    case SET_SUPERVISORY:
      return {
        ...state,
        Supervisory: action.payload.Supervisory,
        Dashboard: "sidebar-item",
        Master: "sidebar-item",
        Sample: "sidebar-item",
        Stoking: "sidebar-item",
        Fico: "sidebar-item",
        Validasi: "sidebar-item",
        Laporan: "sidebar-item",
        Transaksi: "sidebar-item",
        JualBeliLM: "sidebar-item",
        Totalan: "sidebar-item",
        Reparasi: "sidebar-item"
      };
    case SET_JUALBELILM:
      return {
        ...state,
        JualBeliLM: action.payload.JualBeliLM,
        Dashboard: "sidebar-item",
        Master: "sidebar-item",
        Sample: "sidebar-item",
        Stoking: "sidebar-item",
        Fico: "sidebar-item",
        Validasi: "sidebar-item",
        Laporan: "sidebar-item",
        Transaksi: "sidebar-item",
        Supervisory: "sidebar-item",
        Totalan: "sidebar-item",
        Reparasi: "sidebar-item"
      };
    case SET_TOTALAN:
      return {
        ...state,
        Totalan: action.payload.Totalan,
        Dashboard: "sidebar-item",
        Master: "sidebar-item",
        Sample: "sidebar-item",
        Stoking: "sidebar-item",
        Fico: "sidebar-item",
        Validasi: "sidebar-item",
        Laporan: "sidebar-item",
        Transaksi: "sidebar-item",
        Supervisory: "sidebar-item",
        JualBeliLM: "sidebar-item",
        Reparasi: "sidebar-item"
      };
      case SET_REPARASI:
        return {
          ...state,
          Reparasi: "sidebar-item selected",
          Dashboard: "sidebar-item",
          Master: "sidebar-item",
          Sample: "sidebar-item",
          Stoking: "sidebar-item",
          Fico: "sidebar-item",
          Validasi: "sidebar-item",
          Laporan: "sidebar-item",
          Transaksi: "sidebar-item",
          Supervisory: "sidebar-item",
          JualBeliLM: "sidebar-item",
          Totalan: "sidebar-item"
        };
    case SET_DEFAULT:
      return {
        ...state,
        title: "AYU GOLD WEBSITE",
        Dashboard: "sidebar-item",
        Master: "sidebar-item",
        Sample: "sidebar-item ",
        Stoking: "sidebar-item",
        Transaksi: "sidebar-item",
        Fico: "sidebar-item",
        Validasi: "sidebar-item",
        Laporan: "sidebar-item",
        Supervisory: "sidebar-item",
        JualBeliLM: "sidebar-item",
        Reparasi: "sidebar-item"
      };
    case SET_SAMPLE:
      return {
        ...state,
        title: "AYU GOLD WEBSITE",
        Dashboard: "sidebar-item",
        Master: "sidebar-item",
        Sample: "sidebar-item selected",
        Stoking: "sidebar-item",
        Transaksi: "sidebar-item",
        Fico: "sidebar-item",
        Validasi: "sidebar-item",
        Laporan: "sidebar-item",
        Supervisory: "sidebar-item",
        JualBeliLM: "sidebar-item",
        Reparasi: "sidebar-item"
      };
    case SET_LOGIN:
      return {
        ...state,
        Login: true,
      };
    case GET_IS_LOGIN:
      return {
        ...state,
        Login: action.payload.data,
      };
    case SET_ALERT_LOGIN:
      return {
        ...state,
        alert: action.payload.data,
      };
    case HIDE_ALERT_LOGIN:
      return {
        ...state,
        alert: action.payload.data,
      };
    default:
      return state;
  }
};

export default title;
