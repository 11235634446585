import jsPDF from "jspdf";
import "jspdf-autotable";
import { printInfo } from "../../helper";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const PrintNota = (
  row1name = "",
  row1isi = "",
  row2name = "",
  row2isi = "",
  row3name = "",
  row3isi = "",
  row4name = "",
  row4isi = "",
  username = "",
  tanggal = "",
  validby = "",
  tableColumn = [],
  title = "",
  tableRows,
  footer = []
) => {
  // initialize jsPDF
  const doc = new jsPDF("portrait");
  doc.setProperties({
    title: title,
  });

  footer.length !== 0
    ? doc.autoTable({
        head: tableColumn,
        body: tableRows,
        foot: footer,
        startY: 28,
        theme: "plain",
        showHead: "everyPage",
        showFoot: "lastPage",
        rowPageBreak: "avoid",
        margin: { top: 30 },
        bodyStyles: { lineColor: "#969696", fontSize: 8, lineWidth: 0.0 },
        headStyles: {
          lineColor: "#969696",
          lineWidth: 0.0,
          fontSize: 8,
          fillColor: "#E8E8E8",
        },
        footStyles: {
          lineColor: "#969696",
          lineWidth: 0.0,
          fillColor: "#E8E8E8",
          fontSize: 8,
        },
        didDrawPage: function () {
          doc.setFontSize(13);
          //doc.addImage(logo_ayu_base64, "PNG", 14, 15, 36, 12);
          doc.text(title, 14, 10);
          //row 1
          doc.setFontSize(9);
          doc.text(row1name, 14, 16);
          doc.text(row1isi === "" ? "" : ":", 37, 16);
          doc.text(row1isi, 40, 16);
          doc.text(row2name, 120, 16);
          doc.text(row2isi === "" ? "" : ":", 141, 16);
          doc.text(row2isi, 145, 16);
          //row 2
          doc.text(row3name, 14, 21);
          doc.text(row3isi === "" ? "" : ":", 37, 21);
          doc.text(row3isi, 40, 21);
          doc.text(row4name, 120, 21);
          doc.text(row4isi === "" ? "" : ":", 141, 21);
          doc.text(row4isi, 145, 21);
        },
      })
    : doc.autoTable({
        head: tableColumn,
        body: tableRows,
        foot: [footer, printInfo()],
        autoSize: true,
        startY: 28,
        theme: "plain",
        showHead: "everyPage",
        showFoot: "lastPage",
        rowPageBreak: "avoid",
        margin: { top: 30 },
        bodyStyles: { lineColor: "#969696", fontSize: 8, lineWidth: 0.0 },
        headStyles: {
          lineColor: "#969696",
          lineWidth: 0.0,
          fontSize: 8,
          fillColor: "#E8E8E8",
        },
        didDrawPage: function () {
          doc.setFontSize(13);
          //doc.addImage(logo_ayu_base64, "PNG", 14, 15, 36, 12);
          doc.text(title, 14, 10);
          //row 1
          doc.setFontSize(9);
          doc.text(row1name, 14, 16);
          doc.text(row1isi === "" ? "" : ":", 37, 16);
          doc.text(row1isi, 40, 16);
          doc.text(row2name, 120, 16);
          doc.text(row2isi === "" ? "" : ":", 141, 16);
          doc.text(row2isi, 145, 16);
          //row 2
          doc.text(row3name, 14, 21);
          doc.text(row3isi === "" ? "" : ":", 37, 21);
          doc.text(row3isi, 40, 21);
          doc.text(row4name, 120, 21);
          doc.text(row4isi === "" ? "" : ":", 141, 21);
          doc.text(row4isi, 145, 21);
        },
      });

  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    "<html><head><title>" +
      title +
      "</title></head><body style='margin:0 !important'><embed width='100%' height='100%'  src=" +
      string +
      "></embed></body></html>"
  );
  x.document.close();
  //   x.document.write(
  //     `
  // <html>
  // <head>
  //   <title>${title}/title>
  //   <style>
  //     #overlay {
  //       position: absolute;
  //       top: 0;
  //       left: 0;
  //       width: 100%;
  //       height: 100%;
  //       z-index: 1000;
  //     }
  //   </style>
  // </head>
  // <body style='margin:0 !important; position: relative;'>
  //   <iframe src='${string}#toolbar=0' width='100%' height='100%' frameborder='0' style='position: absolute; top: 0; left: 0;'></iframe>
  //   <div id='overlay' oncontextmenu='return false;'></div>
  // </body>
  // </html>
  // `
  //   );
  x.document.close();
};

export default PrintNota;
