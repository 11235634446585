import { GET_TOTALAN_TIMBANGAN_TEMP } from "./total_timbangan_action";


const initialState = {
    listTotalanTimbanganTemp: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TOTALAN_TIMBANGAN_TEMP:
            return {
                ...state,
                listTotalanTimbanganTemp: action.payload,
            };

        default:
            return state;
    }
};

export default reducer;
