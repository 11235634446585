import {
    GET_CETAK_ULANG_PEMBAYARAN_HUTANG_SUPPLIER,
    GET_CETAK_ULANG_PENERIMAN_SUPPLIER, GET_CETAK_ULANG_PENJUALAN, GET_CETAK_ULANG_RETUR_PENJUALAN,
    GET_CETAK_ULANG_RETUR_SUPPLIER, GET_CETAK_ULANG_TOLAKAN_SUPPLIER
} from "./cetak_ulang_action";

const initialState = {
    listPenerimaanSupplier: [],
    listReturSupplier: [],
    listPembayaranHutangSupplier: [],
    detail_bayar_pembayaran_supplier: [],
    listTolakanReturSupplier : [],
    listPenjualan: [],
    listReturPenjualan: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CETAK_ULANG_PENERIMAN_SUPPLIER:
            return {
                ...state,
                listPenerimaanSupplier: action.payload
            }
        case GET_CETAK_ULANG_RETUR_SUPPLIER:
            return {
                ...state,
                listReturSupplier: action.payload
            }
        case GET_CETAK_ULANG_PEMBAYARAN_HUTANG_SUPPLIER:
            return  {
                ...state,
                listPembayaranHutangSupplier: action.payload,
                detail_bayar_pembayaran_supplier: action.detail_bayar
            }
        case GET_CETAK_ULANG_TOLAKAN_SUPPLIER:
            return {
                ...state,
                listTolakanReturSupplier: action.payload
            }
        case GET_CETAK_ULANG_PENJUALAN:
            return {
                ...state,
                listPenjualan:  action.payload
            }
        case GET_CETAK_ULANG_RETUR_PENJUALAN:
            return {
                ...state,
                listReturPenjualan: action.payload
            }
        default:
            return state;
    }
};

export default reducer;
