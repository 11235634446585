import { AxiosMasterGet } from "../Axios";

export const GET_DATA_CLOSE_PENGAMBILAN_BARCODE =
  "GET_DATA_CLOSE_PENGAMBILAN_BARCODE";
export const GET_LIST_TIMBANGAN = "GET_LIST_TIMBANGAN";
export const GET_LIST_TIMBANGAN_SELECTED = "GET_LIST_TIMBANGAN_SELECTED";

export const getListClosePengambilanBarcode = () => {
  return (dispatch) => {
    dispatch({
      type: GET_DATA_CLOSE_PENGAMBILAN_BARCODE,
      payload: {
        data: JSON.parse(localStorage.getItem("closePengambilanBarcode")),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const getClosePengambilanTimbangan = () => {
  return (dispatch) => {
    AxiosMasterGet(`pengambilan-timbang/get-open`)
      .then((res) =>
        dispatch({
          type: GET_LIST_TIMBANGAN,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch(() =>
        dispatch({
          type: GET_LIST_TIMBANGAN,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const getClosePengambilanTimbanganSelected = (row, no_barcode) => {
  return (dispatch) => {
    dispatch({
      type: GET_LIST_TIMBANGAN_SELECTED,
      payload: {
        data: row,
        no_barcode: no_barcode,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
        error: true,
      },
    });
  };
};
