import { EDIT_24K, GET_24K } from "./24kAction";
import { GET_DATA_24K_TEMP } from "../../../Stocking/tambah-24k/redux/tambah-24k-action";

const initialState = {
  list24K: [],
  edit24K: [],
  list24KTemp: [],
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_24K:
      return {
        ...state,
        list24K: action.payload.data,
      };
    case EDIT_24K:
      return {
        ...state,
        edit24K: action.payload,
      };
    case GET_DATA_24K_TEMP:
      return {
        ...state,
        list24KTemp: action.payload.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
