import {
  AxiosMasterDelete,
  AxiosMasterGet,
  AxiosMasterPost,
  AxiosMasterPut,
} from "../../../Axios";
import { finishSend, progressSend } from "../../../actions/LogicAction";
import { toast } from "react-toastify";
import { ToastKirim } from "../../../actions/toast";
import { gagal, reactive } from "../../../Alert";
import { reset } from "redux-form";
import { hideModal } from "../../../actions/ModalAction";
import Swal from "sweetalert2";
import { purifiedData } from "../../../helper";

export const GET_SUPPLIER_LIST = "GET_SUPPLIER_LIST";
export const EDIT_SUPPLIER = "EDIT_SUPPLIER";
export const GET_LOKASI_PINDAH = "GET_LOKASI_PINDAH";

export const getsupplierList = () => {
  return (dispatch) => {
    AxiosMasterGet("supplier/get/all")
      .then((res) =>
        dispatch({
          type: GET_SUPPLIER_LIST,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_SUPPLIER_LIST,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const getLokasiPindah = () => {
  return (dispatch) => {
    AxiosMasterGet("/lokasi/get/lokasi-sales")
      .then((res) =>
        dispatch({
          type: GET_LOKASI_PINDAH,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_SUPPLIER_LIST,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const editsupplier = (
  Alamat,
  Email,
  contactPerson,
  fax,
  handPhone,
  kodeExt,
  kodeKota,
  kodePos,
  kodeSupplier,
  namaSupplier,
  telepon,
  kodeProvinsi,
  kodeKecamatan,
  status_intern,
  noRekening,
  atasNama,
  namaBank
) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_SUPPLIER,
      payload: {
        Alamat: Alamat,
        Email: Email,
        contactPerson: contactPerson,
        fax: fax,
        handPhone: handPhone,
        kodeExt: kodeExt,
        kodeKota: kodeKota,
        kodePos: kodePos,
        kodeSupplier: kodeSupplier,
        namaSupplier: namaSupplier,
        telepon: telepon,
        kodeProvinsi: kodeProvinsi,
        kodeKecamatan: kodeKecamatan,
        status_intern: status_intern,
        noRekening: noRekening,
        atasNama: atasNama,
        namaBank: namaBank,
      },
    });
  };
};

export const sendSupplier = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.SupplierTambah.values;
    purifiedData(selected);
    let data = {
      kode_supplier: selected.kodeSupplier,
      nama_supplier: selected.namaSupplier,
      kode_ext: selected.kodeExt || "-",
      alamat: selected.Alamat || "-",
      contact_person: selected.contactPerson || "-",
      no_telp: selected.telepon || "-",
      no_hp: selected.handPhone || "-",
      fax: selected.fax || "-",
      email: selected.Email || "-",
      status_intern: false,
      kode_kota: selected.kodeKota,
      no_rekening: selected.noRekening || "-",
      nama_bank: selected.namaBank || "-",
      atas_nama: selected.atasNama || "-",
    };
    try {
      await toast.promise(AxiosMasterPost("supplier/add", data), ToastKirim);
    } catch (err) {
      let error = err.response.data;
      let check = error.includes("deleted");
      delete data.kode_supplier;
      check
        ? reactive(
            err,
            selected.kodeSupplier,
            "supplier/reactive/",
            data,
            "supplier/edit/"
          ).then(() => dispatch(getsupplierList()))
        : gagal("Data Gagal Ditambahkan");
      dispatch(getsupplierList());
    }
    dispatch(finishSend());
    dispatch(getsupplierList());
    dispatch(reset("Provinsi"));
    dispatch(hideModal());
  };
};

export const sendEditSupplier = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.Supplier.values;
    purifiedData(selected);
    let data = {
      nama_supplier: selected.namaSupplier,
      kode_ext: selected.kodeExt,
      alamat: selected.Alamat,
      contact_person: selected.contactPerson,
      no_telp: selected.telepon,
      no_hp: selected.handPhone,
      fax: selected.fax,
      email: selected.Email,
      status_intern: false,
      kode_kota: selected.kodeKota,
      no_rekening: selected.noRekening,
      nama_bank: selected.namaBank,
      atas_nama: selected.atasNama,
    };
    try {
      await toast.promise(
        AxiosMasterPut("supplier/edit/" + selected.kodeSupplier, data),
        ToastKirim
      );
      dispatch(finishSend());
      dispatch(getsupplierList());
      dispatch(reset("Supplier"));
      dispatch(hideModal());
    } catch (err) {
      dispatch(finishSend());
      dispatch(getsupplierList());
      dispatch(reset("Supplier"));
      dispatch(hideModal());
    }
  };
};

export const deleteSupplier = (kode) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    Swal.fire({
      title: "Anda Yakin ?",
      text: "Anda Akan Menghapus Data Ini ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          dispatch(progressSend());
          await toast.promise(
            AxiosMasterDelete("supplier/delete/" + kode),
            ToastKirim
          );
          dispatch(finishSend());
          dispatch(getsupplierList());
          dispatch(reset("Supplier"));
          dispatch(hideModal());
        } catch (e) {
          dispatch(finishSend());
        }
      }
    });
    dispatch(finishSend());
  };
};
