import React from "react";
import Skeleton from "react-loading-skeleton";

export const getToday = () => {
  return (
    new Date().getFullYear() +
    "-" +
    ("0" + (new Date().getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + new Date().getDate()).slice(-2)
  );
};

export const SkeletonLoading = ({ label }) => (
  <div className="form-group">
    <label> {label} </label>
    <Skeleton className="form-control" />
  </div>
);
