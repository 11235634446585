import React from "react";

///  nama, colSpan, rangeDate, tgl_from, tgl_to
export const HeadLaporanExcel = (props) => {
  const { nama, colSpan, rangeDate, start, end } = props;
  return (
    <>
      <tr>
        <th
          colSpan={colSpan}
          style={{ backgroundColor: "#E8E5E5", color: "#000", fontSize: 12 }}
        >
          {nama}
        </th>
      </tr>
      {rangeDate && (
        <tr>
          <th
            colSpan={colSpan}
            style={{ backgroundColor: "#E8E5E5", color: "#000", fontSize: 12 }}
          >
            PERIODE : {start} - {end}
          </th>
        </tr>
      )}
      <tr>
        <th style={{ backgroundColor: "#FFFFF", color: "#000" }}>&nbsp;</th>
      </tr>
    </>
  );
};
