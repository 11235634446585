export const GET_IS_LOGIN = "GET_IS_LOGIN";

export const getIsLogin = () => {
  return (dispatch) => {
    dispatch({
      type: GET_IS_LOGIN,
      payload: {
        data: JSON.parse(localStorage.getItem("isLogin")),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
        username: localStorage.getItem("username") || "ADMIN",
        level: localStorage.getItem("level") || "ADMIN",
      },
    });
  };
};
