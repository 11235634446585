import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatDateIndo, formatRp, printInfo } from "../../../helper";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporanPrintBankCard = (
  username = "",
  tanggal = "",
  validby = "",
  data
) => {
  // initialize jsPDF
  const doc = new jsPDF("portrait");
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  let tableColumn = [];
  let finalY = 25;
  doc.setFontSize(15);
  doc.text("LAPORAN KEUANGAN BANK - CARD", 14, 10);
  doc.setFontSize(10);
  doc.text(
    `Periode : ${formatDateIndo(data.tglFrom)} S/D ${formatDateIndo(
      data.tglTo
    )}`,
    14,
    15
  );
  // doc.addImage(logo_ayu_base64, "PNG", 14, 10, 35, 12);

  doc.setFontSize(10);
  data.data.forEach((item, index) => {
    tableColumn = [
      [
        {
          content: `KODE BANK : ${item.kode_bank}`,
          colSpan: 3,
        },
        {
          content: `NOMOR REKENING : ${item.no_rek}`,
          colSpan: 3,
        },
      ],
      [
        {
          content: `TANGGAL`,
        },
        {
          content: `KETERANGAN`,
        },
        {
          content: `SALDO AWAL`,
          styles: { halign: "right" },
        },
        {
          content: `SALDO IN`,
          styles: { halign: "right" },
        },
        {
          content: `SALDO OUT`,
          styles: { halign: "right" },
        },
        {
          content: `SALDO AKHIR`,
          styles: { halign: "right" },
        },
      ],
    ];
    item.detail.forEach((barang, index) => {
      let rows = [
        formatDateIndo(barang.tanggal),
        barang.keterangan,
        { content: formatRp(barang.saldo_awal), styles: { halign: "right" } },
        { content: formatRp(barang.saldo_in), styles: { halign: "right" } },
        { content: formatRp(barang.saldo_out), styles: { halign: "right" } },
        { content: formatRp(barang.saldo_akhir), styles: { halign: "right" } },
      ];
      tableRows.push(rows);
      console.log(tableRows);
    });
    doc.autoTable({
      head: tableColumn,
      body: tableRows,
      foot: [printInfo()],
      showHead: "firstPage",
      showFoot: "lastPage",
      startY: index === 0 ? 20 : finalY + 5,
      theme: "plain",
      rowPageBreak: "avoid",
      pageBreak: "avoid",
      margin: { top: 20 },
      bodyStyles: { lineColor: "#969696", fontSize: 8, lineWidth: 0.0 },
      headStyles: {
        lineColor: "#969696",
        lineWidth: 0.0,
        fontSize: 8,
        fillColor: "#E8E8E8",
      },
      footStyles: {
        lineColor: "#969696",
        lineWidth: 0.0,
        fillColor: "#E8E8E8",
        fontSize: 8,
      },
    });
    finalY = doc.autoTableEndPosY() + 10;
    tableRows = [];
  });
  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  doc.autoPrint();
  doc.setProperties({
    title: "LAPORAN KEUANGAN BANK - CARD",
  });
  // doc.save(`PenerimaanSUpplier.pdf`);
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    `
<html>
<head>
  <title>LAPORAN KEUANGAN BANK - CARD</title>
  <style>
    #overlay {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1000;
    }
  </style>
</head>
<body style='margin:0 !important; position: relative;'>
  <iframe src='${string}#toolbar=0' width='100%' height='100%' frameborder='0' style='position: absolute; top: 0; left: 0;'></iframe>
  <div id='overlay' oncontextmenu='return false;'></div>
</body>
</html>
`
  );
  // setInterval(() => {
  //   x.close();
  // }, 1000);
};

export default LaporanPrintBankCard;
