import {
    GET_KIRIM_STOCK_RETUR,
    GET_KIRIM_STOCK_RETUR_TEMP,
    NO_RETUR_SELECTED,
    SET_KIRIM_STOCK_RETUR,
    SET_LIST_KIRM_STOCK_RETUR
} from "./kirim_stock_retur_action";

const initialState = {
listReturTemp : [],
    listRetur : [],
    listAvailableRetur: [],
    noReturSelected :[]
}

const kirimStockRetur = (state = initialState, action) => {
    switch (action.type) {
        case GET_KIRIM_STOCK_RETUR:
            return {
                ...state,
                listRetur : action.payload
            }

        case GET_KIRIM_STOCK_RETUR_TEMP:
            return {
                ...state,
                listReturTemp: action.payload
            }
        case SET_KIRIM_STOCK_RETUR:
            return {
                ...state,
                listReturTemp: action.payload
            }
            case SET_LIST_KIRM_STOCK_RETUR:
                return {
                    ...state,
                    listAvailableRetur: action.payload
                }
        case NO_RETUR_SELECTED:
            return {
                ...state,
                noReturSelected: action.payload
            }
        default:
            return state
    }
}

export default kirimStockRetur