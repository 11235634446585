import { Modal } from "react-bootstrap";
import React, { Component } from "react";
import { connect } from "react-redux";
import { hideModal } from "../../actions/ModalAction";

const maptoState = (state) => {
  return {
    show: state.modal.show,
  };
};
class ModalGlobal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={() => this.props.dispatch(hideModal())}
        dialogClassName="modal-xl"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#5F76E8", color: "#ffffff" }}
        >
          <Modal.Title>{this.props.title || ""}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.props.content || ""}</Modal.Body>
      </Modal>
    );
  }
}

export default connect(maptoState, null)(ModalGlobal);
