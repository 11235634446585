import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatDateIndo, formatOptional, printInfo } from "../../../helper";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporanPembayaranCustomer = (data, mulai_dari, sampai_dengan) => {
  console.log(data);
  // initialize jsPDF
  const doc = new jsPDF("portrait");
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  let positionY = 25;

  doc.setFontSize(15);
  doc.text("LAPORAN PEMBAYARAN PIUTANG CUSTOMER", 14, 10);
  // doc.addImage(logo_ayu_base64, "PNG", 14, 10, 35, 12);

  doc.setFontSize(10);
  doc.text(`Mulai Dari : ${formatDateIndo(mulai_dari)}`, 14, 16);
  //   row 2
  doc.text(`Sampai Dengan	: ${formatDateIndo(sampai_dengan)}`, 100, 16);
  //   //row 1
  //   doc.text(`Supplier : ${row1isi}`, 14, 25);
  //   //   row 2
  //   doc.text(`Tanggal	: ${row2isi}`, 120, 25);
  data.forEach((item, index) => {
    let tableColumn = [
      [
        {
          content: `KODE CUSTOMER : ${item.kode_customer} / ${item.nama_customer}`,
          colSpan: 8,
        },
      ],
      [
        {
          content: `TANGGAL`,
        },
        {
          content: `NO BON`,
        },
        {
          content: `PIUTANG`,
          styles: { halign: "right" },
        },
        {
          content: `BAYAR`,
          styles: { halign: "right" },
        },
        {
          content: `DENDA`,
          styles: { halign: "right" },
        },
        {
          content: `PUTUS TITIP`,
          styles: { halign: "right" },
        },
        {
          content: `JML PIUTANG`,
          styles: { halign: "right" },
        },
        {
          content: `LBH BAYAR`,
          styles: { halign: "right" },
        },
      ],
    ];
    item.detail.forEach((hasil, index) => {
      let rows = [
        formatDateIndo(hasil.tanggal),
        hasil.no_bon,
        {
          content: formatOptional(hasil.total_piutang),
          styles: { halign: "right" },
        },
        {
          content: formatOptional(hasil.total_pembayaran),
          styles: { halign: "right" },
        },
        {
          content: formatOptional(hasil.total_denda),
          styles: { halign: "right" },
        },
        {
          content: formatOptional(hasil.total_putus_titip),
          styles: { halign: "right" },
        },
        {
          content: formatOptional(hasil.jumlah_piutang),
          styles: { halign: "right" },
        },
        {
          content: formatOptional(hasil.lebih_pembayaran),
          styles: { halign: "right" },
        },
      ];
      tableRows.push(rows);
      let detail = [
        `BAYAR CASH : ${formatOptional(item.detail_bayar[index].bayar_cash)}`,
        `BAYAR CASH USD : ${formatOptional(
          item.detail_bayar[index].bayar_cash_usd
        )}`,
        `BAYAR TRANSFER : ${formatOptional(
          item.detail_bayar[index].bayar_trf
        )}`,
        `BAYAR TRANSFER USD : ${formatOptional(
          item.detail_bayar[index].bayar_trf_usd
        )}`,
        `BAYAR CIOK : ${formatOptional(item.detail_bayar[index].bayar_ciok)}`,
        `BAYAR RONGSOK : ${formatOptional(
          item.detail_bayar[index].bayar_rongsok
        )}`,
        `BAYAR GIRO : ${formatOptional(item.detail_bayar[index].bayar_giro)}`,
      ];
      tableRows.push(detail);
    });

    doc.autoTable({
      head: tableColumn,
      body: tableRows,
      foot: [printInfo()],
      showHead: "firstPage",
      showFoot: "lastPage",
      startY: positionY,
      theme: "plain",
      rowPageBreak: "avoid",
      pageBreak: "avoid",
      margin: { top: 20 },
      bodyStyles: { lineColor: "#969696", fontSize: 8, lineWidth: 0.0 },
      headStyles: {
        lineColor: "#969696",
        lineWidth: 0.0,
        fontSize: 8,
        fillColor: "#E8E8E8",
      },
      footStyles: {
        lineColor: "#969696",
        lineWidth: 0.0,
        fillColor: "#E8E8E8",
        fontSize: 8,
      },
    });

    tableRows = [];
    positionY = doc.autoTableEndPosY() + 10;
  });

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  doc.autoPrint();
  doc.setProperties({
    title: "LAPORAN PEMBAYARAN PIUTANG CUSTOMER",
  });
  // doc.save(`PenerimaanSUpplier.pdf`);
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    `
<html>
<head>
  <title>LAPORAN PEMBAYARAN HUTANG CUSTOMER</title>
  <style>
    #overlay {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1000;
    }
  </style>
</head>
<body style='margin:0 !important; position: relative;'>
  <iframe src='${string}#toolbar=0' width='100%' height='100%' frameborder='0' style='position: absolute; top: 0; left: 0;'></iframe>
  <div id='overlay' oncontextmenu='return false;'></div>
</body>
</html>
`
  );
  // setInterval(() => {
  //   x.close();
  // }, 1000);
};

export default LaporanPembayaranCustomer;
