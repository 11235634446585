import { AxiosMasterGet } from "../Axios";

export const GET_KELOMPOK_HARGA = "GET_KELOMPOK_HARGA";
export const SAVE_DUPLIKAT_DATA = "SAVE_DUPLIKAT_DATA";

export const getKelompokHarga = () => {
  return (dispatch) => {
    AxiosMasterGet("kelompok-harga/get-all")
      .then((res) =>
        dispatch({
          type: GET_KELOMPOK_HARGA,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_KELOMPOK_HARGA,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const saveDuplikatBarang = (data) => {
  return (dispatch) => {
    dispatch({
      type: SAVE_DUPLIKAT_DATA,
      payload: {
        data: data,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};
