import { AxiosMasterGet } from "../Axios";

export const GET_LIST_TUKANG = "GET_LIST_TUKANG";
export const EDIT_LIST_TUKANG = "EDIT_LIST_TUKANG";

export const getListTukang = () => {
  return (dispatch) => {
    AxiosMasterGet("storages2?tipeData=TUKANG")
      .then((res) =>
        dispatch({
          type: GET_LIST_TUKANG,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_LIST_TUKANG,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const editTukang = (kodeLokasi, namaLokasi, status) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_LIST_TUKANG,
      payload: {
        kodeLokasi: kodeLokasi,
        namaLokasi: namaLokasi,
        status: status,
      },
    });
  };
};
