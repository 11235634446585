import { finishSend, progressSend } from "../../../actions/LogicAction";
import { getLocal, saveLocal } from "../../../encrypt";
import { hideModal } from "../../../actions/ModalAction";
import { change, reset } from "redux-form";
import {
  formatGram,
  FormatterNumber,
  getBon,
  getToday,
  getUserID,
  multipleDeleteLocal,
  purifiedData,
} from "../../../helper";
import { AxiosMasterPost } from "../../../Axios";
import { toast } from "react-toastify";
import { ToastKirim } from "../../../actions/toast";
import PrintNota from "../../Component/PrintNota";

export const GET_DATA_24K_TEMP = "GET_DATA_24K_TEMP";

export const getListTambah24K = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_DATA_24K_TEMP,
      payload: {
        data: await getLocal("24KTemp"),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const save24KTemp = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const selected = state.form.FormKirimBahan24K.values;
    purifiedData(selected);
    const payload = {
      kode_barang: selected.kode_barang,
      pkg: FormatterNumber(selected.pkg),
      netto: FormatterNumber(selected.netto),
      bruto: FormatterNumber(selected.bruto),
    };
    const prevData = await getLocal("24KTemp");
    prevData.push(payload);
    await saveLocal("24KTemp", prevData);
    dispatch(getListTambah24K());
    dispatch(finishSend());
    dispatch(hideModal());
    dispatch(reset("FormMutasiBarang"));
  };
};

export const sendTambah24K = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.HeadKirimBahan24K.values;
    purifiedData(selected);
    const noBon = "K24K-" + getBon();
    const detail_barang = await getLocal("24KTemp");
    const payload = {
      tanggal: selected.tanggal || getToday(),
      no_transaksi: noBon,
      detail_barang: detail_barang,
    };
    try {
      var response = await toast.promise(
        AxiosMasterPost("24K/kirim-bahan", payload),
        ToastKirim
      );
      print(
        detail_barang,
        selected.tanggal || getToday(),
        response.data.no_transaksi
      );
      multipleDeleteLocal(["24KTemp"]);
      dispatch(finishSend());
      dispatch(getListTambah24K());
      dispatch(reset("HeadTambah24K"));
      dispatch(change("HeadTambah24K", "tanggal", getToday()));
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

function print(table, tanggal, bon) {
  var tableRows = [];
  table.forEach((ticket, i) => {
    const ticketData = [
      ++i,
      ticket.kode_barang,
      { content: formatGram(ticket.pkg), styles: { halign: "right" } },
      { content: formatGram(ticket.bruto), styles: { halign: "right" } },
      { content: formatGram(ticket.netto), styles: { halign: "right" } },
    ];
    tableRows.push(ticketData);
  });
  var columTable = [
    [
      { content: "No" },
      { content: "Kode 24K" },
      { content: "Pkg", styles: { halign: "right" } },
      { content: "Bruto", styles: { halign: "right" } },
      { content: "Netto", styles: { halign: "right" } },
    ],
  ];
  PrintNota(
    "Tanggal",
    tanggal,
    "",
    "",
    "Nomor Bon",
    bon,
    "",
    "",
    getUserID(),
    tanggal,
    "",
    columTable,
    "Kirim Bahan 24K",
    tableRows
  );
}
