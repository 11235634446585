import { AxiosMasterGet } from "../Axios";

export const GET_KATEGORI_LIST = "GET_KATEGORI_LIST";
export const EDIT_KATEGORI = "EDIT_KATEGORI";

export const getkategoriList = () => {
  return (dispatch) => {
    AxiosMasterGet("kelompok/get/all")
      .then((res) =>
        dispatch({
          type: GET_KATEGORI_LIST,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_KATEGORI_LIST,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};
export const editkategoriList = (kodeKategori, keterangan, kodeGroup) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_KATEGORI,
      payload: {
        kodeKategori: kodeKategori,
        keterangan: keterangan,
        kodeGroup: kodeGroup,
      },
    });
  };
};
