import React, { useEffect } from "react";
import HeaderCard from "../../HeaderCard";
import GlobalTabel from "../../GlobalComponent/GlobalTabel";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import {
  getClosePenjualan,
  seeDetail,
  sendClosePenjualan,
} from "./redux/close_penjualan_action";
import DetailBarangClosePenjualan from "./component/detail_barang_close_penjualan";
import ModalGlobal from "../../DataMaster/Component/ModalMasterData";

const ClosePenjualan = () => {
  const dispatch = useDispatch();
  const dataClosePenjualan = useSelector(
    (state) => state.closePenjualan.dataClosePenjualan
  );
  const columns = [
    {
      dataField: "no_bon",
      text: "Nomor Bon",
    },
    {
      dataField: "no_penjualan",
      text: "Nomor Faktur",
    },
    {
      dataField: "kode_lokasi",
      text: "Lokasi",
    },
    {
      dataField: "kode_sales",
      text: "Sales",
    },
    {
      dataField: "nama_toko",
      text: "Nama Toko",
    },
    {
      dataField: "kode_customer",
      text: "Customer",
    },
    {
      dataField: "Aksi",
      text: "Aksi",
      csvExport: false,
      formatter: (rowContent, rows, rowIndex) => {
        return (
          <div className="justify-content-center row">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <i className="fa fa-ellipsis-v" aria-hidden="true" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    dispatch(seeDetail(rows));
                  }}
                >
                  Close Penjualan <i className="fa fa-times ml-2" />
                </Dropdown.Item>
                {/*<Dropdown.Item onClick={() =>*/}
                {/*{*/}
                {/*    this.editModal()*/}
                {/*}*/}
                {/*}>Edit  <i className="fa fa-edit ml-2"/></Dropdown.Item>*/}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    dispatch(getClosePenjualan());
  }, [dispatch]);
  return (
    <div className="container-fluid" style={{ color: "black" }}>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <HeaderCard title="Close Penjualan" />
            <div className="card-body">
              <div className="col-lg-12 col-md-12">
                <GlobalTabel
                  keyField="no_penjualan"
                  data={dataClosePenjualan}
                  columns={columns}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalGlobal
        content={
          <DetailBarangClosePenjualan
            onSubmit={(data) => dispatch(sendClosePenjualan())}
          />
        }
        title={"Detail Barang"}
      />
    </div>
  );
};

export default ClosePenjualan;
