import { AxiosMasterGet } from "../Axios";
import { checkLaporan } from "../helper";

export const GET_VALIDASI_PO = "GET_VALIDASI_PO";
export const GET_VALIDASI_PO_SELECTED = "GET_VALIDASI_PO_SELECTED";
export const GET_LIST_PO = "GET_LIST_PO";
export const GET_LIST_PACKING = "GET_LIST_PACKING";
export const GET_LIST_PO_PACKING = "GET_LIST_PO_PACKING";
export const GET_LIST_PO_PENJUALAN = "GET_LIST_PO_PENJUALAN";
export const GET_LIST_PACKING_PENJUALAN = "GET_LIST_PACKING_PENJUALAN";

export const getValidasiPO = (kode_supplier, tglTo) => {
  return (dispatch) => {
    AxiosMasterGet(`pre-order/get-all`)
      .then((res) => {
        if (checkLaporan(res.data)) {
          dispatch({
            type: GET_VALIDASI_PO,
            payload: {
              data: res.data,
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch({
            type: GET_VALIDASI_PO,
            payload: {
              data: res.data,
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() =>
        dispatch({
          type: GET_VALIDASI_PO,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};
export const getListPO = () => {
  return (dispatch) => {
    AxiosMasterGet(`valid-pre-order/get-all`)
      .then((res) => {
        if (checkLaporan(res.data)) {
          dispatch({
            type: GET_LIST_PO,
            payload: {
              data: res.data,
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch({
            type: GET_LIST_PO,
            payload: {
              data: res.data,
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() =>
        dispatch({
          type: GET_LIST_PO,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const getListPOPenjualan = () => {
  return (dispatch) => {
    AxiosMasterGet(`pre-order/get-all`)
      .then((res) => {
        dispatch({
          type: GET_LIST_PO_PENJUALAN,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        });
      })
      .catch(() =>
        dispatch({
          type: GET_LIST_PO_PENJUALAN,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const getListPOPacking = (kode_customer) => {
  return (dispatch) => {
    AxiosMasterGet(`penjualan/get/all/trx-by-customer/${kode_customer}`)
      .then((res) => {
        if (checkLaporan(res.data)) {
          dispatch({
            type: GET_LIST_PO_PACKING,
            payload: {
              data: res.data || [],
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch({
            type: GET_LIST_PO_PACKING,
            payload: {
              data: res.data,
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() =>
        dispatch({
          type: GET_LIST_PO_PACKING,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const getValidasiPOSelected = () => {
  var hasil = JSON.parse(localStorage.getItem("FakturTerpilih")) || [];
  return (dispatch) => {
    dispatch({
      type: GET_VALIDASI_PO_SELECTED,
      payload: {
        data: hasil,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
        error: true,
      },
    });
  };
};

export const getListPacking = () => {
  return (dispatch) => {
    AxiosMasterGet(`packing-list/get-no-packing-open`)
      .then((res) => {
        if (checkLaporan(res.data)) {
          dispatch({
            type: GET_LIST_PACKING,
            payload: {
              data: res.data,
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch({
            type: GET_LIST_PACKING,
            payload: {
              data: res.data,
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() =>
        dispatch({
          type: GET_LIST_PACKING,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const getListPackingPenjualan = () => {
  return (dispatch) => {
    AxiosMasterGet(`packing-list/get-packing-list-open-no-image`)
      .then((res) => {
        dispatch({
          type: GET_LIST_PACKING_PENJUALAN,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        });
      })
      .catch(() =>
        dispatch({
          type: GET_LIST_PACKING_PENJUALAN,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};
