import {
  AxiosMasterDelete,
  AxiosMasterGet,
  AxiosMasterPost,
  AxiosMasterPut,
} from "../../../Axios";
import Swal from "sweetalert2";
import { hideModal } from "../../../actions/ModalAction";
import { reactive } from "../../../Alert";
import { toast } from "react-toastify";
import { ToastKirim } from "../../../actions/toast";
import { finishSend, progressSend } from "../../../actions/LogicAction";
import { reset } from "redux-form";
import { purifiedData } from "../../../helper";

export const GET_KOTA_LIST = "GET_KOTA_LIST";
export const EDIT_KOTA = "EDIT_KOTA";

export const getKotaList = () => {
  return (dispatch) => {
    AxiosMasterGet("kota/get/all")
      .then((res) =>
        dispatch({
          type: GET_KOTA_LIST,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_KOTA_LIST,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const editKota = (kodeKota, namaKota, kodeProvinsi, status) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_KOTA,
      payload: {
        kodeKota: kodeKota,
        namaKota: namaKota,
        kodeProvinsi: kodeProvinsi,
        status: status,
      },
    });
  };
};

export const sendKota = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.Provinsi.values;
    purifiedData(selected);
    const isi = {
      nama_kota: selected.NamaKota,
      kode_kota: selected.KodeKota,
      kode_provinsi: selected.kodeProvinsi,
    };
    try {
      await toast.promise(AxiosMasterPost("kota/add", isi), ToastKirim);
    } catch (err) {
      let error = err.response.data;
      let check = error.includes("deleted");
      check
        ? reactive(
            err,
            selected.KodeKota,
            "kota/reactive/",
            {
              nama_kota: selected.NamaKota,
              kode_provinsi: selected.kodeProvinsi,
            },
            "kota/edit/"
          ).then(() => dispatch(getKotaList()))
        : console.log();
      dispatch(getKotaList());
    }
    dispatch(finishSend());
    dispatch(getKotaList());
    dispatch(reset("Provinsi"));
    dispatch(hideModal());
  };
};

export const sendEditKota = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.KotaEdit.values;
    purifiedData(selected);
    let data = {
      nama_kota: selected.NamaKota,
      kode_provinsi: selected.kodeProvinsi,
    };
    try {
      await toast.promise(
        AxiosMasterPut("kota/edit/" + selected.KodeKota, data),
        ToastKirim
      );
      dispatch(finishSend());
      dispatch(getKotaList());
      dispatch(reset("ProvinsiEdit"));
      dispatch(hideModal());
    } catch (err) {
      dispatch(finishSend());
      dispatch(getKotaList());
      dispatch(reset("ProvinsiEdit"));
      dispatch(hideModal());
    }
  };
};

export const deleteKota = (kode) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    Swal.fire({
      title: "Anda Yakin ?",
      text: "Anda Akan Menghapus Data Ini ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await toast.promise(
          AxiosMasterDelete("kota/delete/" + kode),
          ToastKirim
        );
        dispatch(getKotaList());
        dispatch(finishSend());
      }
    });
  };
};
