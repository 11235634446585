import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import { Field, formValueSelector, reduxForm, reset } from "redux-form";
import { getcustomerList } from "./customer/redux/customerAction";
import { berhasil, gagal } from "../Alert";
import { AxiosMasterPost, AxiosMasterPut } from "../Axios";
import GlobalTabel from "../GlobalComponent/GlobalTabel";
import { ToastInfo } from "../GlobalComponent/notification";
import HeaderCard from "../HeaderCard";
import { formatRp, NormalizeNumberOnly } from "../helper";
import { renderField, renderFieldSelect } from "../renderField";
import { createNumberMask } from "redux-form-input-masks";
import {
  getParameterReparasi,
  setDuplikatReparasi,
} from "../actions/parameterReparasiAction";
import { getReparasiLIst } from "../actions/reparasiAction";
import ModalMasterData from "./Component/ModalMasterData";
import FormDuplikatReparasi from "./Component/FormDuplikatReparasi";
import { showModal } from "../actions/ModalAction";

const currencyMask = createNumberMask({
  prefix: "Rp. ",
  suffix: ",-",
  locale: "id-ID",
});

class ParameterReparasi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      columns: [
        {
          dataField: "kode_customer",
          text: "Kode Toko",
          headerStyle: () => {
            return { width: "15%" };
          },
        },
        {
          dataField: "kode_reparasi",
          text: "Kode Reparasi",
          headerStyle: () => {
            return { width: "15%" };
          },
        },
        {
          dataField: "ongkos_reparasi",
          text: "Ongkos Reparasi",
          align: "right",
          headerAlign: "right",
          formatter: (data) => formatRp(data),
          headerStyle: () => {
            return { width: "15%" };
          },
        },
        {
          dataField: "type_reparasi",
          text: "Type Reparasi",
          align: "right",
          headerAlign: "right",
          headerStyle: () => {
            return { width: "15%" };
          },
        },
        {
          dataField: "link",
          text: "Aksi",
          csvExport: false,
          formatter: (rowContent, row) => {
            return (
              <div className="text-center row-justify-center">
                <button
                  data-tip
                  data-for="edit"
                  className="btn btn-success mr-1 mt-1"
                  onClick={() => this.duplikat(row)}
                  data-tut="reactour_3"
                >
                  <i className="fa fa-clone mr-1"></i>
                  <ReactTooltip id="edit" type="dark" effect="solid">
                    <span>Duplikat</span>
                  </ReactTooltip>
                  Duplikat Manual
                </button>
                <button
                  data-tip
                  data-for="edit"
                  className="btn btn-primary mt-1"
                  onClick={() => {
                    this.props.dispatch(setDuplikatReparasi(row));
                    this.props.dispatch(showModal());
                  }}
                  data-tut="reactour_3"
                >
                  <i className="fa fa-clone mr-1"></i>
                  <ReactTooltip id="edit" type="dark" effect="solid">
                    <span>Duplikat</span>
                  </ReactTooltip>
                  Duplikat
                </button>
                <button
                  data-tip
                  data-for="hapus"
                  className="btn btn-warning mt-1 ml-2"
                  onClick={() => this.edit(row)}
                  data-tut="reactour_3"
                >
                  <i className="fa fa-edit "></i>
                  <ReactTooltip id="hapus" type="dark" effect="solid">
                    <span>Hapus</span>
                  </ReactTooltip>
                  Edit
                </button>
              </div>
            );
          },
        },
      ],
    };
  }

  duplikat(data) {
    this.props.change("kode_reparasi", data.kode_reparasi);
    this.props.change("ongkos_reparasi", data.ongkos_reparasi);
    this.props.change("type_reparasi", data.type_reparasi);
    ToastInfo("Duplikasi berhasil");
  }

  edit(data) {
    this.props.change("kode_reparasi", data.kode_reparasi);
    this.props.change("nama_toko", data.kode_customer);
    this.props.change("ongkos_reparasi", data.ongkos_reparasi);
    this.props.change("type_reparasi", data.type_reparasi);
    this.setState({
      edit: true,
    });
  }
  componentDidMount() {
    this.props.dispatch(getParameterReparasi());
    this.props.dispatch(getReparasiLIst());
    this.props.dispatch(getcustomerList());
  }
  simpanReparasi() {
    let data = {
      kode_customer: this.props.nama_toko,
      kode_reparasi: this.props.kode_reparasi,
      ongkos_reparasi: this.props.ongkos_reparasi,
      type_reparasi: this.props.type_reparasi,
    };
    let dataEdit = {
      ongkos_reparasi: this.props.ongkos_reparasi,
      type_reparasi: this.props.type_reparasi,
    };
    console.log(JSON.stringify(data));
    !this.state.edit
      ? AxiosMasterPost("ongkos-reparasi/add", data)
          .then(() => berhasil("Berhasil Disimpan"))
          .then(() => this.props.dispatch(reset("ParameterReparasi")))
          .then(() => this.props.dispatch(getParameterReparasi()))
          .then(() =>
            this.setState({
              edit: false,
            })
          )
          .catch((err) =>
            gagal(`Gagal Menyimpan Data, Detail : ${err.response.data}`)
          )
      : AxiosMasterPut(
          `ongkos-reparasi/update/${this.props.nama_toko}&${this.props.kode_reparasi}`,
          dataEdit
        )
          .then(() => berhasil("Berhasil Disimpan"))
          .then(() => this.props.dispatch(reset("ParameterReparasi")))
          .then(() => this.props.dispatch(getParameterReparasi()))
          .catch((err) =>
            gagal(`Gagal Menyimpan Data, Detail : ${err.response.data}`)
          );
  }
  render() {
    return (
      <div className="container-fluid" style={{ color: "black" }}>
        <div className="row">
          {this.ParameterReparasiBaru()}
          <div className="col-lg-9">
            <div className="card">
              <HeaderCard title="Parameter Reparasi" />
              <div className="card-body">
                <GlobalTabel
                  keyField="id"
                  data={this.props.ParameterReparasi}
                  columns={this.state.columns}
                  search
                />
              </div>
            </div>
          </div>
          <ModalMasterData
            content={<FormDuplikatReparasi />}
            title={"Duplikat Parameter Reparasi"}
          />
        </div>
      </div>
    );
  }

  ParameterReparasiBaru() {
    return (
      <div className="col-lg-3">
        <div className="card">
          <div className="card-body">
            <div className="col-lg-12 text-center">
              <h2>Tambah Parameter Reparasi</h2>
            </div>
            <div className="col-lg-12">
              <hr />
            </div>
            <div className="col-lg-12">
              <Field
                name="nama_toko"
                component={renderFieldSelect}
                options={this.props.kodeCustomer.map((data) => {
                  let hasil = {
                    value: data.kode_customer,
                    name: data.nama_toko,
                  };
                  return hasil;
                })}
                type="text"
                label="Nama Toko"
                placeholder="Masukan Nama Toko"
              />
            </div>
            <div className="col-lg-12">
              <Field
                name="kode_reparasi"
                component={renderFieldSelect}
                options={this.props.kodeReparasi.map((data) => {
                  let hasil = {
                    value: data.kode_reparasi,
                    name: data.deskripsi_reparasi,
                  };
                  return hasil;
                })}
                label="Reparasi"
                placeholder="Masukan Reparasi"
              />
            </div>
            <div className="col-lg-12">
              <Field
                name="type_reparasi"
                component={renderFieldSelect}
                options={[
                  {
                    value: "QTY",
                    name: "Quantity",
                  },
                  {
                    value: "BERAT",
                    name: "Berat",
                  },
                ]}
                type="text"
                label="Type Reparasi"
                placeholder="Pilih Type Reparasi"
              />
            </div>
            <div className="col-lg-12">
              <Field
                name="ongkos_reparasi"
                component={renderField}
                normalize={NormalizeNumberOnly}
                label="Ongkos Reparasi"
                placeholder="Masukan Ongkos Reparasi"
                {...currencyMask}
              />
            </div>

            <div className="col-lg-12 mt-3">
              <button
                className="btn btn-primary btn-block"
                onClick={() => this.simpanReparasi()}
              >
                Selesai
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ParameterReparasi = reduxForm({
  form: "ParameterReparasi",
})(ParameterReparasi);
const selector = formValueSelector("ParameterReparasi");
export default connect((state) => {
  const { kode_reparasi, nama_toko, ongkos_reparasi, type_reparasi } = selector(
    state,
    "kode_reparasi",
    "ongkos_reparasi",
    "nama_toko",
    "type_reparasi"
  );
  return {
    ParameterReparasi: state.provinsi.ParameterReparasi,
    kodeReparasi: state.provinsi.reparasi,
    kodeCustomer: state.provinsi.customer,
    kode_reparasi: kode_reparasi,
    ongkos_reparasi: ongkos_reparasi,
    nama_toko: nama_toko,
    type_reparasi: type_reparasi,
  };
})(ParameterReparasi);
