import { finishSend, progressSend } from "../../../actions/LogicAction";
import { toast } from "react-toastify";
import { AxiosMasterGetWithParams } from "../../../Axios";
import { ToastAmbil } from "../../../actions/toast";
import LaporanGlobal24K from "../component/LaporanGlobal24K";
import { warning } from "../../../Alert";

export const GET_LAPORAN_GLOBAL_24K = "GET_LAPORAN_GLOBAL_24K";

export const GetLaporanGlobal24K = (startDate, endDate) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    try {
      const response = await toast.promise(
        AxiosMasterGetWithParams(`report/24K/global`, {
          start_date: startDate,
          end_date: endDate,
        }),
        ToastAmbil
      );
      console.log(response.data);
      if (response.data.length === 0) {
        dispatch({
          type: GET_LAPORAN_GLOBAL_24K,
          payload: {
            data: {
              detail: [],
            },
            tgl_from: startDate,
            tgl_to: endDate,
          },
        });
        warning("Data Kosong");
      } else {
        dispatch({
          type: GET_LAPORAN_GLOBAL_24K,
          payload: {
            data: response.data,
            tgl_from: startDate,
            tgl_to: endDate,
          },
        });
      }

      dispatch(finishSend());
    } catch (e) {
      dispatch({
        type: GET_LAPORAN_GLOBAL_24K,
        payload: {
          data: {
            detail: [],
          },
          tgl_from: startDate,
          tgl_to: endDate,
        },
      });
      dispatch(finishSend());
    }
  };
};

export const PrintLaporanGlobal24KPDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanGlobal24k = state.laporan.dataLaporanGlobal24k;
    LaporanGlobal24K(dataLaporanGlobal24k.data);
  };
};
