import {
  finishSend,
  isExist,
  isExistSingle,
  progressSend,
} from "../../../actions/LogicAction";
import { getLocal } from "../../../encrypt";
import { hideModal } from "../../../actions/ModalAction";
import {
  formatGram,
  formatRp,
  FormatterNumber,
  getBon,
  getUserID,
  multipleDeleteLocal,
  printInfo,
  purifiedData,
  removeComma,
} from "../../../helper";
import { getToday } from "../../../getDate";
import { toast } from "react-toastify";
import { AxiosMasterGet, AxiosMasterPost } from "../../../Axios";
import { ToastKirim } from "../../../actions/toast";
import { change, reset } from "redux-form";
import PrintNota from "../../../Stocking/Component/PrintNota";
import { warning } from "../../../Alert";
import { SAVE_PREV_NO_TERIMA } from "../../penerimaan-supplier/redux/terimaSupplierAction";
import { getUUID } from "../../../actions/fakturAction";

export const GET_DATA_RETURN_SUPPLIER = "GET_DATA_RETURN_SUPPLIER";
export const GET_UNVALID_RETUR_SUPPLIER = "GET_UNVALID_RETUR_SUPPLIER";
export const GET_DATA_TOLAKAN_RETURN_CUSTOMER =
  "GET_DATA_TOLAKAN_RETURN_CUSTOMER";
export const GET_DATA_TOLAKAN_PEMBAYARAN_CUSTOMER =
  "GET_DATA_TOLAKAN_PEMBAYARAN_CUSTOMER";
export const GET_DATA_TOLAKAN_RETURN_SUPPLIER =
  "GET_DATA_TOLAKAN_RETURN_SUPPLIER";
export const GET_DATA_TOLAKAN_HUTANG_SUPPLIER =
  "GET_DATA_TOLAKAN_HUTANG_SUPPLIER";

export const getListReturnSupplier = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_DATA_RETURN_SUPPLIER,
      payload: {
        data: await getLocal("ReturnSupplierTemp"),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};
export const getListTolakanReturnSupplier = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_DATA_TOLAKAN_RETURN_SUPPLIER,
      payload: {
        data: await getLocal("TolakReturnSupplierTemp"),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const getListTolakHutangSupplier = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_DATA_TOLAKAN_HUTANG_SUPPLIER,
      payload: {
        data: await getLocal("TolakHutangSupplier"),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const getListTolakanPembayaranCustomer = () => {
  return (dispatch) => {
    dispatch({
      type: GET_DATA_TOLAKAN_PEMBAYARAN_CUSTOMER,
      payload: {
        data: JSON.parse(localStorage.getItem("TolakanPembayaranCustomer")),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};
export const getListTolakReturnCustomer = () => {
  return (dispatch) => {
    dispatch({
      type: GET_DATA_TOLAKAN_RETURN_CUSTOMER,
      payload: {
        data: JSON.parse(localStorage.getItem("TolakReturnCustomer")),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const getUnvalidReturBarang = () => {
  return async (dispatch, getState) => {
    const response = await AxiosMasterGet("retur-supplier/unvalid");
    dispatch({
      type: GET_UNVALID_RETUR_SUPPLIER,
      payload: response.data.filter(
        (x) => x.kode_supplier === localStorage.getItem("kodeSupplier")
      ),
    });
  };
};
export const saveEditReturTemp = (data) => {
  return async (dispatch, getState) => {
    dispatch(change("ReturnSupplier", "no_bon", data.no_bon));
    dispatch(
      change("ReturnSupplier", "type_stock", {
        value: data.type_stock,
        label: data.type_stock,
      })
    );
    dispatch({
      type: SAVE_PREV_NO_TERIMA,
      payload: data.no_retur,
    });
    for (let item of data.detail_barang) {
      delete item._id;
      await isExist({
        target: "kode_barang",
        target2: "kode_bahan",
        name: "ReturnSupplierTemp",
        payload: item,
        value: item.kode_barang,
        value2: item.kode_bahan,
      });
    }
    dispatch(getListReturnSupplier());
    dispatch(hideModal());
    dispatch(finishSend());
  };
};
export const saveReturnSupplierTemp = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.TerimaSupplier.values;
    purifiedData(selected);
    const prevData = (await getLocal("ReturnSupplierTemp")) || [];
    selected.no = prevData.length;
    selected.kode_bahan = selected.kode_barang.split("/")[1];
    selected.kode_barang = selected.kode_barang.split("/")[0];
    selected.bruto = FormatterNumber(selected.bruto);
    selected.netto = FormatterNumber(selected.netto);
    selected.gross = FormatterNumber(selected.gross);
    delete selected.no_job_order;
    await isExist({
      target: "kode_barang",
      target2: "kode_bahan",
      name: "ReturnSupplierTemp",
      payload: selected,
      value: selected.kode_barang,
      value2: selected.kode_bahan,
    });
    dispatch(getListReturnSupplier());
    toast.success("Barang Berhasil Ditambahkan");
    dispatch(hideModal());
    dispatch(finishSend());
  };
};
export const saveEditReturnSupplierTemp = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.TerimaSupplierEdit.values;
    purifiedData(selected);
    selected.kode_bahan = selected.kode_barang.split("/")[1];
    selected.kode_barang = selected.kode_barang.split("/")[0];
    selected.bruto = FormatterNumber(selected.bruto);
    selected.netto = FormatterNumber(selected.netto);
    selected.gross = FormatterNumber(selected.gross);
    await isExistSingle({
      target: "no",
      name: "ReturnSupplierTemp",
      payload: selected,
      value: selected.no,
      replace: true,
    });
    dispatch(getListReturnSupplier());
    toast.success("Barang Berhasil Dirubah");
    dispatch(hideModal());
    dispatch(finishSend());
  };
};

export const sendReturnSupplier = (edit) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    let state = getState();
    const selected = state.form.ReturnSupplier.values;
    purifiedData(selected);
    var jenis = selected.kode_supplier
      ? selected.kode_supplier
      : "DEFALT | DEFAULT";
    var result_explode = jenis.split("|");
    var noBon = "RS-" + getBon();
    const detail_barang = await getLocal("ReturnSupplierTemp");
    if (
      selected.tanggal === undefined ||
      selected.kode_supplier === undefined
    ) {
      warning("Mohon Lengkapi Isian");
      return false;
    }
    var result = await PostReturnSupplier({
      detail_barang: detail_barang,
      dispatch: dispatch,
      payload: {
        no_retur: state.provinsi.noFaktur,
        no_bon: noBon,
        is_edit: state.provinsi.prev_no_terima !== "-",
        prev_no_retur: state.provinsi.prev_no_terima,
        type_stock: selected.type_stock,
        kode_supplier: result_explode[0],
        tanggal_invoice: selected.tanggal,
        detail_barang: detail_barang.map(({ no, _id, ...rest }) => rest),
      },
      result_explode: result_explode,
      selected: selected,
    });

    if (result.includes("E11000")) {
      multipleDeleteLocal(["noFaktur"]);
      dispatch(getUUID());
      state = getState();
      await PostReturnSupplier({
        detail_barang: detail_barang,
        dispatch: dispatch,
        payload: {
          no_retur: state.provinsi.noFaktur,
          no_bon: noBon,
          is_edit: state.provinsi.prev_no_terima !== "-",
          prev_no_retur: state.provinsi.prev_no_terima,
          type_stock: selected.type_stock,
          kode_supplier: result_explode[0],
          tanggal_invoice: selected.tanggal,
          detail_barang: detail_barang.map(({ no, _id, ...rest }) => rest),
        },
        result_explode: result_explode,
        selected: selected,
      });
    }
  };
};

async function PostReturnSupplier({
  payload,
  detail_barang,
  result_explode,
  selected,
  dispatch,
}) {
  try {
    var response = await toast.promise(
      AxiosMasterPost("trx-supplier/retur-supplier", payload),
      ToastKirim
    );
    print(
      detail_barang,
      response.data.no_bon,
      selected.tanggal,
      result_explode[0]
    );
    multipleDeleteLocal([
      "ReturnSupplierTemp",
      "noBon",
      "tanggal",
      "tanggalBarang",
      "namaSupplier",
      "kodeSupplier",
      "keterangan",
      "totalNW",
      "noFaktur",
      "temp-returSupplierDate",
      "temp-returSupplierType",
      "temp-returSupplierSupp",
    ]);
    dispatch(getUUID());
    dispatch(finishSend());
    dispatch(getListReturnSupplier());
    dispatch(reset("ReturnSupplier"));
    dispatch(change("ReturnSupplier", "tanggal", getToday()));
    window.location.reload();
    return "SUCCESS";
  } catch (e) {
    dispatch(finishSend());
    return e?.response?.data;
  }
}
function print(table, noBon, tanggal, supplier) {
  var tableRows = [];
  table.forEach((ticket, i) => {
    const ticketData = [
      { content: ++i },
      { content: ticket.kode_barang },
      { content: ticket.kode_bahan },
      { content: ticket.qty, styles: { halign: "right" } },
      { content: ticket.kadar_cetak, styles: { halign: "right" } },
      { content: ticket.pkg, styles: { halign: "right" } },
      { content: formatGram(ticket.bruto), styles: { halign: "right" } },
      { content: ticket.kadar_beli, styles: { halign: "right" } },
      { content: formatGram(ticket.netto, 3), styles: { halign: "right" } },
      { content: formatRp(ticket.ongkos_rp), styles: { halign: "right" } },
    ];
    // push each tickcet's info into a row
    tableRows.push(ticketData);
  });
  const footer = [
    "",
    "",
    "",
    {
      content: table
        .map((list) => removeComma(list.qty))
        .reduce((a, b) => a + b, 0),
      styles: { halign: "right" },
    },
    "",
    {
      content: table
        .map((list) => removeComma(list.pkg))
        .reduce((a, b) => a + b, 0),
      styles: { halign: "right" },
    },
    {
      content: formatGram(
        table.map((list) => removeComma(list.bruto)).reduce((a, b) => a + b, 0)
      ),
      styles: { halign: "right" },
    },
    "",
    {
      content: formatGram(
        table.map((list) => removeComma(list.netto)).reduce((a, b) => a + b, 0),
        3
      ),
      styles: { halign: "right" },
    },
    {
      content: formatRp(
        table.map((list) => parseInt(list.ongkos_rp)).reduce((a, b) => a + b, 0)
      ),
      styles: { halign: "right" },
    },
  ];
  // tableRows.push(footer);
  console.log(JSON.stringify(tableRows));
  var columTable = [
    [
      { content: "No" },
      { content: "Kode\nBarang" },
      { content: "Kode\nBahan" },
      { content: "Qty", styles: { halign: "right" } },
      { content: "Cap\nKode", styles: { halign: "right" } },
      { content: "Qty\nBungkus", styles: { halign: "right" } },
      { content: "Berat\n(Gr)", styles: { halign: "right" } },
      { content: "Harga\n(Gr)", styles: { halign: "right" } },
      { content: "Murni\n(Gr)", styles: { halign: "right" } },
      { content: "Ongkos\n(Rp)", styles: { halign: "right" } },
    ],
  ];
  PrintNota(
    "No Bon",
    noBon,
    "Tanggal",
    tanggal,
    "",
    "",
    "Supplier",
    supplier,
    getUserID(),
    getToday(),
    "",
    columTable,
    "Retur Barang Supplier",
    tableRows,
    [footer, printInfo()]
  );
}
