import { combineReducers } from "redux";
import provinsi from "./provinsi";
import title from "./title";
import config from "./config";
import alert from "./alert";
import perhitungan from "./perhitungan";
import modal from "./modal";
import detailValidasi from "./detailValidasi";
import packing from "./packingList";
import po from "./pre_order";
import barcodeSet from "./barcodeSet";
import kirimStock from "./kirimStock";
import pembayaranCustomer from "./pembayaranCustomer";
import bahan from "./bahan";
import barang from "./barang";
import sample from "./sample";
import master24k from "../DataMaster/24k/redux/24K";
import cetakBarcode from "../Sample/cetak-barcode/redux/cetakBarcode";
import pindahSample from "../Sample/pindah-sample/redux/pindahSample";
import { reducer as formReducer } from "redux-form";
import closePenjualan from "../Transaksi/close_penjualan/redux/close_penjualan_reducer";
import kirimStockBayar from "../Transaksi/kirim_stock_bayar/redux/kirim_stock_bayar_redux";
import kirimStockRetur from "../Transaksi/kirim_stock_retur/redux/kirim_stock_retur_redux";
import correctionNote from "../FICO/correction_note/redux/correction_note_redux";
import totalanTimbangan from "../TotalanMenu/totalan_timbangan/redux/total_timbangan_redux";
import cetakUlang from "../Supervisory/redux/cetak_ulang_redux";
import laporan from "../Laporan/redux/laporan_redux";
import parameterHargaEmas from "../DataMaster/parameter-harga-emas/redux/parameterHargaEmasReducer";
import pdf from "./pdf";
export default combineReducers({
  provinsi,
  title,
  config,
  alert,
  perhitungan,
  modal,
  detailValidasi,
  packing,
  po,
  barcodeSet,
  kirimStock,
  pembayaranCustomer,
  bahan,
  barang,
  sample,
  cetakBarcode,
  pindahSample,
  closePenjualan,
  kirimStockBayar,
  kirimStockRetur,
  correctionNote,
  totalanTimbangan,
  cetakUlang,
  laporan,
  master24k,
  parameterHargaEmas,
  pdf,
  form: formReducer,
});
