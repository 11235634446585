import { change } from "redux-form";
import { AxiosMasterGet } from "../Axios";

export const GET_DATA_STOCK = "GET_DATA_STOCK";

export const getDataStock = (data) => {
  return (dispatch) => {
    AxiosMasterGet("timbang-stock/get/trx-timbang-stock/" + data).then(
      (res) => {
        dispatch({
          type: GET_DATA_STOCK,
          payload: {
            data: res.data?.detail_barang,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        });
        localStorage.setItem(
          "TimbangStock",
          JSON.stringify(res.data?.detail_barang || [])
        );
        const timbangStock =
          JSON.parse(localStorage.getItem("TimbangStock")) || [];
        dispatch(
          change(
            "TimbangStock",
            "total_real",
            timbangStock.map((x) => x.berat_bersih).reduce((a, b) => a + b, 0)
          )
        );
      }
    );
  };
};

export const setEditData = (data) => {
  return (dispatch) => {
    dispatch(change("FormTimbanganStock", "jenis_barang", data.jenis_barang));
    dispatch(change("FormTimbanganStock", "berat_packing", data.berat_packing));
    dispatch(
      change(
        "FormTimbanganStock",
        "gross",
        parseFloat(data.berat_packing) + parseFloat(data.berat_bersih)
      )
    );
    dispatch(change("FormTimbanganStock", "keterangan", data.keterangan));
  };
};

export const clearTimbanganStock = () => {
  return (dispatch) => {
    dispatch(change("FormTimbanganStock", "jenis_barang", ""));
    dispatch(change("FormTimbanganStock", "berat_packing", ""));
    dispatch(change("FormTimbanganStock", "gross", ""));
    dispatch(change("FormTimbanganStock", "keterangan", ""));
  };
};
