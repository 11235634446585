import {
  GET_LIST_SAMPLE,
  SET_EDIT_SAMPLE,
} from "../Sample/data-sample/redux/dataSampleAction";
import { GET_TEMP_HANCUR_BARANG } from "../Sample/hancur-sample/redux/HancurSampleAction";

const initialState = {
  dataSample: [],
  hancurSampleTemp: [],
  editSample: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_SAMPLE:
      return {
        ...state,
        dataSample: action.payload,
      };
    case GET_TEMP_HANCUR_BARANG:
      return {
        ...state,
        hancurSampleTemp: action.payload,
      };
    case SET_EDIT_SAMPLE:
      return {
        ...state,
        editSample: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
