import {
  AxiosMasterDelete,
  AxiosMasterGet,
  AxiosMasterPost,
  AxiosMasterPut,
} from "../../../Axios";
import { toast } from "react-toastify";
import { ToastKirim } from "../../../actions/toast";
import { finishSend, progressSend } from "../../../actions/LogicAction";
import { hideModal } from "../../../actions/ModalAction";
import Swal from "sweetalert2";
import { purifiedData } from "../../../helper";

export const GET_ROSOK_LIST = "GET_ROSOK_LIST";
export const EDIT_ROSOK = "EDIT_ROSOK";

export const getListRosok = (filter) => {
  return (dispatch) => {
    AxiosMasterGet("ciok-rosok")
      .then((res) =>
        dispatch({
          type: GET_ROSOK_LIST,
          payload: {
            data: filter
              ? res.data.filter((x) => x.kode_kategori === filter)
              : res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_ROSOK_LIST,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const editRosok = (data) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_ROSOK,
      payload: data,
    });
  };
};

export const sendCiokRosok = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const selected = state.form.Rosok.values;
    purifiedData(selected);
    let data = {
      kode_kategori: selected.kodeKategori,
      kode_kelompok: selected.kodeKategori.includes("CIOK")
        ? "CIOK"
        : "RONGSOK",
      kode_ciok_rosok: selected.kodeJenis,
      nama_ciok_rosok: selected.namaJenis,
      kadar_cetak: selected.kadarCetak.toString(),
      kadar_beli: selected.kadarBeli,
      kadar_jual: selected.kadarJual,
      kode_barcode:
        selected.kodeJenis +
        "|" +
        selected.namaJenis +
        "|" +
        selected.kadarCetak +
        "|" +
        selected.kadarJual,
    };
    await toast.promise(AxiosMasterPost("ciok-rosok", data), ToastKirim);
    dispatch(finishSend());
    dispatch(hideModal());
    dispatch(getListRosok());
  };
};

export const deleteRosok = (kode) => {
  return async (dispatch, getstate) => {
    dispatch(progressSend());
    Swal.fire({
      title: "Anda Yakin ?",
      text: "Anda Akan Menghapus Data Ini ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await toast.promise(
            AxiosMasterDelete("ciok-rosok/" + kode),
            ToastKirim
          );
          dispatch(getListRosok());
          dispatch(finishSend());
        } catch (e) {
          dispatch(finishSend());
        }
      }
    });
    dispatch(finishSend());
  };
};

export const sendEditCiokRosok = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const selected = state.form.RosokEdit.values;
    purifiedData(selected);
    let data = {
      kode_kategori: selected.kodeKategori,
      kode_kelompok: selected.kodeKategori.includes("CIOK")
        ? "CIOK"
        : "RONGSOK",
      // kode_ciok_rosok: selected.kodeJenis,
      nama_ciok_rosok: selected.namaJenis,
      kadar_cetak: selected.kadarCetak.toString(),
      kadar_beli: selected.kadarBeli,
      kadar_jual: selected.kadarJual,
      kode_barcode:
        selected.kodeJenis +
        "|" +
        selected.namaJenis +
        "|" +
        selected.kadarCetak +
        "|" +
        selected.kadarJual,
    };
    await toast.promise(
      AxiosMasterPut("ciok-rosok/" + selected.kodeJenis, data),
      ToastKirim
    );
    dispatch(finishSend());
    dispatch(hideModal());
    dispatch(getListRosok());
  };
};
