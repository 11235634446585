import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { getToday } from "../../GlobalComponent/function";
import { renderField, renderFieldSelect } from "../../renderField";
import { PrintLaporanValidasiPDF } from "../redux/laporan_action";
import { LaporanExcelValidasiCustomer } from "./component/LaporanExcelValidasi";
import { formatGram, showOwnerOnly } from "../../helper";
import GlobalTabel from "../../GlobalComponent/GlobalTabel";
import { AxiosMasterGet } from "../../Axios";
import { gagal } from "../../Alert";

class FormLaporanValidasi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type_laporan: "SIMPLE",
      listDataUser: [],
      columnValidasi: [
        {
          dataField: "no_faktur",
          text: "No Bon",
        },
        {
          dataField: "tanggal_transaksi",
          text: "Tanggal Transkasi",
        },
        {
          dataField: "tanggal_validasi",
          text: "Tanggal Validasi",
        },
        {
          dataField: "total_bruto",
          text: "Total Bruto",
          align: "right",
          formatter: (data) => formatGram(data),
        },
        {
          dataField: "total_netto",
          text: "Total Netto",
          align: "right",
          formatter: (data) => formatGram(data),
        },
        {
          dataField: "validate_by",
          text: "Validate By",
        },
      ],
    };
  }
  componentDidMount() {
    AxiosMasterGet("users/get/all")
      .then((res) =>
        this.setState({
          listDataUser: res.data,
        })
      )
      .catch(() =>
        gagal(
          "Sepertinya ada gangguan, Mohon Check koneksi dan Refresh halaman"
        )
      );
  }
  render() {
    const listUser = [{ value: "ALL", name: "SEMUA" }];
    this.state.listDataUser.forEach((el) => {
      listUser.push({ value: el.user_id, name: el.user_name });
    });
    switch (this.props.from) {
      case "PROSES":
        return (
          <form onSubmit={this.props.handleSubmit}>
            <div className="col-lg-12">
              <div className="row">
                {/* <div className="col-lg-4">
                  <Field
                    label="User ID"
                    name="user_id"
                    type="text"
                    component={renderField}
                  />
                </div> */}
                <div className="col-lg-4">
                  <Field
                    name="user_id"
                    label="User ID"
                    component={renderFieldSelect}
                    options={listUser}
                  />
                </div>
                <div className="col-lg-4">
                  <Field
                    name="jenis_transaksi"
                    label="Jenis Transaksi"
                    component={renderFieldSelect}
                    options={[
                      {
                        value: "",
                        name: "SEMUA",
                      },
                      {
                        value: "PINDAH BARANG",
                        name: "PINDAH BARANG",
                      },
                      {
                        value: "MUTASI BARANG",
                        name: "MUTASI BARANG",
                      },
                      {
                        value: "PENJUALAN",
                        name: "PENJUALAN",
                      },
                      {
                        value: "PEMBAYARAN PIUTANG",
                        name: "PEMBAYARAN PIUTANG",
                      },
                      {
                        value: "RETUR CUSTOMER",
                        name: "RETUR CUSTOMER",
                      },
                      {
                        value: "TITIPAN CUSTOMER",
                        name: "TITIPAN CUSTOMER",
                      },
                      {
                        value: "TOLAKAN CUSTOMER",
                        name: "TOLAKAN CUSTOMER",
                      },
                      {
                        value: "TRANSFER BALANCE",
                        name: "TRANSFER BALANCE",
                      },
                      {
                        value: "BATAL PENJUALAN",
                        name: "BATAL PENJUALAN",
                      },
                      {
                        value: "BATAL BAYAR PIUTANG",
                        name: "BATAL BAYAR PIUTANG",
                      },
                      {
                        value: "BATAL RETUR CUSTOMER",
                        name: "BATAL RETUR CUSTOMER",
                      },
                      {
                        value: "BATAL TITIP CUSTOMER",
                        name: "BATAL TITIP CUSTOMER",
                      },
                      {
                        value: "BATAL TOLAKAN CUSTOMER",
                        name: "BATAL TOLAKAN CUSTOMER",
                      },
                      {
                        value: "PENERIMAAN SUPPLIER",
                        name: "PENERIMAAN SUPPLIER",
                      },
                      {
                        value: "PEMBAYARAN HUTANG",
                        name: "PEMBAYARAN HUTANG",
                      },
                      {
                        value: "RETUR SUPPLIER",
                        name: "RETUR SUPPLIER",
                      },
                      {
                        value: "TITIPAN SUPPLIER",
                        name: "TITIPAN SUPPLIER",
                      },
                      {
                        value: "TOLAKAN SUPPLIER",
                        name: "TOLAKAN SUPPLIER",
                      },
                      {
                        value: "BATAL TERIMA SUPPLIER",
                        name: "BATAL TERIMA SUPPLIER",
                      },
                      {
                        value: "BATAL BAYAR PIUTANG",
                        name: "BATAL BAYAR PIUTANG",
                      },
                      {
                        value: "BATAL RETUR SUPPLIER",
                        name: "BATAL RETUR SUPPLIER",
                      },
                      {
                        value: "BATAL TITIPAN SUPPLIER",
                        name: "BATAL TITIPAN SUPPLIER",
                      },
                      {
                        value: "BATAL TOLAKAN SUPPLIER",
                        name: "BATAL TOLAKAN SUPPLIER",
                      },
                    ]}
                  />
                </div>
                <div className="col-lg-4">
                  <Field
                    label="Mulai Dari"
                    name="tgl_from"
                    type="date"
                    component={renderField}
                  />
                </div>
                <div className="col-lg-4">
                  <Field
                    label="Sampai Dengan"
                    name="tgl_to"
                    type="date"
                    component={renderField}
                  />
                </div>
              </div>
            </div>
            <div className={"col-lg-3"}>
              <label htmlFor=""> &nbsp;</label>
              <button
                className="btn btn-primary form-control"
                type="submit"
                disabled={this.props.onSend}
              >
                {this.props.onSend ? (
                  <span
                    className="spinner-border spinner-border-sm mr-3"
                    aria-hidden="true"
                  />
                ) : (
                  <i className="fa fa-search mr-2" />
                )}
                Cari Data
              </button>
            </div>
            {this.props.dataLaporanValidasi.data.length > 0 && (
              <>
                {this.props.dataLaporanValidasi.data.map((x) => (
                  <>
                    <div className={"col-lg-12 mt-2"}>
                      <h3 className={"text-center"}>{x.jenis_transaksi}</h3>
                      <div className={"col-lg-12 mt-2"}>
                        <GlobalTabel
                          keyField={"no_bon"}
                          columns={this.state.columnValidasi}
                          data={x.detail_validasi}
                        />
                      </div>
                    </div>
                  </>
                ))}
                <div className={showOwnerOnly()}>
                  <div className={"col-lg-6"}>
                    <LaporanExcelValidasiCustomer
                      data={this.props.dataLaporanValidasi}
                    />
                  </div>
                  <div className={"col-lg-6"}>
                    <button
                      className={"btn btn-danger form-control"}
                      onClick={() =>
                        this.props.dispatch(PrintLaporanValidasiPDF())
                      }
                    >
                      Export PDF
                    </button>
                  </div>
                </div>
              </>
            )}
          </form>
        );
      default:
        break;
    }
  }
}

FormLaporanValidasi = reduxForm({
  form: "FormLaporanValidasi",
  enableReinitialize: true,
})(FormLaporanValidasi);
export default connect((state) => {
  return {
    listBank: state.provinsi.rekening,
    onSend: state.provinsi.onSend,
    listCustomer: state.provinsi.customer,
    dataLaporanValidasi: state.laporan.dataLaporanValidasi,
    initialValues: {
      tgl_from: getToday(),
      tgl_to: getToday(),
    },
  };
})(FormLaporanValidasi);
