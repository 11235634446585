export const GET_DATA_KIRIM_BAHAN = "GET_DATA_KIRIM_BAHAN";

export const getListKirimBahan = () => {
  return (dispatch) => {
    dispatch({
      type: GET_DATA_KIRIM_BAHAN,
      payload: {
        data: JSON.parse(localStorage.getItem("kirimBahan")),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};
