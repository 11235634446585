import Axios from "axios";
import firebase from "./firebase";
import {
  checkLaporan,
  convertBase64,
  dataURLtoFile,
  resizeFile,
} from "./helper";

const server = process.env.REACT_APP_BACKEND_URL;
export function postImage(file, name) {
  return new Promise((resolve, reject) => {
    const storage = firebase.storage();
    let stoageRef = storage.ref(`GROSIR/CADM/${name}.jpg`);
    stoageRef
      .put(file)
      .then((res) => {
        stoageRef.getDownloadURL().then(function (url) {
          resolve(url);
        });
      })
      .catch((err) => {
        reject(JSON.parse(err));
      });
  });
}
export function deleteImage(name) {
  return new Promise((resolve, reject) => {
    let storage = firebase.storage();
    let storageRef = storage.ref();
    let desertRef = storageRef.child(`GROSIR/CADM/${name}.jpg`);
    desertRef
      .delete()
      .then(function () {})
      .catch(function (error) {
        reject(error);
      });
  });
}
export function getImage(file) {
  // console.log("fi;e",file)
  return new Promise((resolve, reject) => {
    const storage = firebase.storage();
    let stoageRef = storage.ref(`GROSIR/CADM/${file}.jpg`);
    stoageRef
      .getDownloadURL()
      .then(function (url) {
        // console.log('ini url', url)
        var xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = async function (event) {
          let data = await convertBase64(xhr.response);
          const file = dataURLtoFile(data);
          const res = await resizeFile(file);
          resolve(res);
        };
        xhr.onerror = async function (err) {
          // console.log('ERROR',err);
          reject("eror");
        };
        xhr.open("GET", url);
        xhr.send();
        // resolve(url);
      })
      .catch((err) => {
        // console.log(err)
        // console.log(JSON.parse(err.customData.serverResponse).error);
        reject(JSON.parse(err.customData.serverResponse).error);
      });
  });
}

export function AxiosMasterGet(endpoint) {
  let config = {
    headers: { "x-auth-token": localStorage.getItem("token") },
  };
  return new Promise((resolve, reject) => {
    Axios.get(server + endpoint, config)
      .then((res) => {
        if (checkLaporan(res.data)) {
          resolve(res);
        } else {
          reject("Data Kosong");
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 402) {
            localStorage.setItem("isLogin", false);
            window.location.replace("/");
          } else {
            reject(err);
          }
        }
      })
      .finally(() => reject("Timeout"));
  });
}

export function AxiosMasterGetNormal(endpoint) {
  let config = {
    headers: { "x-auth-token": localStorage.getItem("token") },
  };
  return new Promise((resolve, reject) => {
    Axios.get(server + endpoint, config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 402) {
            localStorage.setItem("isLogin", false);
            window.location.replace("/");
          } else {
            reject(err);
          }
        }
      })
      .finally(() => reject("Timeout"));
  });
}

export function AxiosMasterGetWithParams(endpoint, params) {
  return new Promise((resolve, reject) => {
    Axios.get(server + endpoint, {
      headers: { "x-auth-token": localStorage.getItem("token") },
      params: params,
    })
      .then((res) => resolve(res))
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 402) {
            localStorage.setItem("isLogin", false);
            window.location.replace("/");
          } else {
            reject(err);
          }
        }
      })
      .finally(() => reject("Timeout"));
  });
}
export function AxiosMasterPost(endpoint, data) {
  let config = {
    headers: { "x-auth-token": localStorage.getItem("token") },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      console.log(percentCompleted);
    },
  };
  console.log("POST AXIOS DATA : " + JSON.stringify(data));
  return new Promise((resolve, reject) => {
    Axios.post(server + endpoint, data, config)
      .then((res) => resolve(res))

      .catch((err) => {
        if (err.response) {
          if (err.response.status === 402) {
            localStorage.setItem("isLogin", false);
            window.location.replace("/");
          } else {
            reject(err);
          }
        }
      })
      .finally(() => reject("Timeout"));
  });
}
export function AxiosMasterPostWithParam(endpoint, data, query) {
  let config = {
    headers: { "x-auth-token": localStorage.getItem("token") },
    params: query,
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      console.log(percentCompleted);
    },
  };
  console.log("POST AXIOS DATA : " + JSON.stringify(data));
  return new Promise((resolve, reject) => {
    Axios.post(server + endpoint, data, config)
      .then((res) => resolve(res))

      .catch((err) => {
        if (err.response) {
          if (err.response.status === 402) {
            localStorage.setItem("isLogin", false);
            window.location.replace("/");
          } else {
            reject(err);
          }
        }
      })
      .finally(() => reject("Timeout"));
  });
}
export function AxiosMasterDelete(endpoint, data) {
  let config = {
    headers: { "x-auth-token": localStorage.getItem("token") },
  };
  return new Promise((resolve, reject) => {
    Axios.delete(server + endpoint, config)
      .then((res) => resolve(res))

      .catch((err) => {
        if (err.response) {
          if (err.response.status === 402) {
            localStorage.setItem("isLogin", false);
            window.location.replace("/");
          } else {
            reject(err);
          }
        }
      })
      .finally(() => reject("Timeout"));
  });
}

export function AxiosMasterDeleteWithParam(endpoint, query) {
  let config = {
    headers: { "x-auth-token": localStorage.getItem("token") },
    params: query,
  };
  return new Promise((resolve, reject) => {
    Axios.delete(server + endpoint, config)
      .then((res) => resolve(res))
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 402) {
            localStorage.setItem("isLogin", false);
            window.location.replace("/");
          } else {
            reject(err);
          }
        }
      })
      .finally(() => reject("Timeout"));
  });
}
export function AxiosMasterPut(endpoint, data) {
  let config = {
    headers: { "x-auth-token": localStorage.getItem("token") },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      console.log(percentCompleted);
    },
  };
  console.log("PUT AXIOS DATA : " + JSON.stringify(data));
  return new Promise((resolve, reject) => {
    Axios.put(server + endpoint, data, config)
      .then((res) => resolve(res))

      .catch((err) => {
        if (err.response) {
          if (err.response.status === 402) {
            localStorage.setItem("isLogin", false);
            window.location.replace("/");
          } else {
            reject(err);
          }
        }
      })
      .finally(() => reject("Timeout"));
  });
}
