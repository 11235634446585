import { finishSend, progressSend } from "../../../actions/LogicAction";
import { toast } from "react-toastify";
import { AxiosMasterGet } from "../../../Axios";
import { ToastAmbil } from "../../../actions/toast";
import { getUserID } from "../../../helper";
import { getToday } from "../../../GlobalComponent/function";
import LaporanPrintReturnSupplier from "../component/LaporanPrintReturnSupplier";
import LaporanPrintReturnSupplierRekap from "../component/LaporanPrintReturnSupplierRekap";

export const GET_LAPORAN_RETURN_SUPPLIER_DETAIL =
  "GET_LAPORAN_RETURN_SUPPLIER_DETAIL";

export const GetLaporanReturnSupplier = (
  type,
  kode_supplier,
  tanggal_awal,
  tanggal_akhir
) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    try {
      const response = await toast.promise(
        AxiosMasterGet(
          "report/retur-barang-supplier/" +
            `${type}&${kode_supplier}&${tanggal_awal}&${tanggal_akhir}`
        ),
        ToastAmbil
      );
      dispatch({
        type: GET_LAPORAN_RETURN_SUPPLIER_DETAIL,
        payload: {
          data: response.data,
          tanggal_awal: tanggal_awal,
          tanggal_akhir: tanggal_akhir,
        },
      });
      dispatch(finishSend());
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

export const PrintLaporanReturnSupplierDetailPDF = (
  tanggal_awal,
  tanggal_akhir
) => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanReturnSupplier = state.laporan.dataLaporanReturnSupplier;
    console.log(dataLaporanReturnSupplier);
    LaporanPrintReturnSupplier(
      tanggal_awal,
      tanggal_akhir,
      getUserID(),
      getToday(),
      getUserID(),
      dataLaporanReturnSupplier.data
    );
  };
};

export const PrintLaporanReturnSupplierRekapPDF = (
  tanggal_awal,
  tanggal_akhir
) => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanReturnSupplier = state.laporan.dataLaporanReturnSupplier;
    LaporanPrintReturnSupplierRekap(
      tanggal_awal,
      tanggal_akhir,
      getUserID(),
      getToday(),
      getUserID(),
      dataLaporanReturnSupplier.data
    );
  };
};

export const ResetLaporanReturnSupplier = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_LAPORAN_RETURN_SUPPLIER_DETAIL,
      payload: {
        data: [],
        tanggal_awal: "",
        tanggal_akhir: "",
      },
    });
  };
};
