import React from "react";
import { Link } from "react-router-dom";
import empty from "../Images/emptyTable.svg";

function EmptyTable(props) {
  return (
    <div className="col-lg-12">
      <div className="text-center">
        <img src={empty} width="250" height="250" alt="Empty"></img>
        <h5>{props.text}</h5>
        {props.link !== undefined ? (
          <Link to={props.link} replace>
            <button className="btn btn-primary">
              <i className="fa fa-chevron-left mr-2"></i>
              {props.location}
            </button>
          </Link>
        ) : null}
      </div>
    </div>
  );
}

export default EmptyTable;
