export const SET_PDF = "SET_PDF";

export const setPDF = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_PDF,
      payload: data,
    });
  };
};
