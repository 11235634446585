import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { getcustomerList } from "../customer/redux/customerAction";
import { getParameterReparasi } from "../../actions/parameterReparasiAction";
import { berhasil, gagal } from "../../Alert";
import { AxiosMasterPost } from "../../Axios";
import GlobalTabel from "../../GlobalComponent/GlobalTabel";

class FormDuplikatReparasi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          dataField: "kode_customer",
          text: "Kode Customer",
        },
        {
          dataField: "nama_customer",
          text: "Nama Customer",
        },
        {
          dataField: "nama_toko",
          text: "Nama Toko",
        },
      ],
    };
  }

  componentDidMount() {
    this.props.dispatch(getcustomerList());
  }

  kirimDuplikat() {
    const listDuplikasi = JSON.parse(
      localStorage.getItem("list_duplikasi_reparasi_temp")
    );
    listDuplikasi.map((data, index) => {
      if (index === listDuplikasi.length - 1) {
        AxiosMasterPost("ongkos-reparasi/add", data)
          .then(() => berhasil("Berhasil Disimpan"))
          .then(() => this.props.dispatch(getParameterReparasi()))
          .then(() =>
            this.setState({
              edit: false,
            })
          )
          .catch((err) =>
            gagal(`Gagal Menyimpan Data, Detail : ${err.response.data}`)
          );
        return false;
      }
      AxiosMasterPost("ongkos-reparasi/add", data).catch((err) =>
        gagal(`Gagal Menyimpan Data, Detail : ${err.response.data}`)
      );
      return true;
    });
  }

  render() {
    const selectRow = {
      mode: "checkbox",
      clickToSelect: false,
      onSelect: (row, isSelect, rowIndex, e) => {
        let array =
          JSON.parse(localStorage.getItem("list_duplikasi_reparasi_temp")) ||
          [];
        const data = {
          kode_customer: row.kode_customer,
          kode_reparasi: this.props.duplikatReparasi.kode_reparasi,
          ongkos_reparasi: this.props.duplikatReparasi.ongkos_reparasi,
          type_reparasi: this.props.duplikatReparasi.type_reparasi,
        };
        if (isSelect) {
          var index1 = array.findIndex((item, i) => {
            return item.kode_customer === row.kode_customer;
          });
          if (index1 < 0) {
            array.push(data);
          } else {
            array.splice(index1, 1);
          }
          localStorage.setItem(
            "list_duplikasi_reparasi_temp",
            JSON.stringify(array)
          );
        } else {
          var index = array.findIndex((item, i) => {
            return item.kode_customer === row.kode_customer;
          });
          array.splice(index, 1);
          localStorage.setItem(
            "list_duplikasi_reparasi_temp",
            JSON.stringify(array)
          );
        }
      },
      onSelectAll: (isSelect, rows, e) => {
        var array = [];
        let prevData = this.props.duplikatReparasi;
        rows.forEach(function (list) {
          const data = {
            kode_customer: list.kode_customer,
            kode_reparasi: prevData.kode_reparasi,
            ongkos_reparasi: prevData.ongkos_reparasi,
            type_reparasi: prevData.type_reparasi,
          };
          array.push(data);
        });
        if (isSelect) {
          localStorage.setItem(
            "list_duplikasi_reparasi_temp",
            JSON.stringify(array)
          );
        } else {
          localStorage.removeItem("list_duplikasi_reparasi_temp");
        }
      },
    };
    return (
      <div className="col-lg-12">
        <p>
          **Silahkan pilih customer yang ingin di duplikasi dengan cara
          mencentang box yang ada di sebelah kiri, lalu tekan simpan
        </p>
        <div className="row">
          <GlobalTabel
            columns={this.state.columns}
            keyField="kode_customer"
            data={this.props.listCustomer}
            selectRow={selectRow}
          />
        </div>
        <div className="row col-lg-12 justify-content-end">
          <button
            className="btn btn-primary"
            onClick={() => this.kirimDuplikat()}
            type="button"
          >
            Simpan
          </button>
        </div>
      </div>
    );
  }
}

const data = reduxForm({
  form: "FormDuplikatReparasi",
})(FormDuplikatReparasi);

export default connect((state) => {
  return {
    listCustomer: state.provinsi.customer,
    duplikatReparasi: state.provinsi.duplikat_reparasi,
  };
})(data);
