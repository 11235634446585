import { AxiosMasterGet } from "../Axios";

export const GET_MENU_LIST = "GET_MENU_LIST";

// const server = process.env.REACT_APP_BACKEND_URL;
export const getMenuList = () => {
  return (dispatch) => {
    AxiosMasterGet("http://localhost:3001/menu")
      .then((res) =>
        dispatch({
          type: GET_MENU_LIST,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_MENU_LIST,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};
