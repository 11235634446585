import {
  AxiosMasterDelete,
  AxiosMasterGet,
  AxiosMasterPost,
  AxiosMasterPut,
} from "../../../Axios";
import { finishSend, progressSend } from "../../../actions/LogicAction";
import { toast } from "react-toastify";
import { ToastKirim } from "../../../actions/toast";
import { hideModal } from "../../../actions/ModalAction";
import { reset } from "redux-form";
import Swal from "sweetalert2";

export const GET_BANK_LIST = "GET_BANK_LIST";
export const EDIT_BANK_LIST = "EDIT_BANK_LIST";

export const getBankList = () => {
  return (dispatch) => {
    AxiosMasterGet("bank")
      .then((res) =>
        dispatch({
          type: GET_BANK_LIST,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_BANK_LIST,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const editBank = (kodeBank, namaBank) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_BANK_LIST,
      payload: {
        kodeBank: kodeBank,
        namaBank: namaBank,
      },
    });
  };
};

export const sendBank = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.DataBank.values;
    let data = {
      kode_bank: selected.kodeBank,
      nama_bank: selected.namaBank,
    };
    try {
      await toast.promise(AxiosMasterPost("bank/add", data), ToastKirim);
    } catch (err) {
      dispatch(getBankList());
    }
    dispatch(finishSend());
    dispatch(getBankList());
    dispatch(reset("DataBank"));
    dispatch(hideModal());
  };
};

export const sendEditBank = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.FormBankEdit.values;
    let data = {
      nama_bank: selected.namaBank,
    };
    try {
      await toast.promise(
        AxiosMasterPut("bank/" + selected.kodeBank, data),
        ToastKirim
      );
      dispatch(finishSend());
      dispatch(getBankList());
      dispatch(reset("FormBankEdit"));
      dispatch(hideModal());
    } catch (err) {
      dispatch(finishSend());
      dispatch(getBankList());
      dispatch(reset("FormBankEdit"));
      dispatch(hideModal());
    }
  };
};

export const deleteBank = (kode) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    Swal.fire({
      title: "Anda Yakin ?",
      text: "Anda Akan Menghapus Data Ini ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          dispatch(progressSend());
          await toast.promise(AxiosMasterDelete("bank/" + kode), ToastKirim);
          dispatch(finishSend());
          dispatch(getBankList());
          dispatch(reset("ProvinsiEdit"));
          dispatch(hideModal());
        } catch (e) {
          dispatch(finishSend());
        }
      }
    });
    dispatch(finishSend());
  };
};
