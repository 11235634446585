import {
  GET_SUM_BRUTO,
  GET_SUM_GROSS,
  GET_SUM_K,
  GET_SUM_NETTO,
  GET_SUM_P,
  GET_SUM_PKG,
  GET_SUM_QTY,
  GET_SUM_RP,
} from "../actions/LogicAction";

const initialState = {
  sumBruto: 0,
  sumNetto: 0,
  sumNettoExt: 0,
  sumGross: 0,
  sumRp: 0,
  sumQty: 0,
  sumK: 0,
  sumP: 0,
  sumPkg: 0,
};

const perhitungan = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUM_BRUTO:
      return {
        ...state,
        sumBruto: action.payload.data,
      };
    case GET_SUM_NETTO:
      return {
        ...state,
        sumNetto: action.payload.data,
      };
    case GET_SUM_GROSS:
      return {
        ...state,
        sumGross: action.payload.data,
      };
    case GET_SUM_RP:
      return {
        ...state,
        sumRp: action.payload.data,
      };
    case GET_SUM_P:
      return {
        ...state,
        sumP: action.payload.data,
      };
    case GET_SUM_K:
      return {
        ...state,
        sumK: action.payload.data,
      };
    case GET_SUM_QTY:
      return {
        ...state,
        sumQty: action.payload.data,
      };
    case GET_SUM_PKG:
      return {
        ...state,
        sumPkg: action.payload.data,
      };
    default:
      return state;
  }
};

export default perhitungan;
