import React, { Component, lazy, Suspense } from "react";

import { save24KTemp, sendTambah24K } from "./redux/kirim-bahan-24k-action";
import { connect } from "react-redux";
import { getUUID } from "../../actions/fakturAction";
import { warning } from "../../Alert";
import HeadTambah24K from "./component/HeadKirimBahan24K";
import Skeleton from "react-loading-skeleton";
import HeaderCard from "../../HeaderCard";
import { getList24K } from "../../DataMaster/24k/redux/24kAction";
import { multipleDeleteLocal } from "../../helper";
import { getListRosok } from "../../DataMaster/ciok-rosok/redux/rosokAction";

const FormKirimBahan24K = lazy(() => import("./component/FormKirimBahan24K"));
const ModalMasterData = lazy(() =>
  import("../../DataMaster/Component/ModalMasterData")
);

const maptostate = (state) => {
  return {
    listBarang: state.provinsi.mutasiBarang,
    noFaktur: state.provinsi.noFaktur,
    username: state.provinsi.username,
    sumBruto: state.perhitungan.sumBruto,
    sumNetto: state.perhitungan.sumBruto,
    sumPkg: state.perhitungan.sumPkg,
    sumQty: state.perhitungan.sumQty,
    sumGross: state.perhitungan.sumGross,
    onSend: state.provinsi.onSend,
  };
};

class KirimBahan24K extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lokasi: [],
      kodeJenis: "",
      kodeJenisTujuan: "",
      noMutasi: "",
      kodeLokasi: "",
      tanggal: "",
      keterangan: "",
      kategori: "",
      noBon: "",
    };
  }

  componentDidMount() {
    multipleDeleteLocal(["24KTemp"]);
    this.props.dispatch(getListRosok());
    this.props.dispatch(getUUID());
    this.props.dispatch(getList24K());
  }

  handleSubmit() {
    this.props.dispatch(save24KTemp());
  }
  onChange = (input) => (e) => {
    this.setState({
      [input]: e.target.value,
    });
  };

  submitTambah24K() {
    if (localStorage.getItem("24KTemp") === null) {
      warning("Mohon Tambahkan Barang Yang akan dipindahkan");
      return false;
    }
    this.props.dispatch(sendTambah24K());
  }

  render() {
    return (
      <div className="container-fluid" style={{ color: "black" }}>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <HeaderCard title="Kirim Bahan 24K" />
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12">
                    <HeadTambah24K
                      onSubmit={(data) => this.submitTambah24K(data)}
                      onSend={this.props.onSend}
                      noFaktur={this.props.noFaktur}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalMasterData
          content={
            <Suspense
              fallback={<Skeleton width={"100%"} height={50} count={5} />}
            >
              <FormKirimBahan24K onSubmit={(data) => this.handleSubmit(data)} />
            </Suspense>
          }
          title="Tambah Data Barcode Sample"
        />
      </div>
    );
  }
}

export default connect(maptostate, null)(KirimBahan24K);
