import React, { useEffect } from "react";
import HeaderCard from "../../HeaderCard";
import GlobalTabel from "../../GlobalComponent/GlobalTabel";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import {
  formatGram,
  getBon,
  multipleDeleteLocal,
  removeComma,
} from "../../helper";
import { change, Field, reduxForm } from "redux-form";
import { renderField, renderFieldSelect } from "../../renderField";
import {
  deleteKirimStockBayar,
  getKirimStockBayarTemp,
  saveKirimStockBayarTemp,
  sendKirimStockBayar,
} from "./redux/kirim_stock_bayar_action";
import FormKirimStockBayar from "./component/FormKirimStockBayar";
import { getListRosok } from "../../DataMaster/ciok-rosok/redux/rosokAction";

const KirimStockBayar = () => {
  const dispatch = useDispatch();
  let onSend = useSelector((state) => state.provinsi.onSend);
  const listItemTemp = useSelector(
    (state) => state.kirimStockBayar.listItemTemp
  );

  const columns = [
    {
      dataField: "",
      text: "No",
      align: "left",
      headerAlign: "left",
      footerAlign: "left",
      formatter: (cell, row, index) => ++index,
      footer: (columnData, column, columnIndex) => columnData.length,
    },
    {
      dataField: "kode_ciok_rosok",
      text: "Kode",
      footer: "",
    },
    {
      dataField: "kadar_cetak",
      text: "Kadar",
      align: "right",
      headerAlign: "right",
      footer: "",
    },
    {
      dataField: "bruto",
      text: "Bruto",
      align: "right",
      headerAlign: "right",
      formatter: (data) => formatGram(data),
      footerAlign: "right",
      footer: (columnData) =>
        formatGram(
          columnData.reduce(
            (acc, item) =>
              parseFloat(removeComma(acc)) + parseFloat(removeComma(item)),
            0
          )
        ),
    },
    {
      dataField: "kadar_beli",
      text: "harga",
      align: "right",
      headerAlign: "right",
      footer: "",
    },
    {
      dataField: "netto",
      text: "Netto",
      align: "right",
      headerAlign: "right",
      formatter: (data) => formatGram(data),
      footerAlign: "right",
      footer: (columnData) =>
        formatGram(
          columnData.reduce(
            (acc, item) =>
              parseFloat(removeComma(acc)) + parseFloat(removeComma(item)),
            0
          )
        ),
    },
    {
      dataField: "Aksi",
      text: "Aksi",
      csvExport: false,
      formatter: (rowContent, rows, rowIndex) => {
        return (
          <div className="justify-content-center row">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <i className="fa fa-ellipsis-v" aria-hidden="true" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    dispatch(deleteKirimStockBayar(rows));
                  }}
                >
                  Hapus Barang <i className="fa fa-trash ml-2" />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    multipleDeleteLocal(["kirimStockBayarTemp"]);
    dispatch(getKirimStockBayarTemp());
    dispatch(change("KirimStockBayar", "no_transaksi", "KP" + getBon()));
  }, [dispatch]);

  return (
    <div className="container-fluid" style={{ color: "black" }}>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <HeaderCard title="Kirim Stock Bayar" />
            <div className="card-body">
              <div className="col-lg-12 col-md-12 row">
                <div className={"col-lg-3"}>
                  <Field
                    name={"no_transaksi"}
                    label={"Nomor Transaksi"}
                    component={renderField}
                    normalize={(data) => data && data.toUpperCase()}
                    readOnly
                  />
                </div>
                <div className={"col-lg-3"}>
                  <Field
                    name={"jenis_stock"}
                    label={"Jenis Stock"}
                    component={renderFieldSelect}
                    onChange={(data) => dispatch(getListRosok(data.value))}
                    options={[
                      {
                        value: "ROSOK",
                        name: "ROSOK",
                      },
                      {
                        value: "CIOK",
                        name: "CIOK",
                      },
                    ]}
                    disabled={listItemTemp.length > 0}
                  />
                </div>
                <div className={"col-lg-3"}>
                  <Field
                    name={"tujuan_stock"}
                    label={"Tujuan Stock"}
                    component={renderFieldSelect}
                    options={[
                      {
                        value: "RECYCLE",
                        name: "RECYCLE",
                      },
                    ]}
                    disabled={listItemTemp.length > 0}
                  />
                </div>
                <div className={"col-lg-3"}>
                  <Field
                    name={"keterangan"}
                    label={"Keterangan"}
                    component={renderField}
                  />
                </div>
                <div className={"col-lg"}>
                  <hr />
                  <FormKirimStockBayar
                    onSubmit={(data) => dispatch(saveKirimStockBayarTemp(data))}
                  />
                </div>
                <div className={"col-lg-12"}>
                  <GlobalTabel
                    keyField="no"
                    data={listItemTemp}
                    columns={columns}
                    pagination={false}
                  />
                </div>
                <div className={"row justify-content-end col-lg-12"}>
                  <div className={"col-lg-2"}>
                    <button
                      type="button"
                      disabled={onSend}
                      className="btn btn-primary form-control"
                      onClick={() => dispatch(sendKirimStockBayar())}
                    >
                      {onSend ? (
                        <span
                          className="spinner-border spinner-border-sm mr-3"
                          aria-hidden="true"
                        />
                      ) : null}
                      Simpan
                      <i className="fa fa-paper-plane ml-3"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default reduxForm({
  form: "KirimStockBayar",
})(KirimStockBayar);
