import { change } from "redux-form";
import { AxiosMasterGet } from "../Axios"

export const GET_LIST_KIRIM_STOCK = "GET LIST KIRIM STOCK";
export const GET_KIRIM_STOCK_VALID = "GET_KIRIM_STOCK_VALID";
export const SET_BRUTO_EDIT_BARANG_KIRIM_STOCK = "SET_BRUTO_EDIT_BARANG_KIRIM_STOCK"

export const getListKirimStock = () => {
    const data = JSON.parse(localStorage.getItem("kirimStock"))
    return (dispatch) => {
        dispatch({
            type : GET_LIST_KIRIM_STOCK,
            payload : {
                data : data
            }
        })
    }
}

export const getKirimStockValid = () => {
    return (dispatch) => {
      AxiosMasterGet("kirim-stock/get/UnValidAll")
        .then((res) =>
          dispatch({
            type: GET_KIRIM_STOCK_VALID,
            payload: {
              data: res.data,
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          })
        )
        .catch((err) =>
          dispatch({
            type: GET_KIRIM_STOCK_VALID,
            payload: {
              data: false,
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
              error: true,
            },
          })
        );
    };
  };


export const setEditKirimStock = (data) => {
    return (dispatch) => {
      dispatch(change("HeadKirimStockEdit", "tanggal", data.tanggal))
      dispatch(change("HeadKirimStockEdit", "kode_kategori", data.kode_kategori))
      dispatch(change("HeadKirimStockEdit", "kode_customer", data.kode_customer))
      dispatch(change("HeadKirimStockEdit", "no_kirim", data.no_kirim))
      dispatch(change("HeadKirimStockEdit", "no_bon", data.no_bon))
      const detail_barang = data.detail_barang;
      localStorage.setItem("kirimStock", JSON.stringify(detail_barang))
    }
  }

  export const setBrutoKirimStock = (data, kode_model) => {
    return (dispatch) => {
      dispatch({
        type : SET_BRUTO_EDIT_BARANG_KIRIM_STOCK,
        payload : {
            data : data,
            kode_model: kode_model
        }
      })
    }
  }