export const GET_JENIS_LABEL = "GET_JENIS_LABEL";

export const getJenisLabel = () => {
  let jenis_label = localStorage.getItem("jenis_label") || "";
  return (dispatch) => {
    dispatch({
      type: GET_JENIS_LABEL,
      payload: {
        jenis_label: jenis_label,
      },
    });
  };
};
