export const GET_DATA_KIRIM_MASAK = "GET_DATA_KIRIM_MASAK";

export const getListKirimMasak = () => {
  return (dispatch) => {
    dispatch({
      type: GET_DATA_KIRIM_MASAK,
      payload: {
        data: JSON.parse(localStorage.getItem("kirimMasak")),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};
