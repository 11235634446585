const validationRosok = (value) => {
    const errors = {}
    if (!value.kodeKategori) {
        errors.kodeKategori = "Tidak Boleh kosong"
    } else if (value.kodeKategori === "DEFAULT"){
        errors.kodeKategori = "Pilih Terlebih dahulu";
    }
    if (!value.kodeJenis) {
        errors.kodeJenis = "Tidak Boleh kosong"
    } else if (value.kodeJenis === "DEFAULT"){
        errors.kodeJenis = "Pilih Terlebih dahulu";
    }
    if (!value.kadarBeli) {
        errors.kadarBeli = "Tidak Boleh kosong"
    } else if (value.kadarBeli === "DEFAULT"){
        errors.kadarBeli = "Pilih Terlebih dahulu";
    }
    if (!value.kadarJual) {
        errors.kadarJual = "Tidak Boleh kosong"
    } else if (value.kadarJual === "DEFAULT"){
        errors.kadarJual = "Pilih Terlebih dahulu";
    }
    if (!value.kadarCetak) {
        errors.kadarCetak = "Tidak Boleh kosong"
    } else if (value.kadarCetak === "DEFAULT"){
        errors.kadarCetak = "Pilih Terlebih dahulu";
    }
    return errors
}

export default validationRosok;