import { AxiosMasterGet } from "../Axios";

export const GET_PARAMETER_REPARASI = "GET_PARAMETER_REPARASI";
export const SET_DUPLIKAT_REPARASI = "SET_DUPLIKAT_REPARASI";

export const getParameterReparasi = () => {
  return (dispatch) => {
    AxiosMasterGet("ongkos-reparasi/get/all")
      .then((res) =>
        dispatch({
          type: GET_PARAMETER_REPARASI,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_PARAMETER_REPARASI,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const setDuplikatReparasi = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_DUPLIKAT_REPARASI,
      payload: {
        data: data,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};
