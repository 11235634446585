import React, { lazy, Suspense } from "react";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import laporanTimbangStock from "./Laporan/laporanTimbangStock";
import FormLaporanPackingList from "./Supervisory/CetakUlangForm/FormLaporanPackingList";
import KirimStock from "./Stocking/KirimStock";
import DaftarBank from "./DataMaster/bank/DaftarBank";
import PengambilanCiok from "./FICO/PengambilanCiok";
import Reparasi from "./DataMaster/Reparasi";
import ParameterReparasi from "./DataMaster/ParameterReparasi";
import laporanValidasi from "./Laporan/laporanValidasi";
import ClosePenjualan from "./Transaksi/close_penjualan";
import KirimStockBayar from "./Transaksi/kirim_stock_bayar";
import KirimStockRetur from "./Transaksi/kirim_stock_retur";
import CorrectionNote from "./FICO/correction_note";
import laporanSampel from "./Laporan/laporanSampel";
import SaldoAwal24K from "./DataMaster/saldo-awal-24k/SaldoAwal24K";
import Master24K from "./DataMaster/24k/Master24K";
import Tambah24K from "./Stocking/tambah-24k/Tambah24K";
import TransaksiPenjualan24K from "./Transaksi/penjualan-24K/TransaksiPenjualan24K";
import KirimBahan24K from "./Stocking/kirim-bahan-24k/KirimBahan24K";
import Laporan24K from "./Laporan/laporan24K";
import ParameterHargaEmas from "./DataMaster/parameter-harga-emas/ParameterHargaEmas";
import { PdfViewer } from "./pdfViewer";
const TimbangStock = lazy(() => import("./TotalanMenu/TimbangStock"));
const DataSample = lazy(() => import("./Sample/data-sample/DataSample"));
const HancurSample = lazy(() => import("./Sample/hancur-sample/HancurSample"));
const CetakBarcodeSample = lazy(() =>
  import("./Sample/cetak-barcode/CetakBarcode")
);
const PindahSample = lazy(() => import("./Sample/pindah-sample/PindahSample"));
const PackingBarang = lazy(() =>
  import("./Stocking/packing-list/PackingBarang")
);
const TransaksiReparasi = lazy(() => import("./Transaksi/TransaksiReparasi"));
const validasiReparasi = lazy(() => import("./Validasi/validasiReparasi"));
const SelesaiReparasi = lazy(() => import("./Reparasi/SelesaiReparasi"));
const laporanReparasi = lazy(() => import("./Laporan/laporanReparasi"));
const SerahReparasi = lazy(() => import("./Reparasi/SerahReparasi"));
const PembayaranCustomer = lazy(() =>
  import("./FICO/pembayaran-customer/PembayaranCustomer")
);
const CetakBarcode = lazy(() => import("./Supervisory/CetakBarcode"));
const TitipanCustomer = lazy(() =>
  import("./FICO/tambah-titipan-customer/TitipanCustomer")
);
const AmbilTitipanCustomer = lazy(() =>
  import("./FICO/ambil-titipan-customer/AmbilTitipanCustomer")
);
const AmbilTitipanSupplier = lazy(() =>
  import("./FICO/ambil-titipan-supplier/AmbilTitipanSupplier")
);
const TolakReturnCustomer = lazy(() =>
  import("./Transaksi/TolakReturnCustomer")
);
const PreOrder = lazy(() => import("./Transaksi/PreOrder"));
const validasiPO = lazy(() => import("./Validasi/validasiPO"));
const PackingList = lazy(() => import("./Transaksi/PackingList"));
const CetakBarcodeModel = lazy(() => import("./Supervisory/CetakBarcodeModel"));
const FormLaporanTolakanCustomer = lazy(() =>
  import("./Supervisory/CetakUlangForm/FormLaporanTolakanCustomer")
);
const KelompokHarga = lazy(() => import("./DataMaster/KelompokHarga"));
const BarangSet = lazy(() => import("./DataMaster/BarangSet"));
const EditProfile = lazy(() => import("./EditProfile"));
const SaldoAwalHutang = lazy(() =>
  import("./DataMaster/saldo-awal-hutang/SaldoAwalHutang")
);
const SaldoAwalPiutangCustomer = lazy(() =>
  import("./DataMaster/saldo-awal-piutang/SaldoAwalPiutangCustomer")
);
const SaldoAwalCT = lazy(() =>
  import("./DataMaster/saldo-awal-ct/SaldoAwalCT")
);
const SaldoAwalStok = lazy(() =>
  import("./DataMaster/saldo-awal-stock/SaldoAwalStok")
);
const CloseTransaksiPenjualan = lazy(() =>
  import("./Transaksi/CloseTransaksiPenjualan")
);
const KelolaAkses = lazy(() => import("./DataMaster/KelolaAkses"));
const EditForm = lazy(() => import("./Stocking/Component/EditForm"));
const Pengaturan = lazy(() => import("./Pengaturan"));
const ValidasiStokJualLM = lazy(() => import("./Totalan/ValidasiStokJualLM"));
const ValidasiStokBeliLM = lazy(() => import("./Totalan/ValidasiStokBeliLM"));
const PreviewNota = lazy(() => import("./PreviewNota"));
const FormLaporanPenerimaanSupplier = lazy(() =>
  import("./Supervisory/CetakUlangForm/FormLaporanPenerimaanSupplier")
);
const FormLaporanHutangSupplier = lazy(() =>
  import("./Supervisory/CetakUlangForm/FormLaporanHutangSupplier")
);
const FormLaporanReturnSupplier = lazy(() =>
  import("./Supervisory/CetakUlangForm/FormLaporanReturnSupplier")
);
const FormLaporanTolakanSupplier = lazy(() =>
  import("./Supervisory/CetakUlangForm/FormLaporanTolakanSupplier")
);
const FormLaporanTitipanSupplier = lazy(() =>
  import("./Supervisory/CetakUlangForm/FormLaporanTitipanSupplier")
);
const FormLaporanPindahBarang = lazy(() =>
  import("./Supervisory/CetakUlangForm/FormLaporanPindahBarang")
);
const FormLaporanMutasiBarang = lazy(() =>
  import("./Supervisory/CetakUlangForm/FormLaporanMutasiBarang")
);
const FormLaporanBuktiPenjualan = lazy(() =>
  import("./Supervisory/CetakUlangForm/FormLaporanBuktiPenjualan")
);
const FormLaporanReturnCustomer = lazy(() =>
  import("./Supervisory/CetakUlangForm/FormLaporanReturnCustomer")
);
const FormLaporanTItipCustomer = lazy(() =>
  import("./Supervisory/CetakUlangForm/FormLaporanTItipCustomer")
);
const FormLaporanSetoranCustomer = lazy(() =>
  import("./Supervisory/CetakUlangForm/FormLaporanSetoranCustomer")
);
const FormLaporanPembayaranPiutangCustomer = lazy(() =>
  import("./Supervisory/CetakUlangForm/FormLaporanPembayaranPiutangCustomer")
);
const FormLaporanSaldoAwal = lazy(() =>
  import("./Supervisory/CetakUlangForm/FormLaporanSaldoAwal")
);
const FormLaporanTolakanTimbangan = lazy(() =>
  import("./Supervisory/CetakUlangForm/FormLaporanTolakanTimbangan")
);
const FormLaporanBayarJualLM = lazy(() =>
  import("./Supervisory/CetakUlangForm/FormLaporanBayarJualLM")
);
const FormLaporanBayarBeliLM = lazy(() =>
  import("./Supervisory/CetakUlangForm/FormLaporanBayarBeliLM")
);
const FormLaporanCetakJualLM = lazy(() =>
  import("./Supervisory/CetakUlangForm/FormLaporanCetakJualLM")
);
const FormLaporanCetakBeliLM = lazy(() =>
  import("./Supervisory/CetakUlangForm/FormLaporanCetakBeliLM")
);
const FormLaporanTerimaMasak = lazy(() =>
  import("./Supervisory/CetakUlangForm/FormLaporanTerimaMasak")
);
const TransaksiPenjualanLM = lazy(() =>
  import("./Totalan/TransaksiPenjualanLM")
);
const TransaksiPembelianLM = lazy(() =>
  import("./Totalan/TransaksiPembelianLM")
);
const PembayaranPenjualanLM = lazy(() =>
  import("./Totalan/PembayaranPenjualanLM")
);
const PembayaranPembelianLM = lazy(() =>
  import("./Totalan/PembayaranPembelianLM")
);
const PembelianRongsok = lazy(() => import("./Totalan/PembelianRongsok"));
const TotalanTimbangan = lazy(() => import("./TotalanMenu/TotalanTimbangan"));
const TotalTimbangRosok = lazy(() => import("./TotalanMenu/TotalTimbangRosok"));
const TotalanBarcode = lazy(() => import("./TotalanMenu/TotalanBarcode"));
const SelisihTimbangan = lazy(() => import("./TotalanMenu/SelisihTimbangan"));
const SelisihTimbangRosok = lazy(() =>
  import("./TotalanMenu/SelisihTimbangRosok")
);
const CetakUlangAll = lazy(() => import("./Supervisory/CetakUlangAll"));
const HistoryUser = lazy(() => import("./Supervisory/HistoryUser"));
const FormGrade = lazy(() => import("./Supervisory/Components/FormGrade"));
const PembayaranSupplierSimpan = lazy(() =>
  import("./FICO/pembayaran-supplier/component/PembayaranSupplierSimpan")
);
const notFound = lazy(() => import("./notFound"));
const editTransaksiSupplier = lazy(() =>
  import("./Validasi/editTransaksiSupplier")
);
const validasiBarang = lazy(() => import("./Validasi/validasiBarang"));
const validasiSupplier = lazy(() => import("./Validasi/validasiSupplier"));
const validasiCustomer = lazy(() => import("./Validasi/validasiCustomer"));
const laporanKeuangan = lazy(() => import("./Laporan/laporanKeuangan"));
const LaporanSupplier = lazy(() => import("./Laporan/LaporanSupplier"));
const LaporanStok = lazy(() => import("./Laporan/LaporanStok"));
// const LaporanSalesman = lazy(() => import("./Laporan/LaporanSalesman"));
const LaporanCustomer = lazy(() => import("./Laporan/LaporanCustomer"));
const ManageUser = lazy(() => import("./DataMaster/ManageUser"));
const AddUser = lazy(() => import("./DataMaster/AddUser"));
const Parameter = lazy(() => import("./Supervisory/Parameter"));
const FormDataHargaEmas = lazy(() =>
  import("./Supervisory/Components/FormDataHargaEmas")
);
const FormPelunasan = lazy(() =>
  import("./Supervisory/Components/FormPelunasan")
);

const Dashboard = lazy(() => import("./Dashboard"));
const Provinsi = lazy(() => import("./DataMaster/provinsi/Provinsi"));
const Kota = lazy(() => import("./DataMaster/kota/Kota"));
const Area = lazy(() => import("./DataMaster/area/Area"));
const DaftarRekening = lazy(() =>
  import("./DataMaster/rekening/DaftarRekening")
);
const Lokasi = lazy(() => import("./DataMaster/lokasi/Lokasi"));
const Salesman = lazy(() => import("./DataMaster/sales/Salesman"));
const Supplier = lazy(() => import("./DataMaster/supplier/Supplier"));
const CabangSupplier = lazy(() =>
  import("./DataMaster/cabang-supplier/CabangSupplier")
);
const Customer = lazy(() => import("./DataMaster/customer/Customer"));
const Koderosok = lazy(() => import("./DataMaster/ciok-rosok/Koderosok"));
const Bahan = lazy(() => import("./DataMaster/bahan/Bahan"));
const SaldoAwal = lazy(() => import("./DataMaster/SaldoAwal"));
const Kadar = lazy(() => import("./DataMaster/kadar/Kadar"));
const KlasifikasiBarang = lazy(() => import("./DataMaster/KlasifikasiBarang"));
const ParameterTransaksi = lazy(() =>
  import("./DataMaster/parameter-transaksi/ParameterTransaksi")
);
const PindahBarang = lazy(() =>
  import("./Stocking/pindah-barang/PindahBarang")
);
const MutasiBarang = lazy(() =>
  import("./Stocking/mutasi-barang/MutasiBarang")
);
const MutasiCiok = lazy(() => import("./Stocking/MutasiCiok"));
const KirimMasak = lazy(() => import("./Stocking/KirimMasak"));
const TerimaMasak = lazy(() => import("./Stocking/TerimaMasak"));
const KirimBahan = lazy(() => import("./Stocking/KirimBahan"));
const TerimaSupplier = lazy(() =>
  import("./Transaksi/penerimaan-supplier/TerimaSupplier")
);
const ReturnSupplier = lazy(() =>
  import("./Transaksi/retur-supplier/ReturnSupplier")
);
const TolakanReturnSupplier = lazy(() =>
  import("./Transaksi/tolakan-supplier/TolakanReturnSupplier")
);
const PengambilanBarcod = lazy(() => import("./Transaksi/PengambilanBarcode"));
const PembayaranSupplier = lazy(() =>
  import("./FICO/pembayaran-supplier/PembayaranSupplier")
);
const Barang = lazy(() => import("./DataMaster/barang/Barang"));
const KodeBarang = lazy(() => import("./DataMaster/KodeBarang"));
const Kelompok = lazy(() => import("./DataMaster/kelompok/Kelompok"));
const ClosePengambilanBarcode = lazy(() =>
  import("./Transaksi/ClosePengambilanBarcode")
);
const TransaksiTimbangBarang = lazy(() =>
  import("./Transaksi/TransaksiTimbangBarang")
);
const BatalPengambilanTimbang = lazy(() =>
  import("./Transaksi/BatalPengambilanTimbang")
);
const TransaksiPenjualan = lazy(() =>
  import("./Transaksi/penjualan-customer/TransaksiPenjualan")
);
const ReturnPenjualan = lazy(() =>
  import("./Transaksi/retur-customer/ReturnPenjualan")
);
const RekapBonCustomer = lazy(() =>
  import("./Transaksi/rekap-bon-penjualan/RekapBonCustomer")
);
const KelolaUangBank = lazy(() =>
  import("./FICO/kelola-uang-bank/KelolaUangBank")
);
const KelolaUangKas = lazy(() =>
  import("./FICO/kelola-uang-cash/KelolaUangKas")
);
const MutasiCashDanBank = lazy(() =>
  import("./FICO/mutasi-cash-bank/MutasiCashDanBank")
);
const KlasifikasiSupplier = lazy(() => import("./FICO/KlasifikasiSupplier"));
const PembayaranHutangSupplier = lazy(() =>
  import("./FICO/PembayaranHutangSupplier")
);
const TolakanHutangSupplier = lazy(() =>
  import("./FICO/tolak-hutang-supplier/TolakaHutangSupplier")
);
const TitipanSupplier = lazy(() =>
  import("./FICO/tambah-titipan-supplier/TitipanSupplier")
);
const KlasifikasiCustomer = lazy(() => import("./FICO/KlasifikasiCustomer"));
// const TitipanGrCustomer = lazy(() => import("./FICO/TitipGrCustomer"));
// const PembayaranPerBon = lazy(() => import("./FICO/PembayaranPerBon"));
const TransferBalance = lazy(() => import("./FICO/TransferBalance"));
const TolakanPembayaranCustomer = lazy(() =>
  import("./FICO/tolak-pembayaran-customer/TolakanPembayaranCustomer")
);
const ValidasiTransferCustomer = lazy(() =>
  import("./FICO/ValidasiTransferCustomer")
);
const ValidasiPembayaranGiro = lazy(() =>
  import("./FICO/ValidasiPembayaranGiro")
);
const editPindahBarang = lazy(() => import("./Validasi/editPindahBarang"));
const editStok = lazy(() => import("./Validasi/editStok"));
const editPenerimaanSupplierNew = lazy(() =>
  import("./Validasi/editPenerimaanSupplierNew")
);
const CetakUlang = lazy(() => import("./Laporan/CetakUlang"));

const checkMenu = (data1) => {
  let akses = localStorage.getItem("level") || "OWN";
  if (akses === "OWN" || akses === "SU") {
    return true;
  } else {
    let check = localStorage.getItem("menu") || "kosong";
    if (check !== "kosong") {
      let data = JSON.parse(localStorage.getItem("menu")) || [];
      return data.find((list) => list.menu_id === data1);
    }
  }
};
export const RouteLink = () => {
  return (
    <Suspense
      fallback={
        <div className="container-fluid text-center">
          <Skeleton width={"100%"} height={500} />
        </div>
      }
    >
      <Switch>
        <Route exact path="/" component={Dashboard} />

        <Route exact path="/dashboard" component={Dashboard} />

        {checkMenu("Data Provinsi") !== undefined ? (
          <Route path="/provinsi" component={Provinsi} />
        ) : null}

        {checkMenu("Data Kota") !== undefined ? (
          <Route path="/kota" component={Kota} />
        ) : null}
        {checkMenu("Data Area") !== undefined ? (
          <Route path="/area" component={Area} />
        ) : null}
        {checkMenu("Daftar Rekening Bank") !== undefined ? (
          <Route path="/rekening" component={DaftarRekening} />
        ) : null}
        {checkMenu("Daftar Bank") !== undefined ? (
          <Route path="/bank" component={DaftarBank} />
        ) : null}
        {checkMenu("Data Lokasi") !== undefined ? (
          <Route path="/lokasi" component={Lokasi} />
        ) : null}
        {checkMenu("Data Salesman") !== undefined ? (
          <Route path="/salesman" component={Salesman} />
        ) : null}
        {checkMenu("Data Supplier") !== undefined ? (
          <Route path="/supplier" component={Supplier} />
        ) : null}
        {checkMenu("Cabang Supplier") !== undefined ? (
          <Route path="/cabangSupplier" component={CabangSupplier} />
        ) : null}
        {checkMenu("Data Customer") !== undefined ? (
          <Route path="/customer" component={Customer} />
        ) : null}
        {checkMenu("Kode Rongsok dan Ciok") !== undefined ? (
          <Route path="/koderosok" component={Koderosok} />
        ) : null}
        {checkMenu("Data Reparasi") !== undefined ? (
          <Route path="/masterreparasi" component={Reparasi} />
        ) : null}
        {checkMenu("Parameter Reparasi") !== undefined ? (
          <Route path="/parameterreparasi" component={ParameterReparasi} />
        ) : null}
        {checkMenu("Saldo Awal") !== undefined ? (
          <Route path="/saldoawal" component={SaldoAwal} />
        ) : null}
        {checkMenu("Data Kadar") !== undefined ? (
          <Route path="/kadar" component={Kadar} />
        ) : null}
        {checkMenu("Data Klasifikasi Barang") !== undefined ? (
          <Route path="/KlasifikasiBarang" component={KlasifikasiBarang} />
        ) : null}
        {checkMenu("Kode Baran") !== undefined ? (
          <Route path="/kodebarang" exact component={KodeBarang} />
        ) : null}
        {checkMenu("Klasifikasi Barang - Kode Barang") !== undefined ? (
          <Route path="/bahan" exact component={Bahan} />
        ) : null}
        {checkMenu("24K") !== undefined ? (
          <Route path="/master24k" exact component={Master24K} />
        ) : null}
        {checkMenu("Kelompok Harga") !== undefined ? (
          <Route path="/kelompokHarga" exact component={KelompokHarga} />
        ) : null}
        {checkMenu("Parameter Harga Emas") !== undefined ? (
          <Route path="/parameter-harga-emas" component={ParameterHargaEmas} />
        ) : null}
        {checkMenu("Parameter") !== undefined ? (
          <Route path="/parameter" component={ParameterTransaksi} />
        ) : null}
        {checkMenu("Pindah Barang") !== undefined ? (
          <Route path="/pindahBarang" component={PindahBarang} />
        ) : null}
        {checkMenu("Kirim Stock") !== undefined ? (
          <Route path="/kirimStock" component={KirimStock} />
        ) : null}
        {checkMenu("Barcode Sample") !== undefined ? (
          <Route path="/barcodeSample" component={MutasiBarang} />
        ) : null}
        {checkMenu("Tambah 24K") !== undefined ? (
          <Route path="/tambah24k" component={Tambah24K} />
        ) : null}
        {checkMenu("Kirim Bahan 24K") !== undefined ? (
          <Route path="/kirimBahan24k" component={KirimBahan24K} />
        ) : null}
        {checkMenu("Mutasi Ciok") !== undefined ? (
          <Route path="/mutasiciok" component={MutasiCiok} />
        ) : null}
        {checkMenu("Kirim Masak") !== undefined ? (
          <Route path="/kirimmasak" component={KirimMasak} />
        ) : null}
        {checkMenu("Terima Masak") !== undefined ? (
          <Route path="/terimamasak" component={TerimaMasak} />
        ) : null}

        <Route path="/kirimbahan" component={KirimBahan} />

        {checkMenu("Penerimaan Supplier") !== undefined ? (
          <Route path="/terimasupplier" component={TerimaSupplier} />
        ) : null}
        {checkMenu("Return Supplier") !== undefined ? (
          <Route path="/returnsupplier" component={ReturnSupplier} />
        ) : null}
        {checkMenu("Tolakan Return Supplier") !== undefined ? (
          <Route
            path="/tolakanreturnsupplier"
            component={TolakanReturnSupplier}
          />
        ) : null}
        {checkMenu("Transaksi Rekam Barang Barcode") !== undefined ? (
          <Route path="/ambilbarcode" component={PengambilanBarcod} />
        ) : null}
        {checkMenu("Klasifikasi Supplier - Pembayaran Hutang Supplier") !==
        undefined ? (
          <Route path="/bayarsupplier" component={PembayaranSupplier} />
        ) : null}

        <Route path="/bayarsupplierform" component={PembayaranSupplierSimpan} />

        {checkMenu("Klasifikasi Barang - Kategori") !== undefined ? (
          <Route path="/barang" exact component={Barang} />
        ) : null}
        {checkMenu("Klasifikasi Barang - Kelompok") !== undefined ? (
          <Route path="/kelompok" exact component={Kelompok} />
        ) : null}
        {checkMenu("Batal Pengambilan Timbang Barang") !== undefined ? (
          <Route
            path="/closePengambilanBarang"
            exact
            component={ClosePengambilanBarcode}
          />
        ) : null}
        {checkMenu("Transksi Timbang Barang") !== undefined ? (
          <Route
            path="/transaksiTimbangBarang"
            exact
            component={TransaksiTimbangBarang}
          />
        ) : null}
        {checkMenu("Batal Pengambilan Timbang Barang") !== undefined ? (
          <Route
            path="/batalPengambilanTimbangan"
            exact
            component={BatalPengambilanTimbang}
          />
        ) : null}
        {checkMenu("Transaksi Penjualan") !== undefined ? (
          <Route
            path="/TransaksiPenjualan"
            exact
            component={TransaksiPenjualan}
          />
        ) : null}
        {checkMenu("Transaksi Penjualan 24K") !== undefined ? (
          <Route
            path="/TransaksiPenjualan24K"
            exact
            component={TransaksiPenjualan24K}
          />
        ) : null}
        {checkMenu("Return Penjualan") !== undefined ? (
          <Route path="/ReturnPenjualan" exact component={ReturnPenjualan} />
        ) : null}
        {checkMenu("Close Penjualan") !== undefined ? (
          <Route path="/ClosePenjualan" exact component={ClosePenjualan} />
        ) : null}
        {checkMenu("Kirim Stock Bayar") !== undefined ? (
          <Route path="/KirimStockBayar" exact component={KirimStockBayar} />
        ) : null}
        {checkMenu("Kirim Stock Retur") !== undefined ? (
          <Route path="/KirimStockRetur" exact component={KirimStockRetur} />
        ) : null}
        {checkMenu("Klasifikasi Customer - Correction Note") !== undefined ? (
          <Route path="/CorrectionNote" exact component={CorrectionNote} />
        ) : null}
        {/* {checkMenu("Return Penjualan Non-Toko") !== undefined ? (
          <Route
            path="/ReturnPenjualanNonToko"
            exact
            component={ReturnPenjualanNonToko}
          />
        ) : null} */}
        {checkMenu("Tolakan Return Customer") !== undefined ? (
          <Route
            path="/TolakanReturnCustomer"
            exact
            component={TolakReturnCustomer}
          />
        ) : null}
        {checkMenu("Transaksi Reparasi") !== undefined ? (
          <Route
            path="/TranskasiReparasi"
            exact
            component={TransaksiReparasi}
          />
        ) : null}
        {checkMenu("Rekap Bon Penjualan") !== undefined ? (
          <Route path="/RekapBonPenjualan" exact component={RekapBonCustomer} />
        ) : null}
        {checkMenu("Kelola Uang Bank") !== undefined ? (
          <Route path="/KelolaUangBank" exact component={KelolaUangBank} />
        ) : null}
        {checkMenu("Kelola Uang Kas") !== undefined ? (
          <Route path="/KelolaUangKas" exact component={KelolaUangKas} />
        ) : null}
        {checkMenu("Mutasi Cash Dan Bank") !== undefined ? (
          <Route
            path="/MutasiCashDanBank"
            exact
            component={MutasiCashDanBank}
          />
        ) : null}
        {checkMenu("Klasifikasi Supplier") !== undefined ? (
          <Route
            path="/KlasifikasiSupplier"
            exact
            component={KlasifikasiSupplier}
          />
        ) : null}
        {checkMenu("Pembayaran Hutang Supplier") !== undefined ? (
          <Route
            path="/PembayaranHutangSupplier"
            exact
            component={PembayaranHutangSupplier}
          />
        ) : null}
        {checkMenu("Klasifikasi Supplier - Tolakan Hutang Supplier") !==
        undefined ? (
          <Route
            path="/TolakanHutangSupplier"
            exact
            component={TolakanHutangSupplier}
          />
        ) : null}
        {checkMenu("Klasifikasi Supplier - Tambah Titipan Supplier") !==
        undefined ? (
          <Route path="/TitipanSupplier" exact component={TitipanSupplier} />
        ) : null}
        {checkMenu("Klasifikasi Supplier - Ambil Titipan Supplier") !==
        undefined ? (
          <Route
            path="/AmbilTitipanSupplier"
            exact
            component={AmbilTitipanSupplier}
          />
        ) : null}
        {checkMenu("Klasifikasi Customer") !== undefined ? (
          <Route
            path="/KlasifikasiCustomer"
            exact
            component={KlasifikasiCustomer}
          />
        ) : null}
        {checkMenu("Klasifikasi Customer - Tambah Titipan Customer") !==
        undefined ? (
          <Route path="/TitipanGrCustomer" exact component={TitipanCustomer} />
        ) : null}
        {checkMenu("Klasifikasi Customer - Ambil Titipan Customer") !==
        undefined ? (
          <Route
            path="/TitipanGrCustomerAmbil"
            exact
            component={AmbilTitipanCustomer}
          />
        ) : null}
        {checkMenu("Klasifikasi Customer - Pembayaran Customer") !==
        undefined ? (
          <Route
            path="/PembayaranPerBon"
            exact
            component={PembayaranCustomer}
          />
        ) : null}
        {checkMenu("Pengamilan Ciok") !== undefined ? (
          <Route path="/PengambilanCiok" exact component={PengambilanCiok} />
        ) : null}
        {checkMenu("Klasifikasi Customer - Transfer Balance") !== undefined ? (
          <Route path="/TransferBalance" exact component={TransferBalance} />
        ) : null}
        {checkMenu("Klasifikasi Customer - Tolakan Pembayaran Customer") !==
        undefined ? (
          <Route
            path="/TolakanPembayaranCustomer"
            exact
            component={TolakanPembayaranCustomer}
          />
        ) : null}
        {checkMenu("Validasi Transfer") !== undefined ? (
          <Route
            path="/ValidasiTransferCustomer"
            exact
            component={ValidasiTransferCustomer}
          />
        ) : null}
        {checkMenu("Validasi Giro") !== undefined ? (
          <Route
            path="/ValidasiPembayaranGiro"
            exact
            component={ValidasiPembayaranGiro}
          />
        ) : null}

        <Route path="/editStok" exact component={editStok} />

        <Route path="/editPindahBarang" exact component={editPindahBarang} />

        <Route
          path="/editPenerimaanSupplierNew"
          exact
          component={editPenerimaanSupplierNew}
        />

        {checkMenu("Cetak Ulang Bukti") !== undefined ? (
          <Route path="/CetakUlang" exact component={CetakUlang} />
        ) : null}

        <Route
          path="/editTransaksiSupplier"
          exact
          component={editTransaksiSupplier}
        />

        {checkMenu("Validasi Barang") !== undefined ? (
          <Route path="/validasiBarang" exact component={validasiBarang} />
        ) : null}
        {checkMenu("Validasi Supplier") !== undefined ? (
          <Route path="/validasiSupplier" exact component={validasiSupplier} />
        ) : null}
        {checkMenu("Validasi Customer") !== undefined ? (
          <Route path="/validasiCustomer" exact component={validasiCustomer} />
        ) : null}
        {checkMenu("Validasi Reparasi") !== undefined ? (
          <Route path="/validasiReparasi" exact component={validasiReparasi} />
        ) : null}
        {checkMenu("Laporan Keuangan") !== undefined ? (
          <Route path="/laporanKeuangan" exact component={laporanKeuangan} />
        ) : null}
        {checkMenu("Laporan Supplier") !== undefined ? (
          <Route path="/laporanSupplier" exact component={LaporanSupplier} />
        ) : null}
        {checkMenu("laporan Stok") !== undefined ? (
          <Route path="/laporanStok" exact component={LaporanStok} />
        ) : null}
        {checkMenu("laporan Sampel") !== undefined ? (
          <Route path="/laporanSampel" exact component={laporanSampel} />
        ) : null}
        {checkMenu("laporan Customer") !== undefined ? (
          <Route path="/laporanCustomer" exact component={LaporanCustomer} />
        ) : null}
        {checkMenu("laporan Reparasi") !== undefined ? (
          <Route path="/laporanReparasi" exact component={laporanReparasi} />
        ) : null}
        {checkMenu("laporan 24K") !== undefined ? (
          <Route path="/laporan24K" exact component={Laporan24K} />
        ) : null}
        {checkMenu("laporan Timbang Stock") !== undefined ? (
          <Route
            path="/laporanTimbangStock"
            exact
            component={laporanTimbangStock}
          />
        ) : null}
        {checkMenu("laporan Validasi") !== undefined ? (
          <Route path="/laporanValidasi" exact component={laporanValidasi} />
        ) : null}
        {checkMenu("Manage user") !== undefined ? (
          <Route path="/manageUser" exact component={ManageUser} />
        ) : null}
        {checkMenu("Kelola Hak Akses") !== undefined ? (
          <Route path="/kelolaUser" exact component={AddUser} />
        ) : null}
        {checkMenu("Parameter Supervisory") !== undefined ? (
          <Route path="/parameterSupervisory" exact component={Parameter} />
        ) : null}
        {checkMenu("Parameter Harga Emas") !== undefined ? (
          <Route path="/dataHargaEmas" exact component={FormDataHargaEmas} />
        ) : null}
        {checkMenu("Parameter Pelunasan") !== undefined ? (
          <Route path="/parameterPelunasan" exact component={FormPelunasan} />
        ) : null}
        {checkMenu("Parameter Grade") !== undefined ? (
          <Route path="/parameterGrade" exact component={FormGrade} />
        ) : null}
        {checkMenu("Cetak History User") !== undefined ? (
          <Route path="/historyUser" exact component={HistoryUser} />
        ) : null}

        <Route path="/previewNota" exact component={PreviewNota} />

        {checkMenu("Cetak Ulang Bukti") !== undefined ? (
          <Route path="/cetakUlangBukti" exact component={CetakUlangAll} />
        ) : null}
        {checkMenu("Cetak Ulang - Penerimaann Supplier") !== undefined ? (
          <Route
            path="/cetakUlangBuktiPenerimaanSupplier"
            exact
            component={FormLaporanPenerimaanSupplier}
          />
        ) : null}
        {checkMenu("Cetak Ulang - Hutang Supplier") !== undefined ? (
          <Route
            path="/FormLaporanHutangSupplier"
            exact
            component={FormLaporanHutangSupplier}
          />
        ) : null}
        {checkMenu("Cetak Ulang - Return Supplier") !== undefined ? (
          <Route
            path="/FormLaporanReturnSupplier"
            exact
            component={FormLaporanReturnSupplier}
          />
        ) : null}
        {checkMenu("Cetak Barcode") !== undefined ? (
          <Route path="/CetakBarcode" exact component={CetakBarcode} />
        ) : null}
        {checkMenu("Cetak Barcode Model") !== undefined ? (
          <Route
            path="/CetakBarcodeModel"
            exact
            component={CetakBarcodeModel}
          />
        ) : null}
        {checkMenu("Cetak Ulang - Tolakan Supplier") !== undefined ? (
          <Route
            path="/FormLaporanTolakanSupplier"
            exact
            component={FormLaporanTolakanSupplier}
          />
        ) : null}
        {checkMenu("Laporan Titipan Supplier") !== undefined ? (
          <Route
            path="/FormLaporanTitipanSupplier"
            exact
            component={FormLaporanTitipanSupplier}
          />
        ) : null}
        {checkMenu("Laporan Pindah Barang") !== undefined ? (
          <Route
            path="/FormLaporanPindahBarang"
            exact
            component={FormLaporanPindahBarang}
          />
        ) : null}
        {checkMenu("Laporan Mutasi Barang") !== undefined ? (
          <Route
            path="/FormLaporanMutasiBarang"
            exact
            component={FormLaporanMutasiBarang}
          />
        ) : null}
        {checkMenu("Cetak Ulang - Bukti Penjualan") !== undefined ? (
          <Route
            path="/FormLaporanBuktiPenjualan"
            exact
            component={FormLaporanBuktiPenjualan}
          />
        ) : null}
        {checkMenu("Cetak Ulang - Return Customer") !== undefined ? (
          <Route
            path="/FormLaporanReturnCustomer"
            exact
            component={FormLaporanReturnCustomer}
          />
        ) : null}
        {checkMenu("Laporan Packing List") !== undefined ? (
          <Route
            path="/FormLaporanPackingList"
            exact
            component={FormLaporanPackingList}
          />
        ) : null}
        {checkMenu("Laporan TTolakan Return Customer") !== undefined ? (
          <Route
            path="/FormLaporanTolakanReturnCustomer"
            exact
            component={FormLaporanTolakanCustomer}
          />
        ) : null}
        {checkMenu("Laporan Titipan Customer") !== undefined ? (
          <Route
            path="/FormLaporanTitipCustomer"
            exact
            component={FormLaporanTItipCustomer}
          />
        ) : null}
        {checkMenu("Laporan Setoran Customer") !== undefined ? (
          <Route
            path="/FormLaporanSetoranCustomer"
            exact
            component={FormLaporanSetoranCustomer}
          />
        ) : null}
        {checkMenu("Laporan Pembayaran Piutang Customer") !== undefined ? (
          <Route
            path="/FormLaporanPembayaranPiutangCustomer"
            exact
            component={FormLaporanPembayaranPiutangCustomer}
          />
        ) : null}
        {checkMenu("Laporan Saldo Awal") !== undefined ? (
          <Route
            path="/FormLaporanSaldoAwal"
            exact
            component={FormLaporanSaldoAwal}
          />
        ) : null}
        {checkMenu("Laporan Tolakan Timbangan") !== undefined ? (
          <Route
            path="/FormLaporanTolakanTimbangan"
            exact
            component={FormLaporanTolakanTimbangan}
          />
        ) : null}
        {checkMenu("Laporan Bayar Jual LM") !== undefined ? (
          <Route
            path="/FormLaporanBayarJualLM"
            exact
            component={FormLaporanBayarJualLM}
          />
        ) : null}
        {checkMenu("Laporan Bayar Beli LM") !== undefined ? (
          <Route
            path="/FormLaporanBayarBeliLM"
            exact
            component={FormLaporanBayarBeliLM}
          />
        ) : null}
        {checkMenu("Laporan Cetak Jual LM") !== undefined ? (
          <Route
            path="/FormLaporanCetakJualLM"
            exact
            component={FormLaporanCetakJualLM}
          />
        ) : null}
        {checkMenu("Laporan Cetak Beli LM") !== undefined ? (
          <Route
            path="/FormLaporanCetakBeliLM"
            exact
            component={FormLaporanCetakBeliLM}
          />
        ) : null}
        {checkMenu("Laporan Terima Masak") !== undefined ? (
          <Route
            path="/FormLaporanTerimaMasak"
            exact
            component={FormLaporanTerimaMasak}
          />
        ) : null}
        {checkMenu("Jual LM") !== undefined ? (
          <Route
            path="/TransaksiPenjualanLM"
            exact
            component={TransaksiPenjualanLM}
          />
        ) : null}
        {checkMenu("Beli LM") !== undefined ? (
          <Route
            path="/TransaksiPembelianLM"
            exact
            component={TransaksiPembelianLM}
          />
        ) : null}
        {checkMenu("Bayar Jual LM") !== undefined ? (
          <Route
            path="/PembayaranPenjualanLM"
            exact
            component={PembayaranPenjualanLM}
          />
        ) : null}
        {checkMenu("Bayar Beli LM") !== undefined ? (
          <Route
            path="/PembayaranPembelianLM"
            exact
            component={PembayaranPembelianLM}
          />
        ) : null}

        <Route path="/PembelianRongsok" exact component={PembelianRongsok} />

        {checkMenu("Totalan Timbangan") !== undefined ? (
          <Route path="/TotalanTimbangan" exact component={TotalanTimbangan} />
        ) : null}
        {checkMenu("Timbangan Stock") !== undefined ? (
          <Route path="/TimbanganStock" exact component={TimbangStock} />
        ) : null}
        <Route path="/TotalTimbangRosok" exact component={TotalTimbangRosok} />

        {checkMenu("Totalan Barcode") !== undefined ? (
          <Route path="/TotalanBarcode" exact component={TotalanBarcode} />
        ) : null}
        {checkMenu("Selisih Timbangan") !== undefined ? (
          <Route path="/SelisihTimbangan" exact component={SelisihTimbangan} />
        ) : null}
        {checkMenu("Selisih Timbang Rongsok") !== undefined ? (
          <Route
            path="/SelisihTimbangRosok"
            exact
            component={SelisihTimbangRosok}
          />
        ) : null}
        {checkMenu("Validasi Stok Jual LM") !== undefined ? (
          <Route
            path="/ValidasiStokJualLM"
            exact
            component={ValidasiStokJualLM}
          />
        ) : null}
        {checkMenu("Hutang") !== undefined ? (
          <Route path="/SaldoAwalHutang" exact component={SaldoAwalHutang} />
        ) : null}
        {checkMenu("Piutang Customer") !== undefined ? (
          <Route
            path="/saldoAwalPiutangCustomer"
            exact
            component={SaldoAwalPiutangCustomer}
          />
        ) : null}
        {checkMenu("Stock") !== undefined ? (
          <Route path="/saldoAwalStock" exact component={SaldoAwalStok} />
        ) : null}
        {checkMenu("CT/CK") !== undefined ? (
          <Route path="/saldoAwalCIOK" exact component={SaldoAwalCT} />
        ) : null}
        {checkMenu("24K") !== undefined ? (
          <Route path="/saldoAwal24K" exact component={SaldoAwal24K} />
        ) : null}
        {checkMenu("Validasi Stok Beli LM") !== undefined ? (
          <Route
            path="/ValidasiStokBeliLM"
            exact
            component={ValidasiStokBeliLM}
          />
        ) : null}
        {checkMenu("Close Transaksi Penjualan") !== undefined ? (
          <Route
            path="/CloseTransaksiPenjualan"
            exact
            component={CloseTransaksiPenjualan}
          />
        ) : null}

        {checkMenu("Kelola Hak Akses") !== undefined ? (
          <Route path="/kelolaHakAkses" exact component={KelolaAkses} />
        ) : null}

        {checkMenu("Pre Order") !== undefined ? (
          <Route path="/PreOrder" exact component={PreOrder} />
        ) : null}

        {checkMenu("Packing List") !== undefined ? (
          <Route path="/PackingList" exact component={PackingList} />
        ) : null}

        {checkMenu("Validasi PO") !== undefined ? (
          <Route path="/validasiPO" exact component={validasiPO} />
        ) : null}

        {checkMenu("Barang Set") !== undefined ? (
          <Route path="/barangSet" exact component={BarangSet} />
        ) : null}
        {checkMenu("Selesai Reparasi") !== undefined ? (
          <Route path="/SelesaiReparasi" exact component={SelesaiReparasi} />
        ) : null}
        {checkMenu("Serah Reparasi") !== undefined ? (
          <Route path="/SerahReparasi" exact component={SerahReparasi} />
        ) : null}
        {checkMenu("Tambah Sample") !== undefined ? (
          <Route path="/addSample" exact component={DataSample} />
        ) : null}
        {checkMenu("Hancur Sample") !== undefined ? (
          <Route path="/hancurSample" exact component={HancurSample} />
        ) : null}
        {checkMenu("Cetak Barcode Sample") !== undefined ? (
          <Route
            path="/cetakBarcodeSample"
            exact
            component={CetakBarcodeSample}
          />
        ) : null}
        {checkMenu("Pindah Sample") !== undefined ? (
          <Route path="/pindahSample" exact component={PindahSample} />
        ) : null}
        {checkMenu("Barcode Barang") !== undefined ? (
          <Route path="/barcodeBarang" exact component={PackingBarang} />
        ) : null}
        <Route path="/EditForm/:from" exact component={EditForm} />

        <Route path="/pengaturan" exact component={Pengaturan} />

        <Route path="/NotaPrint" exact component={PreviewNota} />

        <Route path="/profile-edit" exact component={EditProfile} />

        <Route path="/preview-pdf" exact component={PdfViewer} />

        <Route path="*" exact component={notFound} />
      </Switch>
    </Suspense>
  );
};
