import { GET_LIST_BARANG_PO } from "../actions/preOrder";

const initialState = {
  barangPO_temp: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_BARANG_PO:
      return {
        barangPO_temp: action.payload.data,
      };
    default:
      return state;
  }
};

export default reducer;
