import { toast } from "react-toastify";
import {
  AxiosMasterDelete,
  AxiosMasterGet,
  AxiosMasterPost,
  AxiosMasterPut,
} from "../../../Axios";
import { ToastKirim } from "../../../actions/toast";
import { finishSend, progressSend } from "../../../actions/LogicAction";
import { reset } from "redux-form";
import { hideModal } from "../../../actions/ModalAction";
import Swal from "sweetalert2";
import { purifiedData } from "../../../helper";

export const GET_ALL_CABANG_SUPPLIER = "GET_AL_BARANG_SUPPLIER";
export const SET_EDIT_CABANG_SUPPLIER = "SET_EDIT_CABANG_SUPPLIER";

export const getAllCabangSupplier = () => {
  return async (dispatch, getState) => {
    try {
      const response = await AxiosMasterGet("cabang-supplier");
      dispatch({
        type: GET_ALL_CABANG_SUPPLIER,
        payload: response.data,
      });
    } catch (e) {
      console.log(e);
    }
  };
};
export const setCabangEdit = (row) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_EDIT_CABANG_SUPPLIER,
      payload: row,
    });
  };
};

export const sendCabangSupplier = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.FormCabangSupplier.values;
    purifiedData(selected);
    try {
      await toast.promise(
        AxiosMasterPost("cabang-supplier", selected),
        ToastKirim
      );
    } catch (err) {
      dispatch(getAllCabangSupplier());
    }
    dispatch(finishSend());
    dispatch(getAllCabangSupplier());
    dispatch(reset("FormCabangSupplier"));
    dispatch(hideModal());
  };
};

export const sendEditCabangSupplier = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.FormCabangSupplierEdit.values;
    purifiedData(selected);
    const kode = selected.kode_cabang_supplier;
    delete selected.kode_cabang_supplier;
    delete selected.kode_supplier;
    try {
      await toast.promise(
        AxiosMasterPut("cabang-supplier/" + kode, selected),
        ToastKirim
      );
      dispatch(finishSend());
      dispatch(getAllCabangSupplier());
      dispatch(reset("FormCabangSupplierEdit"));
      dispatch(hideModal());
    } catch (err) {
      dispatch(finishSend());
      dispatch(getAllCabangSupplier());
      dispatch(reset("FormCabangSupplierEdit"));
      dispatch(hideModal());
    }
  };
};

export const deleteCabangSupplier = (kode) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    Swal.fire({
      title: "Anda Yakin ?",
      text: "Anda Akan Menghapus Data Ini ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          dispatch(progressSend());
          await toast.promise(
            AxiosMasterDelete("cabang-supplier/" + kode),
            ToastKirim
          );
          dispatch(finishSend());
          dispatch(getAllCabangSupplier());
          dispatch(hideModal());
        } catch (e) {
          dispatch(finishSend());
        }
      }
    });
    dispatch(finishSend());
  };
};
