import React from "react";
import Logo from "./Images/logo_cadm.png";
import Logo_demo from "./Images/logo_ayu_demo.png";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { setLogout } from "./actions/titleAction";
import Avatar from "react-avatar";
import SelectSearch from "react-select-search";

const mapStateToProps = (state) => {
  return {
    title: state.title.title,
    username: state.provinsi.username,
  };
};
const listMenu = [
  {
    value: "/provinsi",
    name: "Data Provinsi",
  },
  {
    value: "/kota",
    name: "Data Kota",
  },
  {
    value: "/area",
    name: "Data Area",
  },
  {
    value: "/rekening",
    name: "Daftar Rekening Bank",
  },
  {
    value: "/lokasi",
    name: "Data Lokasi",
  },
  {
    value: "/salesman",
    name: "Data Salesman",
  },
  {
    value: "/supplier",
    name: "Data Supplier",
  },
  {
    value: "/customer",
    name: "Data Customer",
  },
  {
    value: "/kadar",
    name: "Data Kadar",
  },
  {
    value: "/KlasifikasiBarang",
    name: "Data Klasifikasi Barang",
  },
  {
    value: "/koderosok",
    name: "Kode Rongsok dan Ciok",
  },
  {
    value: "/parameter-harga-emas",
    name: "Parameter Harga Emas",
  },
  {
    value: "/parameter",
    name: "Data Transaksi",
  },
  {
    value: "/saldoawal",
    name: "Saldo Awal",
  },
  {
    value: "/manageUser",
    name: "Manage user",
  },
  {
    value: "/barcodeBarang",
    name: "Barcode Barang",
  },
  {
    value: "/barcodeSample",
    name: "Barcode Sample",
  },
  // {
  //   value: "/mutasiciok",
  //   name: "Mutasi Ciok",
  // },
  // {
  //   value: "/kirimmasak",
  //   name: "Kirim Masak",
  // },
  // {
  //   value: "/terimamasak",
  //   name: "Terima Masak",
  // },
  {
    value: "/terimasupplier",
    name: "Penerimaan Supplier",
  },
  {
    value: "/returnsupplier",
    name: "Return Supplier",
  },
  {
    value: "/tolakanreturnsupplier",
    name: "Tolakan Return Supplier",
  },
  {
    value: "/ambilbarcode",
    name: "Transaksi Rekam Barang Barcode",
  },
  {
    value: "/closePengambilanBarang",
    name: "Close Pengambilan Barang Barcode",
  },
  {
    value: "/transaksiTimbangBarang",
    name: "Transksi Timbang Barang",
  },
  {
    value: "/transaksiTimbangBarang",
    name: "Transksi Timbang Barang",
  },
  {
    value: "/batalPengambilanTimbangan",
    name: "Batal Pengambilan Timbang Barang",
  },
  {
    value: "/PreOrder",
    name: "Pre Order",
  },
  {
    value: "/TransaksiPenjualan",
    name: "Transaksi Penjualan",
  },
  {
    value: "/ReturnPenjualan",
    name: "Return Penjualan",
  },
  {
    value: "/RekapBonPenjualan",
    name: "Rekap Bon Penjualan",
  },
  {
    value: "/KelolaUangBank",
    name: "Kelola Uang Bank ",
  },
  {
    value: "/KelolaUangKas",
    name: "Kelola Uang Kas",
  },
  // {
  //   value: "/MutasiCashDanBank",
  //   name: "Mutasi Cash Dan Bank",
  // },
  {
    value: "/KlasifikasiSupplier",
    name: "Klasifikasi Supplier",
  },
  {
    value: "/KlasifikasiCustomer",
    name: "Klasifikasi Customer",
  },
  {
    value: "/ValidasiTransferCustomer",
    name: "Validasi Transfer",
  },
  {
    value: "/ValidasiPembayaranGiro",
    name: "Validasi Giro",
  },
  {
    value: "/validasiBarang",
    name: "Validasi Barang",
  },
  {
    value: "/validasiSupplier",
    name: "Validasi Supplier",
  },
  {
    value: "/validasiCustomer",
    name: "Validasi Customer",
  },
  {
    value: "/laporanKeuangan",
    name: "Laporan Keuangan",
  },
  {
    value: "/laporanSupplier",
    name: "Laporan Supplier",
  },
  {
    value: "/laporanStok",
    name: "laporan Stok",
  },
  // {
  //   value: "/laporanSalesman",
  //   name: "laporan Salesman",
  // },
  {
    value: "/laporanCustomer",
    name: "laporan Customer",
  },
  {
    value: "/parameterSupervisory",
    name: "Parameter",
  },
  // {
  //   value: "/historyUser",
  //   name: "Cetak History User",
  // },
  {
    value: "/cetakUlangBukti",
    name: "Cetak Ulang Bukti",
  },
  // {
  //   value: "/TransaksiPenjualanLM",
  //   name: "Jual LM",
  // },
  // {
  //   value: "/TransaksiPembelianLM",
  //   name: "Beli LM",
  // },
  // {
  //   value: "/ValidasiStokJualLM",
  //   name: "Validasi Stok Jual LM",
  // },
  // {
  //   value: "/ValidasiStokBeliLM",
  //   name: "Validasi Stok Beli LM",
  // },
  // {
  //   value: "/PembayaranPenjualanLM",
  //   name: "Bayar Jual LM",
  // },
  {
    value: "/TotalanTimbangan",
    name: "Totalan Timbangan",
  },
  // {
  //   value: "/TotalanBarcode",
  //   name: "Totalan Barcode",
  // },
  {
    value: "/SelisihTimbangan",
    name: "Selisih Timbangan",
  },
  {
    value: "/PackingList",
    name: "Packing List",
  },
  {
    value: "/CetakBarcode",
    name: "Cetak Barcode",
  },
  {
    value: "/CetakBarcodeModel",
    name: "Cetak Barcode Model",
  },
  {
    value: "/validasiPO",
    name: "Validasi PO",
  },
  // {
  //   value: "/SelisihTimbangRosok",
  //   name: "Selisih Timbang Rongsok",
  // },
];

const Header = (props) => {
  let history = useHistory();
  const type = process.env.REACT_APP_TYPE;
  return (
    <header className={props.topbar} data-navbarbg="skin6">
      <nav className="navbar top-navbar navbar-expand-md">
        <div className="navbar-header" data-logobg="skin6">
          <div className="navbar-brand">
            <Link to="/dashboard">
              <b className="logo-icon">
                <img
                  src={type === "real" ? Logo : Logo_demo}
                  alt="homepage"
                  className="light-logo"
                />
              </b>
            </Link>
          </div>
          <a
            className="topbartoggler d-block d-md-none waves-effect waves-light"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            href="/dashboard"
          >
            <i className="ti-more" />
          </a>
        </div>
        <div className="navbar-collapse collapse" id="navbarSupportedContent">
          <ul className="navbar-nav float-left mr-auto d-flex align-items-center ml-4">
            <img
              src={type === "real" ? Logo : Logo_demo}
              alt="homepage"
              style={{ width: 100, height: 35 }}
            />
          </ul>
          <ul className="navbar-nav float-right" style={{ width: 300 }}>
            <SelectSearch
              placeholder="Pintasan Menu" // by default "Search"
              notFoundText="No result found" // by default "No result found"
              options={listMenu}
              search
              onChange={(option) => {
                history.push(option);
              }}
            />
          </ul>
          <ul className="navbar-nav float-right">
            <li className="nav-item dropdown">
              <a
                href="/dashboard"
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <Avatar
                  color={"#5F76E8"}
                  name={props.username}
                  round
                  size={50}
                />
                <span className="ml-2 d-none d-lg-inline-block">
                  <span className="text-dark ml-1">Hello, </span>
                  <span className="text-dark ml-1">{props.username}</span>
                  <i
                    data-feather="chevron-down"
                    className="svg-icon text-dark"
                  />
                </span>
              </a>
              <div className="dropdown-menu dropdown-menu-right user-dd animated flipInY">
                <Link className="dropdown-item" to="/profile-edit">
                  <i className="fa fa-user mr-2 ml-1" />
                  Edit Profile
                </Link>
                <Link className="dropdown-item" to="/pengaturan">
                  <i data-feather="settings" className="svg-icon mr-2 ml-1" />
                  Pengaturan
                </Link>
                <div className="dropdown-divider" />
                <Link
                  className="dropdown-item"
                  to="/dashboard"
                  onClick={() =>
                    props.dispatch(setLogout(window.location.reload()))
                  }
                >
                  <i data-feather="power" className="svg-icon mr-2 ml-1" />
                  Logout
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default connect(mapStateToProps, null)(Header);
