import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { getcustomerList } from "../../DataMaster/customer/redux/customerAction";
import { getListKelompok } from "../../DataMaster/kelompok/redux/kelompokAction";
import { getrekeningList } from "../../DataMaster/rekening/redux/rekeningAction";
import { getToday } from "../../getDate";
import { renderField, renderFieldSelect } from "../../renderField";

class FormTimbangStock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type_laporan: "SIMPLE",
    };
  }

  componentDidMount() {
    this.props.dispatch(getrekeningList());
    this.props.dispatch(getcustomerList());
    this.props.dispatch(getListKelompok());
  }
  render() {
    switch (this.props.from) {
      case "PROSES":
        return (
          <form onSubmit={this.props.handleSubmit}>
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-4">
                  <Field
                    label="Tanggal"
                    name="tanggal"
                    type="date"
                    component={renderField}
                  />
                </div>
                <div className="col-lg-4">
                  <Field
                    className="form-control"
                    name="kode_kategori"
                    label="Kode Kategori"
                    component={renderFieldSelect}
                    options={this.props.Kelompok.filter(
                      (x) => x.kode_kelompok === "PERHIASAN"
                    ).map((obj) => {
                      var payload = {
                        value: obj.kode_group,
                        name: obj.nama_group,
                      };
                      return payload;
                    })}
                  ></Field>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="text-right">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={this.props.onSend}
                >
                  {this.props.onSend ? (
                    <span
                      className="spinner-border spinner-border-sm mr-3"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <i className="fa fa-print mr-2"></i>
                  )}
                  Print
                </button>
              </div>
            </div>
          </form>
        );
      default:
        break;
    }
  }
}

FormTimbangStock = reduxForm({
  form: "FormTimbangStock",
  enableReinitialize: true,
})(FormTimbangStock);
export default connect((state) => {
  return {
    listBank: state.provinsi.rekening,
    onSend: state.provinsi.onSend,
    listCustomer: state.provinsi.customer,
    Kelompok: state.provinsi.Kelompok,
    initialValues: {
      tanggal: getToday(),
    },
  };
})(FormTimbangStock);
