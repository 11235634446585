import { change } from "redux-form";

export const GET_LIST_TIMBANGAN_STOCK = "GET_LIST_TIMBANGAN_STOCK";

export const getListTimbanganStock = () => {
  return (dispatch) => {
    const data = JSON.parse(localStorage.getItem("TimbangStock")) || [];
    dispatch({
      type: GET_LIST_TIMBANGAN_STOCK,
      payload: {
        data: JSON.parse(localStorage.getItem("TimbangStock")),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
    dispatch(
      change(
        "TimbangStock",
        "total_real",
        data.map((x) => x.berat_bersih).reduce((a, b) => a + b, 0)
      )
    );
  };
};
