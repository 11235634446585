import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatOptional, printInfo } from "../../../helper";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporanPembayaranSupplier = (data, mulai_dari, sampai_dengan) => {
  console.log(data);
  // initialize jsPDF
  const doc = new jsPDF("portrait");
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];

  doc.setFontSize(15);
  doc.text("LAPORAN PEMBAYARAN HUTANG SUPPLIER", 14, 10);
  // doc.addImage(logo_ayu_base64, "PNG", 14, 10, 35, 12);
  doc.setFontSize(10);
  doc.text(`Mulai Dari : ${mulai_dari}`, 14, 16);
  //   row 2
  doc.text(`Sampai Dengan	: ${sampai_dengan}`, 100, 16);
  //   //row 1
  //   doc.text(`Supplier : ${row1isi}`, 14, 25);
  //   //   row 2
  //   doc.text(`Tanggal	: ${row2isi}`, 120, 25);
  data.forEach((item, index) => {
    let tableColumn = [
      [
        {
          content: `KODE SUPPLIER : ${item.kode_supplier} / ${item.nama_supplier}`,
          colSpan: 8,
        },
      ],
      [
        {
          content: `TANGGAL`,
        },
        {
          content: `NO BON`,
        },
        {
          content: `JUMLAH HUTANG`,
          styles: { halign: "right" },
        },
        {
          content: `PUTUS TITIP`,
          styles: { halign: "right" },
        },
        {
          content: `DENDA`,
          styles: { halign: "right" },
        },
        {
          content: `HUTANG`,
          styles: { halign: "right" },
        },
        {
          content: `PEMBAYARAN`,
          styles: { halign: "right" },
        },
        {
          content: `LBH BAYAR`,
          styles: { halign: "right" },
        },
      ],
    ];
    item.detail.forEach((hasil) => {
      let rows = [
        hasil.tanggal,
        hasil.no_bon,
        {
          content: formatOptional(hasil.jumlah_hutang),
          styles: { halign: "right" },
        },
        {
          content: formatOptional(hasil.total_putus_titip),
          styles: { halign: "right" },
        },
        {
          content: formatOptional(hasil.total_denda),
          styles: { halign: "right" },
        },
        {
          content: formatOptional(hasil.total_hutang),
          styles: { halign: "right" },
        },
        {
          content: formatOptional(hasil.total_pembayaran),
          styles: { halign: "right" },
        },
        {
          content: formatOptional(hasil.lebih_pembayaran),
          styles: { halign: "right" },
        },
      ];
      tableRows.push(rows);
      // let detail = [
      //     [
      //       {
      //         content: "BAYAR CASH",
      //         styles: { halign: "right" },
      //         colSpan: 6
      //       },
      //       {
      //         content: `${item.detail_bayar[index].bayar_cash}`,
      //         styles: { halign: "right" },
      //         colSpan: 2
      //       },
      //     ],
      //   [
      //     {
      //       content: "BAYAR TRANSFER",
      //       styles: { halign: "right" },
      //       colSpan: 6
      //     },
      //     {
      //       content: `${item.detail_bayar[index].bayar_trf}`,
      //       styles: { halign: "right" },
      //       colSpan: 2
      //     },
      //   ],
      //   [
      //     {
      //       content: "BAYAR CIOK",
      //       styles: { halign: "right" },
      //       colSpan: 6
      //     },
      //     {
      //       content: `${item.detail_bayar[index].bayar_ciok}`,
      //       styles: { halign: "right" },
      //       colSpan: 2
      //     },
      //   ],
      //   [
      //     {
      //       content: "BAYAR ROSOK",
      //       styles: { halign: "right" },
      //       colSpan: 6
      //     },
      //     {
      //       content: `${item.detail_bayar[index].bayar_rongsok}`,
      //       styles: { halign: "right" },
      //       colSpan: 2
      //     },
      //   ],
      //   [
      //     {
      //       content: "BAYAR GIRO",
      //       styles: { halign: "right" },
      //       colSpan: 6
      //     },
      //     {
      //       content: `${item.detail_bayar[index].bayar_giro}`,
      //       styles: { halign: "right" },
      //       colSpan: 2
      //     },
      //   ]
      // ];
      // tableRows.push(...detail);
    });
    doc.autoTable({
      head: tableColumn,
      body: tableRows,
      foot: [printInfo()],
      showHead: "firstPage",
      showFoot: "lastPage",
      startY: 20,
      theme: "plain",
      rowPageBreak: "avoid",

      margin: { top: 20 },
      bodyStyles: { lineColor: "#969696", fontSize: 8, lineWidth: 0.0 },
      headStyles: {
        lineColor: "#969696",
        lineWidth: 0.0,
        fontSize: 8,
        fillColor: "#E8E8E8",
      },
      footStyles: {
        lineColor: "#969696",
        lineWidth: 0.0,
        fillColor: "#E8E8E8",
        fontSize: 8,
      },
    });
  });

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  doc.autoPrint();
  doc.setProperties({
    title: "LAPORAN PEMBAYARAN HUTANG SUPPLIER",
  });
  // doc.save(`PenerimaanSUpplier.pdf`);
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    `
<html>
<head>
  <title>LAPORAN PEMBAYARAN HUTANG SUPPLIER</title>
  <style>
    #overlay {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1000;
    }
  </style>
</head>
<body style='margin:0 !important; position: relative;'>
  <iframe src='${string}#toolbar=0' width='100%' height='100%' frameborder='0' style='position: absolute; top: 0; left: 0;'></iframe>
  <div id='overlay' oncontextmenu='return false;'></div>
</body>
</html>
`
  );
  // setInterval(() => {
  //   x.close();
  // }, 1000);
};

export default LaporanPembayaranSupplier;
