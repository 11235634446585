import { AxiosMasterGet } from "../Axios";
import { checkLaporan, getToday } from "../helper";

export const GET_VALIDASI_SUPPLIER_PENERIMAAN_BARANG_SUPPLIER =
  "GET_VALIDASI_SUPPLIER_PENERIMAAN_BARANG_SUPPLIER";
export const GET_VALIDASI_SUPPLIER_RETURN_SUPPLIER =
  "GET_VALIDASI_SUPPLIER_RETURN_SUPPLIER";
export const GET_VALIDASI_SUPPLIER_TOLAKAN_RETURN_SUPPLIER =
  "GET_VALIDASI_SUPPLIER_TOLAKAN_RETURN_SUPPLIER";
export const GET_VALIDASI_SUPPLIER_PEMBAYARAN_HUTANG_SUPPLIER =
  "GET_VALIDASI_SUPPLIER_PEMBAYARAN_HUTANG_SUPPLIER";
export const GET_VALIDASI_SUPPLIER_TOLAKAN_PEBAYARAN_SUPPLIER =
  "GET_VALIDASI_SUPPLIER_TOLAKAN_PEBAYARAN_SUPPLIER";
export const GET_VALIDASI_SUPPLIER_TITIP_SUPPLIER =
  "GET_VALIDASI_SUPPLIER_TITIP_SUPPLIER";
export const GET_DATA_SELECTED_VALIDASI_SUPPLIER =
  "GET_DATA_SELECTED_VALIDASI_SUPPLIER";

export const getValidasiSupplierAllSupplier = () => {
  return (dispatch) => {
    AxiosMasterGet(`validate-supplier/get/un-valid-data/ALL&${getToday()}`)
      .then((res) => {
        if (checkLaporan(res.data)) {
          dispatch({
            type: GET_VALIDASI_SUPPLIER_PENERIMAAN_BARANG_SUPPLIER,
            payload: {
              data: res.data,
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch({
            type: GET_VALIDASI_SUPPLIER_PENERIMAAN_BARANG_SUPPLIER,
            payload: {
              data: res.data,
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() =>
        dispatch({
          type: GET_VALIDASI_SUPPLIER_PENERIMAAN_BARANG_SUPPLIER,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const getValidasiSupplierPenerimaanBarangSupplier = (
  kode_supplier,
  tglTo
) => {
  return (dispatch) => {
    AxiosMasterGet(
      `validate-supplier/get/un-valid-data/${kode_supplier}&${tglTo}`
    )
      .then((res) => {
        if (
          checkLaporan(
            res.data.filter((list) => list.keterangan === "PENERIMAAN")
          )
        ) {
          dispatch({
            type: GET_VALIDASI_SUPPLIER_PENERIMAAN_BARANG_SUPPLIER,
            payload: {
              data: res.data.filter((list) => list.keterangan === "PENERIMAAN"),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch({
            type: GET_VALIDASI_SUPPLIER_PENERIMAAN_BARANG_SUPPLIER,
            payload: {
              data: res.data.filter((list) => list.keterangan === "PENERIMAAN"),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() =>
        dispatch({
          type: GET_VALIDASI_SUPPLIER_PENERIMAAN_BARANG_SUPPLIER,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const getValidasiSupplierReturnSupplier = (kode_supplier, tglTo) => {
  return (dispatch) => {
    AxiosMasterGet(
      `validate-supplier/get/un-valid-data/${kode_supplier}&${tglTo}`
    )
      .then((res) => {
        if (
          checkLaporan(res.data.filter((list) => list.keterangan === "RETUR"))
        ) {
          dispatch({
            type: GET_VALIDASI_SUPPLIER_RETURN_SUPPLIER,
            payload: {
              data: res.data.filter((list) => list.keterangan === "RETUR"),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch({
            type: GET_VALIDASI_SUPPLIER_RETURN_SUPPLIER,
            payload: {
              data: res.data.filter((list) => list.keterangan === "RETUR"),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() =>
        dispatch({
          type: GET_VALIDASI_SUPPLIER_PENERIMAAN_BARANG_SUPPLIER,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const getValidasiSupplierTolakanReturnSupplier = (
  kode_supplier,
  tglTo
) => {
  return (dispatch) => {
    AxiosMasterGet(
      `validate-supplier/get/un-valid-data/${kode_supplier}&${tglTo}`
    )
      .then((res) => {
        if (
          checkLaporan(
            res.data.filter((list) => list.keterangan === "TOLAKAN SUPPLIER")
          )
        ) {
          dispatch({
            type: GET_VALIDASI_SUPPLIER_TOLAKAN_RETURN_SUPPLIER,
            payload: {
              data: res.data.filter(
                (list) => list.keterangan === "TOLAKAN SUPPLIER"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch({
            type: GET_VALIDASI_SUPPLIER_TOLAKAN_RETURN_SUPPLIER,
            payload: {
              data: res.data.filter(
                (list) => list.keterangan === "TOLAKAN SUPPLIER"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() =>
        dispatch({
          type: GET_VALIDASI_SUPPLIER_TOLAKAN_RETURN_SUPPLIER,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const getValidasiSupplierPembayaranHutangSupplier = (
  kode_supplier,
  tglTo
) => {
  return (dispatch) => {
    AxiosMasterGet(
      `validate-supplier/get/un-valid-data/${kode_supplier}&${tglTo}`
    )
      .then((res) => {
        if (
          checkLaporan(
            res.data.filter((list) => list.keterangan === "PEMBAYARAN")
          )
        ) {
          dispatch({
            type: GET_VALIDASI_SUPPLIER_PEMBAYARAN_HUTANG_SUPPLIER,
            payload: {
              data: res.data.filter((list) => list.keterangan === "PEMBAYARAN"),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch({
            type: GET_VALIDASI_SUPPLIER_PEMBAYARAN_HUTANG_SUPPLIER,
            payload: {
              data: res.data.filter((list) => list.keterangan === "PEMBAYARAN"),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() =>
        dispatch({
          type: GET_VALIDASI_SUPPLIER_PEMBAYARAN_HUTANG_SUPPLIER,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const getValidasiSupplierTolakanPembayaranSupplier = (
  kode_supplier,
  tglTo
) => {
  return (dispatch) => {
    AxiosMasterGet("spp/dc/tlkbyr/heads", {
      params: {
        kode_supplier: "2020-01-01",
        tglTo: "2030-01-01",
      },
    })
      .then((res) => {
        dispatch(getDataSelectedValidasiSupplier());
        if (checkLaporan(res.data)) {
          dispatch({
            type: GET_VALIDASI_SUPPLIER_TOLAKAN_PEBAYARAN_SUPPLIER,
            payload: {
              data: res.data,
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch({
            type: GET_VALIDASI_SUPPLIER_TOLAKAN_PEBAYARAN_SUPPLIER,
            payload: {
              data: res.data,
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() =>
        dispatch({
          type: GET_VALIDASI_SUPPLIER_TOLAKAN_PEBAYARAN_SUPPLIER,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};
export const getValidasiSupplierTitipSupplier = (kode_supplier, tglTo) => {
  return (dispatch) => {
    AxiosMasterGet(
      `validate-supplier/get/un-valid-data/${kode_supplier}&${tglTo}`
    )
      .then((res) => {
        if (
          checkLaporan(
            res.data.filter(
              (list) =>
                list.keterangan === "TAMBAH TITIPAN" ||
                list.keterangan === "AMBIL TITIPAN"
            )
          )
        ) {
          dispatch({
            type: GET_VALIDASI_SUPPLIER_TITIP_SUPPLIER,
            payload: {
              data: res.data.filter(
                (list) =>
                  list.keterangan === "TAMBAH TITIPAN" ||
                  list.keterangan === "AMBIL TITIPAN"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch({
            type: GET_VALIDASI_SUPPLIER_TITIP_SUPPLIER,
            payload: {
              data: res.data.filter(
                (list) =>
                  list.keterangan === "TAMBAH TITIPAN" ||
                  list.keterangan === "AMBIL TITIPAN"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() =>
        dispatch({
          type: GET_VALIDASI_SUPPLIER_TITIP_SUPPLIER,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const getDataSelectedValidasiSupplier = () => {
  var hasil = JSON.parse(localStorage.getItem("FakturTerpilih")) || [];
  return (dispatch) => {
    dispatch({
      type: GET_DATA_SELECTED_VALIDASI_SUPPLIER,
      payload: {
        data: Array.isArray(hasil)
          ? hasil.length === 0
            ? false
            : hasil
          : false,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
        error: true,
      },
    });
  };
};
