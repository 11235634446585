import { AxiosMasterGet } from "../Axios";
import { formatGram, removeComma } from "../helper";

export const SET_TITIP_CUSTOMER = "SET_TITIP_CUSTOMER";
export const SET_CASH_MULTI_TEMP = "SET_CASH_MULTI_TEMP";
export const GET_DATA_SELECTED_PEMBAYARAN_CUSTOMER =
  "GET_DATA_SELECTED_PEMBAYARAN_CUSTOMER";

export const setTitipCustomer = ({ customer }) => {
  return (dispatch) => {
    AxiosMasterGet("trx-customer/saldo-titip/" + customer)
      .then((res) =>
        dispatch({
          type: SET_TITIP_CUSTOMER,
          payload: {
            titip_gr: res.data?.titip_gr || 0,
            titip_rp: res.data?.titip_rp || 0,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: SET_TITIP_CUSTOMER,
          payload: {
            data: false,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const setTitipSupplier = ({ supplier }) => {
  return (dispatch) => {
    AxiosMasterGet("trx-supplier/saldo-titip/" + supplier)
      .then((res) =>
        dispatch({
          type: SET_TITIP_CUSTOMER,
          payload: {
            titip_gr: res.data?.titip_gr || 0,
            titip_rp: res.data?.titip_rp || 0,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: SET_TITIP_CUSTOMER,
          payload: {
            data: false,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const getDataSelectedPembayaranCustomer = () => {
  var hasil = JSON.parse(localStorage.getItem("listHutangAll")) || [];
  return (dispatch) => {
    dispatch({
      type: GET_DATA_SELECTED_PEMBAYARAN_CUSTOMER,
      payload: {
        data: hasil,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
        error: true,
      },
    });
  };
};

export const setTampungCashMulti = () => {
  return (dispatch) => {
    let nettoSum = 0;
    let brutoSum = 0;
    let total = JSON.parse(localStorage.getItem("CashMultiTemp")) || "kosong";
    console.log(total);
    if (total === "kosong" || total.length === 0) {
      nettoSum = 0;
      brutoSum = 0;
    } else {
      nettoSum = total
        .map((hasil) => parseFloat(removeComma(hasil.cash_netto)))
        .reduce((acc, hasil) => acc + hasil, 0);
      localStorage.setItem("totalCashMulti", formatGram(nettoSum));
      brutoSum = total
        .map((hasil) => parseFloat(removeComma(hasil.cash_rp)))
        .reduce((acc, hasil) => acc + hasil, 0);
      localStorage.setItem("totalBrutoCashMulti", formatGram(brutoSum));
    }
    dispatch({
      type: SET_CASH_MULTI_TEMP,
      payload: {
        data: JSON.parse(localStorage.getItem("CashMultiTemp")) || [],
        totalNetto: nettoSum,
        totalBruto: brutoSum,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};
