import { finishSend, progressSend } from "../../../actions/LogicAction";
import { toast } from "react-toastify";
import { AxiosMasterGet } from "../../../Axios";
import { ToastAmbil } from "../../../actions/toast";
import LaporanHutangSupplier from "../component/LaporanHutangSupplier";
import LaporanHutangSupplierCard from "../component/LaporanHutangSupplierCard";
import LaporanHutangSupplierSummary from "../component/LaporanHutangSupplierSummary";

export const GET_LAPORAN_HUTANG_SUPPLIER = "GET_LAPORAN_HUTANG_SUPPLIER";
export const GET_LAPORAN_HUTANG_SUPPLIER_CARD =
  "GET_LAPORAN_HUTANG_SUPPLIER_CARD";
export const GET_LAPORAN_HUTANG_SUPPLIER_SUMMARY =
  "GET_LAPORAN_HUTANG_SUPPLIER_SUMMARY";

export const GetLaporanHutangSupplier = (kode_supplier) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    try {
      const response = await toast.promise(
        AxiosMasterGet("report/hutang-supplier/" + kode_supplier),
        ToastAmbil
      );
      dispatch({
        type: GET_LAPORAN_HUTANG_SUPPLIER,
        payload: response.data,
      });
      dispatch(finishSend());
    } catch (e) {
      dispatch(finishSend());
    }
  };
};
export const GetLaporanHutangSupplierCard = (
  kode_supplier,
  tanggal_awal,
  tanggal_akhir
) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    try {
      const response = await toast.promise(
        AxiosMasterGet(
          "report/hutang-supplier-card/" +
            `${kode_supplier}&${tanggal_awal}&${tanggal_akhir}`
        ),
        ToastAmbil
      );
      dispatch({
        type: GET_LAPORAN_HUTANG_SUPPLIER_CARD,
        payload: {
          data: response.data,
          tanggal_akhir: tanggal_akhir,
          tanggal_awal: tanggal_awal,
        },
      });
      dispatch(finishSend());
    } catch (e) {
      dispatch(finishSend());
    }
  };
};
export const GetLaporanHutangSupplierSummary = (
  kode_supplier,
  tanggal_awal,
  tanggal_akhir
) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    try {
      const response = await toast.promise(
        AxiosMasterGet(
          "report/hutang-supplier-summary/" +
            `${kode_supplier}&${tanggal_awal}&${tanggal_akhir}`
        ),
        ToastAmbil
      );
      dispatch({
        type: GET_LAPORAN_HUTANG_SUPPLIER_SUMMARY,
        payload: {
          data: response.data,
          tanggal_akhir: tanggal_akhir,
          tanggal_awal: tanggal_awal,
        },
      });
      dispatch(finishSend());
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

export const ResetLaporanHutangSupplier = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_LAPORAN_HUTANG_SUPPLIER_SUMMARY,
      payload: {
        data: [],
        tanggal_akhir: "",
        tanggal_awal: "",
      },
    });
    dispatch({
      type: GET_LAPORAN_HUTANG_SUPPLIER_CARD,
      payload: {
        data: [],
        tanggal_akhir: "",
        tanggal_awal: "",
      },
    });
    dispatch({
      type: GET_LAPORAN_HUTANG_SUPPLIER,
      payload: [],
    });
  };
};

export const PrintLaporanHutangSupplierPDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanHutangSupplier = state.laporan.dataLaporanHutangSupplier;
    LaporanHutangSupplier(dataLaporanHutangSupplier);
  };
};

export const PrintLaporanHutangSupplierCardPDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanHutangSupplierCard =
      state.laporan.dataLaporanHutangSupplierCard;
    LaporanHutangSupplierCard(
      dataLaporanHutangSupplierCard.data,
      dataLaporanHutangSupplierCard.tanggal_awal,
      dataLaporanHutangSupplierCard.tanggal_akhir
    );
  };
};
export const PrintLaporanHutangSupplierSummaryPDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanHutangSupplierSummary =
      state.laporan.dataLaporanHutangSupplierSummary;
    LaporanHutangSupplierSummary(
      dataLaporanHutangSupplierSummary.data,
      dataLaporanHutangSupplierSummary.tanggal_awal,
      dataLaporanHutangSupplierSummary.tanggal_akhir
    );
  };
};
