export const GET_LIST_CETAK_ULANG = "GET_LIST_CETAK_ULANG";

export const getCetakUlang = () => {
  return (dispatch) => {
    dispatch({
      type: GET_LIST_CETAK_ULANG,
      payload: {
        items: [
          {
            id: 0,
            name: "Cetak Ulang Bukti Penerimaan Barang Supplier",
            jenis: "penerimaanSupplier",
          },
          {
            id: 1,
            name: "Cetak Ulang Bukti Return Supplier",
            jenis: "returnSupplier",
          },
          {
            id: 2,
            name: "Cetak Ulang Bukti Pembayaran Hutang Supplier",
            jenis: "hutangSupplier",
          },
          {
            id: 3,
            name: "Cetak Ulang Bukti Tolakan Supplier",
            jenis: "tolakanSupplier",
          },
          {
            id: 4,
            name: "Cetak Ulang Bukti Titipan Supplier",
            jenis: "titipanSupplier",
          },
          {
            id: 5,
            name: "Cetak Ulang Bukti Pindah Barang",
            jenis: "pindahBarang",
          },
          {
            id: 6,
            name: "Cetak Ulang Bukti Mutasi Barang",
            jenis: "mutasiBarang",
          },
          {
            id: 7,
            name: "Cetak Ulang Bukti Penjualan",
            jenis: "buktiPenjualan",
          },
          {
            id: 8,
            name: "Cetak Ulang Bukti Return Customer",
            jenis: "returnCustomer",
          },
          {
            id: 9,
            name: "Cetak Ulang Bukti Titip Rp Customer",
            jenis: "titipanRpCustomer",
          },
          {
            id: 10,
            name: "Cetak Ulang Bukti Titip Gr Customer",
            jenis: "titipanGrCustomer",
          },
          {
            id: 11,
            name: "Cetak Ulang Bukti Pembayaran Customer",
            jenis: "pembayaranCustomer",
          },
          {
            id: 12,
            name: "Cetak Ulang Saldo Awal",
            jenis: "saldoAwal",
          },
          {
            id: 13,
            name: "Cetak Ulang Tolakan Timbang ",
            jenis: "tolakanTimbang",
          },
          {
            id: 14,
            name: "Cetak Ulang BayarJual LM ",
            jenis: "bayarJual",
          },
          {
            id: 15,
            name: "Cetak Ulang Bayar Beli LM ",
            jenis: "bayarBeli",
          },
          {
            id: 16,
            name: "Cetak Ulang Jual/Kirim Jual LM ",
            jenis: "jualLM",
          },
          {
            id: 17,
            name: "Cetak Ulang Beli LM/Terima Beli LM / RSK ",
            jenis: "beliLM",
          },
          {
            id: 18,
            name: "Cetak Ulang Kirim/Terima Masak ",
            jenis: "terimaMasak",
          },
        ],
      },
    });
  };
};
