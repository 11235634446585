import {finishSend, progressSend} from "../../../actions/LogicAction";
import {toast} from "react-toastify";
import {AxiosMasterGet} from "../../../Axios";
import {ToastAmbil} from "../../../actions/toast";
import LaporanPembayaranSupplier from "../component/LaporanPembayaranSupplier";


export const GET_LAPORAN_PEMBAYARAN_SUPPLIER = "GET_LAPORAN_PEMBAYARAN_SUPPLIER"



export const GetLaporanPembayaranSupplier = (kode_supplier, tglFrom, tglTo) => {
  return async (dispatch ,getState) => {
    dispatch(progressSend());
    try {
              const response = await toast.promise(AxiosMasterGet(
                  `report/bayar-hutang-supplier/${kode_supplier}&${tglFrom}&${tglTo}&REKAP&REKAP`
              ), ToastAmbil)
        dispatch({
            type : GET_LAPORAN_PEMBAYARAN_SUPPLIER,
            payload : {
                data: response.data,
                tglFrom : tglFrom,
                tglTo : tglTo
            }
        })
        dispatch(finishSend())
    } catch (e) {

          dispatch(finishSend())
          }
  }
}

export const PrintLaporanPembayaranSupplierPDF = (tglFrom, tglTo) => {
  return async (dispatch ,getState) => {
    const state = getState();
    const dataLaporanPembayaranSupplier = state.laporan.dataLaporanPembayaranSupplier;
      LaporanPembayaranSupplier(dataLaporanPembayaranSupplier.data, dataLaporanPembayaranSupplier.tglFrom, dataLaporanPembayaranSupplier.tglTo);
  }
}