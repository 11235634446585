import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { renderField, renderFieldSelect } from "../../../renderField";
import validasiSaldoAwalCT from "../../../Validations/validasiSaldoAwalCT";
import { getToday, preventTabAction } from "../../../helper";
import CustomForm from "../../../CustomForm";
import ButtonSelesai from "../../../button_selesai";

class FormSaldoAwal24K extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listSupplier: [],
    };
  }
  componentDidMount() {
    preventTabAction();
  }

  render() {
    return (
      <CustomForm onSubmit={this.props.handleSubmit}>
        <div className="row">
          <div className="col-lg-4">
            <Field
              label="Tanggal"
              name="tanggal"
              component={renderField}
              type="date"
            />
          </div>
          <div className="col-lg-4">
            <Field
              name="kode_kategori"
              component={renderFieldSelect}
              options={this.props.KodeBarangList.map((data) => {
                return {
                  value: data.kode_24k,
                  name: data.kode_24k + "/" + data.nama_24k,
                };
              })}
              type="text"
              label="Kode Jenis"
              placeholder="Masukan Kode Jenis"
            />
          </div>
          <div className="col-lg-4">
            <Field
              label="No Bon"
              name="no_bon"
              component={renderField}
              type="text"
              normalize={(x) => x && x.toUpperCase()}
            />
          </div>
          <div className="col-lg-3"></div>
          <div className="col-lg-3">
            <Field
              label="Bruto"
              name="bruto"
              component={renderField}
              type="number"
            />
          </div>
          <div className="col-lg-3">
            <Field
              label="Netto"
              name="netto"
              component={renderField}
              type="number"
            />
          </div>
          <div className="col-lg-12">
            <ButtonSelesai />
          </div>
        </div>
      </CustomForm>
    );
  }
}

FormSaldoAwal24K = reduxForm({
  form: "FormSaldoAwal24K",
  enableReinitialize: true,
  validate: validasiSaldoAwalCT,
})(FormSaldoAwal24K);
export default connect((state) => {
  return {
    KodeBarangList: state.master24k.list24K,
    initialValues: {
      tanggal: getToday(),
    },
  };
})(FormSaldoAwal24K);
