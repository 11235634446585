import { toast } from "react-toastify";
import { finishSend } from "../../actions/LogicAction";
import { ToastAmbil } from "../../actions/toast";
import { warning } from "../../Alert";
import { AxiosMasterGetWithParams } from "../../Axios";
import { getToday } from "../../getDate";
import {
  formatDateIndo,
  formatGram,
  formatRp,
  getUserID,
  printInfo,
  purifiedData,
} from "../../helper";
import PrintNota from "../../Stocking/Component/PrintNota";

export const GET_CETAK_ULANG_PENERIMAN_SUPPLIER =
  "GET_CETAK_ULANG_PENERIMAN_SUPPLIER";
export const GET_CETAK_ULANG_RETUR_SUPPLIER = "GET_CETAK_ULANG_RETUR_SUPPLIER";
export const GET_CETAK_ULANG_PEMBAYARAN_HUTANG_SUPPLIER =
  "GET_CETAK_ULANG_PEMBAYARAN_HUTANG_SUPPLIER";
export const GET_CETAK_ULANG_TOLAKAN_SUPPLIER =
  "GET_CETAK_ULANG_TOLAKAN_SUPPLIER";
export const GET_CETAK_ULANG_PENJUALAN = "GET_CETAK_ULANG_PENJUALAN";
export const GET_CETAK_ULANG_RETUR_PENJUALAN =
  "GET_CETAK_ULANG_RETUR_PENJUALAN";

export const getPenerimaanSupplierCU = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const selected = state.form.FormLaporanPenerimaanSupplierHead.values;
    purifiedData(selected);
    if (selected.kode_supplier === undefined) {
      warning("Mohon Pilih Supplier");
      return false;
    }
    try {
      const response = await toast.promise(
        AxiosMasterGetWithParams(
          `report/terima-barang-supplier/DETAIL&${selected.kode_supplier}&${selected.tanggalAwal}&${selected.tanggalAkhir}`,
          {
            show_all_data: true,
          }
        ),
        ToastAmbil
      );
      if (response.data.length < 1) {
        warning("Data Kosong, Silahkan Pilih Supplier atau Tanggal Yang Lain");
        return false;
      }
      dispatch({
        type: GET_CETAK_ULANG_PENERIMAN_SUPPLIER,
        payload: response.data[0].detail,
      });
    } catch (e) {
      dispatch(finishSend());
    }
  };
};
export const getReturnSupplierCU = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const selected = state.form.FormLaporanReturnSupplierHead.values;
    purifiedData(selected);

    if (selected.kode_supplier === undefined) {
      warning("Mohon Pilih Supplier");
      return false;
    }
    try {
      const response = await toast.promise(
        AxiosMasterGetWithParams(
          `report/retur-barang-supplier/DETAIL&${selected.kode_supplier}&${selected.tanggalAwal}&${selected.tanggalAkhir}`,
          {
            show_all_data: true,
          }
        ),
        ToastAmbil
      );
      if (response.data.length < 1) {
        warning("Data Kosong, Silahkan Pilih Supplier atau Tanggal Yang Lain");
        return false;
      }
      dispatch({
        type: GET_CETAK_ULANG_RETUR_SUPPLIER,
        payload: response.data[0].detail,
      });
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

export const getPembayaranHutangSupplierCU = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const selected = state.form.FormLaporanHutangSupplierHead.values;
    purifiedData(selected);

    if (selected.kode_supplier === undefined) {
      warning("Mohon Pilih Supplier");
      return false;
    }
    try {
      const response = await toast.promise(
        AxiosMasterGetWithParams(
          `report/bayar-hutang-supplier/${selected.kode_supplier}&${selected.tanggalAwal}&${selected.tanggalAkhir}&DETAIL&DETAIL`,
          {
            show_all_data: true,
          }
        ),
        ToastAmbil
      );
      if (response.data.length < 1) {
        warning("Data Kosong, Silahkan Pilih Supplier atau Tanggal Yang Lain");
        return false;
      }
      dispatch({
        type: GET_CETAK_ULANG_PEMBAYARAN_HUTANG_SUPPLIER,
        payload: response.data[0].detail,
        detail_bayar: response.data[0].detail_bayar,
      });
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

export const getTolakanSupplierCU = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const selected = state.form.FormLaporanTolakanSupplier.values;
    purifiedData(selected);

    if (selected.kode_supplier === undefined) {
      warning("Mohon Pilih Supplier");
      return false;
    }
    try {
      const response = await toast.promise(
        AxiosMasterGetWithParams(
          `report/tolakan-supplier/${selected.kode_supplier}&${selected.tanggal_awal}&${selected.tanggal_akhir}`,
          {
            show_all_data: true,
          }
        ),
        ToastAmbil
      );
      if (response.data.length < 1) {
        warning("Data Kosong, Silahkan Pilih Supplier atau Tanggal Yang Lain");
        return false;
      }
      dispatch({
        type: GET_CETAK_ULANG_TOLAKAN_SUPPLIER,
        payload: response.data[0].detail,
      });
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

export const getPenjualanCU = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const selected = state.form.FormLaporanBuktiPenjualanHead.values;
    purifiedData(selected);

    if (selected.kode_customer === undefined) {
      warning("Mohon Pilih Customer");
      return false;
    }
    try {
      const response = await toast.promise(
        AxiosMasterGetWithParams(
          `report/penjualan/${selected.kode_customer.split("-")[0]}&LEVEL1&${
            selected.kode_sales
          }&${selected.tanggalAwal}&${selected.tanggalAkhir}`,
          {
            show_all_data: true,
          }
        ),
        ToastAmbil
      );
      if (response.data.length < 1) {
        warning("Data Kosong, Silahkan Pilih Customer atau Tanggal Yang Lain");
        return false;
      }
      dispatch({
        type: GET_CETAK_ULANG_PENJUALAN,
        payload: response.data,
      });
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

export const getReturPenjualanCU = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const selected = state.form.FormLaporanReturnCustomer.values;
    purifiedData(selected);

    if (selected.kode_customer === undefined) {
      warning("Mohon Pilih Customer");
      return false;
    }
    try {
      const response = await toast.promise(
        AxiosMasterGetWithParams(
          `report/retur-customer/DETAIL&${selected.kode_customer}&${selected.tanggal_awal}&${selected.tanggal_akhir}`,
          {
            show_all_data: true,
          }
        ),
        ToastAmbil
      );
      if (response.data.length < 1) {
        warning("Data Kosong, Silahkan Pilih CUstomer atau Tanggal Yang Lain");
        return false;
      }
      dispatch({
        type: GET_CETAK_ULANG_RETUR_PENJUALAN,
        payload: response.data[0].detail,
      });
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

export const printReturSupplierCU = (data) => {
  return async (dispatch, getState) => {
    const state = getState();
    const selected = state.form.FormLaporanReturnSupplierHead.values;
    purifiedData(selected);

    // INISIALISASI AUTOTABLE
    const tableRows = [];
    var table = data.detail_barang;
    // for each ticket pass all its data into an array
    table.forEach((ticket, i) => {
      const ticketData = [
        ++i,
        ticket.kode_barang,
        ticket.kode_bahan,
        { content: ticket.qty, styles: { halign: "right" } },
        { content: ticket.kadar_cetak, styles: { halign: "right" } },
        { content: formatGram(ticket.plastik), styles: { halign: "right" } },
        { content: formatGram(ticket.kulit), styles: { halign: "right" } },
        { content: ticket.pkg, styles: { halign: "right" } },
        { content: formatGram(ticket.bruto), styles: { halign: "right" } },
        { content: ticket.kadar_beli, styles: { halign: "right" } },
        { content: formatGram(ticket.netto, 3), styles: { halign: "right" } },
        { content: formatRp(ticket.ongkos_rp), styles: { halign: "right" } },
        { content: formatRp(ticket.harga_beli), styles: { halign: "right" } },
      ];
      // push each tickcet's info into a row
      tableRows.push(ticketData);
    });
    const footer = [
      "",
      "",
      "",
      {
        content: table
          .map((list) => parseFloat(list.qty))
          .reduce((a, b) => a + b, 0),
        styles: { halign: "right" },
      },
      "",
      "",
      "",
      {
        content: table
          .map((list) => parseFloat(list.pkg))
          .reduce((a, b) => a + b, 0),
        styles: { halign: "right" },
      },
      {
        content: formatGram(
          table.map((list) => parseFloat(list.bruto)).reduce((a, b) => a + b, 0)
        ),
        styles: { halign: "right" },
      },
      "",
      {
        content: formatGram(
          table
            .map((list) => parseFloat(list.netto))
            .reduce((a, b) => a + b, 0),
          3
        ),
        styles: { halign: "right" },
      },
      {
        content: formatRp(
          table
            .map((list) => parseFloat(list.ongkos_rp))
            .reduce((a, b) => a + b, 0)
        ),
        styles: { halign: "right" },
      },
      {
        content: formatRp(
          table
            .map((list) => parseFloat(list.harga_beli))
            .reduce((a, b) => a + b, 0)
        ),
        styles: { halign: "right" },
      },
    ];
    // tableRows.push(footer);
    var columTable = [
      [
        { content: "No" },
        { content: "Kode Barang" },
        { content: "Kode Bahan" },
        { content: "Qty", styles: { halign: "right" } },
        { content: "Kadar", styles: { halign: "right" } },
        { content: "P", styles: { halign: "right" } },
        { content: "K", styles: { halign: "right" } },
        { content: "Bungkus\n(Gr)", styles: { halign: "right" } },
        { content: "Berat\n(Gr)", styles: { halign: "right" } },
        { content: "Harga Jual", styles: { halign: "right" } },
        { content: "Murni\n(Gr)", styles: { halign: "right" } },
        { content: "Ongkos\n(Rp)", styles: { halign: "right" } },
      ],
    ];
    // AKHIR INISIALISASI AUTOTABLE
    PrintNota(
      "No Nota",
      data.no_bon,
      "Tanggal",
      formatDateIndo(data.tanggal_invoice),
      "",
      "",
      "Supplier",
      selected.nama_supplier,
      getUserID(),
      getToday(),
      "",
      columTable,
      "Retur Barang Supplier",
      tableRows,
      [footer, printInfo()]
    );
  };
};

export const printPenerimaanSupplierCU = (data) => {
  return async (dispatch, getState) => {
    const state = getState();
    const selected = state.form.FormLaporanPenerimaanSupplierHead.values;
    purifiedData(selected);

    // INISIALISASI AUTOTABLE
    const tableRows = [];
    var table = data.detail_barang;
    // for each ticket pass all its data into an array
    table.forEach((ticket, i) => {
      const ticketData = [
        { content: ++i },
        { content: ticket.kode_barang },
        { content: ticket.kode_bahan },
        { content: ticket.qty, styles: { halign: "right" } },
        { content: ticket.kadar_cetak, styles: { halign: "right" } },
        { content: formatGram(ticket.plastik), styles: { halign: "right" } },
        { content: formatGram(ticket.kulit), styles: { halign: "right" } },
        { content: ticket.pkg, styles: { halign: "right" } },
        { content: formatGram(ticket.bruto), styles: { halign: "right" } },
        { content: ticket.kadar_beli, styles: { halign: "right" } },
        { content: formatGram(ticket.netto, 3), styles: { halign: "right" } },
        { content: formatRp(ticket.ongkos_rp), styles: { halign: "right" } },
        { content: formatRp(ticket.harga_beli), styles: { halign: "right" } },
      ];
      // push each tickcet's info into a row
      tableRows.push(ticketData);
    });
    const footer = [
      "",
      "",
      "",
      {
        content: table
          .map((list) => parseFloat(list.qty))
          .reduce((a, b) => a + b, 0),
        styles: { halign: "right" },
      },
      "",
      "",
      "",
      {
        content: table
          .map((list) => parseFloat(list.pkg))
          .reduce((a, b) => a + b, 0),
        styles: { halign: "right" },
      },
      {
        content: formatGram(
          table.map((list) => parseFloat(list.bruto)).reduce((a, b) => a + b, 0)
        ),
        styles: { halign: "right" },
      },
      "",
      {
        content: formatGram(
          table
            .map((list) => parseFloat(list.netto))
            .reduce((a, b) => a + b, 0),
          3
        ),
        styles: { halign: "right" },
      },
      {
        content: formatRp(
          table
            .map((list) => parseInt(list.ongkos_rp))
            .reduce((a, b) => a + b, 0)
        ),
        styles: { halign: "right" },
      },
    ];
    // tableRows.push(footer);
    console.log(JSON.stringify(tableRows));
    var columTable = [
      [
        { content: "No" },
        { content: "Kode\nBarang" },
        { content: "Kode\nBahan" },
        { content: "Qty", styles: { halign: "right" } },
        { content: "Cap\nKode", styles: { halign: "right" } },
        { content: "P", styles: { halign: "right" } },
        { content: "K", styles: { halign: "right" } },
        { content: "Bungkus\n(Gr)", styles: { halign: "right" } },
        { content: "Berat\n(Gr)", styles: { halign: "right" } },
        { content: "Harga\n(Gr)", styles: { halign: "right" } },
        { content: "Murni\n(Gr)", styles: { halign: "right" } },
        { content: "Ongkos\n(Rp)", styles: { halign: "right" } },
      ],
    ];
    // AKHIR INISIALISASI AUTOTABLE
    PrintNota(
      "No Nota",
      data.no_bon,
      "Tanggal",
      formatDateIndo(data.tanggal_invoice),
      "",
      "",
      "Supplier",
      selected.kode_supplier,
      getUserID(),
      getToday(),
      "",
      columTable,
      "Terima Barang Supplier",
      tableRows,
      [footer, printInfo()]
    );
  };
};

export const printPembayaranHutangSupplier = (data) => {
  return async (dispatch, getState) => {
    const state = getState();
    const detail_bayar = state.cetakUlang.detail_bayar_pembayaran_supplier;
    const detailNota = state.cetakUlang.listPembayaranHutangSupplier;
    const tableRows = [
      [
        "Tunai",
        "-",
        {
          content: formatRp(detail_bayar[data].bayar_cash[0].cash_rp || 0) || 0,
          styles: { halign: "right" },
        },
        {
          content:
            formatRp(detail_bayar[data].bayar_cash[0].harga_emas || 0) || 0,
          styles: { halign: "right" },
        },
        "",
        "",
        "",
        {
          content:
            formatRp(detail_bayar[data].bayar_cash[0].cash_netto || 0) || 0,
          styles: { halign: "right" },
        },
      ],
      [
        "Transfer",
        "-",
        {
          content:
            formatRp(detail_bayar[data].bayar_trf[0].transfer_rp || 0) || 0,
          styles: { halign: "right" },
        },
        {
          content:
            formatRp(detail_bayar[data].bayar_trf[0].harga_emas || 0) || 0,
          styles: { halign: "right" },
        },
        "",
        "",
        "",
        {
          content:
            formatRp(detail_bayar[data].bayar_trf[0].transfer_netto || 0) || 0,
          styles: { halign: "right" },
        },
      ],
      [
        "Titip",
        "-",
        {
          content: 0,
          styles: { halign: "right" },
        },
        {
          content: formatRp(detailNota[data].total_putus_titip) || 0,
          styles: { halign: "right" },
        },
        "",
        "",
        "",
        {
          content: formatRp(detailNota[data].total_putus_titip) || 0,
          styles: { halign: "right" },
        },
      ],
      [
        "Transfer",
        "-",
        {
          content: formatRp(detail_bayar[data].bayar_trf[0].transfer_rp || 0),
          styles: { halign: "right" },
        },
        {
          content: formatRp(detail_bayar[data].bayar_trf[0].harga_emas || 0),
          styles: { halign: "right" },
        },
        "",
        "",
        "",
        {
          content: formatRp(
            detail_bayar[data].bayar_trf[0].transfer_netto || 0
          ),
          styles: { halign: "right" },
        },
      ],
    ];

    var ciok = detail_bayar[data].bayar_ciok;
    ciok.forEach((ticket, i) => {
      const ticketData = [
        "Ciok",
        ticket.kode_jenis,
        { content: formatGram(ticket.bruto), styles: { halign: "right" } },
        "",
        { content: ticket.kadar_transaksi, styles: { halign: "right" } },
        "",
        "",
        { content: formatGram(ticket.netto, 3), styles: { halign: "right" } },
      ];
      // push each tickcet's info into a row
      tableRows.push(ticketData);
    });
    var rongsok = detail_bayar[data].bayar_rongsok;
    rongsok.forEach((ticket, i) => {
      const ticketData = [
        "Rongsok",
        ticket.kode_jenis,
        { content: formatGram(ticket.bruto), styles: { halign: "right" } },
        "",
        { content: ticket.kadar_transaksi, styles: { halign: "right" } },
        "",
        "",
        { content: formatGram(ticket.netto, 3), styles: { halign: "right" } },
      ];
      // push each tickcet's info into a row
      tableRows.push(ticketData);
    });
    var columTable = [
      [
        "Jenis \nPembayaran",
        "Nama \nBarang",
        { content: "Gross \nBayar", styles: { halign: "right" } },
        { content: "Harga", styles: { halign: "right" } },
        { content: "Kadar", styles: { halign: "right" } },
        { content: "Pkg", styles: { halign: "right" } },
        { content: "Gw+Pkg", styles: { halign: "right" } },
        { content: "Netto", styles: { halign: "right" } },
      ],
    ];
    // AKHIR INISIALISASI AUTOTABLE
    PrintNota(
      "Tanggal",
      formatDateIndo(detailNota[data].tanggal),
      "",
      "",
      "No Nota",
      detailNota[data].no_bon,
      "",
      "",
      getUserID(),
      getToday(),
      "",
      columTable,
      "NOTA PEMBAYARAN SUPPLIER",
      tableRows
    );
  };
};

export const printTolakanSupplier = (data) => {
  return async (dispatch, getState) => {
    const state = getState();
    const selected = state.form.FormLaporanTolakanSupplier.values;
    const tableRows = [];
    var table = data.detail_barang;
    // for each ticket pass all its data into an array
    table.forEach((ticket, i) => {
      const ticketData = [
        ++i,
        ticket.kode_barang,
        ticket.kode_bahan,
        { content: ticket.kadar_cetak, styles: { halign: "right" } },
        { content: formatGram(ticket.plastik), styles: { halign: "right" } },
        { content: formatGram(ticket.kulit), styles: { halign: "right" } },
        { content: formatGram(ticket.pkg), styles: { halign: "right" } },
        { content: formatGram(ticket.bruto), styles: { halign: "right" } },
        { content: ticket.kadar_beli, styles: { halign: "right" } },
        { content: formatGram(ticket.netto, 3), styles: { halign: "right" } },
        { content: formatRp(ticket.ongkos_rp), styles: { halign: "right" } },
      ];
      // push each tickcet's info into a row
      tableRows.push(ticketData);
    });
    const footer = [
      "",
      "",
      "",
      "",
      "",
      {
        content: formatGram(
          table.map((list) => list.pkg).reduce((a, b) => a + b, 0)
        ),
        styles: { halign: "right" },
      },
      {
        content: formatGram(
          table.map((list) => list.bruto).reduce((a, b) => a + b, 0)
        ),
        styles: { halign: "right" },
      },
      "",
      {
        content: formatGram(
          table.map((list) => list.netto).reduce((a, b) => a + b, 0)
        ),
        styles: { halign: "right" },
      },
      {
        content: formatRp(
          table.map((list) => list.ongkos_rp).reduce((a, b) => a + b, 0)
        ),
        styles: { halign: "right" },
      },
    ];
    tableRows.push(footer);
    console.log(JSON.stringify(tableRows));
    var columTable = [
      [
        "No",
        "Kode Barang",
        "Kode Bahan",
        { content: "Cap Kode", styles: { halign: "right" } },
        { content: "P", styles: { halign: "right" } },
        { content: "K", styles: { halign: "right" } },
        { content: "Bungkus(Gr)", styles: { halign: "right" } },
        { content: "Berat(Gr)", styles: { halign: "right" } },
        { content: "Harga(Gr)", styles: { halign: "right" } },
        { content: "Murni(Gr)", styles: { halign: "right" } },
        { content: "Ongkos(Rp)", styles: { halign: "right" } },
      ],
    ];
    // AKHIR INISIALISASI AUTOTABLE
    PrintNota(
      "No Bon",
      data.no_bon.toUpperCase(),
      "Tanggal",
      formatDateIndo(data.tanggal),
      "Supplier",
      selected.kode_supplier,
      "",
      "",
      getUserID(),
      getToday(),
      "",
      columTable,
      "Tolakan Return Barang Supplier",
      tableRows
    );
  };
};

export const printPenjualanCU = (data) => {
  return async (dispatch, getState) => {
    // INISIALISASI AUTOTABLE
    const tableRows = [];
    var table = data.detail_barang;
    // for each ticket pass all its data into an array
    let totalPkg = 0;
    table.forEach((ticket, i) => {
      const brutopkg = parseFloat(ticket.bruto) + parseFloat(ticket.pkg);
      const ticketData = [
        ++i,
        ticket.kode_barang,
        ticket.kode_bahan,
        { content: formatGram(ticket.bruto), styles: { halign: "right" } },
        { content: ticket.kadar_jual, styles: { halign: "right" } },
        { content: formatGram(ticket.disc_gram), styles: { halign: "right" } },
        {
          content: formatGram(ticket.netto_disc, 3),
          styles: { halign: "right" },
        },
        { content: formatGram(ticket.pkg), styles: { halign: "right" } },
        { content: formatGram(brutopkg), styles: { halign: "right" } },
        { content: ticket.qty, styles: { halign: "right" } },
        { content: formatRp(ticket.ongkos_rp), styles: { halign: "right" } },
      ];
      // push each tickcet's info into a row
      tableRows.push(ticketData);
      totalPkg += brutopkg;
    });
    const summary = [
      "",
      "",
      "Total",
      {
        content: formatGram(
          table.map((list) => parseFloat(list.bruto)).reduce((a, b) => a + b, 0)
        ),
        styles: { halign: "right" },
      },
      "",
      {
        content: formatGram(
          table
            .map((list) => parseFloat(list.disc_gram))
            .reduce((a, b) => a + b, 0)
        ),
        styles: { halign: "right" },
      },
      {
        content: formatGram(
          table
            .map((list) => parseFloat(list.netto_disc, 3))
            .reduce((a, b) => a + b, 0),
          3
        ),
        styles: { halign: "right" },
      },
      {
        content: formatGram(
          table
            .map((list) => parseFloat(list.pkg, 3))
            .reduce((a, b) => a + b, 0)
        ),
        styles: { halign: "right" },
      },
      {
        content: formatGram(totalPkg),
        styles: { halign: "right" },
      },
      {
        content: table
          .map((list) => parseFloat(list.qty))
          .reduce((a, b) => a + b, 0),
        styles: { halign: "right" },
      },
      {
        content: formatRp(
          table
            .map((list) => parseFloat(list.ongkos_rp, 3))
            .reduce((a, b) => a + b, 0)
        ),
        styles: { halign: "right" },
      },
    ];

    let footer = [
      summary,
      [
        {
          content: "Diskon Global : " + formatGram(data.disc_gram),
          styles: { halign: "right" },
          colSpan: 11,
        },
      ],
      [
        {
          content: "Total 24K : " + formatGram(data.total_netto, 3),
          styles: { halign: "right" },
          colSpan: 11,
        },
      ],
      printInfo(),
    ];
    var columTable = [
      "No",
      "Kode Barang",
      "Kode Bahan",
      "Bruto \n(Gr)",
      "Harga \n(%)",
      "Diskon",
      "Netto \n(Gr)",
      "Pkg",
      "Bruto + Pkg \n(Gr)",
      "Qty",
      "Ongkos \n(Rp)",
    ];
    // AKHIR INISIALISASI AUTOTABLE
    PrintNota(
      "No Inv",
      data.no_bon,
      "Tanggal",
      formatDateIndo(data.tanggal),
      "",
      "",
      "Customer",
      data.nama_toko,
      "ADMIN",
      getToday(),
      "",
      [columTable],
      "Transaksi Penjualan",
      tableRows,
      footer
    );
  };
};

export const printReturPenjualanCU = (data) => {
  return async (disaptch, getState) => {
    const tableRows = [];
    var table = data.detail_barang;
    // for each ticket pass all its data into an array
    let totalPkg = 0;
    table.forEach((ticket, i) => {
      const brutopkg = parseFloat(ticket.bruto) + parseFloat(ticket.pkg);
      const ticketData = [
        ++i,
        ticket.kode_barang,
        ticket.kode_bahan,
        { content: formatGram(ticket.bruto), styles: { halign: "right" } },
        { content: ticket.kadar_jual, styles: { halign: "right" } },
        { content: formatGram(ticket.disc_gram), styles: { halign: "right" } },
        { content: formatGram(ticket.netto, 3), styles: { halign: "right" } },
        { content: formatGram(ticket.pkg), styles: { halign: "right" } },
        { content: formatGram(brutopkg), styles: { halign: "right" } },
        { content: ticket.qty, styles: { halign: "right" } },
        { content: formatRp(ticket.ongkos_rp), styles: { halign: "right" } },
      ];
      // push each tickcet's info into a row
      tableRows.push(ticketData);
      totalPkg += brutopkg;
    });
    const summary = [
      "",
      "",
      "Total",
      {
        content: formatGram(
          table.map((list) => parseFloat(list.bruto)).reduce((a, b) => a + b, 0)
        ),
        styles: { halign: "right" },
      },
      "",
      {
        content: formatGram(
          table
            .map((list) => parseFloat(list.disc_gram))
            .reduce((a, b) => a + b, 0)
        ),
        styles: { halign: "right" },
      },
      {
        content: formatGram(
          table
            .map((list) => parseFloat(list.netto, 3))
            .reduce((a, b) => a + b, 0),
          3
        ),
        styles: { halign: "right" },
      },
      {
        content: formatGram(
          table
            .map((list) => parseFloat(list.pkg, 3))
            .reduce((a, b) => a + b, 0)
        ),
        styles: { halign: "right" },
      },
      {
        content: formatGram(totalPkg),
        styles: { halign: "right" },
      },
      {
        content: table
          .map((list) => parseFloat(list.qty))
          .reduce((a, b) => a + b, 0),
        styles: { halign: "right" },
      },
      {
        content: formatRp(
          table
            .map((list) => parseFloat(list.ongkos_rp, 3))
            .reduce((a, b) => a + b, 0)
        ),
        styles: { halign: "right" },
      },
    ];

    let total24K =
      table
        .map((list) => parseFloat(list.netto, 3))
        .reduce((a, b) => a + b, 0) - parseFloat(data.disc);
    let footer = [
      summary,
      [
        {
          content: "Diskon Global : " + data.disc,
          styles: { halign: "right" },
          colSpan: 11,
        },
      ],
      [
        {
          content: "Total 24K : " + formatGram(total24K, 3),
          styles: { halign: "right" },
          colSpan: 11,
        },
      ],
      printInfo(),
    ];
    var columTable = [
      "No",
      "Kode Barang",
      "Kode Bahan",
      "Bruto \n(Gr)",
      "Harga \n(%)",
      "Diskon",
      "Netto \n(Gr)",
      "Pkg",
      "Bruto + Pkg \n(Gr)",
      "Qty",
      "Ongkos \n(Rp)",
    ];
    // AKHIR INISIALISASI AUTOTABLE
    PrintNota(
      "No Bon",
      data.no_bon.toUpperCase(),
      "Tanggal",
      formatDateIndo(data.tanggal_invoice),
      "",
      "",
      "",
      "",
      getUserID(),
      getToday(),
      "",
      [columTable],
      "Return Transaksi Penjualan",
      tableRows,
      footer
    );
  };
};
