import { finishSend, progressSend } from "../../actions/LogicAction";
import { toast } from "react-toastify";
import { AxiosMasterGetWithParams } from "../../Axios";
import jsPDF from "jspdf";
import {
  formatDateIndo,
  formatGram,
  formatRp,
  printInfo,
  purifiedData,
} from "../../helper";
import { ToastKirim } from "../../actions/toast";
import { warning } from "../../Alert";

export const GET_LAPORAN_OMZET_SUPPLIER = "GET_LAPORAN_OMZET_SUPPLIER";
export const GET_LAPORAN_VALIDASI = "GET_LAPORAN_VALIDASI";
export const GET_LAPORAN_STOCK_SAMPLE = "GET_LAPORAN_STOCK_SAMPLE";
export const GET_LAPORAN_KARTU_STOCK_SAMPLE = "GET_LAPORAN_KARTU_STOCK_SAMPLE";
export const GET_LAPORAN_SUMMARY_STOCK_SAMPLE =
  "GET_LAPORAN_SUMMARY_STOCK_SAMPLE";
export const GET_LAPORAN_PINDAH_BARANG_SAMPLE =
  "GET_LAPORAN_PINDAH_BARANG_SAMPLE";
export const GET_LAPORAN_SALDO_STOCK_BARCODE =
  "GET_LAPORAN_SALDO_STOCK_BARCODE";
export const GET_LAPORAN_KIRIM_STOCK_RETUR = "GET_LAPORAN_KIRIM_STOCK_RETUR";
export const GET_LAPORAN_CORRECTION_NOTE = "GET_LAPORAN_CORRECTION_NOTE";
export const GET_LAPORAN_OMZET_CUSTOMER = "GET_LAPORAN_OMZET_CUSTOMER";
export const GET_LAPORAN_ANALISA_CUSTOMER = "GET_LAPORAN_ANALISA_CUSTOMER";
export const GET_LAPORAN_STOCK_GLOBAL = "GET_LAPORAN_STOCK_GLOBAL";
export const GET_LAPORAN_KARTU_STOCK = "GET_LAPORAN_KARTU_STOCK";
export const GET_LAPORAN_HISTORY_CUSTOMER = "GET_LAPORAN_HISTORY_CUSTOMER";

export const requestLaporanStockSampel = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.StockSampel.values;
    purifiedData(selected);

    const query = {
      type: "STOCK",
      ...selected,
    };
    if (selected?.lokasi === undefined) {
      warning("Mohon Pilih Lokasi");
      dispatch(finishSend());
      return false;
    }
    try {
      const response = await toast.promise(
        AxiosMasterGetWithParams("sampel/report/", query),
        ToastKirim
      );
      if (response.data.length < 1) {
        warning("Data Kosong");
        dispatch(finishSend());
        return false;
      }
      dispatch({
        type: GET_LAPORAN_STOCK_SAMPLE,
        payload: response.data,
      });
    } catch (e) {
      dispatch(finishSend());
    }
    dispatch(finishSend());
  };
};

export const PrintLaporanStockSamplePDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanStockSample = state.laporan.dataLaporanStockSample;
    printStockSampel(dataLaporanStockSample);
  };
};
export const requestLaporanKartuStockSampel = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.KartuStockSampel.values;
    purifiedData(selected);

    const query = {
      type: "KARTU STOCK",
      ...selected,
    };
    try {
      const response = await toast.promise(
        AxiosMasterGetWithParams("sampel/report/", query),
        ToastKirim
      );
      if (response.data.length < 1) {
        warning("Data Kosong");
        dispatch(finishSend());
        return false;
      }
      dispatch({
        type: GET_LAPORAN_KARTU_STOCK_SAMPLE,
        payload: {
          data: response.data,
          tgl_from: selected.tgl_from,
          tgl_to: selected.tgl_to,
        },
      });
    } catch (e) {
      dispatch(finishSend());
    }
    dispatch(finishSend());
  };
};

export const PrintLaporanKartuStockSamplePDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanKartuStockSample =
      state.laporan.dataLaporanKartuStockSample;
    printKartuStockSampel(dataLaporanKartuStockSample);
  };
};

export const requestLaporanSummaryStockSampel = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.SummaryStockSampel.values;
    purifiedData(selected);

    const query = {
      type: "SUMMARY STOCK",
      ...selected,
    };
    try {
      const response = await toast.promise(
        AxiosMasterGetWithParams("sampel/report/", query),
        ToastKirim
      );
      if (response.data.length < 1) {
        warning("Data Kosong");
        dispatch(finishSend());
        return false;
      }
      dispatch({
        type: GET_LAPORAN_SUMMARY_STOCK_SAMPLE,
        payload: {
          data: response.data,
          tanggal: selected.tanggal,
        },
      });
    } catch (e) {
      dispatch(finishSend());
    }
    dispatch(finishSend());
  };
};

export const PrintLaporanSummaryStockSamplePDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanSummaryStockSample =
      state.laporan.dataLaporanSummaryStockSample;
    printSummaryStockSampel(dataLaporanSummaryStockSample);
  };
};

export const requestLaporanPindahBarangSampel = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.PindahBarangSampel.values;
    purifiedData(selected);

    const query = {
      ...selected,
    };
    if (selected?.kode_lokasi_from === undefined) {
      warning("Mohon Pilih Lokasi asal");
      dispatch(finishSend());
      return false;
    } else if (selected?.kode_lokasi_to === undefined) {
      warning("Mohon Pilih Lokasi tujuan");
      dispatch(finishSend());
      return false;
    }
    try {
      const response = await toast.promise(
        AxiosMasterGetWithParams("pindah-sampel/report/", query),
        ToastKirim
      );
      if (response.data.length < 1) {
        warning("Data Kosong");
        dispatch(finishSend());
        return false;
      }
      dispatch({
        type: GET_LAPORAN_PINDAH_BARANG_SAMPLE,
        payload: {
          data: response.data,
          tgl_from: selected.tgl_from,
          tgl_to: selected.tgl_to,
        },
      });
    } catch (e) {
      dispatch(finishSend());
    }
    dispatch(finishSend());
  };
};

export const PrintLaporanPindahBarangSamplePDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanPindahBarangSample =
      state.laporan.dataLaporanPindahBarangSample;
    printPindahBarangSampel(
      dataLaporanPindahBarangSample.data,
      dataLaporanPindahBarangSample.tgl_from,
      dataLaporanPindahBarangSample.tgl_to
    );
  };
};

export const requestLaporanSaldoStockBarcode = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.SaldoStockBarcode.values;
    purifiedData(selected);
    const query = {
      ...selected,
    };
    if (selected?.kode_lokasi === undefined) {
      warning("Mohon Pilih Lokasi");
      dispatch(finishSend());
      return false;
    }
    try {
      const response = await toast.promise(
        AxiosMasterGetWithParams("mutasi-barang/report/", query),
        ToastKirim
      );
      if (response.data.length < 1) {
        warning("Data Kosong");
        dispatch(finishSend());
        return false;
      }
      dispatch({
        type: GET_LAPORAN_SALDO_STOCK_BARCODE,
        payload: response.data,
      });
    } catch (e) {
      dispatch(finishSend());
    }
    dispatch(finishSend());
  };
};
export const requestLaporanKirimStockRetur = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.LaporanKirimStockRetur.values;
    purifiedData(selected);
    const query = {
      ...selected,
    };
    try {
      const response = await toast.promise(
        AxiosMasterGetWithParams("kirim-stock-retur/report/", query),
        ToastKirim
      );
      if (response.data.length < 1) {
        warning("Data Kosong");
        dispatch(finishSend());
        return false;
      }
      dispatch({
        type: GET_LAPORAN_KIRIM_STOCK_RETUR,
        payload: {
          data: response.data,
          tgl_from: selected.tgl_from,
          tgl_to: selected.tgl_to,
          no_transaksi: selected.no_transaksi,
        },
      });
    } catch (e) {
      console.log(e);
      dispatch(finishSend());
    }
    dispatch(finishSend());
  };
};

export const requestLaporanStockGlobal = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.StockGlobal.values;
    purifiedData(selected);
    const query = {
      ...selected,
    };
    try {
      const response = await toast.promise(
        AxiosMasterGetWithParams("report/stock-global", query),
        ToastKirim
      );
      if (response.data.length < 1) {
        warning("Data Kosong");
        dispatch(finishSend());
        return false;
      }
      let total_in = response.data
        .map((x) => x.saldo_in)
        .reduce((a, b) => a + b, 0);
      let total_out = response.data
        .map((x) => x.saldo_out)
        .reduce((a, b) => a + b, 0);
      dispatch({
        type: GET_LAPORAN_STOCK_GLOBAL,
        payload: {
          data: response.data,
          tgl_from: selected.tgl_from,
          tgl_to: selected.tgl_to,
          kode_lokasi: selected.kode_lokasi,
          total_in: formatGram(total_in),
          total_out: formatGram(total_out),
          saldo_akhir: formatGram(total_in - total_out),
        },
      });
    } catch (e) {
      dispatch(finishSend());
    }
    dispatch(finishSend());
  };
};

export const PrintStockGlobal = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanStockGlobal = state.laporan.dataLaporanStockGlobal;
    printStockGlobal(
      dataLaporanStockGlobal.data,
      dataLaporanStockGlobal.kode_lokasi,
      dataLaporanStockGlobal.tgl_from,
      dataLaporanStockGlobal.tgl_to
    );
  };
};

export const requestLaporanKartuStock = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.KartuStock.values;
    purifiedData(selected);
    const query = {
      ...selected,
    };
    try {
      const response = await toast.promise(
        AxiosMasterGetWithParams("report/kartu-stock", query),
        ToastKirim
      );
      if (response.data.length < 1) {
        warning("Data Kosong");
        dispatch(finishSend());
        return false;
      }
      dispatch({
        type: GET_LAPORAN_KARTU_STOCK,
        payload: {
          data: response.data,
          tgl_from: selected.tgl_from,
          tgl_to: selected.tgl_to,
          kode_lokasi: selected.kode_lokasi,
        },
      });
    } catch (e) {
      dispatch({
        type: GET_LAPORAN_KARTU_STOCK,
        payload: {
          data: [
            {
              tanggal: "27-12-2021",
              no_bon: "TS2201050003",
              terima_supplier: 32594.46,
              batal_jual: 0,
              retur_customer: 0,
              penjualan: 0,
              retur_supplier: 0,
              koreksi_so: 0,
              recycle: 0,
              saldo_barang: 32594.46,
              keterangan: "KETERANGAN / NAMA CUSTOMER",
            },
            {
              tanggal: "27-12-2021",
              no_bon: "TS2201050003",
              terima_supplier: 32594.46,
              batal_jual: 0,
              retur_customer: 0,
              penjualan: 0,
              retur_supplier: 0,
              koreksi_so: 0,
              recycle: 0,
              saldo_barang: 32594.46,
              keterangan: "KETERANGAN / NAMA CUSTOMER",
            },
          ],
          tgl_from: selected.tgl_from,
          tgl_to: selected.tgl_to,
          kode_lokasi: selected.kode_lokasi,
        },
      });
      dispatch(finishSend());
    }
    dispatch(finishSend());
  };
};

export const PrintKartuStock = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanKartuStock = state.laporan.dataLaporanKartuStock;
    printKartuStock(
      dataLaporanKartuStock.data,
      dataLaporanKartuStock.kode_lokasi,
      dataLaporanKartuStock.tgl_from,
      dataLaporanKartuStock.tgl_to
    );
  };
};

export const requestLaporanOmzetSupplier = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.LaporanOmzetSupplier.values;
    purifiedData(selected);

    const query = {
      ...selected,
    };
    if (selected?.type === undefined) {
      warning("Mohon Pilih Type");
      dispatch(finishSend());
      return false;
    } else if (selected?.kode_supplier === undefined) {
      warning("Mohon Pilih Supplier");
      dispatch(finishSend());
      return false;
    } else if (selected?.kode_cabang_supplier === undefined) {
      warning("Mohon Pilih Cabang Supplier");
      dispatch(finishSend());
      return false;
    }
    try {
      const response = await toast.promise(
        AxiosMasterGetWithParams("omzet-supplier/report/", query),
        ToastKirim
      );
      if (response.data.length < 1) {
        warning("Data Kosong");
        dispatch(finishSend());
        return false;
      }

      let feedback = response.data;
      let tanggal =
        selected.type === "PERBULAN"
          ? dateToString(selected.tgl_to)
          : selected.type === "PERTAHUN"
          ? selected.tgl_to.split("-")[0]
          : selected.tgl_to;
      dispatch({
        type: GET_LAPORAN_OMZET_SUPPLIER,
        payload: {
          data: ConvertOmzetSupplier(feedback, tanggal),
          tanggal: tanggal,
          tanggal_awal: selected.tgl_from,
          tanggal_akhir: selected.tgl_to,
          kode_cabang_supplier: selected.kode_cabang_supplier,
        },
      });
      // printOmzetSupplier([ConvertOmzetSupplier(feedback, tanggal)], tanggal)
    } catch (e) {
      dispatch(finishSend());
    }
    dispatch(finishSend());
  };
};
export const PrintLaporanOmzetSupplierPDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanOmzetSupplier = state.laporan.dataLaporanOmzetSupplier;
    printOmzetSupplier(
      [dataLaporanOmzetSupplier.data],
      dataLaporanOmzetSupplier.tanggal
    );
  };
};
export const requestLaporanCorrectionNote = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.LaporanCorrectionNote.values;
    purifiedData(selected);

    const query = {
      ...selected,
    };
    if (selected?.kode_customer === undefined) {
      warning("Mohon Pilih Customer");
      dispatch(finishSend());
      return false;
    }
    try {
      const response = await toast.promise(
        AxiosMasterGetWithParams("correction-note/report/", query),
        ToastKirim
      );
      if (response.data.length < 1) {
        warning("Data Kosong");
        dispatch(finishSend());
        return false;
      }
      dispatch({
        type: GET_LAPORAN_CORRECTION_NOTE,
        payload: {
          data: response.data,
        },
      });
    } catch (e) {
      dispatch(finishSend());
    }
    dispatch(finishSend());
  };
};
export const PrintLaporanCorrectionNotePDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanCorrectioNote = state.laporan.dataLaporanCorrectioNote;
    printCorrectionNote(dataLaporanCorrectioNote.data);
  };
};
export const requestLaporanValidasi = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.FormLaporanValidasi.values;
    purifiedData(selected);

    const query = {
      ...selected,
    };
    if (selected?.user_id === undefined) {
      warning("Mohon Tuliskan User ID");
      dispatch(finishSend());
      return false;
    }
    try {
      const response = await toast.promise(
        AxiosMasterGetWithParams("validasi-transaksi/report", query),
        ToastKirim
      );
      if (response.data.length < 1) {
        warning("Data Kosong");
        dispatch(finishSend());
        return false;
      }
      dispatch({
        type: GET_LAPORAN_VALIDASI,
        payload: {
          data: response.data,
          tgl_from: selected.tgl_from,
          tgl_to: selected.tgl_to,
        },
      });
    } catch (e) {
      dispatch(finishSend());
    }
    dispatch(finishSend());
  };
};
export const PrintLaporanValidasiPDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanValidasi = state.laporan.dataLaporanValidasi;
    printValidasiCustomer(
      dataLaporanValidasi.data,
      dataLaporanValidasi.tgl_from,
      dataLaporanValidasi.tgl_to
    );
  };
};

export const requestLaporanOmzetCustomer = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.LaporanOmzetCustomer.values;
    purifiedData(selected);

    const query = {
      ...selected,
    };
    if (selected?.kode_customer === undefined) {
      warning("Mohon Pilih Customer");
      dispatch(finishSend());
      return false;
    } else if (selected?.kode_kategori === undefined) {
      warning("Mohon Pilih Kategori");
      dispatch(finishSend());
      return false;
    }
    try {
      const response = await toast.promise(
        AxiosMasterGetWithParams("omzet-customer/report/", query),
        ToastKirim
      );
      if (response.data.length < 1) {
        warning("Data Kosong");
        dispatch(finishSend());
        return false;
      }
      dispatch({
        type: GET_LAPORAN_OMZET_CUSTOMER,
        payload: {
          data: response.data,
          tgl_from: selected.tgl_from,
          tgl_to: selected.tgl_to,
        },
      });
    } catch (e) {
      dispatch(finishSend());
    }
    dispatch(finishSend());
  };
};
export const PrintLaporanOmzetCustomerPDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanOmzetCustomer = state.laporan.dataLaporanOmzetCustomer;
    printOmzetCustomer(
      dataLaporanOmzetCustomer.data,
      dataLaporanOmzetCustomer.tgl_from,
      dataLaporanOmzetCustomer.tgl_to
    );
  };
};

export const requestLaporanAnalisaCustomer = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.LaporanAnalisaTransaksiCustomer.values;
    purifiedData(selected);

    const query = {
      ...selected,
    };
    if (selected?.kode_customer === undefined) {
      warning("Mohon Pilih Customer");
      dispatch(finishSend());
      return false;
    } else if (selected?.kadar === undefined) {
      warning("Mohon Pilih Kadar");
      dispatch(finishSend());
      return false;
    }
    try {
      const response = await toast.promise(
        AxiosMasterGetWithParams("transaksi-customer/report/", query),
        ToastKirim
      );
      if (response.data.length < 1) {
        warning("Data Kosong");
        dispatch(finishSend());
        return false;
      }
      dispatch({
        type: GET_LAPORAN_ANALISA_CUSTOMER,
        payload: {
          data: response.data,
          kode_customer: selected.kode_customer,
          kadar: selected.kadar,
          tgl_from: selected.tgl_from,
          tgl_to: selected.tgl_to,
        },
      });
    } catch (e) {
      dispatch(finishSend());
    }
    dispatch(finishSend());
  };
};

export const PrintLaporanAnalisaCustomerPDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanAnalisaCustomer = state.laporan.dataLaporanAnalisaCustomer;
    printAnalisaCustomer(
      dataLaporanAnalisaCustomer.data,
      dataLaporanAnalisaCustomer.kode_customer,
      dataLaporanAnalisaCustomer.kadar,
      dataLaporanAnalisaCustomer.tgl_from,
      dataLaporanAnalisaCustomer.tgl_to
    );
  };
};

export const requestLaporanHistoryCustomer = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.HistoryCustomer.values;
    purifiedData(selected);

    const query = {
      ...selected,
    };
    if (selected?.kode_customer === undefined) {
      warning("Mohon Pilih Customer");
      dispatch(finishSend());
      return false;
    }
    try {
      const response = await toast.promise(
        AxiosMasterGetWithParams("report/history-customer", query),
        ToastKirim
      );
      if (response.data.length < 1) {
        warning("Data Kosong");
        dispatch(finishSend());
        return false;
      }
      dispatch({
        type: GET_LAPORAN_HISTORY_CUSTOMER,
        payload: {
          data: response.data,
          kode_customer: selected.kode_customer,
          start_date: selected.start_date,
          end_date: selected.end_date,
        },
      });
    } catch (e) {
      dispatch(finishSend());
    }
    dispatch(finishSend());
  };
};

export const PrintHistoryCustomer = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanHistoryCustomer = state.laporan.dataLaporanHistoryCustomer;
    const dataCustomer = state.provinsi.customer;
    const selectedCustomer = dataCustomer.find(
      (x) => x.kode_customer === dataLaporanHistoryCustomer.kode_customer
    );
    printHistoryCustomer(
      dataLaporanHistoryCustomer.data,
      selectedCustomer.kode_ext,
      dataLaporanHistoryCustomer.start_date,
      dataLaporanHistoryCustomer.end_date
    );
  };
};

//PRINT AREA
function printStockSampel(data) {
  // initialize jsPDF
  const doc = new jsPDF("portrait");
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];

  let tableColumn = [
    [
      {
        content: `Jenis`,
      },
      {
        content: `Kode`,
      },
      {
        content: `Barcode`,
      },
      {
        content: `Nama Barang`,
        styles: { halign: "right" },
      },
      {
        content: `Kadar`,
        styles: { halign: "right" },
      },
      {
        content: `Berat`,
        styles: { halign: "right" },
      },
      {
        content: `Berat Asli`,
        styles: { halign: "right" },
      },
      {
        content: `Harga Jual`,
        styles: { halign: "right" },
      },
    ],
  ];
  data.forEach((item, index) => {
    doc.setFontSize(15);
    // doc.addImage(logo_ayu_base64, "PNG", 14, 10, 35, 12);
    doc.text("LAPORAN STOCK SAMPEL", 14, 10);

    doc.setFontSize(10);
    //row 1
    doc.text(`GUDANG : ${item.kode_lokasi}`, 14, 15);
    //   row 2
    doc.text(`KATEGORI	    : ${item.kode_kategori}`, 120, 15);
    //   row 3
    doc.text(`BAHAN	: ${item.kode_bahan}`, 14, 18);
    item.detail_barang.forEach((x) => {
      let rows = [
        x.kode_barang,
        x.kode_sampel,
        x.kode_barcode,
        x.nama_sampel,
        { content: x.kadar_cetak, styles: { halign: "right" } },
        { content: formatGram(x.berat, 3), styles: { halign: "right" } },
        { content: formatGram(x.berat), styles: { halign: "right" } },
        { content: x.kadar_jual, styles: { halign: "right" } },
      ];
      tableRows.push(rows);
    });
    doc.autoTable({
      head: tableColumn,
      body: tableRows,
      startY: 25,
      theme: "plain",
      showHead: "firstPage",
      showFoot: "lastPage",
      rowPageBreak: "avoid",
      pageBreak: "avoid",
      margin: { top: 20 },
      bodyStyles: { lineColor: "#969696", fontSize: 8, lineWidth: 0.0 },
      headStyles: {
        lineColor: "#969696",
        lineWidth: 0.0,
        fontSize: 8,
        fillColor: "#E8E8E8",
      },
      footStyles: {
        lineColor: "#969696",
        lineWidth: 0.0,
        fillColor: "#E8E8E8",
        fontSize: 8,
      },
    });

    const pages = doc.internal.getNumberOfPages();
    const pageWidth = doc.internal.pageSize.width; //Optional
    const pageHeight = doc.internal.pageSize.height; //Optional
    doc.setFontSize(10); //Optional
    for (let j = 1; j < pages + 1; j++) {
      let horizontalPos = pageWidth / 2; //Can be fixed number
      let verticalPos = pageHeight - 10; //Can be fixed number
      doc.setPage(j);
      doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
        align: "center",
      });
    }
    if (index < data.length - 1) {
      tableRows = [];
      doc.addPage();
    }
  });
  // const date = Date().split(" ");
  // we use a date string to generate our filename.
  // const dateStr = date[2] + date[3] + date[4];
  // ticket title. and margin-top + margin-left

  doc.autoPrint();
  doc.setProperties({
    title: "LAPORAN STOCK SAMPEL",
  });
  // doc.save(`PenerimaanSUpplier.pdf`);
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    `
<html>
<head>
  <title>LAPORAN STOCK SAMPLE</title>
</head>
<body style='margin:0 !important; position: relative;'>
  <embed width='100%' height='100%'src='${string}'></embed>
</body>
</html>
`
  );
}

function printKartuStockSampel(data) {
  // initialize jsPDF
  const doc = new jsPDF("portrait");
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];

  let tableColumn = [
    [
      {
        content: `TANGGAL`,
      },
      {
        content: `REFF`,
      },
      {
        content: `LOKASI ASAL`,
      },
      {
        content: `LOKASI TUJUAN`,
      },
      {
        content: `KETERANGAN`,
      },
    ],
  ];
  doc.setFontSize(15);
  // doc.addImage(logo_ayu_base64, "PNG", 14, 10, 35, 12);
  doc.text("LAPORAN KARTU STOCK SAMPEL", 14, 10);

  doc.setFontSize(10);
  //row 1
  data.data.forEach((x) => {
    let rows = [
      x.tanggal,
      x.no_reff,
      x.lokasi_awal,
      x.lokasi_tujuan,
      x.keterangan,
    ];
    tableRows.push(rows);
  });
  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    startY: 25,
    theme: "plain",
    rowPageBreak: "avoid",
    pageBreak: "avoid",
    margin: { top: 20 },
    bodyStyles: { lineColor: "#969696", fontSize: 8, lineWidth: 0.0 },
    headStyles: {
      lineColor: "#969696",
      lineWidth: 0.0,
      fontSize: 8,
      fillColor: "#E8E8E8",
    },
    footStyles: {
      lineColor: "#969696",
      lineWidth: 0.0,
      fillColor: "#E8E8E8",
      fontSize: 8,
    },
  });

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  // const date = Date().split(" ");
  // we use a date string to generate our filename.
  // const dateStr = date[2] + date[3] + date[4];
  // ticket title. and margin-top + margin-left

  doc.autoPrint();
  doc.setProperties({
    title: "LAPORAN KARTU STOCK SAMPEL",
  });
  // doc.save(`PenerimaanSUpplier.pdf`);
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    `
<html>
<head>
  <title>LAPORAN KARTU STOCK SAMPLE</title>
</head>
<body style='margin:0 !important; position: relative;'>
  <embed width='100%' height='100%'src='${string}'></embed>
</body>
</html>
`
  );
}

function printSummaryStockSampel(data) {
  // initialize jsPDF
  const doc = new jsPDF("portrait");
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];

  let tableColumn = [
    [
      {
        content: `LOKASI`,
        rowSpan: 2,
      },
      {
        content: `KATEGORI`,
        rowSpan: 2,
      },
      {
        content: `SALDO AWAL`,
        colSpan: 2,
      },
      {
        content: `MUTASI IN`,
        colSpan: 2,
      },
      {
        content: `MUTASI OUT`,
        colSpan: 2,
      },
      {
        content: `HANCUR`,
        colSpan: 2,
      },
      {
        content: `SALDO AKHIR`,
        colSpan: 2,
      },
    ],
    [
      {
        content: `QTY`,
      },
      {
        content: `BERAT`,
      },
      {
        content: `QTY`,
      },
      {
        content: `BERAT`,
      },
      {
        content: `QTY`,
      },
      {
        content: `BERAT`,
      },
      {
        content: `QTY`,
      },
      {
        content: `BERAT`,
      },
      {
        content: `QTY`,
      },
      {
        content: `BERAT`,
      },
    ],
  ];
  doc.setFontSize(15);
  // doc.addImage(logo_ayu_base64, "PNG", 14, 10, 35, 12);
  doc.text("LAPORAN SUMMARY STOCK SAMPEL", 14, 10);

  doc.setFontSize(10);
  //row 1
  data.forEach((x) => {
    let rows = [
      x.kode_lokasi,
      x.kode_kategori,
      x.details[0].saldo_awal.qty,
      formatGram(x.details[0].saldo_awal.berat),
      x.details[0].mutasi_in.qty,
      formatGram(x.details[0].mutasi_in.berat),
      x.details[0].mutasi_out.qty,
      formatGram(x.details[0].mutasi_out.berat),
      x.details[0].hancur_sampel.qty,
      formatGram(x.details[0].hancur_sampel.berat),
      x.details[0].saldo_akhir.qty,
      formatGram(x.details[0].saldo_akhir.berat),
    ];
    tableRows.push(rows);
  });
  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    startY: 25,
    theme: "plain",
    rowPageBreak: "avoid",
    pageBreak: "avoid",
    margin: { top: 20 },
    bodyStyles: { lineColor: "#969696", fontSize: 8, lineWidth: 0.0 },
    headStyles: {
      lineColor: "#969696",
      lineWidth: 0.0,
      fontSize: 8,
      fillColor: "#E8E8E8",
    },
    footStyles: {
      lineColor: "#969696",
      lineWidth: 0.0,
      fillColor: "#E8E8E8",
      fontSize: 8,
    },
  });

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  // const date = Date().split(" ");
  // we use a date string to generate our filename.
  // const dateStr = date[2] + date[3] + date[4];
  // ticket title. and margin-top + margin-left

  doc.autoPrint();
  doc.setProperties({
    title: "LAPORAN SUMMARY STOCK SAMPEL",
  });
  // doc.save(`PenerimaanSUpplier.pdf`);
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    `
<html>
<head>
  <title>LAPORAN SUMMARY STOCK SAMPLE</title>
</head>
<body style='margin:0 !important; position: relative;'>
  <embed width='100%' height='100%'src='${string}'></embed>
</body>
</html>
`
  );
}

function printPindahBarangSampel(data, tgl_from, tgl_to) {
  // initialize jsPDF
  const doc = new jsPDF("portrait");
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  let startY = 25;
  data.forEach((item, index) => {
    let tableColumn = [
      [
        {
          content: `NO PINDAH : ${item.no_pindah_sampel}`,
          colSpan: 2,
        },
        {
          content: `DARI : ${item.dari}`,
          colSpan: 2,
        },
        {
          content: `KE : ${item.tujuan}`,
          colSpan: 2,
        },
      ],
      [
        {
          content: `No`,
        },
        {
          content: ``,
        },
        {
          content: `KODE`,
        },
        {
          content: `BRUTO`,
          styles: { halign: "right" },
        },
        {
          content: `PKG`,
          styles: { halign: "right" },
        },
        {
          content: `GROSS`,
          styles: { halign: "right" },
        },
      ],
    ];
    doc.setFontSize(15);
    // doc.addImage(logo_ayu_base64, "PNG", 14, 10, 35, 12);
    doc.text("LAPORAN STOCK SAMPEL", 14, 10);

    doc.setFontSize(10);
    //row 1
    doc.text(`TANGGAL : ${tgl_from} s/d ${tgl_to}`, 14, 15);
    item.detail_barang.forEach((x, i) => {
      let rows = [
        ++i,
        x.kadar_cetak,
        x.kode_sampel,
        { content: formatGram(x.bruto, 3), styles: { halign: "right" } },
        { content: formatGram(0), styles: { halign: "right" } },
        { content: formatGram(x.bruto), styles: { halign: "right" } },
      ];
      tableRows.push(rows);
    });
    doc.autoTable({
      head: tableColumn,
      body: tableRows,
      startY: startY,
      theme: "plain",
      showHead: "firstPage",
      showFoot: "lastPage",
      rowPageBreak: "avoid",
      pageBreak: "avoid",
      margin: { top: 20 },
      bodyStyles: { lineColor: "#969696", fontSize: 8, lineWidth: 0.0 },
      headStyles: {
        lineColor: "#969696",
        lineWidth: 0.0,
        fontSize: 8,
        fillColor: "#E8E8E8",
      },
      footStyles: {
        lineColor: "#969696",
        lineWidth: 0.0,
        fillColor: "#E8E8E8",
        fontSize: 8,
      },
    });

    const pages = doc.internal.getNumberOfPages();
    const pageWidth = doc.internal.pageSize.width; //Optional
    const pageHeight = doc.internal.pageSize.height; //Optional
    doc.setFontSize(10); //Optional
    for (let j = 1; j < pages + 1; j++) {
      let horizontalPos = pageWidth / 2; //Can be fixed number
      let verticalPos = pageHeight - 10; //Can be fixed number
      doc.setPage(j);
      doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
        align: "center",
      });
    }
    startY = doc.lastAutoTable.finalY + 14;
    tableRows = [];
  });
  // const date = Date().split(" ");
  // we use a date string to generate our filename.
  // const dateStr = date[2] + date[3] + date[4];
  // ticket title. and margin-top + margin-left

  doc.autoPrint();
  doc.setProperties({
    title: "LAPORAN HUTANG SUPPLIER",
  });
  // doc.save(`PenerimaanSUpplier.pdf`);
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    `
<html>
<head>
  <title>LAPORAN HUTANG SUPPLIER</title>
</head>
<body style='margin:0 !important; position: relative;'>
  <embed width='100%' height='100%'src='${string}'></embed>
</body>
</html>
`
  );
}

export const printSaldoStockBarcode = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const data = state.laporan.dataLaporanSaldoStockBarcode;
    // initialize jsPDF
    const doc = new jsPDF("portrait");
    //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
    let tableRows = [];

    let tableColumn = [
      [
        {
          content: `LOKASI`,
        },
        {
          content: `NO MUTASI`,
        },
        {
          content: `NO BARCODE`,
        },
        {
          content: `KODE JENIS`,
        },
        {
          content: `KODE BAHAN`,
        },
        {
          content: `SALDO NETTO`,
          styles: { halign: "right" },
        },
      ],
    ];
    doc.setFontSize(15);
    // doc.addImage(logo_ayu_base64, "PNG", 14, 10, 35, 12);
    doc.text("LAPORAN SALDO STOCK BARCODE", 14, 10);

    doc.setFontSize(10);
    //row 1
    data.forEach((x) => {
      let rows = [
        x.kode_lokasi,
        x.no_mutasi_barang,
        x.kode_barcode,
        x.kode_barang,
        x.kode_bahan,
        {
          content: formatGram(x.netto),
          styles: { halign: "right" },
        },
      ];
      tableRows.push(rows);
    });
    doc.autoTable({
      head: tableColumn,
      body: tableRows,
      startY: 20,
      theme: "plain",
      showHead: "firstPage",
      showFoot: "lastPage",
      rowPageBreak: "avoid",
      pageBreak: "avoid",
      margin: { top: 20 },
      bodyStyles: { lineColor: "#969696", fontSize: 8, lineWidth: 0.0 },
      headStyles: {
        lineColor: "#969696",
        lineWidth: 0.0,
        fontSize: 8,
        fillColor: "#E8E8E8",
      },
      footStyles: {
        lineColor: "#969696",
        lineWidth: 0.0,
        fillColor: "#E8E8E8",
        fontSize: 8,
      },
    });

    const pages = doc.internal.getNumberOfPages();
    const pageWidth = doc.internal.pageSize.width; //Optional
    const pageHeight = doc.internal.pageSize.height; //Optional
    doc.setFontSize(10); //Optional
    for (let j = 1; j < pages + 1; j++) {
      let horizontalPos = pageWidth / 2; //Can be fixed number
      let verticalPos = pageHeight - 10; //Can be fixed number
      doc.setPage(j);
      doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
        align: "center",
      });
    }

    doc.autoPrint();
    doc.setProperties({
      title: "LAPORAN SALDO STOCK BARCODE",
    });
    // doc.save(`PenerimaanSUpplier.pdf`);
    var string = doc.output("datauristring");
    var x = window.open();
    x.document.open();
    x.document.write(
      `
  <html>
  <head>
    <title>LAPORAN SALDO STOCK BARCODE</title>
  </head>
  <body style='margin:0 !important; position: relative;'>
    <embed width='100%' height='100%'src='${string}'></embed>
  </body>
  </html>
  `
    );
  };
};

export const printKirimStockRetur = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const { data, tgl_from, tgl_to, no_transaksi } =
      state.laporan.dataLaporanKirimStockRetur;
    // const data = dataLaporanKirimStockRetur.data;
    // const tgl_from = dataLaporanKirimStockRetur.tgl_from;
    // const tgl_to = dataLaporanKirimStockRetur.tgl_to;
    // const no_transaksi = dataLaporanKirimStockRetur.no_transaksi;

    const doc = new jsPDF("portrait");
    //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
    let tableRows = [];
    let startY = 25;
    let grandTotalBruto = 0;
    let grandTotalNetto = 0;
    data.forEach((item, index) => {
      let tableColumn = [
        [
          {
            content: `TANGGAL : ${item.tanggal}`,
            colSpan: 3,
          },
          {
            content: `NO KIRIM STOCK : ${item.no_transaksi}`,
            colSpan: 4,
          },
        ],
        [
          {
            content: `KETERANGAN : ${item.keterangan}`,
            colSpan: 3,
          },
          {
            content: `NO BON RETUR : ${item.no_retur}`,
            colSpan: 4,
          },
        ],
        [
          {
            content: `KODE`,
          },
          {
            content: `NAMA BARANG`,
          },
          {
            content: `KIRIM KE`,
          },
          {
            content: ``,
            styles: { halign: "right" },
          },
          {
            content: `BRUTO(Gr)`,
            styles: { halign: "right" },
          },
          {
            content: `HARGA(%)`,
            styles: { halign: "right" },
          },
          {
            content: `NETTO(Gr)`,
            styles: { halign: "right" },
          },
        ],
      ];
      doc.setFontSize(15);
      // doc.addImage(logo_ayu_base64, "PNG", 14, 10, 35, 12);
      doc.text("LAPORAN KIRIM STOCK RETUR", 14, 10);

      doc.setFontSize(8);
      //row 1
      doc.text(`No Kirim : ${no_transaksi || "SEMUA LANGGANAN"}`, 14, 16);
      doc.text(`Tanggal : ${tgl_from} s/d ${tgl_to}`, 14, 20);
      item.detail_barang.forEach((x, i) => {
        let rows = [
          x.kode_barang,
          x.nama_barang,
          x.tujuan,
          { content: x.kadar_cetak, styles: { halign: "right" } },
          { content: formatGram(x.bruto, 3), styles: { halign: "right" } },
          { content: x.kadar_jual, styles: { halign: "right" } },
          { content: formatGram(x.netto), styles: { halign: "right" } },
        ];
        tableRows.push(rows);
      });
      let row = [
        { content: "SUB TOTAL : ", styles: { halign: "right" }, colSpan: 4 },
        {
          content: formatGram(getSum(item.detail_barang, "bruto"), 3),
          styles: { halign: "right" },
        },
        {
          content: "",
          styles: { halign: "right" },
        },
        {
          content: formatGram(getSum(item.detail_barang, "netto"), 3),
          styles: { halign: "right" },
        },
      ];
      tableRows.push(row);
      grandTotalBruto += parseFloat(getSum(item.detail_barang, "bruto"));
      grandTotalNetto += parseFloat(getSum(item.detail_barang, "netto"));
      if (index === data.length - 1) {
        console.log(grandTotalBruto);
        let row = [
          {
            content: "GRAND TOTAL : ",
            styles: { halign: "right" },
            colSpan: 4,
          },
          {
            content: formatGram(grandTotalBruto, 3),
            styles: { halign: "right" },
          },
          {
            content: "",
            styles: { halign: "right" },
          },
          {
            content: formatGram(grandTotalNetto, 3),
            styles: { halign: "right" },
          },
        ];
        tableRows.push(row);
      }
      doc.autoTable({
        head: tableColumn,
        body: tableRows,
        startY: startY,
        theme: "plain",
        rowPageBreak: "avoid",
        pageBreak: "avoid",
        margin: { top: 23 },
        bodyStyles: { lineColor: "#969696", fontSize: 8, lineWidth: 0.0 },
        headStyles: {
          lineColor: "#969696",
          lineWidth: 0.0,
          fontSize: 8,
          fillColor: "#E8E8E8",
        },
        footStyles: {
          lineColor: "#969696",
          lineWidth: 0.0,
          fillColor: "#E8E8E8",
          fontSize: 8,
        },
      });

      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width; //Optional
      const pageHeight = doc.internal.pageSize.height; //Optional
      doc.setFontSize(10); //Optional
      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2; //Can be fixed number
        let verticalPos = pageHeight - 10; //Can be fixed number
        doc.setPage(j);
        doc.text(`${j}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      startY = doc.lastAutoTable.finalY + 14;
      tableRows = [];
    });
    // const date = Date().split(" ");
    // we use a date string to generate our filename.
    // const dateStr = date[2] + date[3] + date[4];
    // ticket title. and margin-top + margin-left

    doc.autoPrint();
    doc.setProperties({
      title: "LAPORAN KIRIM STOCK RETUR",
    });
    // doc.save(`PenerimaanSUpplier.pdf`);
    var string = doc.output("datauristring");
    var x = window.open();
    x.document.open();
    x.document.write(
      `
  <html>
  <head>
    <title>LAPORAN KIRIM STOCK RETUR</title>
  </head>
  <body style='margin:0 !important; position: relative;'>
    <embed width='100%' height='100%'src='${string}'></embed>
  </body>
  </html>
  `
    );
  };
};

function printOmzetSupplier(data, tgl_to) {
  // initialize jsPDF
  const doc = new jsPDF("portrait");
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  let startY = 25;
  data.forEach((item, index) => {
    let tableColumn = [
      [
        {
          content: `TANGGAL : ${item.tanggal}`,
          colSpan: 2,
        },
        {
          content: `PABRIK : ${item.nama_supplier}`,
          colSpan: 3,
        },
      ],
      [
        {
          content: `CABANG`,
        },
        {
          content: `AMBIL`,
          styles: { halign: "right" },
        },
        {
          content: `TOLAKAN`,
          styles: { halign: "right" },
        },
        {
          content: `RETUR`,
          styles: { halign: "right" },
        },
        {
          content: `TOTAL`,
          styles: { halign: "right" },
        },
      ],
    ];
    doc.setFontSize(15);
    // doc.addImage(logo_ayu_base64, "PNG", 14, 10, 35, 12);
    doc.text("LAPORAN OMZET SUPPLIER", 14, 10);

    doc.setFontSize(10);
    //row 1
    doc.text(`TANGGAL : ${tgl_to}`, 14, 16);
    item.detail_barang.forEach((list) => {
      let rows = [
        list.kode_lokasi,
        { content: formatGram(list.ambil, 3), styles: { halign: "right" } },
        { content: formatGram(list.tolakan), styles: { halign: "right" } },
        { content: formatGram(list.retur), styles: { halign: "right" } },
        { content: formatGram(list.total), styles: { halign: "right" } },
      ];
      tableRows.push(rows);
    });
    doc.autoTable({
      head: tableColumn,
      body: tableRows,
      foot: [printInfo()],
      showHead: "firstPage",
      showFoot: "lastPage",
      startY: startY,
      theme: "plain",
      rowPageBreak: "avoid",
      margin: { top: 20 },
      bodyStyles: { lineColor: "#969696", fontSize: 8, lineWidth: 0.0 },
      headStyles: {
        lineColor: "#969696",
        lineWidth: 0.0,
        fontSize: 8,
        fillColor: "#E8E8E8",
      },
      footStyles: {
        lineColor: "#969696",
        lineWidth: 0.0,
        fillColor: "#E8E8E8",
        fontSize: 8,
      },
    });

    const pages = doc.internal.getNumberOfPages();
    const pageWidth = doc.internal.pageSize.width; //Optional
    const pageHeight = doc.internal.pageSize.height; //Optional
    doc.setFontSize(10); //Optional
    for (let j = 1; j < pages + 1; j++) {
      let horizontalPos = pageWidth / 2; //Can be fixed number
      let verticalPos = pageHeight - 10; //Can be fixed number
      doc.setPage(j);
      doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
        align: "center",
      });
    }
    startY = doc.lastAutoTable.finalY + 14;
    tableRows = [];
  });
  doc.autoPrint();
  doc.setProperties({
    title: "LAPORAN OMZET SUPPLIER",
  });
  // doc.save(`PenerimaanSUpplier.pdf`);
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    `
<html>
<head>
  <title>LAPORAN OMZET SUPPLIER</title>
</head>
<body style='margin:0 !important; position: relative;'>
  <embed width='100%' height='100%'src='${string}'></embed>
</body>
</html>
`
  );
}
function printKartuStock(data, kode_lokasi, tgl_from, tgl_to) {
  // initialize jsPDF
  const doc = new jsPDF("landscape");
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  let tableColumn = [];
  let startY = 25;
  data.forEach((item, index) => {
    tableColumn = [
      [
        {
          content: `TANGGAL`,
          rowSpan: 2,
          styles: { valign: "center" },
        },
        {
          content: `NO BON`,
          rowSpan: 2,
          styles: { valign: "center" },
        },
        {
          content: `IN`,
          colSpan: 3,
          styles: { halign: "center" },
        },
        {
          content: `OUT`,
          colSpan: 4,
          styles: { halign: "center" },
        },
        {
          content: `SALDO BARANG`,
          rowSpan: 2,
          styles: { valign: "center", halign: "right" },
        },
        {
          content: `KET`,
          rowSpan: 2,
          styles: { valign: "center" },
        },
      ],
      [
        {
          content: `TERIMA BARANG`,
          styles: { halign: "right" },
        },
        {
          content: `BATAL JUAL`,
          styles: { halign: "right" },
        },
        {
          content: `RETUR CUSTOMER`,
          styles: { halign: "right" },
        },
        {
          content: `PENJUALAN`,
          styles: { halign: "right" },
        },
        {
          content: `RETUR SUPPLIER`,
          styles: { halign: "right" },
        },
        {
          content: `KOREKSI SO`,
          styles: { halign: "right" },
        },
        {
          content: `RECYCLE`,
          styles: { halign: "right" },
        },
      ],
    ];
    let rows = [
      item.tanggal,
      item.no_bon,
      {
        content: formatGram(item.terima_supplier, 3),
        styles: { halign: "right" },
      },
      { content: formatGram(item.batal_jual), styles: { halign: "right" } },
      {
        content: formatGram(item.retur_customer),
        styles: { halign: "right" },
      },
      { content: formatGram(item.penjualan), styles: { halign: "right" } },
      {
        content: formatGram(item.retur_supplier),
        styles: { halign: "right" },
      },
      { content: formatGram(item.koreksi_so), styles: { halign: "right" } },
      { content: formatGram(item.recycle), styles: { halign: "right" } },
      { content: formatGram(item.saldo_barang), styles: { halign: "right" } },
      { content: item.keterangan },
    ];
    tableRows.push(rows);
  });
  doc.setFontSize(15);
  // doc.addImage(logo_ayu_base64, "PNG", 14, 10, 35, 12);
  doc.text("", 14, 10);

  doc.setFontSize(10);
  //row 1
  doc.text(`TANGGAL : ${tgl_from} s/d ${tgl_to}`, 14, 15);
  doc.text(`LOKASI : ${kode_lokasi}`, 14, 19);
  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    foot: [printInfo()],
    showHead: "firstPage",
    showFoot: "lastPage",
    startY: startY,
    theme: "plain",
    rowPageBreak: "avoid",
    margin: { top: 20 },
    bodyStyles: { lineColor: "#969696", fontSize: 8, lineWidth: 0.0 },
    headStyles: {
      lineColor: "#969696",
      lineWidth: 0.0,
      fontSize: 8,
      fillColor: "#E8E8E8",
    },
    footStyles: {
      lineColor: "#969696",
      lineWidth: 0.0,
      fillColor: "#E8E8E8",
      fontSize: 8,
    },
  });

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  startY = doc.lastAutoTable.finalY + 14;
  tableRows = [];
  doc.autoPrint();
  doc.setProperties({
    title: "LAPORAN KARTU STOCK",
  });
  // doc.save(`PenerimaanSUpplier.pdf`);
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    `
<html>
<head>
  <title>LAPORAN KARTU STOCK</title>
</head>
<body style='margin:0 !important; position: relative;'>
  <embed width='100%' height='100%'src='${string}'></embed>
</body>
</html>
`
  );
}

function printCorrectionNote(data) {
  // initialize jsPDF
  const doc = new jsPDF("portrait");
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  let startY = 25;
  data.forEach((item, index) => {
    let tableColumn = [
      [
        {
          content: `Customer : ${item.kode_customer}`,
          colSpan: 9,
        },
      ],
      [
        {
          content: `TANGGAL`,
          rowSpan: 2,
          styles: { valign: "middle" },
        },
        {
          content: `NO BON`,
          rowSpan: 2,
          styles: { valign: "middle" },
        },
        {
          content: `KODE JENIS`,
          rowSpan: 2,
          styles: { valign: "middle" },
        },
        {
          content: `AWAL`,
          colSpan: 3,
          styles: { valign: "middle", halign: "center" },
        },
        {
          content: `CN`,
          colSpan: 3,
          styles: { valign: "middle", halign: "center" },
        },
      ],
      [
        {
          content: ``,
          styles: { halign: "right" },
        },
        {
          content: `BRUTO`,
          styles: { halign: "right" },
        },
        {
          content: `NETTO`,
          styles: { halign: "right" },
        },
        {
          content: ``,
          styles: { halign: "right" },
        },
        {
          content: `BRUTO`,
          styles: { halign: "right" },
        },
        {
          content: `NETTO`,
          styles: { halign: "right" },
        },
      ],
    ];
    doc.setFontSize(15);
    // doc.addImage(logo_ayu_base64, "PNG", 14, 10, 35, 12);
    doc.text("LAPORAN CORRECTION NOTE", 14, 10);

    doc.setFontSize(10);
    //row 1
    doc.text(`TANGGAL : ${item.tanggal_from} s/d ${item.tanggal_to}`, 14, 16);
    item.detail_barang.forEach((list, index) => {
      let rows = [
        list.tanggal,
        list.no_penjualan,
        list.kode_barang,
        {
          content: formatGram(list.kadar_jual_awal, 3),
          styles: { halign: "right" },
        },
        { content: formatGram(list.bruto_awal), styles: { halign: "right" } },
        { content: formatGram(list.netto_awal), styles: { halign: "right" } },
        {
          content: formatGram(list.kadar_jual_cn, 3),
          styles: { halign: "right" },
        },
        { content: formatGram(list.bruto_cn), styles: { halign: "right" } },
        { content: formatGram(list.netto_cn), styles: { halign: "right" } },
      ];
      tableRows.push(rows);
      if (index === item.detail_barang.length - 1) {
        let row = [
          { content: "SUBTOTAL : ", styles: { halign: "right" }, colSpan: 4 },
          {
            content: formatGram(getSum(item.detail_barang, "bruto_awal"), 3),
            styles: { halign: "right" },
          },
          {
            content: formatGram(getSum(item.detail_barang, "netto_awal"), 3),
            styles: { halign: "right" },
          },
          { content: "", styles: { halign: "right" } },
          {
            content: formatGram(getSum(item.detail_barang, "bruto_cn"), 3),
            styles: { halign: "right" },
          },
          {
            content: formatGram(getSum(item.detail_barang, "netto_cn"), 3),
            styles: { halign: "right" },
          },
        ];
        tableRows.push(row);
      }
    });
    let footer = [
      [
        {
          content: "Printed On : 2021",
          colSpan: 2,
        },
        {
          content: "GRAND TOTAL",
          colSpan: 2,
          styles: { halign: "right" },
        },
        {
          content: formatGram(getSumGrandTotal(data, "bruto_awal"), 3),
          styles: { halign: "right" },
        },
        {
          content: formatGram(getSumGrandTotal(data, "netto_awal"), 3),
          styles: { halign: "right" },
        },
        { content: "", styles: { halign: "right" } },
        {
          content: formatGram(getSumGrandTotal(data, "bruto_cn"), 3),
          styles: { halign: "right" },
        },
        {
          content: formatGram(getSumGrandTotal(data, "netto_cn"), 3),
          styles: { halign: "right" },
        },
      ],
    ];
    doc.autoTable({
      head: tableColumn,
      body: tableRows,
      foot: footer,
      startY: startY,
      showHead: "firstPage",
      showFoot: "lastPage",
      theme: "plain",
      rowPageBreak: "avoid",
      pageBreak: "avoid",
      margin: { top: 20 },
      bodyStyles: { lineColor: "#d0d0d0", fontSize: 8, lineWidth: 0.0 },
      headStyles: {
        lineColor: "#969696",
        lineWidth: 0.0,
        fontSize: 8,
        fillColor: "#E8E8E8",
      },
      footStyles: {
        lineColor: "#d2d2d2",
        lineWidth: 0.0,
        fillColor: "#E8E8E8",
        fontSize: 8,
      },
    });

    const pages = doc.internal.getNumberOfPages();
    const pageWidth = doc.internal.pageSize.width; //Optional
    const pageHeight = doc.internal.pageSize.height; //Optional
    doc.setFontSize(10); //Optional
    for (let j = 1; j < pages + 1; j++) {
      let horizontalPos = pageWidth / 2; //Can be fixed number
      let verticalPos = pageHeight - 10; //Can be fixed number
      doc.setPage(j);
      doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
        align: "center",
      });
    }
    startY = doc.lastAutoTable.finalY + 14;
    tableRows = [];
  });
  doc.autoPrint();
  doc.setProperties({
    title: "LAPORAN CORRECTION NOTE",
  });
  // doc.save(`PenerimaanSUpplier.pdf`);
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    `
<html>
<head>
  <title>LAPORAN CORRECTION NOTE</title>
</head>
<body style='margin:0 !important; position: relative;'>
  <embed width='100%' height='100%'src='${string}'></embed>
</body>
</html>
`
  );
}

function printValidasiCustomer(data, tgl_from, tgl_to) {
  // initialize jsPDF
  const doc = new jsPDF("portrait");
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  let startY = 25;
  data.forEach((item, index) => {
    let tableColumn = [
      [
        {
          content: `JENIS : ${item.jenis_transaksi}`,
          colSpan: 6,
        },
      ],
      [
        { content: `NO FAKTUR` },
        { content: `TANGGAL FAKTUR` },
        { content: `TANGGAL VALIDASI` },
        { content: `TOTAL BRUTO` },
        { content: `TOTAL NETTO` },
        { content: `USER VALIDASI` },
      ],
    ];
    doc.setFontSize(15);
    // doc.addImage(logo_ayu_base64, "PNG", 12, 10, 43, 17);
    if (doc.internal.getNumberOfPages() === 1) {
      doc.text("LAPORAN VALIDASI CUSTOMER", 14, 14);
      doc.setFontSize(10);
      //row 1
      doc.text(
        `TANGGAL : ${formatDateIndo(tgl_from)} s/d ${formatDateIndo(tgl_to)}`,
        14,
        18
      );
    }
    item.detail_validasi.forEach((list, index) => {
      let rows = [
        list.no_faktur,
        list.tanggal_transaksi,
        list.tanggal_validasi,
        { content: formatGram(list.total_bruto), styles: { halign: "right" } },
        { content: formatGram(list.total_netto), styles: { halign: "right" } },
        list.validate_by,
      ];
      tableRows.push(rows);
    });
    doc.autoTable({
      head: tableColumn,
      body: tableRows,
      foot: [printInfo()],
      showHead: "firstPage",
      showFoot: "lastPage",
      startY: startY,
      theme: "plain",
      // rowPageBreak: "avoid",
      // pageBreak: "avoid",
      margin: { top: 15 },
      bodyStyles: { lineColor: "#d0d0d0", fontSize: 8, lineWidth: 0.0 },
      headStyles: {
        lineColor: "#969696",
        lineWidth: 0.0,
        fontSize: 8,
        fillColor: "#E8E8E8",
      },
      footStyles: {
        lineColor: "#d2d2d2",
        lineWidth: 0.0,
        fillColor: "#E8E8E8",
        fontSize: 8,
      },
    });

    const pages = doc.internal.getNumberOfPages();
    const pageWidth = doc.internal.pageSize.width; //Optional
    const pageHeight = doc.internal.pageSize.height; //Optional
    doc.setFontSize(10); //Optional
    for (let j = 1; j < pages + 1; j++) {
      let horizontalPos = pageWidth / 2; //Can be fixed number
      let verticalPos = pageHeight - 10; //Can be fixed number
      doc.setPage(j);
      doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
        align: "center",
      });
    }
    startY = doc.lastAutoTable.finalY + 14;
    tableRows = [];
  });
  doc.autoPrint();
  doc.setProperties({
    title: "LAPORAN VALIDASI CUSTOMER",
  });
  // doc.save(`PenerimaanSUpplier.pdf`);
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    `
<html>
<head>
  <title>LAPORAN VALIDASI CUSTOMER</title>
</head>
<body style='margin:0 !important; position: relative;'>
  <embed width='100%' height='100%'src='${string}'></embed>
</body>
</html>
`
  );
}

function printOmzetCustomer(data, tgl_from, tgl_to) {
  // initialize jsPDF
  const doc = new jsPDF("portrait");
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  let startY = 25;
  data.forEach((item, index) => {
    let tableColumn = [
      [
        {
          content: `Kategori : ${item.nama_kategori}`,
          colSpan: 6,
        },
      ],
      [
        {
          content: `TANGGAL`,
        },
        {
          content: `NAMA TOKO`,
        },
        {
          content: `KODE BARANG`,
        },
        {
          content: `KODE BAHAN`,
        },
        {
          content: `BRUTO`,
          styles: { valign: "middle", halign: "center" },
        },
        {
          content: `NETTO`,
          styles: { valign: "middle", halign: "center" },
        },
      ],
    ];
    doc.setFontSize(15);
    // doc.addImage(logo_ayu_base64, "PNG", 14, 10, 35, 12);
    doc.text("LAPORAN OMZET CUSTOMER", 14, 10);

    doc.setFontSize(10);
    //row 1
    doc.text(
      `TANGGAL : ${formatDateIndo(tgl_from)} s/d ${formatDateIndo(tgl_to)}`,
      14,
      16
    );
    item.detail_barang.forEach((list, index) => {
      let rows = [
        list.tanggal,
        list.nama_toko,
        list.kode_barang,
        list.kode_bahan,
        { content: formatGram(list.bruto), styles: { halign: "right" } },
        { content: formatGram(list.netto), styles: { halign: "right" } },
      ];
      tableRows.push(rows);
    });
    doc.autoTable({
      head: tableColumn,
      body: tableRows,
      foot: [printInfo()],
      showHead: "firstPage",
      showFoot: "lastPage",
      startY: startY,
      theme: "plain",
      margin: { top: 20 },
      bodyStyles: { lineColor: "#d0d0d0", fontSize: 8, lineWidth: 0.0 },
      headStyles: {
        lineColor: "#969696",
        lineWidth: 0.0,
        fontSize: 8,
        fillColor: "#E8E8E8",
      },
      footStyles: {
        lineColor: "#d2d2d2",
        lineWidth: 0.0,
        fillColor: "#E8E8E8",
        fontSize: 8,
      },
    });

    const pages = doc.internal.getNumberOfPages();
    const pageWidth = doc.internal.pageSize.width; //Optional
    const pageHeight = doc.internal.pageSize.height; //Optional
    doc.setFontSize(10); //Optional
    for (let j = 1; j < pages + 1; j++) {
      let horizontalPos = pageWidth / 2; //Can be fixed number
      let verticalPos = pageHeight - 10; //Can be fixed number
      doc.setPage(j);
      doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
        align: "center",
      });
    }
    startY = doc.lastAutoTable.finalY + 14;
    tableRows = [];
  });
  doc.autoPrint();
  doc.setProperties({
    title: "LAPORAN OMZET CUSTOMER",
  });
  // doc.save(`PenerimaanSUpplier.pdf`);
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    `
<html>
<head>
  <title>LAPORAN OMZET CUSTOMER</title>
</head>
<body style='margin:0 !important; position: relative;'>
  <embed width='100%' height='100%'src='${string}'></embed>
</body>
</html>
`
  );
}

function printAnalisaCustomer(data, kode_customer, kadar, tgl_from, tgl_to) {
  // initialize jsPDF
  const doc = new jsPDF("portrait");
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  let startY = 25;
  data.forEach((item, index) => {
    let rows = [
      item.kode_barang,
      item.nama_barang,
      { content: formatGram(item.bruto), styles: { halign: "right" } },
    ];
    tableRows.push(rows);
  });
  let tableColumn = [
    [
      {
        content: `CUSTOMER : ${kode_customer}`,
        colSpan: 3,
      },
    ],
    [
      {
        content: ` : ${kadar}`,
        colSpan: 3,
      },
    ],
    [
      {
        content: `TANGGAL : ${tgl_from} s/d ${tgl_to}`,
        colSpan: 3,
      },
    ],
    [
      {
        content: `KODE`,
      },
      {
        content: `DEKSRIPSI`,
      },
      {
        content: `JUMLAH`,
        styles: { halign: "right" },
      },
    ],
  ];
  doc.setFontSize(15);
  // doc.addImage(logo_ayu_base64, "PNG", 14, 10, 35, 12);
  doc.text("LAPORAN ANALISA TRANSAKSI CUSTOMER", 14, 10);

  doc.setFontSize(10);
  let footer = [
    [
      { content: "Printed On : 2021-Desember 2021" },
      { content: "TOTAL:", styles: { halign: "right" } },
      {
        content: formatGram(getSum(data, "bruto")),
        styles: { halign: "right" },
      },
    ],
  ];
  //row 1
  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    foot: footer,
    startY: startY,
    theme: "plain",
    rowPageBreak: "avoid",
    pageBreak: "avoid",
    margin: { top: 20 },
    bodyStyles: { lineColor: "#d0d0d0", fontSize: 8, lineWidth: 0.0 },
    headStyles: {
      lineColor: "#969696",
      lineWidth: 0.0,
      fontSize: 8,
      fillColor: "#E8E8E8",
    },
    footStyles: {
      lineColor: "#d2d2d2",
      lineWidth: 0.0,
      fillColor: "#E8E8E8",
      fontSize: 8,
    },
  });

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  startY = doc.lastAutoTable.finalY + 14;
  tableRows = [];
  doc.autoPrint();
  doc.setProperties({
    title: "LAPORAN ANALISA TRANSAKSI CUSTOMER",
  });
  // doc.save(`PenerimaanSUpplier.pdf`);
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    `
<html>
<head>
  <title>LAPORAN ANALISA TRANSAKSI CUSTOMER</title>
</head>
<body style='margin:0 !important; position: relative;'>
  <embed width='100%' height='100%'src='${string}'></embed>
</body>
</html>
`
  );
}

function printStockGlobal(data, kode_lokasi, tgl_from, tgl_to) {
  // initialize jsPDF
  const doc = new jsPDF("portrait");
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  let startY = 25;
  let total_in = 0;
  let total_out = 0;
  data.forEach((item, index) => {
    let rows = [
      item.deskripsi,
      { content: formatGram(item.saldo_in), styles: { halign: "right" } },
      { content: formatGram(item.saldo_out), styles: { halign: "right" } },
    ];
    total_in = total_in + item.saldo_in;
    total_out = total_out + item.saldo_out;
    tableRows.push(rows);
  });
  tableRows.push([
    { content: "TOTAL :", styles: { halign: "right" } },
    {
      content: formatGram(total_in),
      styles: { halign: "right" },
    },
    {
      content: formatGram(total_out),
      styles: { halign: "right" },
    },
  ]);
  tableRows.push([
    { content: "SALDO AKHIR", styles: { halign: "right" }, colSpan: 2 },
    {
      content: formatGram(total_in - total_out),
      styles: { halign: "right" },
    },
  ]);
  let tableColumn = [
    [
      {
        content: `DESKRIPSI`,
      },
      {
        content: `TAMBAH (+)`,
        styles: { halign: "right" },
      },
      {
        content: `KELUAR (-)`,
        styles: { halign: "right" },
      },
    ],
  ];
  doc.setFontSize(15);
  // doc.addImage(logo_ayu_base64, "PNG", 14, 10, 35, 12);
  doc.text("LAPORAN STOCK GLOBAL", 14, 10);

  doc.setFontSize(8);
  doc.text(`Kode Lokasi : ${kode_lokasi}`, 14, 15);
  doc.text(`Tanggal : ${tgl_from} s/d ${tgl_to}`, 14, 20);

  let footer = [printInfo()];
  //row 1
  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    foot: footer,
    startY: startY,
    theme: "plain",
    rowPageBreak: "avoid",
    pageBreak: "avoid",
    margin: { top: 20 },
    bodyStyles: { lineColor: "#d0d0d0", fontSize: 8, lineWidth: 0.0 },
    headStyles: {
      lineColor: "#969696",
      lineWidth: 0.0,
      fontSize: 8,
      fillColor: "#E8E8E8",
    },
    footStyles: {
      lineColor: "#d2d2d2",
      lineWidth: 0.0,
      fillColor: "#E8E8E8",
      fontSize: 8,
    },
  });

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  startY = doc.lastAutoTable.finalY + 14;
  tableRows = [];
  doc.autoPrint();
  doc.setProperties({
    title: "LAPORAN ANALISA TRANSAKSI CUSTOMER",
  });
  // doc.save(`PenerimaanSUpplier.pdf`);
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    `
<html>
<head>
  <title>LAPORAN ANALISA TRANSAKSI CUSTOMER</title>
</head>
<body style='margin:0 !important; position: relative;'>
  <embed width='100%' height='100%'src='${string}'></embed>
</body>
</html>
`
  );
}

function printHistoryCustomer(data, kode_customer, start_date, end_date) {
  // initialize jsPDF
  const doc = new jsPDF("landscape");
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  let tableColumn = [];
  let startY = 25;
  data.forEach((item, index) => {
    tableColumn = [
      [
        {
          content: `TANGGAL`,
          rowSpan: 2,
          styles: { valign: "center" },
        },
        {
          content: `INVOICE`,
          rowSpan: 2,
          styles: { valign: "center" },
        },
        {
          content: `PEMBELIAN`,
          colSpan: 3,
          styles: { halign: "center" },
        },
        {
          content: `REKAP`,
          colSpan: 2,
          styles: { halign: "center" },
        },
        {
          content: `BAYAR`,
          colSpan: 2,
          styles: { halign: "center" },
        },
        {
          content: `TITIPAN (GR)`,
          rowSpan: 2,
          styles: { valign: "center", halign: "right" },
        },
        {
          content: `SALDO TITIP (GR)`,
          rowSpan: 2,
          styles: { valign: "center", halign: "right" },
        },
        {
          content: `TITIPAN (RP)`,
          rowSpan: 2,
          styles: { valign: "center", halign: "right" },
        },
        {
          content: `SALDO TITIP (RP)`,
          rowSpan: 2,
          styles: { valign: "center", halign: "right" },
        },
        {
          content: `SALDO PIUTANG`,
          colSpan: 2,
          styles: { halign: "center" },
        },
        {
          content: `KET`,
          rowSpan: 2,
          styles: { valign: "center" },
        },
      ],
      [
        {
          content: `BRUTO (GR)`,
          styles: { halign: "right" },
        },
        {
          content: `NETTO (GR)`,
          styles: { halign: "right" },
        },
        {
          content: `ONGKOS RP`,
          styles: { halign: "right" },
        },
        {
          content: `GR`,
          styles: { halign: "right" },
        },
        {
          content: `RP`,
          styles: { halign: "right" },
        },
        {
          content: `GR`,
          styles: { halign: "right" },
        },
        {
          content: `RP`,
          styles: { halign: "right" },
        },
        {
          content: `GR`,
          styles: { halign: "right" },
        },
        {
          content: `RP`,
          styles: { halign: "right" },
        },
      ],
    ];
    let rows = [
      item.tanggal,
      item.no_bon,
      {
        content: formatGram(item.pembelian_bruto, 3),
        styles: { halign: "right" },
      },
      {
        content: formatGram(item.pembelian_netto),
        styles: { halign: "right" },
      },
      {
        content: formatRp(item.pembelian_ongkos_rp),
        styles: { halign: "right" },
      },
      { content: formatGram(item.rekap_gr), styles: { halign: "right" } },
      {
        content: formatRp(item.rekap_rp),
        styles: { halign: "right" },
      },
      { content: formatGram(item.bayar_gr), styles: { halign: "right" } },
      {
        content: formatRp(item.bayar_rp),
        styles: { halign: "right" },
      },
      { content: formatGram(item.titip_gr), styles: { halign: "right" } },
      { content: formatGram(item.saldo_titip_gr), styles: { halign: "right" } },
      { content: formatRp(item.titip_rp), styles: { halign: "right" } },
      { content: formatRp(item.saldo_titip_rp), styles: { halign: "right" } },
      { content: formatGram(item.piutang_gr), styles: { halign: "right" } },
      { content: formatRp(item.piutang_rp), styles: { halign: "right" } },
      {
        content:
          // item.keterangan.split("(CASH)").length > 1
          //   ? item.keterangan.split("(CASH)")[0] +
          //     " (CASH) " +
          //     formatRp(item.keterangan.split("(CASH)")[1])
          //   : item.keterangan,
          item.keterangan
            .replace(/((\(CASH\)|\(KU\))\s)(\d+)/g, (match, p1, p2, p3) => {
              return p1 + formatRp(p3);
            })
            .replace(/-/g, " - "),
        styles: { halign: "right" },
      },
    ];
    tableRows.push(rows);
  });
  doc.setFontSize(15);
  // doc.addImage(logo_ayu_base64, "PNG", 14, 10, 35, 12);
  doc.text("LAPORAN HISTORY CUSTOMER", 14, 10);

  doc.setFontSize(10);
  //row 1
  doc.text(`CUSTOMER : ${kode_customer}`, 14, 18);
  doc.text(
    `TANGGAL : ${formatDateIndo(start_date)} s/d ${formatDateIndo(end_date)}`,
    90,
    18
  );
  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    foot: [printInfo()],
    showHead: "firstPage",
    showFoot: "lastPage",
    startY: startY,
    theme: "plain",
    rowPageBreak: "avoid",
    margin: { top: 20 },
    bodyStyles: { lineColor: "#969696", fontSize: 8, lineWidth: 0.0 },
    headStyles: {
      lineColor: "#969696",
      lineWidth: 0.0,
      fontSize: 8,
      fillColor: "#E8E8E8",
    },
    footStyles: {
      lineColor: "#969696",
      lineWidth: 0.0,
      fillColor: "#E8E8E8",
      fontSize: 8,
    },
  });

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  startY = doc.lastAutoTable.finalY + 14;
  tableRows = [];
  doc.autoPrint();
  doc.setProperties({
    title: "LAPORAN HISTORY CUSTOMER",
  });
  // doc.save(`PenerimaanSUpplier.pdf`);
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    `
<html>
<head>
  <title>LAPORAN HISTORY CUSTOMER</title>
</head>
<body style='margin:0 !important; position: relative;'>
  <embed width='100%' height='100%'src='${string}'></embed>
</body>
</html>
`
  );
}

function getSum(data, target) {
  return data.map((x) => Number(x[target] || "0")).reduce((a, b) => a + b, 0);
}

function getSumGrandTotal(data, target) {
  let listData = [];
  data.forEach((x) => {
    x.detail_barang.forEach((y) => {
      listData.push(Number(y[target] || "0"));
    });
  });
  return listData.reduce((a, b) => a + b, 0);
}

function ConvertOmzetSupplier(data, tgl_to) {
  const listKategori = [];
  data.forEach((x) => {
    listKategori.push(x.kode_kategori);
  });
  let result = [...new Set(listKategori)];

  let draft = {
    tanggal: tgl_to,
    detail_barang: [],
  };
  result.forEach((x) => {
    let filtered = data.filter((y) => y.kode_kategori === x);

    let feedback = filtered;
    let detail_barang = [];
    filtered.forEach((z) => {
      draft.nama_supplier = z.nama_supplier;
      let feedbackForAmbil = feedback.find((x) => x.jenis === "TERIMA");
      let feedbackForRetur = feedback.find((x) => x.jenis === "RETUR");
      let feedbackForTolakan = feedback.find((x) => x.jenis === "TOLAKAN");
      let ambil =
        (feedbackForAmbil &&
          feedbackForAmbil.detail_barang
            .map((x) => x.total_bruto)
            .reduce((a, b) => a + b, 0)) ||
        0;
      let retur =
        (feedbackForRetur &&
          feedbackForRetur.detail_barang
            .map((x) => x.total_bruto)
            .reduce((a, b) => a + b, 0)) ||
        0;
      let tolakan =
        (feedbackForTolakan &&
          feedbackForTolakan.detail_barang
            .map((x) => x.total_bruto)
            .reduce((a, b) => a + b, 0)) ||
        0;
      detail_barang = {
        kode_lokasi: z.kode_cabang_supplier,
        nama_kategori: z.nama_kategori,
        ambil,
        retur,
        tolakan,
        total: parseFloat(ambil) + parseFloat(tolakan) - parseFloat(retur),
      };
    });
    draft.detail_barang.push(detail_barang);
  });
  return draft;
}

function dateToString(date) {
  let month = date.split("-")[1];
  let year = date.split("-")[0];
  console.log(month);
  switch (String(month)) {
    case "1":
      return "JANUARI " + year;
    case "2":
      return "FEBRUARI " + year;
    case "3":
      return "MARET " + year;
    case "4":
      return "APRIL " + year;
    case "5":
      return "MEI " + year;
    case "6":
      return "JUNI " + year;
    case "7":
      return "JULI " + year;
    case "8":
      return "AGUSTUS " + year;
    case "9":
      return "SEPTEMBER " + year;
    case "10":
      return "OKTOBER " + year;
    case "11":
      return "NOVEMBER " + year;
    case "12":
      return "DESEMBER " + year;
    default:
      break;
  }
}
