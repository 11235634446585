import {
  AxiosMasterDeleteWithParam,
  AxiosMasterGet,
  AxiosMasterPost,
  AxiosMasterPut,
} from "../../../Axios";
import { toast } from "react-toastify";
import { ToastKirim } from "../../../actions/toast";
import { finishSend, progressSend } from "../../../actions/LogicAction";
import { hideModal } from "../../../actions/ModalAction";
import { reset } from "redux-form";
import Swal from "sweetalert2";
import { purifiedData } from "../../../helper";

export const GET_DATA_BARANG_SET = "GET_DATA_BARANG_SET";
export const GET_BARANG_SET_OPEN = "GET_BARANG_SET_OPEN";
export const GET_BARANG = "GET_BARANG";
export const EDIT_MODEL = "EDIT_MODEL";
export const GET_ALL_GROUP = "GET_ALL_GROUP";
export const SET_EDIT_BARANG = "SET_EDIT_BARANG";

export const getListBarangSet = () => {
  return (dispatch) => {
    dispatch({
      type: GET_DATA_BARANG_SET,
      payload: {
        data: JSON.parse(localStorage.getItem("barangSet_temp")),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const getBarangSet = (input) => {
  return (dispatch) => {
    AxiosMasterGet("barang-set/get-all-open")
      .then((res) =>
        dispatch({
          type: GET_BARANG_SET_OPEN,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_BARANG_SET_OPEN,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const editModel = (
  kodeJenis,
  kodeKategori,
  kode_kelompok,
  namaJenis,
  kadarBeli,
  kadarJual,
  kadarCetak,
  image,
  kodeIntern,
  kodeBarcode,
  harga_jual,
  harga_beli
) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_MODEL,
      payload: {
        kodeJenis: kodeJenis,
        kodeKategori: `${kodeKategori}`,
        namaJenis: namaJenis,
        kadarBeli: kadarBeli,
        kadarJual: kadarJual,
        kadarCetak: kadarCetak.toString(),
        image: image,
        kodeIntern: kodeIntern,
        kodeBarcode: kodeBarcode,
        kode_kelompok: kode_kelompok,
        harga_jual: harga_jual,
        harga_beli: harga_beli,
      },
    });
  };
};

export const getBarang = () => {
  return async (dispatch, getState) => {
    AxiosMasterGet("barang")
      .then((res) =>
        dispatch({
          type: GET_BARANG,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_BARANG,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const getListGroup = () => {
  return async (dispatch, getState) => {
    AxiosMasterGet("group/get/all")
      .then((res) =>
        dispatch({
          type: GET_ALL_GROUP,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_ALL_GROUP,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const sendBarang = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const selected = state.form.FormBarang.values;
    purifiedData(selected);
    const data = {
      kode_kategori: selected.kodeGroup,
      kode_bahan: selected.kodeBahan,
      kode_barang: selected.kodeBarang,
      nama_barang: selected.namaBarang,
      kadar_cetak: selected.kadarCetak,
      kadar_beli: selected.kadarBeli,
      kadar_jual: selected.kadarJual,
      berat_per_item: selected.beratPerItem,
      ongkos_beli: selected.ongkosBeli || 0,
      ongkos_jual: selected.ongkosJual || 0,
    };
    await toast.promise(AxiosMasterPost("barang", data), ToastKirim);
    dispatch(finishSend());
    dispatch(hideModal());
    dispatch(getBarang());
    dispatch(reset("FormBarang"));
  };
};

export const sendEditBarang = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const selected = state.form.FormBarangEdit.values;
    purifiedData(selected);
    const data = {
      nama_barang: selected.namaBarang,
      kadar_cetak: selected.kadarCetak,
      kadar_beli: selected.kadarBeli,
      kadar_jual: selected.kadarJual,
      berat_per_item: selected.beratPerItem,
      ongkos_beli: selected.ongkosBeli,
      ongkos_jual: selected.ongkosJual,
      kode_bahan: selected.kodeBahan,
    };
    await toast.promise(
      AxiosMasterPut(
        "barang/" + selected.kodeBarang + "&" + selected.kodeBahan,
        data
      ),
      ToastKirim
    );
    dispatch(finishSend());
    dispatch(hideModal());
    dispatch(getBarang());
    dispatch(reset("FormBarang"));
  };
};

export const setEditBarang = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_EDIT_BARANG,
      payload: {
        data: data,
      },
    });
  };
};

export const deleteBarang = (kode, kode_bahan) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    Swal.fire({
      title: "Anda Yakin ?",
      text: "Anda Akan Menghapus Data Ini ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await toast.promise(
            AxiosMasterDeleteWithParam("barang", {
              kode_barang: kode,
              kode_bahan: kode_bahan,
            }),
            ToastKirim
          );
          dispatch(getBarang());
          dispatch(finishSend());
        } catch (e) {
          dispatch(finishSend());
        }
      }
    });
    dispatch(finishSend());
  };
};
