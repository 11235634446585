import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { getTimbangan } from "../../GetTimbangan";
import { getListBarang } from "../../actions/kodeBarangAction";
import { renderField, renderFieldSelect } from "../../renderField";
import {  setFocus, SkeletonLoading } from "../../helper";


class FormKirimStock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listBarang: [],
      kodeJenis: "",
      kadarCetak: "",
      kadarBeli: "",
      non_aksesoris: "col-lg-12 row",
      aksesoris: "col-lg-12 row d-none",
    };
  }

  componentDidMount() {
    this.props.dispatch(getListBarang());
    getTimbangan(this.props.change);
  }

  handleChange = (input) => {
    var supp = input ? input : "DEFAULT";
    if (supp === "AKSESORIS") {
      this.setState({
        non_aksesoris: "col-lg-12 row d-none",
        aksesoris: "col-lg-12 row",
      });
    } else {
      this.setState({
        non_aksesoris: "col-lg-12 row ",
        aksesoris: "col-lg-12 row d-none",
      });
    }
    this.setState({
      kodeJenis: supp
    });
    setFocus("Bruto")
  };

  setNetto() {
    this.props.change(
      "netto",
      this.props.netto === "NaN" ? 0 : this.props.netto
    );
  }
  render() {
    console.log(this.props.kodeKategori);
    return (
      <form onSubmit={this.props.handleSubmit}>
        <div className="row">
          <div className="col-md-3 form-group">
            {String(this.props.KodeBarangList) !== "" ? (
              <Field
                name="kodeBarang"
                label="Kode Model"
                component={renderFieldSelect}
                onChange={(data) => this.handleChange(data)}
                options={this.props.KodeBarangList.filter((fil) => fil.kode_kategori === this.props.kodeKategori ).map((list) => {
                  let value = {
                    value: list.kode_jenis,
                    name: list.nama_jenis,
                  };
                  return value;
                })}
              ></Field>
            ) : (
              <SkeletonLoading label="Kode Model" />
            )}
          </div>
          <div className="col-md-3 form-group">
            <Field
              name="bruto"
              label="Bruto"
              component={renderField}
              type="text"
              normalize={(value) => value && value.toUpperCase()}
            />
          </div>
          <div className="col-md-12 form-group">
            <div className="text-right">
              <button
                className="btn btn-primary"
                type="submit"
                onClick={() => document.getElementById("Kode Model").focus()}
              >
                Simpan
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

FormKirimStock = reduxForm({
  form: "FormKirimStock",
  enableReinitialize: true,
})(FormKirimStock);
const selector = formValueSelector("FormKirimStock"); // <-- same as form name
export default connect((state) => {
  const { bruto, kadarTransaksi } = selector(state, "bruto", "kadarTransaksi");
  return {
    KodeBarangList: state.provinsi.kodeBarang,
    kodeKategori : state.form?.HeadKirimStock?.values?.kode_kategori || state.form?.HeadKirimStockEdit?.values?.kode_kategori,
    netto: (parseFloat(bruto) * (parseFloat(kadarTransaksi) / 100)).toFixed(3),
    initialValues: {
      bruto: state.kirimStock.bruto,
      kodeBarang: state.kirimStock.kodeBarang
    },
  };
})(FormKirimStock);
