import {finishSend, progressSend} from "../../../actions/LogicAction";
import {toast} from "react-toastify";
import {AxiosMasterGet} from "../../../Axios";
import {ToastAmbil} from "../../../actions/toast";
import LaporanPrintMutasi from "../component/LaporanPrintMutasi";
import {getToday} from "../../../GlobalComponent/function";
import {getUserID} from "../../../helper";

export const GET_LAPORAN_MUTASI_BARANG_ACTION = "GET_LAPORAN_MUTASI_BARANG_ACTION"


export const GetLaporanMutasiBarang = (kode_lokasi, tanggal_awal, tanggal_akhir) => {
  return async (dispatch ,getState) => {
    dispatch(progressSend())
      try {
                const response = await toast.promise(AxiosMasterGet(
                    `report/mutasi-stock/${kode_lokasi}&${tanggal_awal}&${tanggal_akhir}`
                ), ToastAmbil)
                dispatch({
                    type : GET_LAPORAN_MUTASI_BARANG_ACTION,
                    payload : {
                        data : response.data,
                        tanggal_akhir: tanggal_akhir,
                        tanggal_awal : tanggal_awal
                    }
                })
          dispatch(finishSend())
      } catch (e) {

            dispatch(finishSend())
            }
  }
}


export const PrintLaporanMutasiBarangPDF = () => {
    return async  (dispatch, getState) => {
        const state = getState();
        const dataLaporanMutasiBarang = state.laporan.dataLaporanMutasiBarang
        LaporanPrintMutasi(
            dataLaporanMutasiBarang.tanggal_awal,
            dataLaporanMutasiBarang.tanggal_akhir,
            getUserID(),
            getToday(),
            getUserID(),
            dataLaporanMutasiBarang.data
        );
    }
}