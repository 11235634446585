import {
  finishSend,
  isExist,
  progressSend,
} from "../../../actions/LogicAction";
import { hideModal } from "../../../actions/ModalAction";
import { reset } from "redux-form";
import { getLocal } from "../../../encrypt";
import {
  getBon,
  getToday,
  multipleDeleteLocal,
  purifiedData,
} from "../../../helper";
import { toast } from "react-toastify";
import { AxiosMasterPost } from "../../../Axios";
import { warning } from "../../../Alert";
import { ToastKirim } from "../../../actions/toast";

export const GET_ALL_PINDAH_SAMPEL = "GET_ALL_PINDAH_SAMPEL";

export const getPindahSampleTemp = () => {
  return async (dispatch, getState) => {
    const response = await getLocal("PindahSampleTemp");
    dispatch({
      type: GET_ALL_PINDAH_SAMPEL,
      payload: response,
    });
  };
};

export const savePindahSampleTemp = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const selected = state.form.AddPindahSample.values;
    purifiedData(selected);
    await isExist({
      name: "PindahSampleTemp",
      value: selected.kode_barcode,
      value2: selected.kode_barcode,
      target: "kode_barcode",
      target2: "kode_barcode",
      payload: selected,
    });
    dispatch(getPindahSampleTemp());
    dispatch(finishSend());
    dispatch(hideModal());
    dispatch(reset("AddPindahSample"));
  };
};

export const sendPindahBarang = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.PindahSample.values;
    purifiedData(selected);
    const noBon = "PS-" + getBon();
    const detail_barang = await getLocal("PindahSampleTemp");
    if (selected.dari === undefined || selected.tujuan === undefined) {
      warning("harap isi dari / tujuan pindah");
      return false;
    }
    const payload = {
      tanggal: selected.tanggal || getToday(),
      dari: selected.dari,
      tujuan: selected.tujuan,
      keterangan: selected.keterangan,
      no_pindah_sampel: noBon,
      detail_barang: detail_barang,
    };
    try {
      await toast.promise(
        AxiosMasterPost("/pindah-sampel", payload),
        ToastKirim
      );
      multipleDeleteLocal(["PindahSampleTemp"]);
      dispatch(finishSend());
      dispatch(getPindahSampleTemp());
      dispatch(reset("PindahSample"));
    } catch (e) {
      dispatch(finishSend());
    }
  };
};
