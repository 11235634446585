import React, { Component } from "react";
import { connect } from "react-redux";
import { showModal } from "../../actions/ModalAction";
import ModalMasterData from "../Component/ModalMasterData";
import HeaderCard from "../../HeaderCard";
import {
  deleteBank,
  editBank,
  getBankList,
  sendBank,
  sendEditBank,
} from "./redux/bankAction";
import FormBank from "./component/FormBank";
import FormBankEdit from "./component/FormBankEdit";
import { Dropdown } from "react-bootstrap";
import GlobalTabel from "../../GlobalComponent/GlobalTabel";

const defaultSorted = [
  {
    dataField: "idBank",
    order: "asc",
  },
];

const maptoState = (state) => {
  return {
    daftarBank: state.provinsi.bank,
    alert: state.provinsi.alert,
    error: state.provinsi.error,
    onSend: state.provinsi.onSend,
  };
};

class DaftarBank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Rekening: [],
      response: "",
      columns: [
        {
          dataField: "kode_bank",
          text: "Kode Bank",
        },
        {
          dataField: "nama_bank",
          text: "Nama Bank",
        },
        {
          dataField: "Aksi",
          text: "Aksi",
          csvExport: false,
          formatter: (rowContent, row) => {
            return (
              <div className={"row justify-content-center"}>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <i className="fa fa-ellipsis-v" aria-hidden="true" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() =>
                        this.props.dispatch(deleteBank(row.kode_bank))
                      }
                    >
                      Delete <i className="fa fa-trash ml-2" />
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        this.props.dispatch(
                          editBank(
                            row.kode_bank,
                            row.nama_bank,
                            this.editModal()
                          )
                        )
                      }
                    >
                      Edit <i className="fa fa-edit ml-2" />
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    this.props.dispatch(getBankList());
  }

  updateStatus = (input) => {
    console.log(input);
    this.setState({
      response: input,
    });
    this.props.dispatch(getBankList());
  };

  handleSubmit() {
    this.props.dispatch(sendBank());
  }
  handleEdit() {
    this.props.dispatch(sendEditBank());
  }
  tambahModal() {
    this.props.dispatch(showModal());
    this.setState({
      isEdit: false,
    });
  }
  editModal() {
    this.props.dispatch(showModal());
    this.setState({
      isEdit: true,
    });
  }

  render() {
    return (
      <div className="container-fluid" style={{ color: "black" }}>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <HeaderCard title="Data Bank" />
              <div className="card-body">
                <ModalMasterData
                  content={
                    this.state.isEdit ? (
                      <FormBankEdit
                        updateStatus={this.updateStatus}
                        onSubmit={(data) => this.handleEdit(data)}
                        onSend={this.props.onSend}
                      />
                    ) : (
                      <FormBank
                        updateStatus={this.updateStatus}
                        onSubmit={(data) => this.handleSubmit(data)}
                        onSend={this.props.onSend}
                      />
                    )
                  }
                  title={
                    this.state.isEdit ? "Edit Data Bank" : "Tambah Data Bank"
                  }
                />
                <div className="col-lg-12 col-md-12">
                  <div className="text-right">
                    <button
                      name="simpan"
                      id="simpan"
                      type="button"
                      className="btn btn-info"
                      onClick={() => this.tambahModal()}
                      data-tut="reactour_1"
                    >
                      Tambah Data Bank
                    </button>
                  </div>
                </div>
                <div className="col-lg-12" data-tut="reactour_2">
                  <GlobalTabel
                    data={this.props.daftarBank}
                    columns={this.state.columns}
                    defaultSorted={defaultSorted}
                    keyField="kode_bank"
                    bordered={true}
                    headerClasses="header-table"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(maptoState, null)(DaftarBank);
