import { AxiosMasterGet } from "../Axios";

export const GET_KODE_BARANG_LIST = "GET_KODE_BARANG_LIST";
export const GET_KODE_BARANG_MASTER = "GET_KODE_BARANG_MASTER";
export const GET_KODE_BARANG_CIOK = "GET_KODE_BARANG_CIOK";
export const GET_KODE_AKSESORIS_LIST = "GET_KODE_AKSESORIS_LIST";
export const EDIT_KODE_BARANG = "EDIT_KODE_BARANG";

export const getListBarang = () => {
  return (dispatch) => {
    AxiosMasterGet("jenis/get/all-no-image")
      .then((res) =>
        dispatch({
          type: GET_KODE_BARANG_LIST,
          payload: {
            data: res.data.filter(
              (list) =>
                list.kode_kelompok === "PERHIASAN" ||
                list.kode_kelompok === "AKSESORIS"
            ),
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_KODE_BARANG_LIST,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const getListBarangCiok = () => {
  return (dispatch) => {
    AxiosMasterGet("jenis/get/all-no-image")
      .then((res) =>
        dispatch({
          type: GET_KODE_BARANG_CIOK,
          payload: {
            data: res.data.filter(
              (list) =>
                list.kode_kelompok === "CIOK" ||
                list.kode_kelompok === "RONGSOK"
            ),
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_KODE_BARANG_CIOK,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const getListBarangMaster = () => {
  return (dispatch) => {
    AxiosMasterGet("jenis/get/all")
      .then((res) => {
        dispatch({
          type: GET_KODE_BARANG_LIST,
          payload: {
            data: res.data.filter(
              (list) =>
                list.kode_kelompok === "PERHIASAN" ||
                list.kode_kelompok === "AKSESORIS"
            ),
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        });
      })
      .catch((err) =>
        dispatch({
          type: GET_KODE_BARANG_LIST,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const getListAksesoris = () => {
  return (dispatch) => {
    AxiosMasterGet("jenis/get/all-no-image")
      .then((res) =>
        dispatch({
          type: GET_KODE_AKSESORIS_LIST,
          payload: {
            data: res.data.filter((list) => list.kode_kelompok === "AKSESORIS"),
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_KODE_AKSESORIS_LIST,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};
export const editRosok = (
  kodeJenis,
  statusAktif,
  kodeKategori,
  namaJenis,
  kadarBeli,
  kadarJual,
  kadarCetak
) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_KODE_BARANG,
      payload: {
        kodeJenis: kodeJenis,
        statusAktif: statusAktif,
        kodeKategori: kodeKategori,
        namaJenis: namaJenis,
        kadarBeli: kadarBeli,
        kadarJual: kadarJual,
        kadarCetak: kadarCetak,
      },
    });
  };
};
