import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { getUUID } from "../../actions/fakturAction";
import {
  deleteLocalPenjualan24KTemp,
  getListTransaksiPenjualan24K,
  saveEditPenjualan24K,
  savePenjualanEditTemp,
  savePenjualan24KTemp,
  sendPenjualan24K,
} from "./redux/transaksiPenjualan24KAction";
import { showModal } from "../../actions/ModalAction";
import HeaderCard from "../../HeaderCard";
import { getcustomerList } from "../../DataMaster/customer/redux/customerAction";
import GlobalTabel from "../../GlobalComponent/GlobalTabel";
import { renderField, renderFieldSelect } from "../../renderField";
import {
  formatGram,
  formatRp,
  getBon,
  getToday,
  NormalizeGramasi,
  removeComma,
} from "../../helper";
import ModalGlobal from "../../DataMaster/Component/ModalMasterData";
import { Dropdown } from "react-bootstrap";
import FormValueSelector from "redux-form/lib/formValueSelector";
import ListEditPenjualan from "./component/ListEditPenjualan24K";

import FormTransaksiPenjualan24K from "./component/FormTranskasiPenjualan24K";
import FormTransaksiPenjualan24KEdit from "./component/FormTranskasiPenjualan24KEdit";
import { getListBarang } from "../../actions/kodeBarangAction";
import { getBarang } from "../../DataMaster/barang/redux/barangAction";
import { getListRosok } from "../../DataMaster/ciok-rosok/redux/rosokAction";

const selector = FormValueSelector("TransaksiPenjualan24K");
const maptostate = (state) => {
  return {
    isEdit: state.provinsi.prev_no_penjualan !== "-",
    noFaktur: state.provinsi.noFaktur,
    transaksiPenjualan: state.provinsi.transaksiPenjualan,
    listCustomer: state.provinsi.customer,
    listLokasi: state.provinsi.lokasi,
    listSales: state.provinsi.salesman,
    totalNW: selector(state, "totalNW"),
    disc_global: selector(state, "disc_global"),
    disc_global_gr: selector(state, "disc_global_gr"),
    grand_total_nw: selector(state, "grand_total_nw"),
    no_bon: selector(state, "no_bon"),
    keterangan: selector(state, "keterangan"),
    kode_customer: selector(state, "kode_customer"),
    onSend: state.provinsi.onSend,
    kodeBarang: state.provinsi.kodeBarang,
    customer: state.provinsi.customer,
    initialValues: {
      tanggal: getToday(),
      keterangan: "-",
    },
  };
};
class TransaksiPenjualan24K extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listLokasi: [],
      listSales: [],
      listCustomer: [],
      listPacking: [],
      kodeCustomer: "",
      customerInduk: [],
      columns: [
        {
          dataField: "",
          text: "No",
          align: "left",
          headerAlign: "left",
          footerAlign: "left",
          formatter: (cell, row, index) => ++index,
          footer: (columnData, column, columnIndex) => columnData.length,
        },
        {
          dataField: "kode_barang",
          text: "Kode Barang",
          footer: "",
        },
        {
          dataField: "kode_bahan",
          text: "Kode Bahan",
          footer: "",
        },
        {
          dataField: "kadar_cetak",
          text: "Cap Kode",
          align: "right",
          headerAlign: "right",
          footer: "",
        },
        {
          dataField: "qty",
          text: "Qty",
          align: "right",
          headerAlign: "right",
          formatter: (data) => data,
          footerAlign: "right",
          footer: (columnData) =>
            columnData.reduce(
              (acc, item) =>
                parseFloat(removeComma(acc)) + parseFloat(removeComma(item)),
              0
            ),
        },
        {
          dataField: "pkg",
          text: "Bungkus\n(Gr)",
          align: "right",
          headerAlign: "right",
          formatter: (data) => formatGram(data),
          footerAlign: "right",
          footer: (columnData) =>
            formatGram(
              columnData.reduce(
                (acc, item) =>
                  parseFloat(removeComma(acc)) + parseFloat(removeComma(item)),
                0
              )
            ),
        },
        {
          dataField: "bruto",
          text: "Berat\n(Gr)",
          align: "right",
          headerAlign: "right",
          formatter: (data) => formatGram(data),
          footerAlign: "right",
          footer: (columnData) =>
            formatGram(
              columnData.reduce(
                (acc, item) =>
                  parseFloat(removeComma(acc)) + parseFloat(removeComma(item)),
                0
              )
            ),
        },
        {
          dataField: "kadar_jual",
          text: "Kode\nTukar",
          align: "right",
          headerAlign: "right",
          footer: "",
        },
        {
          dataField: "netto",
          text: "Murni\n(Gr)",
          align: "right",
          headerAlign: "right",
          formatter: (data) => formatGram(data),
          footerAlign: "right",
          footer: (columnData) =>
            formatGram(
              columnData.reduce(
                (acc, item) =>
                  parseFloat(removeComma(acc)) + parseFloat(removeComma(item)),
                0
              )
            ),
        },
        {
          dataField: "disc_gram",
          text: "Disc\n(Gr)",
          align: "right",
          headerAlign: "right",
          formatter: (data) => formatGram(data),
          footerAlign: "right",
          footer: (columnData) =>
            formatGram(
              columnData.reduce(
                (acc, item) =>
                  parseFloat(removeComma(acc)) + parseFloat(removeComma(item)),
                0
              )
            ),
        },
        {
          dataField: "netto_disc",
          text: "Netto\nDisc(Gr)",
          align: "right",
          headerAlign: "right",
          formatter: (data) => formatGram(data),
          footerAlign: "right",
          footer: (columnData) =>
            formatGram(
              columnData.reduce(
                (acc, item) =>
                  parseFloat(removeComma(acc)) + parseFloat(removeComma(item)),
                0
              )
            ),
        },
        {
          dataField: "ongkos_rp",
          text: "Ongkos\n(Rp)",
          align: "right",
          headerAlign: "right",
          formatter: (cell) => <span>Rp. {formatRp(cell)}</span>,
          footerAlign: "right",
          footer: (columnData) => (
            <span>
              Rp.{" "}
              {formatRp(
                columnData.reduce(
                  (acc, item) =>
                    parseFloat(removeComma(acc)) +
                    parseFloat(removeComma(item)),
                  0
                )
              )}
            </span>
          ),
        },
        {
          dataField: "Aksi",
          text: "Aksi",
          csvExport: false,
          formatter: (rowContent, row, rowIndex) => {
            return (
              <div className="justify-content-center row">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <i className="fa fa-ellipsis-v" aria-hidden="true" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        this.props.dispatch(
                          deleteLocalPenjualan24KTemp(
                            "Penjualan24KTemp",
                            rowIndex,
                            getListTransaksiPenjualan24K()
                          )
                        );
                      }}
                    >
                      Hapus Barang <i className="fa fa-trash ml-2" />
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        this.showEditBarang(row);
                      }}
                    >
                      Edit <i className="fa fa-edit ml-2" />
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            );
          },
        },
      ],
      discGlobalGr: 0,
      discGlobalPr: 0,
      totalNWExt: 0,
      noBon: getBon(),
      listCustomerLevel1: [],
      namaCustomer: "",
      lokasi: "",
      sales: "",
      namaToko: "",
      tanggal: "",
      data: "",
      isEdit: false,
      isListEdit: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(getcustomerList());
    this.props.dispatch(getUUID());
    this.props.dispatch(getListTransaksiPenjualan24K());
    this.props.dispatch(getListRosok());
    this.props.dispatch(getListBarang());
    this.props.dispatch(getBarang());
    let tanggal = localStorage.getItem("temp-penjualan24KDate");
    let keterangan = localStorage.getItem("temp-penjualan24KKeterangan");
    let customer = localStorage.getItem("temp-penjualan24KCustomer");
    let discGlobalState = localStorage.getItem("temp-penjualan24KDiscGlobal");
    let grandTotalNw = localStorage.getItem("temp-penjualan24KGrandTotalNW");

    setTimeout(() => {
      console.log(discGlobalState);
      if (customer !== null) {
        this.props.change("kode_customer", {
          value: customer.split("|")[0],
          label: customer.split("|")[1],
        });
      }
      if (tanggal !== null) {
        this.props.change("tanggal", tanggal);
      }
      if (keterangan !== null) {
        this.props.change("keterangan", keterangan);
      }
      if (discGlobalState !== null) {
        this.props.change("disc_global", discGlobalState);
      }
      if (grandTotalNw !== null) {
        this.props.change("grand_total_nw_ext", grandTotalNw);
      }
    }, 300);
  }

  handleSubmit() {
    this.props.dispatch(savePenjualan24KTemp());
  }
  handleSubmitEdit() {
    this.props.dispatch(savePenjualanEditTemp());
  }
  onClick() {
    const disc_gr = formatGram(
      removeComma(this.props.totalNW) *
        (removeComma(this.props.disc_global) / 100)
    );
    const grandTotal = formatGram(
      removeComma(this.props.totalNW) - removeComma(disc_gr)
    );
    this.props.change("disc_global_gr", disc_gr);
    this.props.change("grand_total_nw", grandTotal);
  }

  kirimData() {
    this.props.dispatch(sendPenjualan24K(this.state.isEdit));
  }

  showTambahBarang() {
    this.setState({
      isEdit: false,
      isListEdit: false,
    });
    this.props.dispatch(showModal());
  }

  showEditBarang(row) {
    this.setState({
      isEdit: true,
      isListEdit: false,
    });
    this.props.dispatch(showModal());
    this.props.dispatch(saveEditPenjualan24K(row));
  }
  showListEdit() {
    this.setState({
      isEdit: true,
      isListEdit: true,
    });
    this.props.dispatch(showModal());
  }
  render() {
    return (
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            <HeaderCard
              title="Transaksi Penjualan 24K"
              listDeleteLocal={[
                "Penjualan24KTemp",
                "temp-penjualan24KCustomer",
                "temp-penjualan24KDate",
                "temp-penjualan24KKeterangan",
                "temp-penjualan24KDiscGlobal",
                "temp-penjualan24KGrandTotalNW",
              ]}
            />
            <div className="card-body">
              <div className={"row"}>
                <div className="col-lg-12">
                  <p>*) Wajib Diisi</p>
                </div>
                <div className={"col-lg-3"}>
                  <Field
                    name={"tanggal"}
                    label={"Tanggal"}
                    component={renderField}
                    type={"date"}
                    readOnly={this.props.transaksiPenjualan.length > 0}
                    onChange={(data) =>
                      localStorage.setItem(
                        "temp-penjualan24KDate",
                        data.target.value
                      )
                    }
                  />
                </div>
                <div
                  className={this.props.isEdit ? "col-lg-3" : "col-lg-3 d-none"}
                >
                  <Field
                    name={"no_bon"}
                    label={"Nomor Bon *"}
                    component={renderField}
                    type={"text"}
                    normalize={(data) => data && data.toUpperCase()}
                    readOnly={this.props.transaksiPenjualan.length > 0}
                  />
                </div>
                <div className={"col-lg-4"}>
                  <Field
                    name={"keterangan"}
                    label={"Keterangan"}
                    component={renderField}
                    type={"text"}
                    normalize={(data) => data && data.toUpperCase()}
                    readOnly={this.props.transaksiPenjualan.length > 0}
                    onChange={(data) =>
                      localStorage.setItem(
                        "temp-penjualan24KKeterangan",
                        data.target.value
                      )
                    }
                  />
                </div>
                <div className={"col-lg-4"}>
                  <Field
                    name={"kode_customer"}
                    label={"Customer *"}
                    component={renderFieldSelect}
                    readOnly={this.props.isEdit}
                    options={this.props.listCustomer.map((x) => {
                      return {
                        value: x.kode_customer,
                        name: x.nama_toko + " / " + x.nama_customer,
                      };
                    })}
                    onChange={(data) => {
                      localStorage.setItem(
                        "temp-penjualan24KCustomer",
                        `${data.value}|${data.label}`
                      );
                      localStorage.setItem("kodeCustomer", data.value);
                    }}
                    disabled={this.props.transaksiPenjualan.length > 0}
                  />
                </div>
                <div className={"col-lg-4"}>
                  <Field
                    name={"totalNW"}
                    label={"Total NW"}
                    component={renderField}
                    type={"text"}
                    normalize={(data) => data && data.toUpperCase()}
                    readOnly
                  />
                </div>
                <div className={"col-lg-4"}>
                  <Field
                    name={"disc_global"}
                    label={"Disc Global"}
                    component={renderField}
                    type={"number"}
                    normalize={NormalizeGramasi}
                    onChange={this.onClick()}
                    onBlur={(e) =>
                      localStorage.setItem(
                        "temp-penjualan24KDiscGlobal",
                        e.target.value
                      )
                    }
                  />
                </div>
                <div className={"col-lg-4"}>
                  <Field
                    name={"disc_global_gr"}
                    label={"Disc Global"}
                    component={renderField}
                    type={"text"}
                    normalize={(data) => data && data.toUpperCase()}
                    readOnly
                  />
                </div>
                <div className={"col-lg-4"}>
                  <Field
                    name={"grand_total_nw"}
                    label={"Grand Total NW"}
                    component={renderField}
                    type={"text"}
                    onFocus={() => {
                      localStorage.setItem(
                        "temp-penjualan24KGrandTotalNW",
                        this.props.grand_total_nw
                      );
                      this.props.change(
                        "grand_total_nw_ext",
                        this.props.grand_total_nw
                      );
                    }}
                    readOnly
                  />
                </div>
                <div className={"col-lg-4"}>
                  <Field
                    name={"grand_total_nw_ext"}
                    label={"Grand Total NW Ext"}
                    component={renderField}
                    type={"text"}
                    onChange={(data) => {
                      localStorage.setItem(
                        "temp-penjualan24KGrandTotalNW",
                        data.target.value
                      );
                    }}
                  />
                </div>
                <div className="col-md-12 col-lg-12 mt-3 mb-3">
                  <div className="row">
                    <div className="col-lg-8 col-md-8">
                      <div className="text-center">
                        <button
                          className="btn btn-primary"
                          type="button"
                          disabled={this.props.kode_customer === ""}
                          onClick={() => this.showListEdit()}
                        >
                          Edit Penjualan
                          <i className="fa fa-plus ml-3"></i>
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                      <div className="text-right">
                        <button
                          className="btn btn-primary"
                          disabled={this.props.kode_customer === undefined}
                          onClick={() => this.showTambahBarang()}
                        >
                          Tambah Data
                          <i className="fa fa-plus ml-3" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"col-lg-12"}>
                  <GlobalTabel
                    keyField="no"
                    data={this.props.transaksiPenjualan}
                    columns={this.state.columns}
                    pagination={false}
                  />
                  <div className="text-right">
                    <button
                      className="btn btn-primary"
                      disabled={
                        this.props.onSend ||
                        this.props.kode_customer === undefined
                      }
                      onClick={() => this.kirimData()}
                    >
                      {this.props.onSend ? (
                        <span
                          className="spinner-border spinner-border-sm mr-3"
                          aria-hidden="true"
                        />
                      ) : null}
                      Selesai
                      <i class="fa fa-paper-plane ml-3" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalGlobal
          content={
            this.state.isListEdit ? (
              <ListEditPenjualan />
            ) : this.state.isEdit ? (
              <FormTransaksiPenjualan24KEdit
                onSubmit={(data) => this.handleSubmitEdit(data)}
              />
            ) : (
              <FormTransaksiPenjualan24K
                onSubmit={(data) => this.handleSubmit(data)}
              />
            )
          }
          title={"Tambah Barang Penjualan"}
        />
      </div>
    );
  }
}

TransaksiPenjualan24K = reduxForm({
  form: "TransaksiPenjualan24K",
  enableReinitialize: true,
})(TransaksiPenjualan24K);
export default connect(maptostate, null)(TransaksiPenjualan24K);
