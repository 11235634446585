export const ToastKirim = {
  pending: "Sedang Mengirim... 📤📤",
  success: "Mengirim Data Berhasil... ✔️✔️",
  error: {
    render({ data }) {
      // When the promise reject, data will contains the error
      return `Mengirim Data Gagal..., ${data?.response?.data || ""}`;
    },
  },
};

export const ToastAmbil = {
  pending: "Sedang Mengambil... 📥📥",
  success: "Mengambil Data Berhasil... ✔️✔️",
  error: {
    render({ data }) {
      // When the promise reject, data will contains the error
      return `Mengambil Data Gagal..., ${
        data?.response?.data || "Data Kosong"
      }`;
    },
  },
};
