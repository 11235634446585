export const GET_LIST_BARANG_BARCODE_SET = "GET_LIST_BARANG_BARCODE_SET";


export const setListBarangBarcodeSet = (data) => {
        return (dispatch) => {
            dispatch({
                type : GET_LIST_BARANG_BARCODE_SET,
                payload : {
                    data : data
                }
            })
        }
}