import { AxiosMasterGet } from "../Axios";

export const GET_DATA_CLOST_TRANSAKSI_PENJUALAN =
  "GET_DATA_CLOST_TRANSAKSI_PENJUALAN";
export const GET_DATA_CLOSE_TRANSAKSI_PENJUALAN_SELECTED =
  "GET_DATA_CLOSE_TRANSAKSI_PENJUALAN_SELECTED";

export const getListCloseTransaksiPenjualan = (tglFrom, tglTo, sales) => {
  return (dispatch) => {
    AxiosMasterGet("cst/dc/pjl/heads", {
      params: {
        statusValid: "OPEN",
        tglFrom: tglFrom,
        tglTo: tglTo,
        kodeCustomer: sales,
      },
    })
      .then((res) =>
        dispatch({
          type: GET_DATA_CLOST_TRANSAKSI_PENJUALAN,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_DATA_CLOST_TRANSAKSI_PENJUALAN,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const getListCloseTransaksiPenjualanSelected = () => {
  return (dispatch) => {
    dispatch({
      type: GET_DATA_CLOSE_TRANSAKSI_PENJUALAN_SELECTED,
      payload: {
        data: JSON.parse(localStorage.getItem("closeTransaksiPenjualan")),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};
