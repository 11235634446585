import {
  EDIT_PARAMETER_HARGA_EMAS,
  GET_PARAMETER_HARGA_EMAS_LIST,
  GET_PARAMETER_HARGA_EMAS,
} from "./parameterHargaEmasAction";

const initialState = {
  listHargaEmas: [],
  hargaEmas: null,
  editDataHargaEmas: null,
};

const parameterHargaEmas = (state = initialState, action) => {
  switch (action.type) {
    case GET_PARAMETER_HARGA_EMAS_LIST:
      return {
        ...state,
        listHargaEmas: action.payload.data,
      };
    case EDIT_PARAMETER_HARGA_EMAS:
      return {
        ...state,
        editDataHargaEmas: action.payload,
      };
    case GET_PARAMETER_HARGA_EMAS:
      return {
        ...state,
        hargaEmas: action.payload.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export default parameterHargaEmas;
