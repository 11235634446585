import {
  GET_LAPORAN_KEUANGAN_CASH,
  GET_LAPORAN_KEUANGAN_CASH_CARD,
  GET_LAPORAN_KEUANGAN_CASH_SUMMARY,
} from "../laporan_keuangan_cash/redux/laporan_keuangan_cash_action";
import {
  GET_LAPORAN_KEUANGAN_TRANSFER,
  GET_LAPORAN_KEUANGAN_TRANSFER_CARD,
  GET_LAPORAN_KEUANGAN_TRANSFER_SUMMARY,
} from "../laporan_keuangan_transfer/redux/laporan_keuangan_transfer_action";
import { GET_LAPORAN_PENERIMAAN_SUPPLIER } from "../laporan_penerimaan_supplier/redux/laporan_penerimaan_supplier_action";
import { GET_LAPORAN_RETURN_SUPPLIER_DETAIL } from "../laporan_return_supplier/redux/laporan_return_supplier_action";
import {
  GET_LAPORAN_HUTANG_SUPPLIER,
  GET_LAPORAN_HUTANG_SUPPLIER_CARD,
  GET_LAPORAN_HUTANG_SUPPLIER_SUMMARY,
} from "../laporan_hutang_supplier/redux/laporan_hutang_supplier_action";
import {
  GET_LAPORAN_ANALISA_CUSTOMER,
  GET_LAPORAN_CORRECTION_NOTE,
  GET_LAPORAN_HISTORY_CUSTOMER,
  GET_LAPORAN_KARTU_STOCK,
  GET_LAPORAN_KARTU_STOCK_SAMPLE,
  GET_LAPORAN_KIRIM_STOCK_RETUR,
  GET_LAPORAN_OMZET_CUSTOMER,
  GET_LAPORAN_OMZET_SUPPLIER,
  GET_LAPORAN_PINDAH_BARANG_SAMPLE,
  GET_LAPORAN_SALDO_STOCK_BARCODE,
  GET_LAPORAN_STOCK_GLOBAL,
  GET_LAPORAN_STOCK_SAMPLE,
  GET_LAPORAN_SUMMARY_STOCK_SAMPLE,
  GET_LAPORAN_VALIDASI,
} from "./laporan_action";
import { GET_LAPORAN_PEMBAYARAN_SUPPLIER } from "../laporan_pembayaran_supplier/redux/laporan_pembayaran_supplier_action";
import { GET_LAPORAN_TOLAKAN_SUPPLIER } from "../laporan_tolakan_supplier/redux/laporan_tolakan_supplier_action";
import {
  GET_LAPORAN_TITIP_SUPPLIER,
  GET_LAPORAN_TITIP_SUPPLIER_CARD,
  GET_LAPORAN_TITIP_SUPPLIER_SUMMARY,
} from "../laporan_titip_supplier/redux/laporan_titip_supplier_action";
import { GET_LAPORAN_SUMMARY_BARANG } from "../laporan_summary_barang/redux/laporan_summary_barang_action";
import { GET_LAPORAN_KARTU_STOCK_BARANG } from "../laporan_kartu_stock_barang/redux/laporan_kartu_stock_barang_action";
import { GET_LAPORAN_MUTASI_BARANG_ACTION } from "../laporan_mutasi_barang/redux/laporan_mutasi_barang_action";
import { GET_LAPORAN_PINDAH_BARANG } from "../laporan_packing_barang/redux/laporan_pidah_barang_action";
import { GET_LAPORAN_PER_KATEGORI } from "../laporan_stock_per_kategori/redux/laporan_stock_per_kategori_action";
import { GET_LAPORAN_STOCK_PER_LOKASI } from "../laporan_stock_per_lokasi/redux/laporan_stock_per_lokasi_action";
import { GET_LAPORAN_PENJUALAN_CUSTOMER } from "../laporan_penjualan_customer/redux/laporan_penjualan_customer_action";
import {
  GET_LAPORAN_PIUTANG_CUSTOMER,
  GET_LAPORAN_PIUTANG_CUSTOMER_CARD,
  GET_LAPORAN_PIUTANG_CUSTOMER_SUMMARY,
} from "../laporan_piutang_customer/redux/laporan_piutang_customer_action";
import { GET_LAPORAN_RETURN_CUSTOMER } from "../laporan_return_customer/redux/laporan_return_customer_action";
import { GET_LAPORAN_PEMBAYARAN_CUSTOMER } from "../laporan_pembayaran_customer/redux/laporan_pembayaran_customer_action";
import {
  GET_LAPORAN_TITIP_CUSTOMER,
  GET_LAPORAN_TITIP_CUSTOMER_CARD,
  GET_LAPORAN_TITIP_CUSTOMER_SUMMARY,
} from "../laporan_titip_customer/redux/laporan_titip_customer_action";
import { GET_LAPORAN_TAMBAH_SALDO_24K } from "../laporan_tambah_saldo_24k/redux/laporan_tambah_saldo_24k_action";
import { GET_LAPORAN_PENJUALAN_24K } from "../laporan_penjualan_24k/redux/laporan_penjualan_24k_action";
import { GET_LAPORAN_GLOBAL_24K } from "../laporan_global_24k/redux/laporan_global_24k_action";

const initialState = {
  dataLaporanKeuanganCash: [],
  dataLaporanKeuanganCashCard: {
    data: [],
    tglFrom: "",
    tglTo: "",
  },
  dataLaporanKeuanganCashSummary: {
    data: [],
    tglFrom: "",
    tglTo: "",
  },
  dataLaporanKeuanganTransfer: [],
  dataLaporanKeuanganTransferCard: {
    data: [],
    tglFrom: "",
    tglTo: "",
  },
  dataLaporanKeuanganTransferSummary: {
    data: [],
    tglFrom: "",
    tglTo: "",
  },
  dataLaporanPenerimaanSupplier: {
    data: [],
    tanggal_awal: "",
    tanggal_akhir: "",
  },
  dataLaporanReturnSupplier: {
    data: [],
    tanggal_awal: "",
    tanggal_akhir: "",
  },
  dataLaporanHutangSupplier: [],
  dataLaporanHutangSupplierCard: {
    data: [],
    tanggal_awal: "",
    tanggal_akhir: "",
  },
  dataLaporanHutangSupplierSummary: {
    data: [],
    tanggal_awal: "",
    tanggal_akhir: "",
  },
  dataLaporanOmzetSupplier: {
    data: [],
    tanggal: "",
  },
  dataLaporanPembayaranSupplier: {
    data: [],
    tglFrom: "",
    tglTo: "",
  },
  dataLaporanTolakanSupplier: {
    data: [],
    tanggal_awal: "",
    tanggal_akhir: "",
    kode_supplier: "",
  },
  dataLaporanTitipSupplier: [],
  dataLaporanTitipSupplierCard: {
    data: [],
    tanggal_awal: "",
    tanggal_akhir: "",
  },
  dataLaporanTitipSupplierSummary: {
    data: [],
    tanggal_awal: "",
    tanggal_akhir: "",
  },
  dataLaporanSummaryBarang: {
    data: [],
    tanggal_awal: "",
    tanggal_akhir: "",
  },
  dataLaporanKartuStockBarang: {
    data: [],
    tanggal_awal: "",
    tanggal_akhir: "",
  },
  dataLaporanMutasiBarang: {
    data: [],
    tanggal_awal: "",
    tanggal_akhir: "",
  },
  dataLaporanPackingBarang: {
    data: [],
    tanggal_awal: "",
    tanggal_akhir: "",
  },
  dataLaporanValidasi: {
    data: [],
    tgl_from: "",
    tgl_to: "",
  },
  dataLaporanPindahBarangSample: {
    data: [],
    tgl_from: "",
    tgl_to: "",
  },
  dataLaporanStockSample: [],
  dataLaporanKartuStockSample: {
    data: [],
    tgl_from: "",
    tgl_to: "",
  },
  dataLaporanSummaryStockSample: {
    data: [],
    tanggal: "",
  },
  dataLaporanSaldoStockBarcode: [],
  dataLaporanKirimStockRetur: {
    data: [],
    tgl_from: "",
    tgl_to: "",
    no_transaksi: "",
  },
  dataLaporanStockPerKategori: [],
  dataLaporanCorrectioNote: {
    data: [],
  },
  dataLaporanStockPerLokasi: {
    data: [],
    kode_lokasi: "",
  },
  dataLaporanPenjualanCustomer: {
    data: [],
    tglFrom: "",
    tglTo: "",
  },
  dataLaporanPiutangCustomer: {
    data: [],
    kode_customer: "",
  },
  dataLaporanPiutangCustomerCard: {
    data: [],
    kode_customer: "",
    tglFrom: "",
    tglTo: "",
  },
  dataLaporanPiutangCustomerSummary: {
    data: [],
    tglFrom: "",
    kode_customer: "",
    tglTo: "",
  },
  dataLaporanReturnCustomer: {
    data: [],
    tglFrom: "",
    kode_customer: "",
    tglTo: "",
  },
  dataLaporanPembayaranCustomer: {
    data: [],
    tglFrom: "",
    kode_customer: "",
    tglTo: "",
  },
  dataLaporanTitipCustomer: {
    data: [],
    kode_customer: "",
  },
  dataLaporanTitipCustomerCard: {
    data: [],
    tanggal_awal: "",
    kode_customer: "",
    tanggal_akhir: "",
    jenis_transaksi: "",
  },
  dataLaporanTitipCustomerSummary: {
    data: [],
    tanggal_awal: "",
    kode_customer: "",
    tanggal_akhir: "",
    jenis_transaksi: "",
  },
  dataLaporanOmzetCustomer: {
    data: [],
    tgl_from: "",
    tgl_to: "",
  },
  dataLaporanAnalisaCustomer: {
    data: [],
    tgl_from: "",
    tgl_to: "",
  },
  dataLaporanTambahSaldo24k: {
    data: [],
    tgl_from: "",
    tgl_to: "",
    kode_barang: "",
  },
  dataLaporanPenjualan24k: {
    data: [],
    tgl_from: "",
    tgl_to: "",
    kode_customer: "",
  },
  dataLaporanGlobal24k: {
    data: {
      detail: [],
    },
    tgl_from: "",
    tgl_to: "",
  },
  dataLaporanStockGlobal: {
    data: [],
    tgl_from: "",
    tgl_to: "",
    kode_lokasi: "",
    total_in: 0,
    total_out: 0,
    saldo_akhir: 0,
  },
  dataLaporanKartuStock: {
    data: [],
    tgl_from: "",
    tgl_to: "",
    kode_lokasi: "",
  },
  dataLaporanHistoryCustomer: {
    data: [],
    kode_customer: "",
  },
};

const bahan = (state = initialState, action) => {
  switch (action.type) {
    case GET_LAPORAN_KEUANGAN_CASH:
      return {
        ...state,
        dataLaporanKeuanganCash: action.payload,
      };
    case GET_LAPORAN_KEUANGAN_CASH_CARD:
      return {
        ...state,
        dataLaporanKeuanganCashCard: action.payload,
      };
    case GET_LAPORAN_KEUANGAN_CASH_SUMMARY:
      return {
        ...state,
        dataLaporanKeuanganCashSummary: action.payload,
      };
    case GET_LAPORAN_KEUANGAN_TRANSFER:
      return {
        ...state,
        dataLaporanKeuanganTransfer: action.payload,
      };
    case GET_LAPORAN_KEUANGAN_TRANSFER_CARD:
      return {
        ...state,
        dataLaporanKeuanganTransferCard: action.payload,
      };
    case GET_LAPORAN_KEUANGAN_TRANSFER_SUMMARY:
      return {
        ...state,
        dataLaporanKeuanganTransferSummary: action.payload,
      };
    case GET_LAPORAN_PENERIMAAN_SUPPLIER:
      return {
        ...state,
        dataLaporanPenerimaanSupplier: action.payload,
      };
    case GET_LAPORAN_RETURN_SUPPLIER_DETAIL:
      return {
        ...state,
        dataLaporanReturnSupplier: action.payload,
      };
    case GET_LAPORAN_HUTANG_SUPPLIER:
      return {
        ...state,
        dataLaporanHutangSupplier: action.payload,
      };
    case GET_LAPORAN_HUTANG_SUPPLIER_CARD:
      return {
        ...state,
        dataLaporanHutangSupplierCard: action.payload,
      };
    case GET_LAPORAN_HUTANG_SUPPLIER_SUMMARY:
      return {
        ...state,
        dataLaporanHutangSupplierSummary: action.payload,
      };
    case GET_LAPORAN_OMZET_SUPPLIER:
      return {
        ...state,
        dataLaporanOmzetSupplier: action.payload,
      };
    case GET_LAPORAN_PEMBAYARAN_SUPPLIER:
      return {
        ...state,
        dataLaporanPembayaranSupplier: action.payload,
      };
    case GET_LAPORAN_TOLAKAN_SUPPLIER:
      return {
        ...state,
        dataLaporanTolakanSupplier: action.payload,
      };
    case GET_LAPORAN_TITIP_SUPPLIER:
      return {
        ...state,
        dataLaporanTitipSupplier: action.payload,
      };
    case GET_LAPORAN_TITIP_SUPPLIER_CARD:
      return {
        ...state,
        dataLaporanTitipSupplierCard: action.payload,
      };
    case GET_LAPORAN_TITIP_SUPPLIER_SUMMARY:
      return {
        ...state,
        dataLaporanTitipSupplierSummary: action.payload,
      };
    case GET_LAPORAN_VALIDASI:
      return {
        ...state,
        dataLaporanValidasi: action.payload,
      };
    case GET_LAPORAN_STOCK_SAMPLE:
      return {
        ...state,
        dataLaporanStockSample: action.payload,
      };
    case GET_LAPORAN_KARTU_STOCK_SAMPLE:
      return {
        ...state,
        dataLaporanKartuStockSample: action.payload,
      };
    case GET_LAPORAN_SUMMARY_STOCK_SAMPLE:
      return {
        ...state,
        dataLaporanSummaryStockSample: action.payload,
      };
    case GET_LAPORAN_PINDAH_BARANG_SAMPLE:
      return {
        ...state,
        dataLaporanPindahBarangSample: action.payload,
      };
    case GET_LAPORAN_SUMMARY_BARANG:
      return {
        ...state,
        dataLaporanSummaryBarang: action.payload,
      };
    case GET_LAPORAN_KARTU_STOCK_BARANG:
      return {
        ...state,
        dataLaporanKartuStockBarang: action.payload,
      };
    case GET_LAPORAN_MUTASI_BARANG_ACTION:
      return {
        ...state,
        dataLaporanMutasiBarang: action.payload,
      };
    case GET_LAPORAN_PINDAH_BARANG:
      return {
        ...state,
        dataLaporanPackingBarang: action.payload,
      };
    case GET_LAPORAN_SALDO_STOCK_BARCODE:
      return {
        ...state,
        dataLaporanSaldoStockBarcode: action.payload,
      };
    case GET_LAPORAN_KIRIM_STOCK_RETUR:
      return {
        ...state,
        dataLaporanKirimStockRetur: action.payload,
      };
    case GET_LAPORAN_PER_KATEGORI:
      return {
        ...state,
        dataLaporanStockPerKategori: action.payload,
      };
    case GET_LAPORAN_STOCK_PER_LOKASI:
      return {
        ...state,
        dataLaporanStockPerLokasi: action.payload,
      };
    case GET_LAPORAN_PENJUALAN_CUSTOMER:
      return {
        ...state,
        dataLaporanPenjualanCustomer: action.payload,
      };
    case GET_LAPORAN_PIUTANG_CUSTOMER:
      return {
        ...state,
        dataLaporanPiutangCustomer: action.payload,
      };
    case GET_LAPORAN_PIUTANG_CUSTOMER_CARD:
      return {
        ...state,
        dataLaporanPiutangCustomerCard: action.payload,
      };
    case GET_LAPORAN_PIUTANG_CUSTOMER_SUMMARY:
      return {
        ...state,
        dataLaporanPiutangCustomerSummary: action.payload,
      };
    case GET_LAPORAN_RETURN_CUSTOMER:
      return {
        ...state,
        dataLaporanReturnCustomer: action.payload,
      };
    case GET_LAPORAN_PEMBAYARAN_CUSTOMER:
      return {
        ...state,
        dataLaporanPembayaranCustomer: action.payload,
      };
    case GET_LAPORAN_TITIP_CUSTOMER:
      return {
        ...state,
        dataLaporanTitipCustomer: action.payload,
      };
    case GET_LAPORAN_TITIP_CUSTOMER_CARD:
      return {
        ...state,
        dataLaporanTitipCustomerCard: action.payload,
      };
    case GET_LAPORAN_TITIP_CUSTOMER_SUMMARY:
      return {
        ...state,
        dataLaporanTitipCustomerSummary: action.payload,
      };
    case GET_LAPORAN_CORRECTION_NOTE:
      return {
        ...state,
        dataLaporanCorrectioNote: action.payload,
      };
    case GET_LAPORAN_OMZET_CUSTOMER:
      return {
        ...state,
        dataLaporanOmzetCustomer: action.payload,
      };
    case GET_LAPORAN_ANALISA_CUSTOMER:
      return {
        ...state,
        dataLaporanAnalisaCustomer: action.payload,
      };
    case GET_LAPORAN_TAMBAH_SALDO_24K:
      return {
        ...state,
        dataLaporanTambahSaldo24k: action.payload,
      };
    case GET_LAPORAN_PENJUALAN_24K:
      return {
        ...state,
        dataLaporanPenjualan24k: action.payload,
      };
    case GET_LAPORAN_GLOBAL_24K:
      return {
        ...state,
        dataLaporanGlobal24k: action.payload,
      };
    case GET_LAPORAN_STOCK_GLOBAL:
      return {
        ...state,
        dataLaporanStockGlobal: action.payload,
      };
    case GET_LAPORAN_KARTU_STOCK:
      return {
        ...state,
        dataLaporanKartuStock: action.payload,
      };
    case GET_LAPORAN_HISTORY_CUSTOMER:
      return {
        ...state,
        dataLaporanHistoryCustomer: action.payload,
      };

    default:
      return state;
  }
};

export default bahan;
