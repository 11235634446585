import {finishSend, progressSend} from "../../../actions/LogicAction";
import { reset} from "redux-form";
import Swal from "sweetalert2";
import {AxiosMasterDelete, AxiosMasterGet, AxiosMasterPost, AxiosMasterPut} from "../../../Axios";
import {hideModal, showModal} from "../../../actions/ModalAction";
import {toast} from "react-toastify";
import {ToastKirim} from "../../../actions/toast";


export const GET_LIST_BAHAN = "GET_LIST_BAHAN";
export const SET_EDIT_BAHAN = "SET_EDIT_BAHAN";

export const setEditBahan = (data) => {
    return async (dispatch, getState) => {
        dispatch(progressSend())
       dispatch({
           type : SET_EDIT_BAHAN,
           payload : data
       })
        dispatch(finishSend())
        dispatch(showModal())
    }
}

export const getListBahan = () => {
    return async (dispatch) => {
        const result = await AxiosMasterGet("bahan");
        dispatch({
            type : GET_LIST_BAHAN,
            payload : result.data
        })
    }
}

export const deleteBahan = (kode) => {
    return async (dispatch, getState) => {
        dispatch(progressSend())
        Swal.fire({
            title: "Anda Yakin ?",
            text: "Anda Akan Menghapus Data Ini ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async(result) => {
            if (result.isConfirmed) {
               try {
                   await toast.promise(AxiosMasterDelete("bahan/" + kode), ToastKirim)
                   dispatch(getListBahan())
                   dispatch(finishSend())
                     } catch (e) {
                         dispatch(finishSend())
                     }
            }
        });
        dispatch(finishSend())
    }
}

export const sendBahan = () => {
    return async (dispatch, getState) => {
        dispatch(progressSend())
        const state = getState();
        const selected = state.form.FormBahan.values;
        let data = {
            kode_bahan: selected.kodeBahan,
            nama_bahan: selected.namaBahan,
        };

        try {
            await toast.promise(AxiosMasterPost("bahan", data), ToastKirim)
            dispatch(reset("FormBahan"))
            dispatch(finishSend())
            dispatch(hideModal())
            dispatch(getListBahan())
        } catch (e){
            dispatch(finishSend())
            dispatch(hideModal())
            dispatch(getListBahan())
        }

    }
}

export const sendEditBahan = () => {
    return async (dispatch, getState) => {
        dispatch(progressSend())
        const state = getState();
        const selected = state.form.FormBahanEdit.values;
        let data = {
            nama_bahan: selected.namaBahan,
        };

        await toast.promise(AxiosMasterPut("bahan/"+ selected.kodeBahan, data), ToastKirim)
        dispatch(reset("FormBahanEdit"))
        dispatch(finishSend())
        dispatch(hideModal())
        dispatch(getListBahan())
    }
}