import {
  finishSend,
  isExist,
  isExistSingle,
  progressSend,
} from "../../../actions/LogicAction";
import { getLocal, saveLocal } from "../../../encrypt";
import { change, reset, submit } from "redux-form";
// import { hideModal } from "../../../actions/ModalAction";
import {
  formatDateIndo,
  formatGram,
  formatRp,
  FormatterNumber,
  getBon,
  getUserID,
  multipleDeleteLocal,
  printInfo,
  purifiedData,
  removeComma,
  setupSelectedValue,
  setupSelectedValueCodeAndName,
} from "../../../helper";
import { toast } from "react-toastify";
import { AxiosMasterGet, AxiosMasterPost } from "../../../Axios";
import { ToastKirim } from "../../../actions/toast";
import { getUUID } from "../../../actions/fakturAction";
import { getToday } from "../../../getDate";
import { hideModal } from "../../../actions/ModalAction";
import PrintNota from "../../../Stocking/Component/PrintNota";

export const GET_DATA_RETURN_PENJUALAN = "GET_DATA_RETURN_PENJUALAN";
export const GET_DATA_RETURN_PENJUALAN_NON_TOKO =
  "GET_DATA_RETURN_PENJUALAN_NON_TOKO";
export const GET_TOTAL_NW = "GET_TOTAL_NW";
export const SET_EDIT_RETUR_PENJUALAN = "SET_EDIT_RETUR_PENJUALAN";
export const GET_UNVALID_RETUR_CUSTOMER = "GET_UNVALID_RETUR_CUSTOMER";
export const SET_EDIT_TRANSAKSI_RETURN_PENJUALAN =
  "SET_EDIT_TRANSAKSI_RETURN_PENJUALAN";
export const CHANGE_EDIT_BON_RETURN_PENJUALAN =
  "CHANGE_EDIT_BON_RETURN_PENJUALAN";
export const SAVE_PREV_NO_RETURN_PENJUALAN = "SAVE_PREV_NO_RETURN_PENJUALAN";

export const getListReturnPenjualan = () => {
  return async (dispatch) => {
    const result = await getLocal("ReturnPenjualanTemp");
    const totalNW = result
      .map((x) => parseFloat(removeComma(x.netto_disc)))
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    dispatch(change("ReturnPenjualan", "totalNW", formatGram(totalNW)));
    dispatch(
      change("ReturnPenjualan", "grand_total_nw_ext", formatGram(totalNW))
    );
    dispatch(change("ReturnPenjualan", "disc_global", 0));
    dispatch(change("ReturnPenjualan", "disc_global_gr", 0));
    dispatch(change("ReturnPenjualan", "grand_total_nw", formatGram(totalNW)));
    dispatch({
      type: GET_DATA_RETURN_PENJUALAN,
      payload: {
        data: result,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const saveEditReturPenjualan = (data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_EDIT_RETUR_PENJUALAN,
      payload: data,
    });
  };
};

export const getListReturnPenjualanNonToko = () => {
  return (dispatch) => {
    dispatch({
      type: GET_DATA_RETURN_PENJUALAN_NON_TOKO,
      payload: {
        data: JSON.parse(localStorage.getItem("ReturnPenjualanNonToko")),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const getTotalNW = () => {
  return (dispatch) => {
    dispatch({
      type: GET_TOTAL_NW,
      payload: {
        data: localStorage.getItem("GrandTotalNetto") || 0,
      },
    });
  };
};

export const changeEditBonReturnPenjualan = (data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: CHANGE_EDIT_BON_RETURN_PENJUALAN,
      payload: data,
    });
  };
};

export const setEditTransaksiReturnPenjualan = () => {
  return (dispatch) => {
    dispatch({
      type: SET_EDIT_TRANSAKSI_RETURN_PENJUALAN,
      payload: {
        data: localStorage.getItem("EditTransaksiReturn") || "false",
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const setPrevNoBON = () => {
  return (dispatch) => {
    dispatch({
      type: SAVE_PREV_NO_RETURN_PENJUALAN,
      payload: localStorage.getItem("prev_no_bon_return") || "-",
    });
  };
};

export const getNoBonReturnEdit = () => {
  return async (dispatch, getState) => {
    const noBon = await getLocal("noBonReturnEdit");
    if (!Array.isArray(noBon)) {
      dispatch(changeEditBonReturnPenjualan(true));
    } else {
      dispatch(changeEditBonReturnPenjualan(false));
    }
  };
};

export const saveEditReturnPenjualanTemp = (data) => {
  return async (dispatch, getState) => {
    await saveLocal("noBonReturnEdit", data.no_bon);
    dispatch(changeEditBonReturnPenjualan(true));
    const state = getState();
    const listCustomer = state.provinsi.customer;
    // const listSales = state.provinsi.salesman;
    const listLokasi = state.provinsi.lokasi;
    dispatch(change("ReturnPenjualan", "keterangan", "-"));
    dispatch(change("ReturnPenjualan", "no_bon", data.no_bon));
    dispatch(
      change(
        "ReturnPenjualan",
        "kode_lokasi",
        setupSelectedValue({
          target: data.kode_lokasi,
          value: "nama_lokasi",
          keyTarget: "kode_lokasi",
          source: listLokasi,
        })
      )
    );
    // dispatch(
    //   change(
    //     "ReturnPenjualan",
    //     "kode_sales",
    //     setupSelectedValue({
    //       target: data.kode_sales === "-" ? "MRKT" : data.kode_sales,
    //       value: "nama_sales",
    //       keyTarget: "kode_sales",
    //       source: listSales,
    //     })
    //   )
    // );
    dispatch(
      change(
        "ReturnPenjualan",
        "kode_customer",
        setupSelectedValueCodeAndName({
          target: data.kode_customer,
          value: "kode_customer",
          value2: "nama_customer",
          keyTarget: "kode_customer",
          source: listCustomer,
        })
      )
    );
    localStorage.setItem("kodeCustomer", data.kode_customer);
    const cust = setupSelectedValueCodeAndName({
      target: data.kode_customer,
      value: "kode_customer",
      value2: "nama_customer",
      keyTarget: "kode_customer",
      source: listCustomer,
    });

    localStorage.setItem(
      "temp-returPenjualanCustomer",
      `${cust.value}|${cust.label}`
    );
    dispatch({
      type: SAVE_PREV_NO_RETURN_PENJUALAN,
      payload: data.no_retur,
    });
    let number = 1;
    for (let item of data.detail_barang) {
      delete item._id;
      item.bruto = FormatterNumber(item.bruto);
      item.netto = FormatterNumber(item.netto);
      item.gross = FormatterNumber(item.gross);
      item.no = number;
      await isExist({
        target: "kode_barang",
        target2: "kode_bahan",
        name: "ReturnPenjualanTemp",
        payload: item,
        value: item.kode_barang,
        value2: item.kode_bahan,
      });
      const result = await getLocal("ReturnPenjualanTemp");
      const totalNW = result
        .map((x) => parseFloat(removeComma(x.netto_disc)))
        .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
      dispatch(change("ReturnPenjualan", "totalNW", formatGram(totalNW)));
      dispatch(change("ReturnPenjualan", "disc_global", 0));
      dispatch(change("ReturnPenjualan", "disc_global_gr", 0));
      dispatch(
        change("ReturnPenjualan", "grand_total_nw_ext", formatGram(totalNW))
      );
      dispatch(
        change("ReturnPenjualan", "grand_total_nw", formatGram(totalNW))
      );
      number += 1;
    }
    localStorage.setItem("EditTransaksiReturn", "true");
    localStorage.setItem("prev_no_bon_return", data.no_retur);
    dispatch(getListReturnPenjualan());
    dispatch(setEditTransaksiReturnPenjualan());
    dispatch(setPrevNoBON());
    toast.success("Barang Berhasil Dirubah");

    dispatch(hideModal());
    dispatch(finishSend());
  };
};
export const saveReturnTemp = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const selected = state.form.FormTransaksiReturn.values;
    const prevData = (await getLocal("ReturnPenjualanTemp")) || [];
    purifiedData(selected);
    const payload = {
      no: prevData.length,
      kode_barang: selected.kodeBarang.split("/")[0],
      kode_bahan: selected.kodeBarang.split("/")[1].trim(),
      kode_barcode: selected.kode_barcode || "-",
      qty: selected.qty || 0,
      plastik: selected.p || 0,
      kulit: selected.k || 0,
      gross: FormatterNumber(selected.gross) || 0,
      pkg: FormatterNumber(selected.pkg) || 0,
      bruto: FormatterNumber(selected.bruto) || 0,
      kadar_cetak: selected.kadar || 0,
      kadar_jual: selected.kadarTransaksi || 0,
      netto: FormatterNumber(selected.netto) || 0,
      disc_persen: selected.discGlobalPr || 0,
      disc_gram: FormatterNumber(selected.discGlobalGr) || 0,
      netto_disc: FormatterNumber(selected.nettoDiscGlobalExt) || 0,
      ongkos_rp: selected.ongkos_rp || 0,
    };
    await isExist({
      target: "kode_barang",
      target2: "kode_bahan",
      name: "ReturnPenjualanTemp",
      payload: payload,
      value: selected.kodeBarang.split("/")[0],
      value2: selected.kodeBarang.split("/")[1].trim(),
    });
    const result = await getLocal("ReturnPenjualanTemp");
    const totalNW = result
      .map((x) => parseFloat(removeComma(x.netto_disc)))
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    dispatch(change("ReturnPenjualan", "totalNW", formatGram(totalNW)));
    dispatch(
      change("ReturnPenjualan", "grand_total_nw_ext", formatGram(totalNW))
    );
    dispatch(change("ReturnPenjualan", "disc_global", 0));
    dispatch(change("ReturnPenjualan", "disc_global_gr", 0));
    dispatch(change("ReturnPenjualan", "grand_total_nw", formatGram(totalNW)));
    dispatch(getListReturnPenjualan());
    toast.success("Barang Berhasil Ditambahkan");
    dispatch(reset("FormTransaksiReturn"));
    // dispatch(hideModal());
    dispatch(finishSend());
  };
};

export const saveReturEditTemp = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const selected = state.form.FormTransaksiReturnEdit.values;
    purifiedData(selected);
    console.log("POSITION", selected.no - 1);
    const payload = {
      no: selected.no,
      kode_barang: selected.kodeBarang.split("/")[0],
      kode_bahan: selected.kodeBarang.split("/")[1].trim(),
      kode_barcode: selected.kode_barcode || "-",
      qty: selected.qty || 0,
      plastik: selected.p || 0,
      kulit: selected.k || 0,
      gross: FormatterNumber(selected.gross) || 0,
      pkg: FormatterNumber(selected.pkg) || 0,
      bruto: FormatterNumber(selected.bruto) || 0,
      kadar_cetak: selected.kadar || 0,
      kadar_jual: selected.kadarTransaksi || 0,
      netto: FormatterNumber(selected.netto) || 0,
      disc_persen: selected.discGlobalPr || 0,
      disc_gram: FormatterNumber(selected.discGlobalGr) || 0,
      netto_disc: FormatterNumber(selected.nettoDiscGlobalExt) || 0,
      ongkos_rp: selected.ongkos_rp || 0,
    };
    const res = await isExistSingle({
      target: "no",
      name: "ReturnPenjualanTemp",
      payload: payload,
      value: selected.no,
      replace: true,
    });
    const result = await getLocal("ReturnPenjualanTemp");
    const totalNW = result
      .map((x) => parseFloat(removeComma(x.netto_disc)))
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    dispatch(change("ReturnPenjualan", "totalNW", formatGram(totalNW)));
    dispatch(
      change("ReturnPenjualan", "grand_total_nw_ext", formatGram(totalNW))
    );
    dispatch(change("ReturnPenjualan", "disc_global", 0));
    dispatch(change("ReturnPenjualan", "disc_global_gr", 0));
    dispatch(change("ReturnPenjualan", "grand_total_nw", formatGram(totalNW)));
    dispatch(getListReturnPenjualan());
    if (res !== "DECLINED") {
      toast.success("Barang Berhasil Dirubah");
    }
    dispatch(hideModal());
    dispatch(finishSend());
  };
};

export const deleteLocalReturnTemp = (nama, index, getFunction) => {
  return async (dispatch) => {
    dispatch(progressSend());
    const data = await getLocal(nama);
    data.splice(index, 1);
    await saveLocal(nama, data);
    const result = await getLocal("ReturnPenjualanTemp");
    const totalNW = result
      .map((x) => parseFloat(removeComma(x.netto_disc)))
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    dispatch(change("ReturnPenjualan", "totalNW", formatGram(totalNW)));
    dispatch(
      change("ReturnPenjualan", "grand_total_nw_ext", formatGram(totalNW))
    );
    dispatch(change("ReturnPenjualan", "disc_global", 0));
    dispatch(change("ReturnPenjualan", "disc_global_gr", 0));
    dispatch(change("ReturnPenjualan", "grand_total_nw", formatGram(totalNW)));
    dispatch(getListReturnPenjualan());
    dispatch(finishSend());
  };
};

export const sendReturn = () => {
  return async (dispatch, getState) => {
    let state = getState();
    const selected = state.form.ReturnPenjualan.values;
    purifiedData(selected);
    const noBon = "RPJ-" + getBon();
    const detail_barang = await getLocal("ReturnPenjualanTemp");
    const total_netto = detail_barang
      .map((x) => parseFloat(removeComma(x.netto_disc)))
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    dispatch(submit("ReturnPenjualan"));
    if (state.form.ReturnPenjualan.syncErrors) {
      return false;
    }
    let result = await PostReturnCustomer({
      dispatch: dispatch,
      payload: {
        no_retur: state.provinsi.noFaktur,
        no_bon: noBon,
        is_edit: state.provinsi.prev_no_return_penjualan !== "-",
        prev_no_retur: state.provinsi.prev_no_return_penjualan,
        tanggal: selected.tanggal,
        kode_lokasi: selected.kode_lokasi || "PUSAT",
        kode_customer: selected.kode_customer,
        detail_barang: detail_barang.map(({ no, _id, ...rest }) => rest),
        total_netto: FormatterNumber(total_netto),
        disc_persen: selected.disc_global || 0,
        disc_gram: FormatterNumber(selected.disc_global_gr) || 0,
        total_netto_disc: FormatterNumber(selected.grand_total_nw),
        total_netto_disc_ext: FormatterNumber(selected.grand_total_nw_ext),
      },
    });
    const tableRows = [];
    var table = detail_barang;
    // for each ticket pass all its data into an array
    let totalPkg = 0;
    table.forEach((ticket, i) => {
      const brutopkg = parseFloat(ticket.bruto) + parseFloat(ticket.pkg);
      const ticketData = [
        ++i,
        ticket.kode_barang,
        ticket.kode_bahan,
        { content: formatGram(ticket.bruto), styles: { halign: "right" } },
        { content: ticket.kadar_jual, styles: { halign: "right" } },
        { content: formatGram(ticket.disc_gram), styles: { halign: "right" } },
        { content: formatGram(ticket.netto, 3), styles: { halign: "right" } },
        { content: formatGram(ticket.pkg), styles: { halign: "right" } },
        { content: formatGram(brutopkg), styles: { halign: "right" } },
        { content: ticket.qty, styles: { halign: "right" } },
        { content: formatRp(ticket.ongkos_rp), styles: { halign: "right" } },
      ];
      // push each tickcet's info into a row
      tableRows.push(ticketData);
      totalPkg += brutopkg;
    });
    const summary = [
      "",
      "",
      "Total",
      {
        content: formatGram(
          table.map((list) => parseFloat(list.bruto)).reduce((a, b) => a + b, 0)
        ),
        styles: { halign: "right" },
      },
      "",
      {
        content: formatGram(
          table
            .map((list) => parseFloat(list.disc_gram))
            .reduce((a, b) => a + b, 0)
        ),
        styles: { halign: "right" },
      },
      {
        content: formatGram(
          table
            .map((list) => parseFloat(list.netto, 3))
            .reduce((a, b) => a + b, 0),
          3
        ),
        styles: { halign: "right" },
      },
      {
        content: formatGram(
          table
            .map((list) => parseFloat(list.pkg, 3))
            .reduce((a, b) => a + b, 0)
        ),
        styles: { halign: "right" },
      },
      {
        content: formatGram(totalPkg),
        styles: { halign: "right" },
      },
      {
        content: table
          .map((list) => parseFloat(list.qty))
          .reduce((a, b) => a + b, 0),
        styles: { halign: "right" },
      },
      {
        content: formatRp(
          table
            .map((list) => parseFloat(list.ongkos_rp, 3))
            .reduce((a, b) => a + b, 0)
        ),
        styles: { halign: "right" },
      },
    ];

    let total24K =
      table
        .map((list) => parseFloat(list.netto, 3))
        .reduce((a, b) => a + b, 0) - parseFloat(selected.disc_global_gr);
    let footer = [
      summary,
      [
        {
          content: "Diskon Global : " + selected.disc_global_gr,
          styles: { halign: "right" },
          colSpan: 11,
        },
      ],
      [
        {
          content: "Total 24K : " + formatGram(total24K, 3),
          styles: { halign: "right" },
          colSpan: 11,
        },
      ],
      printInfo(),
    ];
    var columTable = [
      "No",
      "Kode Barang",
      "Kode Bahan",
      "Bruto \n(Gr)",
      "Harga \n(%)",
      "Diskon",
      "Netto \n(Gr)",
      "Pkg",
      "Bruto + Pkg \n(Gr)",
      "Qty",
      "Ongkos \n(Rp)",
    ];
    // AKHIR INISIALISASI AUTOTABLE
    PrintNota(
      "No Bon",
      noBon.toUpperCase(),
      "Tanggal",
      formatDateIndo(selected.tanggal),
      "",
      "",
      "",
      "",
      getUserID(),
      getToday(),
      "",
      [columTable],
      "Return Transaksi Penjualan",
      tableRows,
      footer
    );
    if (result.includes("E11000")) {
      multipleDeleteLocal(["noFaktur"]);
      dispatch(finishSend());
      dispatch(getUUID());
      state = getState();
      await PostReturnCustomer({
        dispatch: dispatch,
        payload: {
          no_retur: state.provinsi.noFaktur,
          no_bon: noBon,
          is_edit: state.provinsi.prev_no_return_penjualan !== "-",
          prev_no_return_penjualan: state.provinsi.prev_no_return_penjualan,
          tanggal: selected.tanggal,
          kode_lokasi: selected.kode_lokasi || "PUSAT",
          kode_customer: selected.kode_customer,
          detail_barang: detail_barang.map(({ no, _id, ...rest }) => rest),
          total_netto: FormatterNumber(total_netto),
          disc_persen: selected.disc_global || 0,
          disc_gram: FormatterNumber(selected.disc_global_gr) || 0,
          total_netto_disc: FormatterNumber(selected.grand_total_nw),
          total_netto_disc_ext: FormatterNumber(selected.grand_total_nw_ext),
        },
      });
    }
  };
};

export const PostReturnCustomer = async ({ payload, dispatch }) => {
  try {
    await toast.promise(
      AxiosMasterPost("trx-customer/retur-customer", payload),
      ToastKirim
    );
    // print(detail_barang, noBon, selected.tanggal, result_explode[0])
    multipleDeleteLocal([
      "ReturnPenjualanTemp",
      "noBon",
      "tanggal",
      "tanggalBarang",
      "namaSupplier",
      "kodeSupplier",
      "keterangan",
      "totalNW",
      "noFaktur",
      "temp-returPenjualanDate",
      "temp-returPenjualanKeterangan",
      "temp-returPenjualanCustomer",
      "temp-returPenjualanSales",
      "temp-returPenjualanLokasi",
      "temp-returPenjualanDiscGlobal",
      "noBonReturnEdit",
      "prev_no_bon_return",
    ]);
    dispatch(finishSend());
    dispatch(getUUID());
    dispatch(getListReturnPenjualan());
    dispatch(reset("ReturnPenjualan"));
    dispatch(change("ReturnPenjualan", "tanggal", getToday()));
    dispatch(change("ReturnPenjualan", "totalNW", 0));
    dispatch(change("ReturnPenjualan", "grand_total_nw_ext", 0));
    dispatch(change("ReturnPenjualan", "disc_global", 0));
    dispatch(change("ReturnPenjualan", "disc_global_gr", 0));
    dispatch(change("ReturnPenjualan", "grand_total_nw", 0));
    return "SUCCESS";
  } catch (e) {
    dispatch(finishSend());
    return e?.response?.data;
  }
};

export const getUnvalidReturCustomer = () => {
  return async (dispatch, getState) => {
    const response = await AxiosMasterGet("retur-customer/unvalid");
    dispatch({
      type: GET_UNVALID_RETUR_CUSTOMER,
      payload:
        localStorage.getItem("kodeCustomer") === "ALL"
          ? response.data
          : response.data.filter(
              (x) => x.kode_customer === localStorage.getItem("kodeCustomer")
            ),
    });
  };
};
