import { finishSend, progressSend } from "../../../actions/LogicAction";
import { toast } from "react-toastify";
import { AxiosMasterGet } from "../../../Axios";
import { ToastAmbil } from "../../../actions/toast";
import LaporanReturnCustomer from "../component/LaporanReturnCustomer";

export const GET_LAPORAN_RETURN_CUSTOMER = "GET_LAPORAN_RETURN_CUSTOMER";

export const GetLaporanReturnCustomer = (kode_customer, tglFrom, tglTo) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    try {
      const response = await toast.promise(
        AxiosMasterGet(
          `report/retur-customer/REKAP&${
            kode_customer.split("|")[0]
          }&${tglFrom}&${tglTo}`
        ),
        ToastAmbil
      );
      dispatch({
        type: GET_LAPORAN_RETURN_CUSTOMER,
        payload: {
          data: response.data,
          kode_customer: kode_customer,
          tglFrom: tglFrom,
          tglTo: tglTo,
        },
      });
      dispatch(finishSend());
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

export const PrintLaporanReturnCustomerPDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanReturnCustomer = state.laporan.dataLaporanReturnCustomer;
    LaporanReturnCustomer(
      dataLaporanReturnCustomer.data,
      dataLaporanReturnCustomer.tglFrom,
      dataLaporanReturnCustomer.tglTo
    );
  };
};
