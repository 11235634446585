import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import { getUUID } from "../actions/fakturAction";
import Swal from "sweetalert2";
import { getListTolakReturnCustomer } from "../Transaksi/retur-supplier/redux/returnSupplier";
import { Field, formValueSelector, reduxForm, reset } from "redux-form";

import PrintNota from "../Stocking/Component/PrintNota";
import { warning } from "../Alert";
import ReactTooltip from "react-tooltip";
import { finishSend, progressSend } from "../actions/LogicAction";
import { AxiosMasterPost } from "../Axios";
import Skeleton from "react-loading-skeleton";
import { showModal } from "../actions/ModalAction";
import HeaderCard from "../HeaderCard";
import { renderField, renderFieldSelect } from "../renderField";
import GlobalTabel from "../GlobalComponent/GlobalTabel";
import { formatGram, getBon, getUserID, multipleDeleteLocal, printInfo } from "../helper";
import { getcustomerList } from "../DataMaster/customer/redux/customerAction";
import { getToday } from "../getDate";
import FormPengambilanCiok from "./Component/FormPengambilanCiok";
import { getListPengambilanCiok } from "../actions/pengambilanCiokAction";
import { getsalesmanList } from "../DataMaster/sales/redux/salesmanAction";

const ModalMasterData = lazy(() =>
  import("../DataMaster/Component/ModalMasterData")
);

class PengambilanCiok extends Component {
  constructor(props) {
    var date = new Date();
    var formatedDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
    super(props);
    this.state = {
      supplierList: [],
      DataSupplier: [],
      startDate: formatedDate,
      kodeSupplier: "",
      namaSupplier: "",
      tanggal: formatedDate,
      status: "",
      tampungBarang: [],
      noBon: "AS/AMB-" + getBon(),
      columns: [
        {
          dataField: "kode_jenis",
          text: "Kode Barang",
        },
        {
          dataField: "kadar_transaksi",
          text: "Harga Jual",
          align: "right",
          headerAlign: "right",
        },
        {
          dataField: "bruto",
          text: "Berat\n(Gr)",
          align: "right",
          headerAlign: "right",
        },
        {
          dataField: "netto",
          text: "Murni\n(Gr)",
          align: "right",
          headerAlign: "right",
        },
        {
          dataField: "Aksi",
          text: "Aksi",
          csvExport: false,
          formatter: (rowContent, row, rowIndex) => {
            return (
              <div className="text-center">
                <button
                  data-tip
                  data-for="hapus"
                  className="btn btn-danger mr-2"
                  onClick={() => this.deleteRow(rowIndex)}
                >
                  <ReactTooltip id="hapus" type="dark" effect="solid">
                    <span>Hapus</span>
                  </ReactTooltip>
                  <i className="fa fa-trash mr-1"></i>
                </button>
                <br />
              </div>
            );
          },
        },
      ],
    };
  }

  deleteRow(rowIndex) {
    Swal.fire({
      title: "Anda Yakin ?",
      text: "Anda Akan Menghapus Data Ini ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const array = JSON.parse(localStorage.getItem("PengambilanCiok")) || [];
        array.splice(rowIndex, 1);
        localStorage.setItem("PengambilanCiok", JSON.stringify(array));
        Swal.fire({
          text: "Berhasil",
          timer: 2000,
          showConfirmButton: false,
          position: "top-right",
          icon: "success",
        })
          .then(() => this.props.dispatch(getListPengambilanCiok()))
          .then(() =>
            this.setState({
              updated: true,
            })
          );
      }
    });
  }
  componentDidMount() {
    localStorage.removeItem("PengambilanCiok");
    this.props.dispatch(getcustomerList());
    this.props.dispatch(getsalesmanList());
    this.props.dispatch(getUUID());
    this.props.dispatch(getListPengambilanCiok());
  }

  inputChange = (input) => (e) => {
    this.setState({
      [input]: e.target.value,
    });
  };

  handleSubmit(data) {
    var simpan = {
      kode_jenis: data.kodeBarang.split("|")[0],
      kadar_transaksi: data.kadarTransaksi,
      bruto: data.bruto,
      netto: data.netto,
    };

    var array = JSON.parse(localStorage.getItem("PengambilanCiok")) || [];
    array.push(simpan);
    localStorage.setItem("PengambilanCiok", JSON.stringify(array));
    Swal.fire({
      position: "top-end",
      icon: "success",
      text: "Data Berhasil Ditambahkan!",
      showConfirmButton: false,
      timer: 1500,
    });
    this.props.dispatch(getListPengambilanCiok());
    this.props.dispatch(reset("FormPengambilanCiok"));
  }

  returnSupplierSubmit() {
    if (this.props.tanggal_selected === "") {
      warning("Mohon isi Tanggal");
      return false;
    } else if (localStorage.getItem("PengambilanCiok") === null) {
      warning("Barang masih kosong, silahkan tambahkan barang");
      return false;
    }
    let isi = {
      tanggal: this.props.tanggal_selected || getToday(),
      no_bon: this.state.noBon,
      kode_customer: this.props.supplier_selected.split("-")[0],
      kode_sales: "-",
      detail_barang: JSON.parse(localStorage.getItem("PengambilanCiok")),
    };
    console.log(JSON.stringify(isi));
    this.props.dispatch(progressSend());
    // INISIALISASI AUTOTABLE
    const tableRows = [];
    var table = JSON.parse(localStorage.getItem("PengambilanCiok"));
    // for each ticket pass all its data into an array
    table.forEach((ticket, i) => {
      const ticketData = [
        ++i,
        ticket.kode_jenis,
        { content: formatGram(ticket.bruto), styles: { halign: "right" } },
        { content: ticket.kadar_transaksi, styles: { halign: "right" } },
        { content: formatGram(ticket.netto, 3), styles: { halign: "right" } },
      ];
      // push each tickcet's info into a row
      tableRows.push(ticketData);
    });
    const footer = [
      "",
      "",
      {
        content: table
          .map((list) => parseFloat(list.bruto))
          .reduce((a, b) => a + b, 0),
        styles: { halign: "right" },
      },
      "",
      {
        content: formatGram(
          table.map((list) => parseFloat(list.netto)).reduce((a, b) => a + b, 0)
        ),
        styles: { halign: "right" },
      },
    ];
    // tableRows.push(footer);
    console.log(JSON.stringify(tableRows));
    var columTable = [
      [
        "No",
        "Kode Barang",
        { content: "Bruto", styles: { halign: "right" } },
        { content: "Harga Jual", styles: { halign: "right" } },
        { content: "Netto", styles: { halign: "right" } },
      ],
    ];

    // AKHIR INISIALISASI AUTOTABLE
    AxiosMasterPost("/trx-ambil-ciok", isi)
      .then((res) =>
        Swal.fire({
          position: "top-end",
          icon: "success",
          text: "Data Berhasil Ditambahkan!",
          showConfirmButton: false,
          timer: 1500,
        })
          .then(() =>
            PrintNota(
              "No Bon",
              this.state.noBon,
              "Tanggal",
              this.props.tanggal_selected || getToday,
              "Customer",
              this.props.supplier_selected.split("-")[1],
              "",
              "",
              getUserID(),
              getToday(),
              "",
              columTable,
              "Catatan Pengambilan Dari Pelanggan",
              tableRows,
              [footer, printInfo()]
            )
          )
          .then(() => multipleDeleteLocal(["TolakReturnCustomer", "noFaktur"]))
          .then(() => this.props.dispatch(reset("TolakReturnCustomer")))
          .then(() => this.props.dispatch(finishSend()))
          .then(() => this.props.dispatch(getUUID()))
          .then(() => this.props.dispatch(getListTolakReturnCustomer()))
          .then(() => window.location.reload())
          .then(() =>
            this.setState({
              noBon: "",
              kodeSupplier: "",
            })
          )
          .then(() => document.getElementById("myForm").reset())
      )
      .catch((err) =>
        Swal.fire({
          position: "top-end",
          icon: "error",
          text: err?.response.data,
          showConfirmButton: false,
          timer: 1500,
        }).then(() => this.props.dispatch(finishSend()))
      );
  }
  render() {
    return (
      <div className="container-fluid" style={{ color: "black" }}>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <HeaderCard title="Pengambilan Ciok & Rongsok Customer" />
              <div className="card-body">
                <ModalMasterData
                  content={
                    <Suspense
                      fallback={<Skeleton width={"100%"} height={400} />}
                    >
                      <FormPengambilanCiok
                        from="tolakan"
                        kodeSupplier={this.props.supplier_selected}
                        onSubmit={(data) => this.handleSubmit(data)}
                        noFaktur={this.props.noFaktur}
                      />
                    </Suspense>
                  }
                  title="Tambah Data Barang"
                />
                <div className="col-lg-12 col-md-12">
                  <div className="row">
                    <div className="col-lg-12">
                      <form id="myForm">
                        <div className="row">
                          <div className="col-lg-4">
                            <Field
                              name="supplier"
                              component={renderFieldSelect}
                              options={this.props.customer.map((list) => {
                                let data = {
                                  value:
                                    list.kode_customer +
                                    "-" +
                                    list.nama_customer,
                                  name: list.nama_customer,
                                };
                                return data;
                              })}
                              type="text"
                              label="Customer"
                              placeholder="Pilih Customer"
                            />
                          </div>
                          <div className="col-lg-4">
                            <Field
                              name="tanggal"
                              component={renderField}
                              type="date"
                              label="Tanggal"
                              placeholder="Pilih Tanggal"
                            />
                          </div>
                          {/* <div className="col-lg-4">
                            <Field
                              name="sales"
                              component={renderFieldSelect}
                              options={this.props.sales.map((list) => {
                                let data = {
                                  value:
                                    list.kode_sales + "-" + list.nama_sales,
                                  name: list.nama_sales,
                                };
                                return data;
                              })}
                              type="text"
                              label="Sales"
                              placeholder="Pilih Sales"
                            />
                          </div> */}
                        </div>
                      </form>
                    </div>
                    <div className="col-md-12 col-lg-12 mt-3">
                      <div className="row">
                        <div className="col-lg-6"></div>
                        <div className="col-lg-6">
                          <div className="text-right">
                            <button
                              disabled={
                                this.props.supplier_selected === undefined
                              }
                              className="btn btn-primary"
                              onClick={() => this.props.dispatch(showModal())}
                            >
                              Tambah Data
                              <i class="fa fa-plus ml-3" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 mt-3">
                      <GlobalTabel
                        keyField="id"
                        data={this.props.listPengambilanCiok}
                        columns={this.state.columns}
                      />
                      <div className="text-right">
                        <button
                          className="btn btn-primary"
                          disabled={this.props.onSend}
                          onClick={() => this.returnSupplierSubmit()}
                        >
                          {this.props.onSend ? (
                            <span
                              className="spinner-border spinner-border-sm mr-3"
                              aria-hidden="true"
                            ></span>
                          ) : null}
                          Selesai
                          <i
                            class="fa fa-paper-plane ml-3"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
PengambilanCiok = reduxForm({
  form: "PengambilanCiok",
  enableReinitialize: true,
})(PengambilanCiok);
const selector = formValueSelector("PengambilanCiok");
export default connect((state) => {
  const { tanggal, supplier, no_bon, sales } = selector(
    state,
    "tanggal",
    "supplier",
    "no_bon",
    "sales"
  );
  return {
    noFaktur: state.provinsi.noFaktur,
    username: state.provinsi.username,
    listPengambilanCiok: state.provinsi.listPengambilanCiok,
    alert: state.provinsi.alert,
    error: state.provinsi.error,
    onSend: state.provinsi.onSend,
    supplier: state.provinsi.supplier,
    customer: state.provinsi.customer,
    sales: state.provinsi.salesman,
    tanggal_selected: tanggal,
    supplier_selected: supplier,
    sales_selected: sales,
    no_bon_selected: no_bon,
    initialValues: {
      tanggal: getToday(),
    },
  };
})(PengambilanCiok);
