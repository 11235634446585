import { finishSend, progressSend } from "../../../actions/LogicAction";
import { toast } from "react-toastify";
import { AxiosMasterGet } from "../../../Axios";
import { ToastAmbil } from "../../../actions/toast";
import { getToday } from "../../../getDate";
import LaporanPrintBank from "../component/LaporanPrintBank";
import LaporanPrintBankCard from "../component/LaporanPrintBankCard";
import LaporanPrintBankSummary from "../component/LaporanPrintBankSummary";

export const GET_LAPORAN_KEUANGAN_TRANSFER = "GET_LAPORAN_KEUANGAN_TRANSFER";
export const GET_LAPORAN_KEUANGAN_TRANSFER_CARD =
  "GET_LAPORAN_KEUANGAN_TRANSFER_CARD";
export const GET_LAPORAN_KEUANGAN_TRANSFER_SUMMARY =
  "GET_LAPORAN_KEUANGAN_TRANSFER_SUMMARY";

export const ResetLaporanKeuanganTransfer = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_LAPORAN_KEUANGAN_TRANSFER,
      payload: [],
    });
    dispatch({
      type: GET_LAPORAN_KEUANGAN_TRANSFER_CARD,
      payload: {
        data: [],
        tglFrom: "",
        tglTo: "",
      },
    });
    dispatch({
      type: GET_LAPORAN_KEUANGAN_TRANSFER_SUMMARY,
      payload: {
        data: [],
        tglFrom: "",
        tglTo: "",
      },
    });
  };
};

export const getLaporanKeuanganTransfer = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    try {
      const response = await toast.promise(
        AxiosMasterGet("report/keuangan-bank"),
        ToastAmbil
      );
      dispatch({
        type: GET_LAPORAN_KEUANGAN_TRANSFER,
        payload: response.data,
      });
      dispatch(finishSend());
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

export const getLaporanKeuanganTransferCard = (no_rekening, tglFrom, tglTo) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    try {
      const response = await toast.promise(
        AxiosMasterGet(
          `report/keuangan-bank-card/${
            no_rekening || "CASH"
          }&${tglFrom}&${tglTo}`
        ),
        ToastAmbil
      );
      dispatch({
        type: GET_LAPORAN_KEUANGAN_TRANSFER_CARD,
        payload: {
          data: response.data,
          tglFrom: tglFrom,
          tglTo: tglTo,
        },
      });
      dispatch(finishSend());
    } catch (e) {
      dispatch(finishSend());
    }
  };
};
export const getLaporanKeuanganTransferSummary = (
  kode_bank,
  tglFrom,
  tglTo
) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    try {
      const response = await toast.promise(
        AxiosMasterGet(
          `report/keuangan-bank-summary/${
            kode_bank || "CASH"
          }&${tglFrom}&${tglTo}`
        ),
        ToastAmbil
      );
      dispatch({
        type: GET_LAPORAN_KEUANGAN_TRANSFER_SUMMARY,
        payload: {
          data: response.data,
          tglFrom: tglFrom,
          tglTo: tglTo,
        },
      });
      dispatch(finishSend());
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

export const PrintLaporanKeuanganTransferPDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanKeuanganTransfer =
      state.laporan.dataLaporanKeuanganTransfer;
    LaporanPrintBank(
      localStorage.getItem("username"),
      getToday(),
      localStorage.getItem("username"),
      dataLaporanKeuanganTransfer
    );
  };
};

export const PrintLaporanKeuanganTransferCardPDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanKeuanganTransferCard =
      state.laporan.dataLaporanKeuanganTransferCard;
    LaporanPrintBankCard(
      localStorage.getItem("username"),
      getToday(),
      localStorage.getItem("username"),
      dataLaporanKeuanganTransferCard
    );
  };
};

export const PrintLaporanKeuanganTransferSummaryPDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanKeuanganTransferSummary =
      state.laporan.dataLaporanKeuanganTransferSummary;
    LaporanPrintBankSummary(
      localStorage.getItem("username"),
      getToday(),
      localStorage.getItem("username"),
      dataLaporanKeuanganTransferSummary
    );
  };
};
