export const GET_SIDEBAR_UPDATE = "GET_SIDEBAR_UPDATE"

export const getareaList = () => {
    return (dispatch) => {
        dispatch({
            type : GET_SIDEBAR_UPDATE,
            payload: {
                data: "REFRESH",
            }
        })
    }
}

