import React from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { HeadLaporanExcel } from "../../Component/HeadLaporanExcel";
import { formatGram } from "../../../helper";

export const LaporanExcelValidasiCustomer = (props) => {
  const { data } = props;
  return (
    <>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="btn btn-success btn-block"
        table="table-to-xls"
        filename="Laporan Validasi Customer"
        sheet="Laporan Validasi Customer"
        buttonText="Export Excel"
      />
      <table rowkey="kode_member" id="table-to-xls" style={{ display: "none" }}>
        <thead>
          <HeadLaporanExcel
            nama={"LAPORAN VALIDASI CUSTOMER"}
            colSpan={7}
            rangeDate
            start={data.tgl_from}
            end={data.tgl_to}
          />
          <tr>
            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>NO</th>
            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
              NO FAKTUR
            </th>
            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
              TANGGAL FAKTUR
            </th>
            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
              TANGGAL VALIDASI
            </th>
            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
              TOTAL BRUTO
            </th>
            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
              TOTAL NETTO
            </th>
            <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
              USER VALIDASI
            </th>
          </tr>
        </thead>
        <tbody>
          {data.data.map((x, no) => (
            <>
              <tr>
                <td
                  colSpan={7}
                  style={{ backgroundColor: "#E8E5E5", color: "#000" }}
                >
                  JENIS : {x.jenis_transaksi}
                </td>
              </tr>
              {x.detail_validasi.map((y, index) => (
                <tr>
                  <td>{++index}</td>
                  <td>{y.no_faktur}</td>
                  <td>{y.tanggal_transaksi}</td>
                  <td>{y.tanggal_validasi}</td>
                  <td align="right">{formatGram(y.total_bruto)}</td>
                  <td align="right">{formatGram(y.total_netto)}</td>
                  <td>{y.validate_by}</td>
                </tr>
              ))}
            </>
          ))}
        </tbody>
      </table>
    </>
  );
};
