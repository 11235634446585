import jsPDF from "jspdf";
import "jspdf-autotable";
import { getToday } from "../../getDate";
import { formatGram, getUserID } from "../../helper";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporanPrintTimbangStock = (
  username = "",
  tanggal = "",
  validby = "",
  data = []
) => {
  // initialize jsPDF
  const doc = new jsPDF("portrait");
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  let tableRowsTitipan = [];
  let tableRowsReturn = [];

  let finalY = 25;
  doc.setFontSize(15);
  doc.text("LAPORAN TIMBANG STOCK ", 14, 10);
  // doc.addImage(logo_ayu_base64, "PNG", 14, 10, 35, 12);

  doc.setFontSize(10);

  if (data.length === 3) {
    data.map((data, index) => {
      if (index === 0) {
        let tableColumn = [
          [
            {
              content: "Tanggal: " + data?.tanggal,
              colSpan: 4,
            },
          ],
          [
            {
              content: "Kode Kategori: " + data?.nama_kategori,
              colSpan: 4,
            },
          ],
          [
            {
              content: `JENIS BARANG`,
            },
            {
              content: `BERAT BERSIH (GR)`,
              styles: { halign: "right" },
            },
            {
              content: `BERAT + PACKING (GR)`,
              styles: { halign: "right" },
            },
            {
              content: `KETERANGAN`,
            },
          ],
        ];
        data.detail_barang.forEach((detail) => {
          let rows = [
            detail.jenis_barang,
            {
              content: formatGram(detail.berat_bersih),
              styles: { halign: "right" },
            },
            {
              content: formatGram(detail.berat_packing),
              styles: { halign: "right" },
            },
            detail.keterangan,
          ];
          tableRows.push(rows);
        });
        let total_real = [
          "TOTAL REAL",
          {
            content: formatGram(data?.total_bruto),
            styles: { halign: "right" },
          },
        ];
        tableRows.push(total_real);
        let total_sistem = [
          "TOTAL SISTEM",
          {
            content: formatGram(data?.total_bruto_sistem),
            styles: { halign: "right" },
          },
        ];
        tableRows.push(total_sistem);
        let selisih = [
          "SELISIH",
          {
            content: formatGram(
              parseFloat(data?.total_bruto) -
                parseFloat(data?.total_bruto_sistem)
            ),
            styles: { halign: "right" },
          },
        ];
        tableRows.push(selisih);
        doc.autoTable({
          head: tableColumn,
          body: tableRows,
          startY: finalY,
          theme: "plain",
          rowPageBreak: "avoid",

          margin: { top: 20 },
          bodyStyles: { lineColor: "#969696", fontSize: 8, lineWidth: 0.0 },
          headStyles: {
            lineColor: "#969696",
            lineWidth: 0.0,
            fontSize: 8,
            fillColor: "#E8E8E8",
          },
          footStyles: {
            lineColor: "#969696",
            lineWidth: 0.0,
            fillColor: "#E8E8E8",
            fontSize: 8,
          },
        });
      } else if (index === 1) {
        let tableColumnTitipan = [
          [{ content: "TITIPAN REPARASI", colSpan: 4 }],
          [
            {
              content: `JENIS BARANG`,
            },
            {
              content: `BERAT BERSIH`,
            },
            {
              content: `BERAT + PACKING`,
            },
            {
              content: `KETERANGAN`,
            },
          ],
        ];
        data.detail_barang.forEach((detail) => {
          let rows = [
            detail.jenis_barang,
            detail.berat_bersih,
            detail.berat_packing,
            detail.keterangan,
          ];
          tableRowsTitipan.push(rows);
        });
        doc.autoTable({
          head: tableColumnTitipan,
          body: tableRowsTitipan,
          startY: doc.autoTableEndPosY() + 10,
          theme: "plain",
          rowPageBreak: "avoid",

          margin: { top: 20 },
          bodyStyles: { lineColor: "#969696", fontSize: 8, lineWidth: 0.0 },
          headStyles: {
            lineColor: "#969696",
            lineWidth: 0.0,
            fontSize: 8,
            fillColor: "#E8E8E8",
          },
          footStyles: {
            lineColor: "#969696",
            lineWidth: 0.0,
            fillColor: "#E8E8E8",
            fontSize: 8,
          },
        });
      } else if (index === 2) {
        let tableColumnRetur = [
          [{ content: "BARANG RETUR (BELUM DIBUKA)", colSpan: 4 }],
          [
            {
              content: `JENIS BARANG`,
            },
            {
              content: `BERAT BERSIH`,
            },
            {
              content: `BERAT + PACKING`,
            },
            {
              content: `KETERANGAN`,
            },
          ],
        ];
        data.detail_barang.forEach((detail) => {
          let rows = [
            detail.jenis_barang,
            detail.berat_bersih,
            detail.berat_packing,
            detail.keterangan,
          ];
          tableRowsReturn.push(rows);
        });
        doc.autoTable({
          head: tableColumnRetur,
          body: tableRowsReturn,
          startY: doc.autoTableEndPosY() + 10,
          theme: "plain",
          rowPageBreak: "avoid",

          margin: { top: 20 },
          bodyStyles: { lineColor: "#969696", fontSize: 8, lineWidth: 0.0 },
          headStyles: {
            lineColor: "#969696",
            lineWidth: 0.0,
            fontSize: 8,
            fillColor: "#E8E8E8",
          },
          footStyles: {
            lineColor: "#969696",
            lineWidth: 0.0,
            fillColor: "#E8E8E8",
            fontSize: 8,
          },
        });
      }
      return true;
    });
  } else {
    data.map((data, index) => {
      if (index === 0) {
        let tableColumnTitipan = [
          [{ content: "TITIPAN REPARASI", colSpan: 4 }],
          [
            {
              content: `JENIS BARANG`,
            },
            {
              content: `BERAT BERSIH`,
            },
            {
              content: `BERAT + PACKING`,
            },
            {
              content: `KETERANGAN`,
            },
          ],
        ];
        data.detail_barang.forEach((detail) => {
          let rows = [
            detail.jenis_barang,
            detail.berat_bersih,
            detail.berat_packing,
            detail.keterangan,
          ];
          tableRowsTitipan.push(rows);
        });
        doc.autoTable({
          head: tableColumnTitipan,
          body: tableRowsTitipan,
          startY: finalY,
          theme: "plain",
          rowPageBreak: "avoid",

          margin: { top: 20 },
          bodyStyles: { lineColor: "#969696", fontSize: 8, lineWidth: 0.0 },
          headStyles: {
            lineColor: "#969696",
            lineWidth: 0.0,
            fontSize: 8,
            fillColor: "#E8E8E8",
          },
          footStyles: {
            lineColor: "#969696",
            lineWidth: 0.0,
            fillColor: "#E8E8E8",
            fontSize: 8,
          },
        });
      } else if (index === 1) {
        let tableColumnRetur = [
          [{ content: "BARANG RETUR (BELUM DIBUKA)", colSpan: 4 }],
          [
            {
              content: `JENIS BARANG`,
            },
            {
              content: `BERAT BERSIH`,
            },
            {
              content: `BERAT + PACKING`,
            },
            {
              content: `KETERANGAN`,
            },
          ],
        ];
        data.detail_barang.forEach((detail) => {
          let rows = [
            detail.jenis_barang,
            detail.berat_bersih,
            detail.berat_packing,
            detail.keterangan,
          ];
          tableRowsReturn.push(rows);
        });
        doc.autoTable({
          head: tableColumnRetur,
          body: tableRowsReturn,
          startY: doc.autoTableEndPosY() + 10,
          theme: "plain",
          rowPageBreak: "avoid",

          margin: { top: 20 },
          bodyStyles: { lineColor: "#969696", fontSize: 8, lineWidth: 0.0 },
          headStyles: {
            lineColor: "#969696",
            lineWidth: 0.0,
            fontSize: 8,
            fillColor: "#E8E8E8",
          },
          footStyles: {
            lineColor: "#969696",
            lineWidth: 0.0,
            fillColor: "#E8E8E8",
            fontSize: 8,
          },
        });
      }
      return true;
    });
  }

  doc.text(`User	: ${getUserID()}`, 14, doc.autoTableEndPosY() + 10);
  doc.text(`Cetak	: ${getToday()}`, 14, doc.autoTableEndPosY() + 16);
  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  doc.autoPrint();
  doc.setProperties({
    title: "LAPORAN TIMBANGAN STOCK",
  });
  // doc.save(`PenerimaanSUpplier.pdf`);
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    "<html><head><title>LAPORAN TIMBANG STOCK</title></head><body style='margin:0 !important'><embed width='100%' height='100%'  src=" +
      string +
      "></embed></body></html>"
  );
  // setInterval(() => {
  //   x.close();
  // }, 1000);
};

export default LaporanPrintTimbangStock;
