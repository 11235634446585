export const GET_DATA_BARANG_PACKING = "GET_DATA_BARANG_PACKING";
export const GET_DATA_BARANG_PACKING_S = "GET_DATA_BARANG_PACKING_S";
export const SET_DATA_GAMBAR_S = "SET_DATA_GAMBAR_S";
export const GET_DATA_BARANG_PACKING_M = "GET_DATA_BARANG_PACKING_M";
export const SET_DATA_GAMBAR_M = "SET_DATA_GAMBAR_M";
export const SET_DATA_GAMBAR_L = "SET_DATA_GAMBAR_L";
export const SET_CUSTOMER_SELECTED = "SET_CUSTOMER_SELECTED";

export const getListBarangPacking = () => {
  return (dispatch) => {
    dispatch({
      type: GET_DATA_BARANG_PACKING,
      payload: {
        data: JSON.parse(localStorage.getItem("packingList_temp")),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const getListBarangPackingS = () => {
  return (dispatch) => {
    dispatch({
      type: GET_DATA_BARANG_PACKING_S,
      payload: {
        data: JSON.parse(localStorage.getItem("PackingS")),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const setGambarS = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_DATA_GAMBAR_S,
      payload: {
        data: data,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const setGambarM = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_DATA_GAMBAR_M,
      payload: {
        data: data,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const setGambarL = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_DATA_GAMBAR_L,
      payload: {
        data: data,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const getListBarangPackingM = () => {
  return (dispatch) => {
    dispatch({
      type: GET_DATA_BARANG_PACKING_M,
      payload: {
        data: JSON.parse(localStorage.getItem("PackingM")),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const setCustomer = (
  level1,
  level2,
  level3,
  nama1,
  nama2,
  nama3,
  levelCustomer
) => {
  return (dispatch) => {
    dispatch({
      type: SET_CUSTOMER_SELECTED,
      payload: {
        level1: level1,
        nama1: nama1,
        nama2: nama2,
        nama3: nama3,
        level2: level2,
        level3: level3,
        levelCustomer: levelCustomer,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};
