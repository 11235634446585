import React, { Component } from "react";
import { connect } from "react-redux";
import { hideGuide } from "./actions/ModalAction";
import Tour from "reactour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const mapToState = (state) => {
  return {
    showTourGuide: state.provinsi.showTourGuide,
  };
};
class TourGuide extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  disableBody = (target) => disableBodyScroll(target);
  enableBody = (target) => enableBodyScroll(target);
  render() {
    let tourConfig = this.props.tourConfig.map((list, index) => {
      let data = {
        selector: '[data-tut="reactour_' + ++index + '"]',
        content: list,
      };
      return data;
    });
    console.log(tourConfig);
    return (
      <Tour
        onRequestClose={() => this.props.dispatch(hideGuide())}
        steps={tourConfig}
        isOpen={this.props.showTourGuide}
        maskClassName="mask"
        className="helper"
        rounded={5}
        accentColor="#5cb7b7"
        onAfterOpen={this.disableBody}
        onBeforeClose={this.enableBody}
      />
    );
  }
}

export default connect(mapToState, null)(TourGuide);
