import {
  AxiosMasterDelete,
  AxiosMasterGet,
  AxiosMasterPost,
  AxiosMasterPut,
} from "../../../Axios";
import { finishSend, progressSend } from "../../../actions/LogicAction";
import { toast } from "react-toastify";
import { ToastKirim } from "../../../actions/toast";
import { reactive } from "../../../Alert";
import { reset } from "redux-form";
import { hideModal } from "../../../actions/ModalAction";
import Swal from "sweetalert2";
import { purifiedData } from "../../../helper";

export const GET_SALESMAN_LIST = "GET_SALESMAN_LIST";
export const EDIT_SALESMAN = "EDIT_SALESMAN";

export const getsalesmanList = () => {
  return (dispatch) => {
    AxiosMasterGet("sales/get/all")
      .then((res) =>
        dispatch({
          type: GET_SALESMAN_LIST,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_SALESMAN_LIST,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const editsalesmanList = (kodeLokasi, namaLokasi, noRekening) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_SALESMAN,
      payload: {
        kodeLokasi: kodeLokasi,
        namaLokasi: namaLokasi,
        noRekening: noRekening,
      },
    });
  };
};
export const sendSalesman = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.Salesman.values;
    purifiedData(selected);
    const data = {
      kode_sales: selected.kodeSales,
      nama_sales: selected.namaSales,
      no_rekening: selected.noRekening || "-",
    };
    try {
      await toast.promise(AxiosMasterPost("sales/add", data), ToastKirim);
    } catch (err) {
      let error = err.response.data;
      let check = error.includes("deleted");
      check
        ? reactive(
            err,
            selected.kodeSales,
            "sales/reactive/",
            { nama_sales: selected.namaSales },
            "sales/edit/"
          ).then(() => dispatch(getsalesmanList()))
        : console.log();
      dispatch(getsalesmanList());
    }
    dispatch(finishSend());
    dispatch(getsalesmanList());
    dispatch(reset("Salesman"));
    dispatch(hideModal());
  };
};

export const editSalesman = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.SalesmanEdit.values;
    purifiedData(selected);
    const data = {
      nama_sales: selected.namaSales,
      no_rekening: selected.noRekening,
    };
    try {
      await toast.promise(
        AxiosMasterPut("sales/edit/" + selected.kodeSales, data),
        ToastKirim
      );
      dispatch(finishSend());
      dispatch(getsalesmanList());
      dispatch(reset("SalesmanEdit"));
      dispatch(hideModal());
    } catch (err) {
      dispatch(finishSend());
      dispatch(getsalesmanList());
      dispatch(reset("SalesmanEdit"));
      dispatch(hideModal());
    }
  };
};

export const deleteSalesman = (kode) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    Swal.fire({
      title: "Anda Yakin ?",
      text: "Anda Akan Non-Aktifkan Data Ini ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Non Aktifkan..",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          dispatch(progressSend());
          await toast.promise(
            AxiosMasterDelete("sales/delete/" + kode),
            ToastKirim
          );
          dispatch(finishSend());
          dispatch(getsalesmanList());
          dispatch(reset("SalesmanEdit"));
          dispatch(hideModal());
        } catch (e) {
          dispatch(finishSend());
        }
      }
    });
    dispatch(finishSend());
  };
};

export const reactiveSales = (kode) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    try {
      await toast.promise(AxiosMasterPut("sales/reactive/" + kode), ToastKirim);
      dispatch(finishSend());
      dispatch(getsalesmanList());
    } catch (e) {
      dispatch(finishSend());
    }
    dispatch(finishSend());
  };
};
