import { SET_CUSTOMER_SELECTED } from "../actions/PackingListAction";

const initialState = {
  level1: "-",
  level2: "-",
  level3: "-",
  nama1: "-",
  nama2: "-",
  nama3: "-",
  levelCustomer: "LEVEL1",
};

const packing = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMER_SELECTED:
      return {
        level1: action.payload.level1,
        level2: action.payload.level2,
        level3: action.payload.level3,
        nama1: action.payload.nama1,
        nama2: action.payload.nama2,
        nama3: action.payload.nama3,
        levelCustomer: action.payload.levelCustomer,
      };
    default:
      return state;
  }
};

export default packing;
