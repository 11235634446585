import { AxiosMasterGetWithParams, AxiosMasterPost } from "../Axios";
import { toast } from "react-toastify";
import { finishSend, progressSend } from "./LogicAction";
import { ToastKirim } from "./toast";

export const GET_DATA_REKAP_BON_CUSTOMER = "GET_DATA_REKAP_BON_CUSTOMER";
export const SET_SELECTED_REKAP_BON = "SET_SELECTED_REKAP_BON";

export const getListRekapBon = (params) => {
  return async (dispatch) => {
    // const response = await AxiosMasterGet("penjualan/valid");
    const response = await AxiosMasterGetWithParams("penjualan/valid", params);
    dispatch({
      type: GET_DATA_REKAP_BON_CUSTOMER,
      payload: response,
    });
  };
};

export const setSelectedRekapBon = (row, isSelect) => {
  return async (dispatch, getState) => {
    const state = getState();
    const array = state.provinsi.selectedRekapBon;
    if (isSelect) {
      array.push(row);
      dispatch({
        type: SET_SELECTED_REKAP_BON,
        payload: array,
      });
    } else {
      var index = array.findIndex((item, i) => {
        return item.no_penjualan === row.no_penjualan;
      });
      array.splice(index, 1);
      dispatch({
        type: SET_SELECTED_REKAP_BON,
        payload: array,
      });
    }
  };
};

export const resetSelectedRekapBon = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_SELECTED_REKAP_BON,
      payload: [],
    });
  };
};

export const selectAllRekapBon = (rows, isSelect) => {
  return async (dispatch, getState) => {
    var array = [];
    rows.forEach(function (list) {
      array.push(list);
    });
    if (isSelect) {
      dispatch({
        type: SET_SELECTED_REKAP_BON,
        payload: array,
      });
    } else {
      dispatch({
        type: SET_SELECTED_REKAP_BON,
        payload: [],
      });
    }
  };
};

export const sendRekapBon = (faktur_tujuan) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const array = state.provinsi.selectedRekapBon;
    const payload = {
      faktur_tujuan: faktur_tujuan,
      list_faktur: array.map((x) => {
        return {
          no_faktur: x.no_penjualan,
        };
      }),
    };
    try {
      await toast.promise(AxiosMasterPost("rekap-bon", payload), ToastKirim);
      dispatch(getListRekapBon());
      dispatch(finishSend());
    } catch (e) {
      dispatch(finishSend());
    }
  };
};
