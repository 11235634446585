import React from "react";
// import SelectSearch from "react-select-search";
import Select from "react-select";

export const renderField = ({
  input,
  label,
  type,
  name,
  limit,
  readOnly,
  placeholder,
  onChange,
  search,
  tabIndex = 1,
  meta: { touched, error, warning },
}) => (
  <div className="form-group">
    <label htmlFor="" className="text-black">
      {label}
    </label>
    <input
      {...input}
      type={type}
      id={label}
      className="form-control"
      readOnly={readOnly}
      placeholder={placeholder}
      tabIndex={readOnly ? "-1" : tabIndex}
      onKeyPress={(e) => {
        e.key === "Enter" && e.preventDefault();
      }}
    />
    {touched &&
      ((error && <p className="invalid-feedback">{error}</p>) ||
        (warning && <p>{warning}</p>))}
  </div>
);

export const renderFieldGroup = ({
  input,
  label,
  type,
  name,
  limit,
  readOnly,
  placeholder,
  search,
  onClick,
  buttonText,
  meta: { touched, error, warning },
}) => (
  <div className="form-group">
    <label htmlFor="" className="text-black">
      {label}
    </label>
    <div className={"input-group"}>
      <input
        {...input}
        type={type}
        id={label}
        className="form-control"
        readOnly={readOnly}
        placeholder={placeholder}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      />
      <div className="input-group-append">
        <button
          className="btn btn-outline-secondary"
          type="button"
          onClick={() => onClick()}
        >
          {buttonText}
        </button>
      </div>
    </div>
    {touched &&
      ((error && <p className="invalid-feedback">{error}</p>) ||
        (warning && <p>{warning}</p>))}
  </div>
);

// export const renderFieldSelect = ({
//   input,
//   label,
//   options,
//   placeholder,
//   name,
//   disabled,
//   value,
//   readOnly,
//   autoComplete,
//   search,
//   meta: { touched, error, warning },
// }) => (
//   <div className="form-group">
//     <label htmlFor="" className="text-black">
//       {label}
//     </label>
//     <SelectSearch
//       {...input}
//       options={options}
//       search
//       emptyMessage="Tidak Ada Data"
//       id={label}
//       placeholder={placeholder || "Silahkan Pilih"}
//       disabled={disabled}
//       onKeyPress={(e) => {
//         e.key === "Enter" && e.preventDefault();
//       }}
//       autoComplete={autoComplete}
//       readOnly={readOnly}
//     />
//     {touched &&
//       ((error && <p className="invalid-feedback">{error}</p>) ||
//         (warning && <p>{warning}</p>))}
//   </div>
// );

export const renderFieldSelect = ({
  input,
  label,
  options,
  readOnly,
  customRef,
  tabIndex = 1,
  meta: { touched, error, warning },
}) => {
  options = options.map((x) => {
    return {
      value: x.value,
      label: x.name,
    };
  });
  return (
    <div className="form-group">
      <label htmlFor="" className="text-black">
        {label}
      </label>
      <Select
        {...input}
        options={options}
        isSearchable={true}
        id={label}
        ref={customRef}
        tabIndex={tabIndex}
        isDisabled={readOnly}
        onChange={(value) => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}
      />
      {touched &&
        ((error && <p className="invalid-feedback">{error}</p>) ||
          (warning && <p>{warning}</p>))}
    </div>
  );
};

export const renderFieldCheckbox = ({
  input,
  label,
  type,
  name,
  limit,
  readOnly,
  placeholder,
  onChange,
  search,
  meta: { touched, error, warning },
}) => (
  <div className="custom-control custom-checkbox mr-sm-2">
    <input
      {...input}
      type="checkbox"
      className="custom-control-input"
      id={label}
    />
    <label className="custom-control-label" htmlFor="customControlAutosizing" />
  </div>
);
