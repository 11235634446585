import React, {useEffect, useState} from 'react';
import {change, Field, reduxForm} from "redux-form";
import {useDispatch, useSelector} from "react-redux";
import HeaderCard from "../../HeaderCard";
import {renderField, renderFieldGroup, renderFieldSelect} from "../../renderField";
import {formatGram, formatRp, getBon, getToday} from "../../helper";
import ModalGlobal from "../../DataMaster/Component/ModalMasterData";
import {showModal} from "../../actions/ModalAction";
import {
    deleteCorrectionNoteTemp,
    getCorrectionNoteTemp,
    saveCorrectionNoteTemp,
    sendCorrectionNote
} from "./redux/correction_note_action";
import {getListBarang} from "../../actions/kodeBarangAction";
import GlobalTabel from "../../GlobalComponent/GlobalTabel";
import {Dropdown} from "react-bootstrap";
import ListCorrectionNote from "./component/listCorrectionNote";
import FormTambahBarangCorrectionNote from "./component/form_tambah_barang_correction_note";
import {getcustomerList} from "../../DataMaster/customer/redux/customerAction";

const CorrectionNote = () => {
    const dispatch = useDispatch();
    const [cariBon, setCariBon] = useState(false);
    const listBarangTemp = useSelector(state => state.correctionNote.listBarangTemp)
    const listCustomer = useSelector(state => state.provinsi.customer)
    const columns = [
        {
            dataField: "kode_barang",
            text: "Kode Barang",
        },
        {
            dataField: "kode_bahan",
            text: "Kode Bahan",
        },
        {
            dataField: "kadar_cetak",
            text: "Cap\nKode",
            align: "right",
            headerAlign: "right",
        },
        {
            dataField: "qty",
            text: "Qty",
            align: "right",
            headerAlign: "right",
            headerStyle: () => {
            return { width: "5%" };
},
},
    {
        dataField: "pkg",
            text: "Bungkus\n(Gr)",
        align: "right",
        headerAlign: "right",
        formatter: (data) => formatGram(data),
    },
    {
        dataField: "bruto",
            text: "Berat\n(Gr)",
        align: "right",
        headerAlign: "right",
        formatter: (data) => formatGram(data),
    },
    {
        dataField: "kadar_jual",
            text: "Kode\nTukar",
        align: "right",
        headerAlign: "right",
        headerStyle: () => {
        return { width: "7%" };
    },
    },
    {
        dataField: "netto",
            text: "Murni\n(Gr)",
        align: "right",
        headerAlign: "right",
        formatter: (data) => formatGram(data, 3),
        footerClasses: "sum-netto-terima-supplier",
    },
    {
        dataField: "disc_gram",
            text: "Disc\n(Gr)",
        align: "right",
        headerAlign: "right",
        formatter: (data) => formatGram(data),
        footerClasses: "sum-netto-terima-supplier",
    },
    {
        dataField: "netto_disc",
            text: "Netto\nDisc(Gr)",
        align: "right",
        headerAlign: "right",
        formatter: (data) => formatGram(data, 3),
    },
    {
        dataField: "ongkos_rp",
            text: "Ongkos\n(Rp)",
        align: "right",
        headerAlign: "right",
        formatter: (data) => {
        return formatRp(data);
    },
    },
    {
        dataField: "Aksi",
            text: "Aksi",
        csvExport: false,
        formatter: (rowContent, row, rowIndex) => {
        return (
            <div className="justify-content-center row">
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <i className="fa fa-ellipsis-v" aria-hidden="true"/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item  onClick={() => {dispatch(deleteCorrectionNoteTemp("correctionNoteTemp", rowIndex, getCorrectionNoteTemp()))} }>Hapus Barang <i className="fa fa-trash ml-2"/></Dropdown.Item>
                        {/*<Dropdown.Item onClick={() =>*/}
                        {/*{*/}
                        {/*    this.editModal()*/}
                        {/*}*/}
                        {/*}>Edit  <i className="fa fa-edit ml-2"/></Dropdown.Item>*/}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    },
    },
];
    useEffect(() => {
        dispatch(change("CorrectionNote", "tanggal", getToday()))
        dispatch(change("CorrectionNote", "no_correction_note", "CN" + getBon()))
        dispatch(getListBarang())
        dispatch(getCorrectionNoteTemp())
        dispatch(getcustomerList())
    }, [dispatch])

    const showCariBon = () => {
        setCariBon(true)
        dispatch(showModal())
    }
    const showTambahBarang = () => {
        setCariBon(false)
        dispatch(showModal())
    }
    return (
        <div className="container-fluid" style={{color: "black"}}>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <HeaderCard title="Correction Note"/>
                        <div className="card-body">
                            <div className="col-lg-12 col-md-12 row">
                                <div className="col-lg-3">
                                    <Field
                                        name={"tanggal"}
                                        label={"Tanggal"}
                                        component={renderField}
                                        type={"date"}
                                        normalize={(data) => data && data.toUpperCase()}
                                    />
                                </div>
                                   <div className="col-lg-3">
                                       <Field
                                           name={"no_correction_note"}
                                           label={"No CN"}
                                           component={renderField}
                                           normalize={(data) => data && data.toUpperCase()}
                                           readOnly

                                       />
                                   </div>
                                <div className="col-lg-3">
                                    <Field
                                        name={"kode_customer"}
                                        label={"Customer"}
                                        component={renderFieldSelect}
                                        options={listCustomer.map(x => {
                                            return {
                                                value : x.kode_customer,
                                                name : x.nama_customer
                                            }
                                        })}
                                        disabled
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <Field
                                        name={"no_penjualan"}
                                        label={"No Bon"}
                                        component={renderFieldGroup}
                                        readOnly
                                        onClick={() => showCariBon()}
                                        buttonText={"Cari"}
                                    />
                                </div>
                                <div className="col-lg-12 row justify-content-end mt-2">
                                    <div className="col-lg-2">
                                        <button className={"btn btn-primary btn-block"} onClick={()=>showTambahBarang()}>Tambah Barang</button>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <GlobalTabel
                                        keyField="no_penjualan"
                                        data={listBarangTemp}
                                        columns={columns}
                                    />
                                </div>
                                <div className="col-lg-12 row justify-content-end mt-2">
                                    <div className="col-lg-2">
                                        <button className={"btn btn-primary btn-block"} onClick={()=> dispatch(sendCorrectionNote())}>Simpan</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ModalGlobal
                        content={cariBon ? <ListCorrectionNote /> : <FormTambahBarangCorrectionNote onSubmit={(data) => dispatch(saveCorrectionNoteTemp())} />}
                        title={"Lihat History Penjualan"}
                    />
                </div>
            </div>
        </div>
    );
};

export default reduxForm({
    form: "CorrectionNote"
})(CorrectionNote)