import { finishSend, progressSend } from "../../../actions/LogicAction";
import { toast } from "react-toastify";
import { AxiosMasterGet } from "../../../Axios";
import { ToastAmbil } from "../../../actions/toast";
import LaporanPiutangCustomer from "../component/LaporanPiutangCustomer";
import LaporanPiutangCustomerCard from "../component/LaporanPiutangCustomerCard";
import LaporanPiutangCustomerSummary from "../component/LaporanPiutangCustomerSummary";

export const GET_LAPORAN_PIUTANG_CUSTOMER = "GET_LAPORAN_PIUTANG_CUSTOMER";
export const GET_LAPORAN_PIUTANG_CUSTOMER_CARD =
  "GET_LAPORAN_PIUTANG_CUSTOMER_CARD";
export const GET_LAPORAN_PIUTANG_CUSTOMER_SUMMARY =
  "GET_LAPORAN_PIUTANG_CUSTOMER_SUMMARY";

export const GetLaporanPiutangCustomer = (kode_customer) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    try {
      const response = await toast.promise(
        AxiosMasterGet(
          `report/piutang-customer/${kode_customer.split("|")[0]}`
        ),
        ToastAmbil
      );
      dispatch({
        type: GET_LAPORAN_PIUTANG_CUSTOMER,
        payload: {
          data: response.data,
          kode_customer: kode_customer,
        },
      });
      dispatch(finishSend());
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

export const GetLaporanPiutangCustomerCard = (
  kode_customer,
  tglFrom,
  tglTo
) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    try {
      const response = await toast.promise(
        AxiosMasterGet(
          `report/piutang-customer-card/${
            kode_customer.split("|")[0]
          }&${tglFrom}&${tglTo}`
        ),
        ToastAmbil
      );
      dispatch({
        type: GET_LAPORAN_PIUTANG_CUSTOMER_CARD,
        payload: {
          data: response.data,
          kode_customer: kode_customer,
          tglTo: tglTo,
          tglFrom: tglFrom,
        },
      });
      dispatch(finishSend());
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

export const GetLaporanPiutangCustomerSummary = (
  kode_customer,
  tglFrom,
  tglTo
) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    try {
      const response = await toast.promise(
        AxiosMasterGet(
          `report/piutang-customer-summary/${
            kode_customer.split("|")[0]
          }&${tglFrom}&${tglTo}`
        ),
        ToastAmbil
      );
      dispatch({
        type: GET_LAPORAN_PIUTANG_CUSTOMER_SUMMARY,
        payload: {
          data: response.data,
          kode_customer: kode_customer,
          tglTo: tglTo,
          tglFrom: tglFrom,
        },
      });
      dispatch(finishSend());
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

export const ResetLaporanPiutang = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_LAPORAN_PIUTANG_CUSTOMER_CARD,
      payload: {
        data: [],
        kode_customer: "",
        tglTo: "",
        tglFrom: "",
      },
    });
    dispatch({
      type: GET_LAPORAN_PIUTANG_CUSTOMER_SUMMARY,
      payload: {
        data: [],
        kode_customer: "",
        tglTo: "",
        tglFrom: "",
      },
    });
    dispatch({
      type: GET_LAPORAN_PIUTANG_CUSTOMER,
      payload: {
        data: [],
        kode_customer: "",
      },
    });
  };
};

export const PrintLaporanPiutangCustomerPDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanPiutangCustomer = state.laporan.dataLaporanPiutangCustomer;
    LaporanPiutangCustomer(dataLaporanPiutangCustomer.data);
  };
};

export const PrintLaporanPiutangCustomerCardPDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanPiutangCustomerCard =
      state.laporan.dataLaporanPiutangCustomerCard;
    LaporanPiutangCustomerCard(
      dataLaporanPiutangCustomerCard.data,
      dataLaporanPiutangCustomerCard.tglFrom,
      dataLaporanPiutangCustomerCard.tglTo
    );
  };
};

export const PrintLaporanPiutangCustomerSummaryPDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanPiutangCustomerSummary =
      state.laporan.dataLaporanPiutangCustomerSummary;
    LaporanPiutangCustomerSummary(
      dataLaporanPiutangCustomerSummary.data,
      dataLaporanPiutangCustomerSummary.tglFrom,
      dataLaporanPiutangCustomerSummary.tglTo
    );
  };
};
