import React, { Component } from "react";
import { connect } from "react-redux";
import { showModal } from "../../actions/ModalAction";
import ModalMasterData from "../Component/ModalMasterData";
import HeaderCard from "../../HeaderCard";
import {
  editParameterHargaEmas,
  getParameterHargaEmasList,
  sendEditParameterHargaEmas,
} from "./redux/parameterHargaEmasAction";
import FormParameterHargaEmasEdit from "./component/FormParameterHargaEmasEdit";
import GlobalTabel from "../../GlobalComponent/GlobalTabel";
import { formatRp } from "../../helper";

const defaultSorted = [
  {
    dataField: "kode",
    order: "asc",
  },
];

const maptoState = (state) => {
  return {
    daftarParamHargaEmas: state.parameterHargaEmas.listHargaEmas || [],
    alert: state.provinsi.alert,
    error: state.provinsi.error,
    onSend: state.provinsi.onSend,
  };
};

class ParameterHargaEmas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Rekening: [],
      response: "",
      columns: [
        {
          dataField: "kode_harga",
          text: "Kode",
        },
        {
          dataField: "rupiah",
          text: "Rupiah",
          formatter: (data) => {
            return formatRp(data);
          },
        },
        {
          dataField: "usd",
          text: "USD",
          formatter: (data) => {
            return formatRp(data);
          },
        },
        {
          dataField: "Aksi",
          text: "Aksi",
          csvExport: false,
          formatter: (rowContent, row) => {
            return (
              <div className="text-center">
                <button
                  className="btn btn-warning"
                  data-tut="reactour_4"
                  onClick={() =>
                    this.props.dispatch(
                      editParameterHargaEmas(
                        row.kode_harga,
                        row.rupiah,
                        row.usd,
                        this.edithModal()
                      )
                    )
                  }
                >
                  <i className="fa fa-edit mr-2" />
                  Edit
                </button>
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    this.props.dispatch(getParameterHargaEmasList());
  }

  updateStatus = (input) => {
    this.setState({
      response: input,
    });
    this.props.dispatch(getParameterHargaEmasList());
  };

  handleEdit() {
    this.props.dispatch(sendEditParameterHargaEmas());
  }

  edithModal() {
    this.props.dispatch(showModal());
    this.setState({
      isEdit: true,
    });
  }

  render() {
    return (
      <div className="container-fluid" style={{ color: "black" }}>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <HeaderCard title="Data Parameter Harga Emas" />
              <div className="card-body">
                <ModalMasterData
                  content={
                    <FormParameterHargaEmasEdit
                      updateStatus={this.updateStatus}
                      onSubmit={(data) => this.handleEdit(data)}
                      onSend={this.props.onSend}
                    />
                  }
                  title="Edit Data Parameter Harga Emas"
                />
                <div className="col-lg-12" data-tut="reactour_2">
                  <GlobalTabel
                    data={this.props.daftarParamHargaEmas}
                    columns={this.state.columns}
                    defaultSorted={defaultSorted}
                    keyField="kode"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(maptoState, null)(ParameterHargaEmas);
