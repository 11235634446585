import {
  AxiosMasterDelete,
  AxiosMasterGet,
  AxiosMasterPost,
  AxiosMasterPut,
} from "../../../Axios";
import { finishSend, progressSend } from "../../../actions/LogicAction";
import { ToastKirim } from "../../../actions/toast";
import { toast } from "react-toastify";
import { gagal, reactive } from "../../../Alert";
import { reset } from "redux-form";
import { hideModal } from "../../../actions/ModalAction";
import Swal from "sweetalert2";
import { purifiedData } from "../../../helper";

export const GET_REKENING_LIST = "GET_REKENING_LIST";
export const EDIT_REKENING_LIST = "EDIT_REKENING_LIST";

export const getrekeningList = () => {
  return (dispatch) => {
    AxiosMasterGet("rekening/get/all")
      .then((res) =>
        dispatch({
          type: GET_REKENING_LIST,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_REKENING_LIST,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: false,
          },
        })
      );
  };
};
export const editRekening = (RekeningModel) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_REKENING_LIST,
      payload: {
        noRekening: RekeningModel.noRekening,
        atasNama: RekeningModel.atasNama,
        kodeBank: RekeningModel.kodeBank,
        mataUang: RekeningModel.mataUang,
      },
    });
  };
};

export const sendRekening = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.DataRekening.values;
    purifiedData(selected);
    let data = {
      atas_nama: selected.atasNama,
      kode_bank: selected.kodeBank,
      no_rekening: selected.noRekening,
      mata_uang: selected.mataUang,
    };
    try {
      await toast.promise(AxiosMasterPost("rekening/add", data), ToastKirim);
    } catch (err) {
      let error = err.response.data;
      let check = error.includes("deleted");
      check
        ? reactive(
            err,
            selected.noRekening,
            "rekening/reactive/",
            {
              atas_nama: selected.atasNama,
              kode_bank: selected.kodeBank,
              mata_uang: selected.mataUang,
            },
            "rekening/edit/"
          ).then(() => dispatch(getrekeningList()))
        : gagal("Data Gagal Ditambahkan");
      dispatch(getrekeningList());
    }
    dispatch(finishSend());
    dispatch(getrekeningList());
    dispatch(reset("DataRekening"));
    dispatch(hideModal());
  };
};

export const sendEditRekening = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.FormRekeningEdit.values;
    purifiedData(selected);
    let data = {
      atas_nama: selected.atasNama,
      kode_bank: selected.kodeBank,
      mata_uang: selected.mataUang,
    };
    try {
      await toast.promise(
        AxiosMasterPut("rekening/edit/" + selected.noRekening, data),
        ToastKirim
      );
      dispatch(finishSend());

      dispatch(getrekeningList());
      dispatch(reset("FormRekeningEdit"));
      dispatch(hideModal());
    } catch (err) {
      dispatch(finishSend());
      dispatch(getrekeningList());
      dispatch(reset("FormRekeningEdit"));
      dispatch(hideModal());
    }
  };
};

export const deleteRekening = (kode) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    Swal.fire({
      title: "Anda Yakin ?",
      text: "Anda Akan Menghapus Data Ini ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await toast.promise(
            AxiosMasterDelete("rekening/delete/" + kode),
            ToastKirim
          );
          dispatch(finishSend());
          dispatch(getrekeningList());
          dispatch(reset("ProvinsiEdit"));
          dispatch(hideModal());
        } catch (e) {
          dispatch(finishSend());
        }
      }
    });
    dispatch(finishSend());
  };
};
