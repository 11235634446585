import {GET_ALL_CETAK_BARCODE_SAMPLE} from "./cetakBarcodeSampleAction";
import {GET_CETAK_BARCODE} from "../../../Supervisory/cetak-barcode/redux/cetak_barcode_action";

const initialState = {
    dataCetakBarcode: [],
    listCetakBarcode : [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_CETAK_BARCODE_SAMPLE:
            return {
                ...state,
                dataCetakBarcode: action.payload,
            };
        case GET_CETAK_BARCODE:
            return {
                ...state,
                listCetakBarcode : action.payload
            }
        default:
            return state;
    }
};

export default reducer;
