import React, { Component } from "react";
import { Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { reduxForm } from "redux-form";
import { io } from "socket.io-client";
import Swal from "sweetalert2";
import backConnection from "../src/Images/BackConnection.gif";
import browser from "../src/Images/browser.svg";
import lostConnection from "../src/Images/LostConnection.gif";
import { finishSend, progressSend } from "./actions/LogicAction";
import { getIsLogin } from "./actions/loginAction";
import { showGuide } from "./actions/ModalAction";
import { setAlertLogin, setDefault, setLogin } from "./actions/titleAction";
import { berhasil } from "./Alert";
import "./App.css";
import { AxiosMasterPost } from "./Axios";
import Header from "./Header";
import { doDecrypt } from "./helper";
import logo_demo from "./Images/logo_ayu_demo.png";
import logo from "./Images/logo_cadm.png";
import metadata from "./metadata.json";
import { RouteLink } from "./Route";
import Sidebar from "./Sidebar";

const maptoState = (state) => {
  return {
    login: state.title.Login,
    alert: state.title.alert,
    onSend: state.provinsi.onSend,
  };
};
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: false,
      password: false,
      header: "topbar d-none",
      sidebar: "left-sidebar d-none",
    };
  }
  componentDidMount() {
    // this.checkBrowser();
    this.props.dispatch(setDefault());
    this.props.dispatch(getIsLogin());
    window.addEventListener("online", this.updateStatus);
    window.addEventListener("offline", this.updateStatus);
    if (navigator.onLine) {
      console.log("Your Connection is Back");
    } else {
      console.log("Your have lost your internet connection");
    }
    this.checkLicense();
  }

  async checkLicense() {
    const isActive = process.env.REACT_APP_LICENSE;
    console.log(isActive);
    this.mySocket = await io.connect("http://localhost:3005", "", {
      transports: ["websocket"],
      reconnection: false,
      reconnectionAttempts: 1,
    });
    if (isActive === "true") {
      setTimeout(async () => {
        if (!this.mySocket.connected) {
          await Swal.fire({
            allowOutsideClick: false,
            imageUrl:
              "https://firebasestorage.googleapis.com/v0/b/gambar-78b2b.appspot.com/o/GROSIR%2FCADM%2Fassets%2F11132.jpg?alt=media&token=4ddc9292-fc11-4daa-808b-b899d71c5a20",
            imageWidth: 400,
            imageHeight: 280,
            imageAlt: "Custom image",
            text: "Lisensi tidak valid",
            position: "top-center",
            confirmButtonText: "Refresh",
            showConfirmButton: true,
          }).then(async (result) => {
            window.location.reload();
          });
        } else {
          this.mySocket.on("validate-login", (row) => {
            let hasilSocket = doDecrypt(row);
            if (hasilSocket.CDA2D699D2E4E4DFA0 !== "CADM") {
              this.mySocket.emit("end-socket", () => {});
            }
          });
        }
      }, 500);
    }
  }

  updateStatus(event) {
    if (navigator.onLine) {
      Swal.fire({
        position: "center",
        imageUrl: backConnection,
        title:
          "Looks like you connection is Back, You Can Use Our Website Again",
        showConfirmButton: true,
      });
    } else {
      Swal.fire({
        position: "center",
        imageUrl: lostConnection,
        title: "Looks like you lost your internet connection",
        showConfirmButton: false,
        allowOutsideClick: false,
      });
    }
  }
  checkBrowser() {
    // Chrome 1 - 79
    var isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    if (isChrome) {
      return true;
    } else {
      Swal.fire({
        text: "For Better Experience , Please Download use Chrome",
        showConfirmButton: false,
        footer:
          '<a href="https://www.google.com/chrome/" target="_blank">Download Google Chrome</a>',
        imageUrl: browser,
      });
    }
  }

  HandleLogin(e) {
    this.props.dispatch(progressSend());
    e.preventDefault();
    let data = {
      user_id: this.state.user_id,
      password: this.state.password,
    };

    AxiosMasterPost("users/login", data)
      .then((res) => this.directLogin(res))
      .then(() => this.props.dispatch(finishSend()))
      .catch((err) => {
        this.props.dispatch(setAlertLogin());
        this.props.dispatch(finishSend());
      });
  }

  directLogin(res) {
    this.props.dispatch(setLogin(res.data.user_name, res.data.level));
    localStorage.setItem("token", res.data.token);
    localStorage.setItem("username", res.data.user_name);
    localStorage.setItem("level", res.data.level);
    localStorage.setItem("menu", JSON.stringify(res.data.akses));
    berhasil("Login Berhasil").then(() => {
      window.location.reload();
    });
    return false;
  }
  showTour() {
    this.props.dispatch(showGuide());
  }
  render() {
    var link = window.location.pathname;
    switch (link) {
      case "/provinsi":
        link = "Data Provinsi";
        break;
      case "/kota":
        link = "Data Kota";
        break;
      case "/area":
        link = "Data Area";
        break;
      case "/rekening":
        link = "Daftar Rekening Bank";
        break;
      case "/lokasi":
        link = "Data Lokasi";
        break;
      case "/salesman":
        link = "Data Salesman";
        break;
      case "/supplier":
        link = "Data Supplier";
        break;
      case "/customer":
        link = "Data Customer";
        break;
      case "/kadar":
        link = "Data Kadar";
        break;
      case "/KlasifikasiBarang":
        link = "Data Klasifikasi Barang";
        break;
      case "/koderosok":
        link = "Kode Rongsok dan Ciok";
        break;
      case "/parameter-harga-emas":
        link = "Parameter Harga Emas"
        break;
      case "/parameter":
        link = "Data Transaksi";
        break;
      case "/saldoawal":
        link = "Saldo Awal";
        break;
      case "/manageUser":
        link = "Manage user";
        break;
      case "/pindahBarang":
        link = "Pindah Barang";
        break;
      case "/mutasibarang":
        link = "Mutasi Barang";
        break;
      case "/mutasiciok":
        link = "Mutasi Ciok";
        break;
      case "/kirimmasak":
        link = "Kirim Masak";
        break;
      case "/terimamasak":
        link = "Terima Masak";
        break;
      case "/terimasupplier":
        link = "Penerimaan Supplier";
        break;
      case "/returnsupplier":
        link = "Return Supplier";
        break;
      case "/tolakanreturnsupplier":
        link = "Tolakan Return Supplier";
        break;
      case "/ambilbarcode":
        link = "Transaksi Rekam Barang Barcode";
        break;
      case "/closePengambilanBarang":
        link = "Close Pengambilan Barang Barcode";
        break;
      case "/transaksiTimbangBarang":
        link = "Transksi Timbang Barang";
        break;
      case "/batalPengambilanTimbangan":
        link = "Batal Pengambilan Timbang Barang";
        break;
      case "/TransaksiPenjualan":
        link = "Transaksi Penjualan";
        break;
      case "/ReturnPenjualan":
        link = "Return Penjualan";
        break;
      case "/RekapBonPenjualan":
        link = "Rekap Bon Penjualan";
        break;
      case "/KelolaUangBank":
        link = "Kelola Uang Bank ";
        break;
      case "/KelolaUangKas":
        link = "Kelola Uang Kas";
        break;
      case "/MutasiCashDanBank":
        link = "Mutasi Cash Dan Bank";
        break;
      case "/KlasifikasiSupplier":
        link = "Klasifikasi Supplier";
        break;
      case "/KlasifikasiCustomer":
        link = "Klasifikasi Customer";
        break;
      case "/ValidasiTransferCustomer":
        link = "Validasi Transfer";
        break;
      case "/ValidasiPembayaranGiro":
        link = "Validasi Giro";
        break;
      case "/validasiBarang":
        link = "Validasi Barang";
        break;
      case "/validasiSupplier":
        link = "Validasi Supplier";
        break;
      case "/validasiCustomer":
        link = "Validasi Customer";
        break;
      case "/laporanKeuangan":
        link = "Laporan Keuangan";
        break;
      case "/laporanSupplier":
        link = "Laporan Supplier";
        break;
      case "/laporanStok":
        link = "laporan Stok";
        break;
      case "/laporanSalesman":
        link = "laporan Salesman";
        break;
      case "/laporanCustomer":
        link = "laporan Customer";
        break;
      case "/parameterSupervisory":
        link = "Parameter";
        break;
      case "/historyUser":
        link = "Cetak History User";
        break;
      case "/cetakUlangBukti":
        link = "Cetak Ulang Bukti";
        break;
      case "/TransaksiPenjualanLM":
        link = "Jual LM";
        break;
      case "/TransaksiPembelianLM":
        link = "Beli LM";
        break;
      case "/ValidasiStokJualLM":
        link = "Validasi Stok Jual LM";
        break;
      case "/ValidasiStokBeliLM":
        link = "Validasi Stok Beli LM";
        break;
      case "/PembayaranPenjualanLM":
        link = "Bayar Jual LM";
        break;
      case "/PembayaranPembelianLM":
        link = "Bayar Beli LM";
        break;
      case "/TotalanTimbangan":
        link = "Totalan Timbangan";
        break;
      case "/TotalanBarcode":
        link = "Totalan Barcode";
        break;
      case "/SelisihTimbangan":
        link = "Selisih Timbangan";
        break;
      case "/SelisihTimbangRosok":
        link = "Selisih Timbang Rongsok";
        break;

      default:
        link = "AYU GOLD";
    }
    const type = process.env.REACT_APP_TYPE;

    return (
      <>
        <ToastContainer autoClose={2000} />
        <div
          id="main-wrapper"
          data-theme="light"
          data-layout="vertical"
          data-navbarbg="skin6"
          data-sidebartype="mini-sidebar"
          data-sidebar-position="fixed"
          data-header-position="fixed"
          data-boxed-layout="full"
        >
          {!this.props.login ? (
            <div className="auth-wrapper d-flex justify-content-center align-items-center position-relative">
              <div className="auth-box row" style={{ width: "80%" }}>
                <div className="col-lg-12">
                  <Alert key={0} variant="danger" show={this.props.alert}>
                    Ooopps ..
                    <br />
                    Sepertinya ada yang error, Mohon Periksa Kembali Email /
                    Password yang anda masukan
                  </Alert>
                </div>
                <div className="col-lg-7 col-md-5 d-flex align-items-center">
                  <img
                    className="mx-auto my-auto"
                    src={type === "real" ? logo : logo_demo}
                    alt="Logo Perusahaan"
                  />
                </div>
                <div className="col-lg-5 col-md-7 bg-white align-self-center">
                  <div className="p-3 align-self-center">
                    <h2 className="mt-3 text-center">Selamat Datang</h2>
                    <p className="text-center">
                      Silahkan masukan Email dan password untuk Masuk Kedalam
                      sistem.
                    </p>
                    <form className="mt-4 ">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="text-dark" htmlFor="uname">
                              User ID
                            </label>
                            <input
                              className="form-control"
                              id="uname"
                              type="text"
                              placeholder="Masukan Email"
                              onChange={(e) =>
                                this.setState({
                                  user_id: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="text-dark" htmlFor="pwd">
                              Password
                            </label>
                            <input
                              className="form-control"
                              type="password"
                              id="pwd"
                              placeholder="Masukan Password"
                              onChange={(e) =>
                                this.setState({
                                  password: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 text-center mb-5 mt-3">
                          <Link to="/dashboard">
                            <button
                              type="submit"
                              className="btn btn-block btn-dark"
                              onClick={(e) => this.HandleLogin(e)}
                              disabled={this.props.onSend}
                            >
                              {this.props.onSend ? (
                                <span
                                  className="spinner-border spinner-border-sm mr-3"
                                  aria-hidden="true"
                                ></span>
                              ) : null}
                              Masuk
                            </button>
                          </Link>
                        </div>
                        <div className="col-lg-12 text-center">
                          <p style={{ marginBottom: "0px" }}>
                            © NSI - All Right Reserved 2021
                          </p>
                          <h6>{`V ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`}</h6>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <Header topbar="topbar" />
              <Sidebar sidebar="left-sidebar" />
              <div className="page-wrapper">
                <RouteLink />
              </div>
            </>
          )}
          {/* <Fab
              icon={<img src={helper} width={35} alt="FAB" />}
              alwaysShowTitle={true}
              mainButtonStyles={{ backgroundColor: "#5F76E8" }}
              onClick={() => alert("HELLO")}
            >
              <Action
                text="Guide Tour"
                children={<img src={guide} width={35} alt="GUIDE" />}
                onClick={() => this.showTour()}
              />
              <Action
                text="Bersihkan Data"
                children={<img src={bersih} width={35} alt="CLEAN" />}
                onClick={() => alert("THIS IS BUTTON")}
              />
            </Fab> */}
        </div>
      </>
    );
  }
}

App = reduxForm({
  form: "App",
})(App);
export default connect(maptoState, null)(App);
