import {
  AxiosMasterDelete,
  AxiosMasterGet,
  AxiosMasterPost,
} from "../../../Axios";
import { finishSend, progressSend } from "../../../actions/LogicAction";
import { ToastKirim } from "../../../actions/toast";
import { toast } from "react-toastify";
import { hideModal } from "../../../actions/ModalAction";
import Swal from "sweetalert2";

export const GET_PARAMETER_TRANSAKSI = "GET_PARAMETER_TRANSAKSI";

export const getListParameter = () => {
  return (dispatch) => {
    AxiosMasterGet("transaksi-kas/get/all")
      .then((res) =>
        dispatch({
          type: GET_PARAMETER_TRANSAKSI,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_PARAMETER_TRANSAKSI,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const SendParameterTransaksi = (hasil) => {
  return async (dispatch, getState) => {
    let data = {
      kategori_transaksi: hasil.kodeParameter,
    };
    dispatch(progressSend());
    try {
      await toast.promise(
        AxiosMasterPost("transaksi-kas/add", data),
        ToastKirim
      );
      dispatch(finishSend());
      dispatch(hideModal());
      dispatch(getListParameter());
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

export const deleteParameterTransaksi = (kode) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    Swal.fire({
      title: "Anda Yakin ?",
      text: "Anda Akan Menghapus Data Ini ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await toast.promise(
            AxiosMasterDelete("transaksi-kas/delete/" + kode),
            ToastKirim
          );
          dispatch(finishSend());
          dispatch(getListParameter());
        } catch (e) {
          dispatch(finishSend());
        }
      }
    });
    dispatch(finishSend());
  };
};
