import { finishSend, progressSend } from "../../../actions/LogicAction";
import { toast } from "react-toastify";
import { AxiosMasterGet } from "../../../Axios";
import { ToastAmbil } from "../../../actions/toast";
import LaporanStockPerKategoriLokasi from "../component/LaporanStockPerKategoriLokasi";

export const GET_LAPORAN_STOCK_PER_LOKASI = "GET_LAPORAN_STOCK_PER_LOKASI";

export const GetLaporanStockPerLokasi = (
  group_by,
  filter_by,
  kode_kategori,
  kode_lokasi
) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    try {
      const response = await toast.promise(
        AxiosMasterGet(
          "report/stock-barang/" +
            `${group_by}&${filter_by}&${kode_kategori || kode_lokasi || "ALL"}`
        ),
        ToastAmbil
      );
      dispatch({
        type: GET_LAPORAN_STOCK_PER_LOKASI,
        payload: {
          data: response.data,
          kode_lokasi: kode_lokasi,
        },
      });
      dispatch(finishSend());
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

export const PrintLaporanStockPerLokasiPDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanStockPerLokasi = state.laporan.dataLaporanStockPerLokasi;
    LaporanStockPerKategoriLokasi(
      dataLaporanStockPerLokasi.data,
      dataLaporanStockPerLokasi.kode_lokasi
    );
  };
};
