import { AxiosMasterGet } from "../Axios";

export const GET_TABEL_TOTALAN_TIMBANGAN = "GET_TABEL_TOTALAN_TIMBANGAN";
export const GET_TABEL_TOTALAN_BARCODE = "GET_TABEL_TOTALAN_BARCODE";
export const GET_STATUS_TOTALAN = "GET_STATUS_TOTALAN";
export const GET_SELISIH_TOTALAN = "GET_SELISIH_TOTALAN";

export const getTableTotalanTimbangan = () => {
  return (dispatch) => {
    dispatch({
      type: GET_TABEL_TOTALAN_TIMBANGAN,
      payload: {
        data: JSON.parse(localStorage.getItem("totalanTimbangan")),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};
export const getTableTotalanBarcode = () => {
  return (dispatch) => {
    dispatch({
      type: GET_TABEL_TOTALAN_BARCODE,
      payload: {
        data: JSON.parse(localStorage.getItem("totalanBarcode")),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const getStatusTotalan = () => {
  return (dispatch) => {
    AxiosMasterGet("totalan-barang/cek-proses-totalan")
      .then((res) =>
        dispatch({
          type: GET_STATUS_TOTALAN,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_STATUS_TOTALAN,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const getSelisihTimbangan = (no_totalan) => {
  return (dispatch) => {
    AxiosMasterGet(
      `totalan-barang/selisih-totalan/get-selisih-totalan/${no_totalan}`
    )
      .then((res) =>
        dispatch({
          type: GET_SELISIH_TOTALAN,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_SELISIH_TOTALAN,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};
