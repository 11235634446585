import React, { Component } from "react";
import { connect } from "react-redux";
import { finishSend, progressSend } from "../actions/LogicAction";
import { warning } from "../Alert";
import Form24K from "./Component/Form24K";
import { getList24K } from "../DataMaster/24k/redux/24kAction";
import { GetLaporanTambahSaldo24K } from "./laporan_tambah_saldo_24k/redux/laporan_tambah_saldo_24k_action";
import { getcustomerList } from "../DataMaster/customer/redux/customerAction";
import { GetLaporanPenjualan24K } from "./laporan_penjualan_24k/redux/laporan_penjualan_24k_action";
import { GetLaporanGlobal24K } from "./laporan_global_24k/redux/laporan_global_24k_action";
import { purifiedData } from "../helper";

class Laporan24K extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.dispatch(getList24K());
    this.props.dispatch(getcustomerList());
  }

  handleTambahSaldo(data) {
    purifiedData(data);
    this.props.dispatch(progressSend());
    if (data.kodeBarang === undefined) {
      warning("Mohon Pilih Kode Barang");
      this.props.dispatch(finishSend());
      return false;
    }
    this.props.dispatch(
      GetLaporanTambahSaldo24K(data.kodeBarang, data.tglFrom, data.tglTo)
    );
  }

  handlePenjualan(data) {
    purifiedData(data);

    this.props.dispatch(progressSend());
    if (data.kodeCustomer === undefined) {
      warning("Mohon Pilih Kode Customer");
      this.props.dispatch(finishSend());
      return false;
    }
    this.props.dispatch(
      GetLaporanPenjualan24K(data.kodeCustomer, data.tglFrom, data.tglTo)
    );
  }
  handleGlobal(data) {
    purifiedData(data);

    this.props.dispatch(progressSend());
    this.props.dispatch(GetLaporanGlobal24K(data.tglFrom, data.tglTo));
  }
  render() {
    return (
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                    <li className="nav-item">
                      <a
                        href="#tambah-saldo-tab"
                        data-toggle="tab"
                        aria-expanded="false"
                        className="nav-link active"
                      >
                        <i className="mdi mdi-home-variant d-lg-none d-block mr-1" />
                        <span className="d-none d-lg-block">Tambah Saldo</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#penjualan-tab"
                        data-toggle="tab"
                        aria-expanded="true"
                        className="nav-link "
                      >
                        <i className="mdi mdi-account-circle d-lg-none d-block mr-1" />
                        <span className="d-none d-lg-block">Penjualan</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#global-bahan-tab"
                        data-toggle="tab"
                        aria-expanded="true"
                        className="nav-link "
                      >
                        <i className="mdi mdi-account-circle d-lg-none d-block mr-1" />
                        <span className="d-none d-lg-block">Global Bahan</span>
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div className="tab-pane show active" id="tambah-saldo-tab">
                      <Form24K
                        from="TAMBAH SALDO"
                        onSubmit={(data) => this.handleTambahSaldo(data)}
                      />
                    </div>
                    <div className="tab-pane " id="penjualan-tab">
                      <Form24K
                        from="PENJUALAN"
                        onSubmit={(data) => this.handlePenjualan(data)}
                      />
                    </div>

                    <div className="tab-pane" id="global-bahan-tab">
                      <Form24K
                        from="GLOBAL BAHAN"
                        onSubmit={(data) => this.handleGlobal(data)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(Laporan24K);
