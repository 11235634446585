import {AxiosMasterGet, AxiosMasterPost} from "../../../Axios";
import {finishSend, progressSend} from "../../../actions/LogicAction";
import {hideModal, showModal} from "../../../actions/ModalAction";
import {toast} from "react-toastify";
import {ToastKirim} from "../../../actions/toast";
import {getBon, getToday} from "../../../helper";
import {reset} from "redux-form";

export const GET_CLOSE_PENJUALAN = "GET_CLOSE_PENJUALAN";
export const SET_DETAIL_CLOSE_PENJUALAN = "SET_DETAIL_CLOSE_PENJUALAN"

export const getClosePenjualan = () => {
    return async (dispatch, getState) => {
        try {
            const response = await AxiosMasterGet("penjualan/valid");
            dispatch({
                type : GET_CLOSE_PENJUALAN,
                payload  : response.data
            })
        } catch (e) {
        dispatch({
            type : GET_CLOSE_PENJUALAN,
            payload  : []
        })
        dispatch(finishSend())
        }
    }
}

export const seeDetail = (data) => {
    return async (dispatch, getState) => {
        dispatch({
            type : SET_DETAIL_CLOSE_PENJUALAN,
            payload : data.detail_barang,
            selected : data
        })
        dispatch(showModal())
    }
}

export const sendClosePenjualan = () => {
    return async (dispatch, getState) => {
        dispatch(progressSend())
        const state = getState();
        const formDetail = state.form.DetailBarangClosePenjualan.values;
        const selected = state.closePenjualan.selectedClosePenjualan;
        const noBon = "CJ-" + getBon();
        const payload = {
            no_close : noBon,
            tanggal_close : getToday(),
            keterangan :formDetail.keterangan,
            no_penjualan: selected.no_penjualan,
        }
        try {
            await toast.promise(AxiosMasterPost("close-penjualan", payload), ToastKirim)
            dispatch(finishSend())
            dispatch(hideModal())
            dispatch(reset("DetailBarangClosePenjualan"))
            dispatch(getClosePenjualan())
        } catch (e) {

        dispatch(finishSend())
        }
    }
}
