import { toast } from "react-toastify";
import { AxiosMasterGet } from "../../../Axios";
import { ToastAmbil } from "../../../actions/toast";
import { finishSend, progressSend } from "../../../actions/LogicAction";
import LaporanPrintPenerimaanSupplier from "../component/LaporanPrintPenerimaanSupplier";
import { getUserID } from "../../../helper";
import { getToday } from "../../../GlobalComponent/function";
import LaporanPrintPenerimaanSupplierRekap from "../component/LaporanPrintPenerimaanSupplierRekap";

export const GET_LAPORAN_PENERIMAAN_SUPPLIER =
  "GET_LAPORAN_PENERIMAAN_SUPPLIER";

export const GetLaporanPenerimaanSupplier = (
  type,
  kode_supplier,
  tanggal_awal,
  tanggal_akhir
) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    try {
      const response = await toast.promise(
        AxiosMasterGet(
          "report/terima-barang-supplier/" +
            `${type}&${kode_supplier}&${tanggal_awal}&${tanggal_akhir}`
        ),
        ToastAmbil
      );
      dispatch({
        type: GET_LAPORAN_PENERIMAAN_SUPPLIER,
        payload: {
          data: response.data,
          tanggal_awal: tanggal_awal,
          tanggal_akhir: tanggal_akhir,
        },
      });
      dispatch(finishSend());
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

export const ResetLaporanPenerimaanSupplier = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_LAPORAN_PENERIMAAN_SUPPLIER,
      payload: {
        data: [],
        tanggal_awal: "",
        tanggal_akhir: "",
      },
    });
  };
};

export const PrintLaporanPenerimaanSupplierDetailPDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanPenerimaanSupplier =
      state.laporan.dataLaporanPenerimaanSupplier;
    LaporanPrintPenerimaanSupplier(
      dataLaporanPenerimaanSupplier.tanggal_awal,
      dataLaporanPenerimaanSupplier.tanggal_akhir,
      getUserID(),
      getToday(),
      getUserID(),
      dataLaporanPenerimaanSupplier.data
    );
  };
};

export const PrintLaporanPenerimaanSupplierRekapPDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanPenerimaanSupplier =
      state.laporan.dataLaporanPenerimaanSupplier;
    LaporanPrintPenerimaanSupplierRekap(
      dataLaporanPenerimaanSupplier.tanggal_awal,
      dataLaporanPenerimaanSupplier.tanggal_akhir,
      getUserID(),
      getToday(),
      getUserID(),
      dataLaporanPenerimaanSupplier.data
    );
  };
};
