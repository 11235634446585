import React from "react";
import { useSelector } from "react-redux";

function ButtonSelesai(props) {
  const onSend = useSelector((state) => state.provinsi.onSend);
  return (
    <div className={"text-right"}>
      <button
        tabIndex={props.tabIndex ?? 1}
        id={`button_selesai_${props.number ?? 1}`}
        type="submit"
        className={"btn btn-primary"}
        disabled={onSend}
        onClick={props.onClick}
      >
        {onSend ? (
          <span
            className="spinner-border spinner-border-sm mr-3"
            aria-hidden="true"
          />
        ) : null}
        {props.title ?? "Selesai"}
        <i className="fa fa-paper-plane ml-3" />
      </button>
    </div>
  );
}

export default ButtonSelesai;
