import {
  AxiosMasterDelete,
  AxiosMasterGet,
  AxiosMasterPost,
} from "../../../Axios";
import { finishSend, progressSend } from "../../../actions/LogicAction";
import { toast } from "react-toastify";
import { ToastKirim } from "../../../actions/toast";
import { hideModal } from "../../../actions/ModalAction";
import Swal from "sweetalert2";

export const GET_KADAR_LIST = "GET_KADAR_LIST";
export const GET_ALL_KADAR = "GET_ALL_KADAR";
export const EDIT_KADAR = "EDIT_KADAR";

export const getKadarList = (input) => {
  return (dispatch) => {
    AxiosMasterGet("kadar/get/by-type/" + input)
      .then((res) =>
        dispatch({
          type: GET_KADAR_LIST,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_KADAR_LIST,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const getAllKadar = (input) => {
  return (dispatch) => {
    AxiosMasterGet("kadar/get/all")
      .then((res) =>
        dispatch({
          type: GET_ALL_KADAR,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_ALL_KADAR,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};
export const editKadar = (kadar, tipe, status) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_KADAR,
      payload: {
        kadar: kadar,
        tipeKadar: tipe,
        statusAktif: status,
      },
    });
  };
};

export const sendKadarCetak = (hasil, isEdit) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    let data = {
      kadar: hasil.kadar,
      type_kadar: "KADAR-CETAK",
    };
    try {
      await toast.promise(
        AxiosMasterPost(isEdit ? "rate" : "kadar/add", data),
        ToastKirim
      );
      dispatch(finishSend());
      dispatch(hideModal());
      dispatch(getKadarList("KADAR-CETAK"));
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

export const sendKadarJual = (hasil, isEdit) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    let data = {
      kadar: hasil.kadar,
      type_kadar: "KADAR-JUAL",
    };
    try {
      await toast.promise(
        AxiosMasterPost(isEdit ? "rate" : "kadar/add", data),
        ToastKirim
      );
      dispatch(finishSend());
      dispatch(hideModal());
      dispatch(getKadarList("KADAR-JUAL"));
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

export const sendKadarBeli = (hasil, isEdit) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    let data = {
      kadar: hasil.kadar,
      type_kadar: "KADAR-BELI",
    };
    try {
      await toast.promise(
        AxiosMasterPost(isEdit ? "rate" : "kadar/add", data),
        ToastKirim
      );
      dispatch(finishSend());
      dispatch(hideModal());
      dispatch(getKadarList("KADAR-BELI"));
    } catch (e) {
      dispatch(finishSend());
    }
  };
};

export const deleteKadar = (kode, tipe) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    Swal.fire({
      title: "Anda Yakin ?",
      text: "Anda Akan Menghapus Data Ini ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await toast.promise(
            AxiosMasterDelete(`kadar/delete/${kode}&${tipe}`),
            ToastKirim
          );
          dispatch(finishSend());
          dispatch(getKadarList(tipe));
        } catch (e) {
          dispatch(finishSend());
        }
      }
    });
    dispatch(finishSend());
  };
};
