import Swal from "sweetalert2";

export const deleteLocalStorage = (keyRemove) => {
  keyRemove.forEach((e) => {
    localStorage.removeItem(e);
  });
};

export const deleteIndex = (nama, index, dispatch, func) => {
  Swal.fire({
    title: "Anda Yakin ?",
    text: "Anda Akan Menghapus Data Ini ?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      const array = JSON.parse(localStorage.getItem(nama)) || [];
      array.splice(index, 1);
      localStorage.setItem(nama, JSON.stringify(array));
      Swal.fire({
        text: "Berhasil",
        timer: 2000,
        showConfirmButton: false,
        position: "top-right",
        icon: "success",
      }).then(() => dispatch(func));
    }
  });
};
export const deleteIndexUpdatetotalNW = (nama, index, dispatch, func) => {
  return new Promise((resolve, reject) => {
    Swal.fire({
      title: "Anda Yakin ?",
      text: "Anda Akan Menghapus Data Ini ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const array = JSON.parse(localStorage.getItem(nama)) || [];
        array.splice(index, 1);
        localStorage.setItem(nama, JSON.stringify(array));
        Swal.fire({
          text: "Berhasil",
          timer: 2000,
          showConfirmButton: false,
          position: "top-right",
          icon: "success",
        })
          .then(() => dispatch(func))
          .then(resolve("Berhasil"))
          .catch("Gagal");
      }
    });
  });
};

export const deleteIndexUpdateNettoNW = (
  nama,
  index,
  dispatch,
  func,
  nilai,
  func2,
  state
) => {
  Swal.fire({
    title: "Anda Yakin ?",
    text: "Anda Akan Menghapus Data Ini ?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      const array = JSON.parse(localStorage.getItem(nama)) || [];
      array.splice(index, 1);
      localStorage.setItem(nama, JSON.stringify(array));
      var awal = localStorage.getItem("GrandTotalNetto");
      var hasil = parseFloat(awal) - parseFloat(nilai);
      localStorage.setItem("GrandTotalNetto", parseFloat(hasil).toFixed(3));
      Swal.fire({
        text: "Berhasil",
        timer: 2000,
        showConfirmButton: false,
        position: "top-right",
        icon: "success",
      })
        .then(() => dispatch(func))
        .then(() => dispatch(func2));
    }
  });
};
