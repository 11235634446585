import {
  AxiosMasterDelete,
  AxiosMasterGet,
  AxiosMasterPost,
  AxiosMasterPut,
} from "../../../Axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { reset } from "redux-form";
import { hideModal } from "../../../actions/ModalAction";
import { finishSend, progressSend } from "../../../actions/LogicAction";
import { ToastKirim } from "../../../actions/toast";
import { reactive } from "../../../Alert";
import { purifiedData } from "../../../helper";

export const GET_AREA_LIST = "GET_AREA_LIST";
export const EDIT_AREA = "EDIT_AREA";

export const getareaList = () => {
  return (dispatch) => {
    AxiosMasterGet("kecamatan/get/all")
      .then((res) =>
        dispatch({
          type: GET_AREA_LIST,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_AREA_LIST,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const editarea = (kodeArea, namaArea, kodeKota, Status) => {
  let data = {
    kodeArea: kodeArea,
    namaArea: namaArea,
    kodeKota: kodeKota,
    Status: Status,
  };
  console.log(data);
  return (dispatch) => {
    dispatch({
      type: EDIT_AREA,
      payload: {
        kodeArea: kodeArea,
        namaArea: namaArea,
        kodeKota: kodeKota,
        Status: Status,
      },
    });
  };
};

export const sendKecamatan = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.Provinsi.values;
    purifiedData(selected);
    const isi = {
      nama_kecamatan: selected.KodeArea,
      kode_kecamatan: selected.NamaArea,
      kode_kota: selected.kodeKota,
      kode_pos: selected.kodePos,
    };
    try {
      await toast.promise(AxiosMasterPost("kecamatan/add", isi), ToastKirim);
    } catch (err) {
      let error = err.response.data;
      let check = error.includes("deleted");
      check
        ? reactive(
            err,
            selected.KodeArea,
            "kecamatan/reactive/",
            {
              nama_kecamatan: selected.NamaArea,
              kode_kota: selected.kodeKota,
              kode_pos: selected.kodePos,
            },
            "kecamatan/edit/"
          ).then(() => dispatch(getareaList()))
        : console.log();
      dispatch(getareaList());
    }
    dispatch(finishSend());
    dispatch(getareaList());
    dispatch(reset("Provinsi"));
    dispatch(hideModal());
  };
};
export const sendEditKecamatan = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.KotaEdit.values;
    purifiedData(selected);
    let data = {
      nama_kecamatan: selected.NamaArea,
      kode_kota: selected.kodeKota,
      kode_pos: selected.kodePos,
    };
    try {
      await toast.promise(
        AxiosMasterPut("kecamatan/edit/" + selected.KodeArea, data),
        ToastKirim
      );
      dispatch(finishSend());
      dispatch(getareaList());
      dispatch(reset("KotaEdit"));
      dispatch(hideModal());
    } catch (err) {
      dispatch(finishSend());
      dispatch(getareaList());
      dispatch(reset("KotaEdit"));
      dispatch(hideModal());
    }
  };
};

export const deleteKecamatan = (kode) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    Swal.fire({
      title: "Anda Yakin ?",
      text: "Anda Akan Menghapus Data Ini ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await toast.promise(
            AxiosMasterDelete("kecamatan/delete/" + kode),
            ToastKirim
          );
        } catch (e) {
          dispatch(finishSend());
        }
        dispatch(finishSend());
        dispatch(getareaList());
        dispatch(reset("ProvinsiEdit"));
        dispatch(hideModal());
      }
    });
    dispatch(finishSend());
  };
};
