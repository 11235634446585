import {GET_LIST_BAHAN, SET_EDIT_BAHAN} from "../DataMaster/bahan/redux/bahanAction";

const initialState= {
    listBahan : [],
    editDataBahan: []
}

const bahan = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_BAHAN:
            return {
                ...state,
                listBahan : action.payload
            }
        case SET_EDIT_BAHAN:
                return {
                    ...state,
                    editDataBahan: action.payload
                }

        default:
            return state
    }
}

export default bahan;