import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { createNumberMask } from "redux-form-input-masks";
import { renderField, renderFieldSelect } from "../../../renderField";
import { convertKadarCetak, NormalizeGramasi, setFocus } from "../../../helper";

const currencyMask = createNumberMask({
  prefix: "Rp. ",
  suffix: ",-",
  locale: "id-ID",
});

class FormTambahBarangCorrectionNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kodeBarang: [],
      non_aksesoris: "col-lg-12 row",
      aksesoris: "col-lg-12 row d-none",
      stockBruto: 0,
      stockNetto: 0,
    };
  }

  onclick1() {
    this.props.change("netto", this.props.netto);
    this.props.change("noFaktur", this.props.noFaktur);
    this.props.change("gross", this.props.gross);
    this.props.change("discGlobalGr", this.props.discGlobalGr);
    this.props.change(
      "nettoDiscGlobalExt",
      (this.props.netto - this.props.discGlobalGr).toFixed(3)
    );
  }

  pilihBarang(data) {
    let hasil = this.props.dataBarang.find(
      (x) =>
        x.kode_barang === data.split("/")[0] &&
        x.kode_bahan === data.split("/")[1].trim()
    );
    this.props.change("p", hasil.plastik);
    this.props.change("k", hasil.kulit);
    this.props.change("pkg", hasil.pkg);
    this.props.change("qty", hasil.qty);
    this.props.change("bruto", hasil.bruto);
    this.props.change("ongkos_rp", hasil.ongkos_rp);
    this.props.change(
      "kadar",
      convertKadarCetak(hasil.kode_bahan, hasil.kadar_cetak)
    );
    this.props.change("kadarTransaksi", hasil.kadar_jual);
    this.props.change("discGlobalPr", hasil.disc_persen);
    setFocus("Plastik");
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <div className="row">
          <div className="col-md-12 col-lg-12 d-none">
            <Field
              name="noFaktur"
              label={this.props.noFaktur}
              type="text"
              readOnly={true}
              component={renderField}
              defaultValue={this.props.noFaktur}
              normalize={(value) => value && value.toUpperCase()}
            />
          </div>
          <div className="col-md-4 col-lg-4">
            <Field
              name="kodeBarang"
              label="Kode Model"
              component={renderFieldSelect}
              className="form-control"
              onChange={(data) => this.pilihBarang(data.value)}
              options={this.props.dataBarang.map((kode) => {
                return {
                  value: kode.kode_barang + "/ " + kode.kode_bahan,
                  name: kode.kode_barang + "/ " + kode.kode_bahan,
                };
              })}
            />
          </div>
          <div className={this.state.non_aksesoris}>
            <div className="col-md-3 col-lg-3 d-none">
              <Field
                name="namaKategori"
                label="Harga Jual"
                type="number"
                component={renderField}
              />
            </div>
            <div className="col-md-3 col-lg-3">
              <Field
                name="p"
                label="Plastik"
                type="number"
                component={renderField}
                normalize={NormalizeGramasi}
              />
            </div>
            <div className="col-md-3 col-lg-3">
              <Field
                name="k"
                label="Kertas"
                type="number"
                component={renderField}
                normalize={NormalizeGramasi}
              />
            </div>
            <div className="col-md-3 col-lg-3">
              <Field
                name="pkg"
                label="Pkg (Gr)"
                type="number"
                component={renderField}
                normalize={NormalizeGramasi}
              />
            </div>
            <div className="col-md-3 col-lg-3">
              <Field
                name="qty"
                label="Qty"
                type="number"
                component={renderField}
              />
            </div>
            <div className="col-md-3 col-lg-3">
              <Field
                name="bruto"
                label="Berat (Gr)"
                type="number"
                component={renderField}
                onChange={this.onclick1()}
                normalize={NormalizeGramasi}

                // onChange={this.setBruto()}
                // onBlur={this.setBruto()}
              />
            </div>
            <div className="col-md-3 col-lg-3">
              <Field
                name="kadar"
                label="Kadar"
                type="text"
                component={renderField}
                readOnly
              />
            </div>
            <div className="col-md-3 col-lg-3">
              <Field
                name="kadarTransaksi"
                label="Harga"
                type="number"
                component={renderField}
                readOnly
              />
            </div>
            <div className="col-md-3 col-lg-3">
              <Field
                name="netto"
                label="Netto (Gr)"
                type="number"
                component={renderField}
                readOnly={true}
              />
            </div>
            <div className="col-md-3 col-lg-3">
              <Field
                name="gross"
                label="Gross (Gr)"
                type="number"
                component={renderField}
                readOnly={true}
              />
            </div>
            <div className="col-md-3 col-lg-3">
              <Field
                name="discGlobalPr"
                label="Diskon (%)"
                type="number"
                component={renderField}
              />
            </div>
            <div className="col-md-3 col-lg-3">
              <Field
                name="discGlobalGr"
                label="Diskon (Gr)"
                type="number"
                component={renderField}
                readOnly={true}
              />
            </div>
            <div className="col-md-3 col-lg-3">
              <Field
                name="nettoDiscGlobalExt"
                label="Netto - Diskon (Gr)"
                type="number"
                component={renderField}
                readOnly={true}
              />
            </div>
            <div className="col-md-3 col-lg-3">
              <label htmlFor="">Ongkos (Rp)</label>
              <Field
                name="ongkos_rp"
                component="input"
                type="tel"
                className="form-control"
                {...currencyMask}
              />
            </div>
          </div>
          <div className="col-lg-12 col-md-12">
            <div className="text-right">
              <button
                type="submit"
                disabled={this.props.onSend}
                className="btn btn-primary"
              >
                {this.props.onSend ? (
                  <span
                    className="spinner-border spinner-border-sm mr-3"
                    aria-hidden="true"
                  />
                ) : null}
                Simpan
                <i className="fa fa-paper-plane ml-3" />
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

FormTambahBarangCorrectionNote = reduxForm({
  form: "FormTambahBarangCorrectionNote",
  enableReinitialize: true,
})(FormTambahBarangCorrectionNote);
const selector = formValueSelector("FormTambahBarangCorrectionNote"); // <-- same as form name
export default connect((state) => {
  const { bruto, kadarTransaksi, pkg, discGlobalPr } = selector(
    state,
    "bruto",
    "kadarTransaksi",
    "pkg",
    "discGlobalPr"
  );
  return {
    netto: (
      parseFloat(bruto || 0) *
      (parseFloat(kadarTransaksi || 0) / 100)
    ).toFixed(3),
    gross: parseFloat(bruto || 0) + parseFloat(pkg || 0),
    discGlobalGr: (
      parseFloat(bruto || 0) *
      (parseFloat(discGlobalPr || 0) / 100)
    ).toFixed(3),
    dataBarang: state.correctionNote.listItemsCorrectionNote,
    onSend: state.provinsi.onSend,
    initialValues: {
      discGlobalPr: 0,
      p: 0,
      k: 0,
      pkg: 0,
      bruto: 0,
    },
    bruto: bruto,
  };
})(FormTambahBarangCorrectionNote);
