import { GET_KIRIM_STOCK_VALID, GET_LIST_KIRIM_STOCK, SET_BRUTO_EDIT_BARANG_KIRIM_STOCK } from "../actions/kirimStock";


const initialState = {
    list_barang : [],
    list_kirim_stock_valid : [],
    bruto: 0,
    kodeBarang: ""
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case GET_LIST_KIRIM_STOCK:
            return {
                list_barang : action.payload.data
            }
        case GET_KIRIM_STOCK_VALID:
            return {
                list_kirim_stock_valid : action.payload.data
            }
        case SET_BRUTO_EDIT_BARANG_KIRIM_STOCK:
            return {
                ...state,
                bruto : action.payload.data,
                kodeBarang: action.payload.kode_model
            }
        default:
            return state
    }
}

export default reducer;
