import { finishSend, progressSend } from "../../../actions/LogicAction";
import { toast } from "react-toastify";
import { AxiosMasterGetWithParams } from "../../../Axios";
import { ToastAmbil } from "../../../actions/toast";
import LaporanTambahSaldo24K from "../component/LaporanTambahSaldo24K";

export const GET_LAPORAN_TAMBAH_SALDO_24K = "GET_LAPORAN_TAMBAH_SALDO_24K";

export const GetLaporanTambahSaldo24K = (kodeBarang, startDate, endDate) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    try {
      const response = await toast.promise(
        AxiosMasterGetWithParams(`report/24K/tambah-saldo`, {
          kode_barang: kodeBarang,
          start_date: startDate,
          end_date: endDate,
        }),
        ToastAmbil
      );
      dispatch({
        type: GET_LAPORAN_TAMBAH_SALDO_24K,
        payload: {
          data: response.data,
          kode_barang: kodeBarang,
          tgl_from: startDate,
          tgl_to: endDate,
        },
      });
      dispatch(finishSend());
    } catch (e) {
      dispatch({
        type: GET_LAPORAN_TAMBAH_SALDO_24K,
        payload: {
          data: [],
          kode_barang: kodeBarang,
          tgl_from: startDate,
          tgl_to: endDate,
        },
      });
      dispatch(finishSend());
    }
  };
};

export const PrintLaporanTambahSaldo24KPDF = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const dataLaporanTambahSaldo24k = state.laporan.dataLaporanTambahSaldo24k;
    LaporanTambahSaldo24K(dataLaporanTambahSaldo24k.data);
  };
};
