export const GET_DATA_PINDAH_BARANG = "GET_DATA_PINDAH_BARANG";
export const GET_DATA_EDIT_PINDAH_BARANG = "GET_DATA_EDIT_PINDAH_BARANG";
export const EDIT_PINDAH_BARANG = "EDIT_PINDAH_BARANG";

export const getListPindahBarang = () => {
  return (dispatch) => {
    dispatch({
      type: GET_DATA_PINDAH_BARANG,
      payload: {
        data: JSON.parse(localStorage.getItem("pindahBarang")),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const getEditPindahBarang = () => {
  var data = JSON.parse(localStorage.getItem("editPindahBarang")) || [];
  return (dispatch) => {
    dispatch({
      type: GET_DATA_EDIT_PINDAH_BARANG,
      payload: {
        dariIsi: data.kodeLokasiAsal,
        ke: data.kodeLokasiTujuan,
        tanggal: data.tanggal,
        keterangan: data.keterangan,
        noBon: data.noBon,
      },
    });
  };
};

export const EditPindahBarang = (data) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_PINDAH_BARANG,
      payload: {
        data: data,
      },
    });
  };
};
