import { getLocal, saveLocal } from "../../../encrypt";
import {
  finishSend,
  isExist,
  progressSend,
} from "../../../actions/LogicAction";
import { change, reset } from "redux-form";
import { hideModal } from "../../../actions/ModalAction";
import { AxiosMasterGet, AxiosMasterPost } from "../../../Axios";
import { toast } from "react-toastify";
import { ToastKirim } from "../../../actions/toast";
import { getBon, multipleDeleteLocal, purifiedData } from "../../../helper";
import { getToday } from "../../../GlobalComponent/function";

export const GET_CORRECTION_NOTE_TEMP = "GET_CORRECTION_NOTE_TEMP";
export const SET_LIST_BON = "SET_LIST_BON";
export const GET_LIST_CORRECTION_NOTE = "GET_LIST_CORRECTION_NOTE";
export const GET_ITEM_LIST_CORRECTION_NOTE = "GET_ITEM_LIST_CORRECTION_NOTE";

export const getListItemsCorrectionNote = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_ITEM_LIST_CORRECTION_NOTE,
      payload: await getLocal("selectedItemsCorrectioNote"),
    });
  };
};

export const getListCorrectionNote = () => {
  return async (dispatch, getState) => {
    const response = await AxiosMasterGet("penjualan/valid");
    // const listPenjualan = [{
    //     no_bon : "ABCDE",
    //     no_penjualan : "KAJFJHA-QURIVMN-99175KD77",
    //     kode_lokasi : "PUSAT",
    //     kode_sales: "SLS1",
    //     nama_toko : "OCTA MEMBANGUN",
    //     kode_customer : "CS1",
    //     tanggal : "2021-12-19",
    //     total_netto : 100,
    //     total_bruto: 200,
    //     detail_barang :[
    //         {
    //             kode_barang: 'BRG01',
    //             kode_bahan: "ALLOY",
    //             kode_barcode: "-",
    //             qty: 1,
    //             plastik: 1,
    //             kulit: 1,
    //             gross: 5,
    //             pkg: 2,
    //             bruto: 7,
    //             kadar_cetak: 80,
    //             kadar_jual: 90,
    //             netto: 6,
    //             disc_persen: 0,
    //             disc_gram: 0,
    //             netto_disc: 6,
    //             ongkos_rp: 0,
    //         },
    //         {
    //             kode_barang: 'BRG02',
    //             kode_bahan: "ALLOY",
    //             kode_barcode: "-",
    //             qty: 2,
    //             plastik: 2,
    //             kulit: 2,
    //             gross: 3,
    //             pkg: 2,
    //             bruto: 5,
    //             kadar_cetak: 80,
    //             kadar_jual: 90,
    //             netto: 4.75,
    //             disc_persen: 0,
    //             disc_gram: 0,
    //             netto_disc: 6,
    //             ongkos_rp: 0,
    //         }
    //     ]
    // }];
    dispatch({
      type: GET_LIST_CORRECTION_NOTE,
      payload: response.data,
    });
  };
};

export const getCorrectionNoteTemp = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_CORRECTION_NOTE_TEMP,
      payload: await getLocal("correctionNoteTemp"),
    });
  };
};

export const saveCorrectionNoteTemp = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const selected = state.form.FormTambahBarangCorrectionNote.values;
    purifiedData(selected);
    const payload = {
      kode_barang: selected.kodeBarang.split("/")[0],
      kode_bahan: selected.kodeBarang.split("/")[1].trim(),
      kode_barcode: selected.kode_barcode || "-",
      qty: selected.qty,
      plastik: selected.p,
      kulit: selected.k,
      gross: selected.gross,
      pkg: selected.pkg,
      bruto: selected.bruto,
      kadar_cetak: String(selected.kadar),
      kadar_jual: selected.kadarTransaksi,
      netto: selected.netto,
      disc_persen: selected.discGlobalPr,
      disc_gram: selected.discGlobalGr,
      netto_disc: selected.nettoDiscGlobalExt,
      ongkos_rp: selected.ongkos_rp,
    };
    await isExist({
      target: "kode_barang",
      target2: "kode_bahan",
      name: "correctionNoteTemp",
      payload: payload,
      value: selected.kodeBarang.split("/")[0],
      value2: selected.kodeBarang.split("/")[1].trim(),
    });
    const result = await getLocal("correctionNoteTemp");
    const totalNW = result
      .map((x) => parseFloat(x.netto_disc))
      .reduce((a, b) => a + b, 0);
    dispatch(change("TransaksiPenjualan", "totalNW", totalNW));
    dispatch(change("TransaksiPenjualan", "disc_global", 0));
    dispatch(change("TransaksiPenjualan", "disc_global_gr", 0));
    dispatch(change("TransaksiPenjualan", "grand_total_nw", totalNW));
    dispatch(getCorrectionNoteTemp());
    dispatch(hideModal());
    dispatch(finishSend());
  };
};

export const deleteCorrectionNoteTemp = (nama, index, getFunction) => {
  return async (dispatch) => {
    dispatch(progressSend());
    const data = await getLocal(nama);
    data.splice(index, 1);
    await saveLocal(nama, data);
    const result = await getLocal("correctionNoteTemp");
    const totalNW = result
      .map((x) => parseFloat(x.netto_disc))
      .reduce((a, b) => a + b, 0);
    dispatch(change("TransaksiPenjualan", "totalNW", totalNW));
    dispatch(change("TransaksiPenjualan", "disc_global", 0));
    dispatch(change("TransaksiPenjualan", "disc_global_gr", 0));
    dispatch(change("TransaksiPenjualan", "grand_total_nw", totalNW));
    dispatch(getCorrectionNoteTemp());
    dispatch(finishSend());
  };
};

export const setSelectedBon = (data) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    localStorage.removeItem("selectedItemsCorrectioNote");
    await saveLocal("selectedItemsCorrectioNote", data.detail_barang);
    dispatch(change("CorrectionNote", "no_penjualan", data.no_penjualan));
    dispatch(change("CorrectionNote", "kode_customer", data.kode_customer));
    dispatch(getListItemsCorrectionNote());
    dispatch(hideModal());
    dispatch(finishSend());
  };
};

export const sendCorrectionNote = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.CorrectionNote.values;
    const payload = {
      no_correction_note: selected.no_correction_note,
      tgl_correction_note: selected.tanggal,
      kode_customer: selected.kode_customer,
      no_penjualan: selected.no_penjualan,
      detail_barang: await getLocal("correctionNoteTemp"),
    };
    try {
      await toast.promise(
        AxiosMasterPost("correction-note", payload),
        ToastKirim
      );
      multipleDeleteLocal(["correctionNoteTemp", "selectedItemsCorrectioNote"]);
      dispatch(reset("CorrectionNote"));
      dispatch(change("CorrectionNote", "tanggal", getToday()));
      dispatch(change("CorrectionNote", "no_correction_note", "CN" + getBon()));
      dispatch(getCorrectionNoteTemp());
      dispatch(getListItemsCorrectionNote());
      dispatch(finishSend());
    } catch (e) {
      dispatch(finishSend());
    }
  };
};
