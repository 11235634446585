import { toast } from "react-toastify";
import { reset } from "redux-form";
import {
  finishSend,
  isExist,
  progressSend,
} from "../../../actions/LogicAction";
import { hideModal } from "../../../actions/ModalAction";
import { AxiosMasterPost } from "../../../Axios";
import { getLocal } from "../../../encrypt";
import { getBon, multipleDeleteLocal, purifiedData } from "../../../helper";
import { ToastKirim } from "../../../actions/toast";

export const GET_TOTALAN_TIMBANGAN_TEMP = "GET_TOTALAN_TIMBANGAN_TEMP";

export const getTotalanTimbangan = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_TOTALAN_TIMBANGAN_TEMP,
      payload: await getLocal("TotalanTimbaganTemp"),
    });
  };
};

export const saveTotalTimbanganTemp = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.FormTotalanTimbangan.values;
    purifiedData(selected);
    const barang = selected.kode_barang.split("/");
    selected.kode_barang = barang[0];
    selected.kode_bahan = barang[1];
    await isExist({
      name: "TotalanTimbaganTemp",
      payload: selected,
      target: "kode_barang",
      target2: "kode_bahan",
      value: selected.kode_barang,
      value2: selected.kode_bahan,
    });
    dispatch(hideModal());
    dispatch(finishSend());
    dispatch(reset("FormTotalanTimbangan"));
    dispatch(getTotalanTimbangan());
  };
};

export const sendTotalanTimbang = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.TotalanTimbangan.values;
    purifiedData(selected);
    const noBon = "SO-" + getBon();
    const payload = {
      no_opname: noBon,
      tanggal: selected.tanggal,
      kode_lokasi: selected.kode_lokasi,
      detail_barang: await getLocal("TotalanTimbaganTemp"),
    };
    try {
      await toast.promise(
        AxiosMasterPost("stock-opname/", payload),
        ToastKirim
      );
      dispatch(finishSend());
      multipleDeleteLocal(["TotalanTimbaganTemp"]);
      dispatch(getTotalanTimbangan());
      dispatch(reset("TotalanTimbangan"));
    } catch (error) {
      dispatch(finishSend());
    }
  };
};
