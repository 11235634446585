import {
  AxiosMasterDelete,
  AxiosMasterGet,
  AxiosMasterPost,
  AxiosMasterPut,
} from "../../../Axios";
import { finishSend, progressSend } from "../../../actions/LogicAction";
import { toast } from "react-toastify";
import { ToastKirim } from "../../../actions/toast";
import { gagal, reactive } from "../../../Alert";
import { reset } from "redux-form";
import { hideModal } from "../../../actions/ModalAction";
import Swal from "sweetalert2";

export const GET_LOKASI_LIST = "GET_LOKASI_LIST";
export const EDIT_LOKASI = "EDIT_LOKASI";
export const GET_LOKASI_MIX = "GET_LOKASI_MIX";

export const getLokasi = () => {
  return (dispatch) => {
    AxiosMasterGet("lokasi/get/all")
      .then((res) =>
        dispatch({
          type: GET_LOKASI_LIST,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_LOKASI_LIST,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const getLokasiPindah = () => {
  return (dispatch) => {
    AxiosMasterGet("/lokasi/get/lokasi-sales")
      .then((res) =>
        dispatch({
          type: GET_LOKASI_MIX,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_LOKASI_MIX,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const editLokasi = (kodeLokasi, namaLokasi) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_LOKASI,
      payload: {
        kodeLokasi: kodeLokasi,
        namaLokasi: namaLokasi,
      },
    });
  };
};

export const sendLokasi = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.Provinsi.values;
    const data = {
      kode_lokasi: selected.KodeLokasi,
      nama_lokasi: selected.NamaLokasi,
    };
    try {
      await toast.promise(AxiosMasterPost("lokasi/add", data), ToastKirim);
    } catch (err) {
      let error = err.response.data;
      let check = error.includes("deleted");
      check
        ? reactive(
            err,
            selected.KodeLokasi,
            "lokasi/reactive/",
            { nama_lokasi: selected.NamaLokasi },
            "lokasi/edit/"
          ).then(() => dispatch(getLokasi()))
        : gagal("Data Gagal Ditambahkan");
      dispatch(getLokasi());
    }
    dispatch(finishSend());
    dispatch(getLokasi());
    dispatch(reset("Provinsi"));
    dispatch(hideModal());
  };
};

export const sendEditLokasi = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.LokasiEdit.values;
    let data = {
      nama_lokasi: selected.NamaLokasi,
    };
    try {
      await toast.promise(
        AxiosMasterPut("lokasi/edit/" + selected.KodeLokasi, data),
        ToastKirim
      );
      dispatch(finishSend());
      dispatch(getLokasi());
      dispatch(reset("LokasiEdit"));
      dispatch(hideModal());
    } catch (err) {
      dispatch(finishSend());
      dispatch(getLokasi());
      dispatch(reset("LokasiEdit"));
      dispatch(hideModal());
    }
  };
};

export const deleteLokasi = (kode) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    Swal.fire({
      title: "Anda Yakin ?",
      text: "Anda Akan Menghapus Data Ini ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          dispatch(progressSend());
          await toast.promise(
            AxiosMasterDelete("lokasi/delete/" + kode),
            ToastKirim
          );
          dispatch(finishSend());
          dispatch(getLokasi());
          dispatch(reset("LokasiEdit"));
          dispatch(hideModal());
        } catch (e) {
          dispatch(finishSend());
        }
      }
    });
    dispatch(finishSend());
  };
};
