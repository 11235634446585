const validasiHeadMutasiBarang = (value) => {
  const errors = {};
  if (!value.tanggal) {
    errors.tanggal = "Tidak Boleh kosong";
  }
  if (!value.noMutasi) {
    errors.noMutasi = "Tidak Boleh kosong";
  }
  if (!value.bonMutasi) {
    errors.bonMutasi = "Tidak Boleh kosong";
  }
  if (!value.kategori) {
    errors.kategori = "Tidak Boleh kosong";
  }

  if (!value.lokasi) {
    errors.lokasi = "Tidak Boleh kosong";
  }
  return errors;
};

export default validasiHeadMutasiBarang;
